import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { CompanyType } from "src/app/apis/model";
import { Annotate } from "src/app/components/atoms";
import {
  Category1EmissionByClassificationChart,
  ChartAndTableView,
  DataField,
} from "src/app/components/organisms";
import { Category1EmissionByClassificationType } from "src/app/models";

export type Category1EmissionByClassificationTemplateProps = {
  companyType: Exclude<CompanyType, "user-company">;
  isProcessing: boolean;

  // グラフ用Props
  chartData: Category1EmissionByClassificationType[];

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

export const Category1EmissionByClassificationTemplate: React.FC<
  Category1EmissionByClassificationTemplateProps
> = (props) => {
  return (
    <ChartAndTableView
      title="カテゴリ1　工場別 × 分類別（年）"
      subtitle={
        <Annotate>
          TORCH算定結果のみを表示。自社算定結果を入力した工場情報は表示されません。
        </Annotate>
      }
      chartElement={
        <Category1EmissionByClassificationChart
          data={props.chartData}
          companyType={props.companyType}
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};
