/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import { rest } from "msw";

import { messages } from "src/app/configs";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserProcurementAmountInputMock = () => [
  /**
   * 調達量一覧APIを呼び出される(一時保存フラグがtrue)
   */
  ...userProcurementTrueMock,
  /**
   * 調達量一覧APIを呼び出される(一時保存フラグがfalse)
   */
  //...userProcurementFalseMock,
  /**
   * 調達量一覧APIを呼び出される(0件)
   */
  // ...userProcurement0Mock,
  /**
   * ユーザ調達量一時保存APIを呼び出される
   */
  ...userTempProcurementMock,
  /**
   * 調達量更新APIを呼び出される
   */
  ...userProcurementMock,
  /**
   * 調達量一覧APIのエラー:network
   */
  // ...userProcurementNetworkErrorMock,
  /**
   * 調達量一覧APIのエラー:timeout
   */
  // ...userProcurementTimeoutErrorMock,
  /**
   * 調達量一覧APIのエラー:ERR000002
   */
  // ...userProcurementERR000002Mock,
  /**
   * 調達量一覧APIのエラー:ERR000003
   */
  // ...userProcurementERR000003Mock,
  /**
   * 調達量一覧APIのエラー:unknown
   */
  // ...userProcurementUnknownErrorMock,
  /**
   * 調達量一覧APIのエラー:ERR000000
   */
  // ...userProcurementERR000000Mock,
  /**
   * 調達量一覧APIのエラー:ERR000001
   */
  // ...userProcurementERR000001Mock,
  /**
   * 調達量一覧APIのエラー:ERR000004
   */
  // ...userProcurementERR000004Mock,

  /**
   * ユーザ調達量一時保存APIのエラー:network
   */
  // ...userTempProcurementNetworkErrorMock,
  /**
   * ユーザ調達量一時保存APIのエラー:timeout
   */
  // ...userTempProcurementTimeoutErrorMock,
  /**
   * ユーザ調達量一時保存APIのエラー:unknown
   */
  // ...userTempProcurementUnknownErrorMock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR000000
   */
  // ...userTempProcurementERR000000Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR000001
   */
  // ...userTempProcurementERR000001Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR000004
   */
  // ...userTempProcurementERR000004Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR028000
   */
  // ...userTempProcurementERR028000Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR000002
   */
  // ...userTempProcurementERR000002Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR000003
   */
  // ...userTempProcurementERR000003Mock,
  /**
   * ユーザ調達量一時保存APIのエラー:ERR028001
   */
  // ...userTempProcurementERR028001Mock,

  /**
   * 調達量更新APIのエラー:network
   */
  // ...userProcurementPutNetworkErrorMock,
  /**
   * 調達量更新APIのエラー:timeout
   */
  // ...userProcurementPutTimeoutErrorMock,
  /**
   * 調達量更新APIののエラー:unknown
   */
  // ...userProcurementPutUnknownErrorMock,
  /**
   * 調達量更新APIののエラー:ERR000000
   */
  // ...userProcurementPutERR000000Mock,
  /**
   * 調達量更新APIののエラー:ERR000001
   */
  // ...userProcurementPutERR000001Mock,
  /**
   * 調達量更新APIののエラー:ERR000004
   */
  // ...userProcurementPutERR000004Mock,
  /**
   * 調達量更新APIののエラー:ERR013000
   */
  // ...userProcurementPutERR013000Mock,
  /**
   * 調達量更新APIののエラー:ERR000002
   */
  // ...userProcurementPutERR000002Mock,
  /**
   * 調達量更新APIののエラー:ERR000003
   */
  // ...userProcurementPutERR000003Mock,
  /**
   * 調達量更新APIのエラー:ERR013001
   */
  // ...userProcurementPutERR013001Mock,
];

const userProcurementTrueMock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        companyId: "hokkaido",
        isTemporary: true,
        version: 0,
        userFactories: [
          {
            factoryId: "factory01",
            factoryType: "user",
            factoryName: "ユーザー 工場1",
            factoryNameForSelf: "ユーザー工場1",
            factoryNameForOthers: "ユーザー1",
            sortOrder: 0,
            routes: [
              {
                routeId: "1-1",
                routeName: "11",
                productType: "productStandard1",
                productName: "350ml× 24らくもてケース",
                productShortName: "規格１",
                amount: 5,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro2",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "yashio",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "sapporo",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
              {
                routeId: "1-2",
                routeName: "22",
                productType: "productStandard2",
                productName: "500ml× 24らくもてケース",
                productShortName: "規格2",
                amount: 10,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
            ],
          },
          {
            factoryId: "factory02",
            factoryType: "user",
            factoryName: "ユーザー 工場2",
            factoryNameForSelf: "ユーザー工場2",
            factoryNameForOthers: "ユーザー2",
            sortOrder: 0,
            routes: [
              {
                routeId: "parsing",
                routeName: "UDP",
                productType: "Division",
                productName: "SSL",
                productShortName: "Jazz",
                amount: 1234567,
                supplierFactories: [
                  {
                    companyId: "Pickup",
                    companyName: "zero",
                    factoryId: "Ruthenium",
                    factoryType: "process",
                    factoryName: "Nebraska",
                    factoryNameForOthers: "fortunately",
                    productType: "Car",
                    productName: "ultimate",
                    productShortName: "Buckinghamshire",
                    productPurpose: "frontLinear",
                  },
                ],
              },
            ],
          },
        ],
      })
    );
  }),
];

const userProcurementFalseMock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        companyId: "hokkaido",
        isTemporary: false,
        version: 0,
        userFactories: [
          {
            factoryId: "factory01",
            factoryType: "user",
            factoryName: "ユーザー 工場1",
            factoryNameForSelf: "ユーザー工場1",
            factoryNameForOthers: "ユーザー1",
            sortOrder: 0,
            routes: [
              {
                routeId: "1-1",
                routeName: "11",
                productType: "productStandard1",
                productName: "350ml× 24らくもてケース",
                productShortName: "規格１",
                amount: 5,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro2",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "yashio",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "sapporo",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
              {
                routeId: "1-2",
                routeName: "22",
                productType: "productStandard2",
                productName: "500ml× 24らくもてケース",
                productShortName: "規格2",
                amount: 10,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
            ],
          },
          {
            factoryId: "factory02",
            factoryType: "user",
            factoryName: "ユーザー 工場2",
            factoryNameForSelf: "ユーザー工場2",
            factoryNameForOthers: "ユーザー2",
            sortOrder: 0,
            routes: [
              {
                routeId: "parsing",
                routeName: "UDP",
                productType: "Division",
                productName: "SSL",
                productShortName: "Jazz",
                amount: 372191,
                supplierFactories: [
                  {
                    companyId: "Pickup",
                    companyName: "zero",
                    factoryId: "Ruthenium",
                    factoryType: "process",
                    factoryName: "Nebraska",
                    factoryNameForOthers: "fortunately",
                    productType: "Car",
                    productName: "ultimate",
                    productShortName: "Buckinghamshire",
                    productPurpose: "frontLinear",
                  },
                ],
              },
            ],
          },
        ],
      })
    );
  }),
];

const userProcurement0Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        companyId: "hokkaido",
        isTemporary: true,
        version: 0,
        userFactories: [],
      })
    );
  }),
];

const userTempProcurementMock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(201, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        companyId: "hokkaido",
        isTemporary: true,
        version: 0,
        userFactories: [
          {
            factoryId: "factory01",
            factoryType: "user",
            factoryName: "ユーザー 工場1",
            factoryNameForSelf: "ユーザー工場1",
            factoryNameForOthers: "ユーザー1",
            sortOrder: 0,
            routes: [
              {
                routeId: "1-1",
                routeName: "11",
                productType: "productStandard1",
                productName: "350ml× 24らくもてケース",
                productShortName: "規格１",
                amount: 5,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro2",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "yashio",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "sapporo",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
              {
                routeId: "1-2",
                routeName: "22",
                productType: "productStandard2",
                productName: "500ml× 24らくもてケース",
                productShortName: "規格2",
                amount: 10,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
            ],
          },
          {
            factoryId: "factory02",
            factoryType: "user",
            factoryName: "ユーザー 工場2",
            factoryNameForSelf: "ユーザー工場2",
            factoryNameForOthers: "ユーザー2",
            sortOrder: 0,
            routes: [
              {
                routeId: "parsing",
                routeName: "UDP",
                productType: "Division",
                productName: "SSL",
                productShortName: "Jazz",
                amount: 1234567,
                supplierFactories: [
                  {
                    companyId: "Pickup",
                    companyName: "zero",
                    factoryId: "Ruthenium",
                    factoryType: "process",
                    factoryName: "Nebraska",
                    factoryNameForOthers: "fortunately",
                    productType: "Car",
                    productName: "ultimate",
                    productShortName: "Buckinghamshire",
                    productPurpose: "frontLinear",
                  },
                ],
              },
            ],
          },
        ],
      })
    );
  }),
];

const userProcurementMock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(201, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2021", "yyyy"),
        companyId: "hokkaido",
        isTemporary: true,
        version: 0,
        userFactories: [
          {
            factoryId: "factory01",
            factoryType: "user",
            factoryName: "ユーザー 工場1",
            factoryNameForSelf: "ユーザー工場1",
            factoryNameForOthers: "ユーザー1",
            sortOrder: 0,
            routes: [
              {
                routeId: "1-1",
                routeName: "11",
                productType: "productStandard1",
                productName: "350ml× 24らくもてケース",
                productShortName: "規格１",
                amount: 5,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro2",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "yashio",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "sapporo",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
              {
                routeId: "1-2",
                routeName: "22",
                productType: "productStandard2",
                productName: "500ml× 24らくもてケース",
                productShortName: "規格2",
                amount: 10,
                supplierFactories: [
                  {
                    companyId: "process-company",
                    companyName: "加工会社",
                    factoryId: "tonegawa",
                    factoryType: "process",
                    factoryName: "加工会社 利根川工場",
                    factoryNameForSelf: "加工 利根川工場",
                    factoryNameForOthers: "加工利根川",
                    productType: "productStandard1",
                    productName: "350ml× 24らくもてケース",
                    productShortName: "規格１",
                    productPurpose: undefined,
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "nayoro1",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯1",
                    factoryNameForSelf: "製紙A 中芯1",
                    factoryNameForOthers: "中芯A1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyA",
                    companyName: "製紙会社A",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社A 中芯2",
                    factoryNameForSelf: "製紙A 中芯2",
                    factoryNameForOthers: "中芯A2",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "nayoro3",
                    factoryType: "paper",
                    factoryName: "製紙会社B 中芯1",
                    factoryNameForSelf: "製紙B 中芯1",
                    factoryNameForOthers: "中芯B1",
                    productType: "generalCorrugationMedium",
                    productName: "一般中芯",
                    productShortName: "一般中芯",
                    productPurpose: "corrugationMedium",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 表ライナー工場",
                    factoryNameForSelf: "製紙B 表ライナー工場",
                    factoryNameForOthers: "表ライナーB",
                    productType: "cLinear",
                    productName: "Cライナー",
                    productShortName: "Cライナー",
                    productPurpose: "frontLinear",
                  },
                  {
                    companyId: "paper-companyB",
                    companyName: "製紙会社B",
                    factoryId: "other",
                    factoryType: "paper",
                    factoryName: "製紙会社B 裏ライナー工場",
                    factoryNameForSelf: "製紙B 裏ライナー工場",
                    factoryNameForOthers: "裏ライナーB",
                    productType: "kLinear",
                    productName: "Kライナー",
                    productShortName: "Kライナー",
                    productPurpose: "backLinear",
                  },
                ],
              },
            ],
          },
          {
            factoryId: "factory02",
            factoryType: "user",
            factoryName: "ユーザー 工場2",
            factoryNameForSelf: "ユーザー工場2",
            factoryNameForOthers: "ユーザー2",
            sortOrder: 0,
            routes: [
              {
                routeId: "parsing",
                routeName: "UDP",
                productType: "Division",
                productName: "SSL",
                productShortName: "Jazz",
                amount: 1234567,
                supplierFactories: [
                  {
                    companyId: "Pickup",
                    companyName: "zero",
                    factoryId: "Ruthenium",
                    factoryType: "process",
                    factoryName: "Nebraska",
                    factoryNameForOthers: "fortunately",
                    productType: "Car",
                    productName: "ultimate",
                    productShortName: "Buckinghamshire",
                    productPurpose: "frontLinear",
                  },
                ],
              },
            ],
          },
        ],
      })
    );
  }),
];

const category4Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            co2Emission: 1500.15,
            tonKmBasedCo2Intensity: 0.01,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.01,
                tonKmBasedCo2Intensity: 0.02,
              },
              {
                destinationId: "b",
                factoryId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.03,
                tonKmBasedCo2Intensity: 0.04,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 0.6,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.11,
                tonKmBasedCo2Intensity: 0.12,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.13,
                tonKmBasedCo2Intensity: 0.14,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            co2Emission: 2250,
            tonKmBasedCo2Intensity: 0.8,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.21,
                tonKmBasedCo2Intensity: 0.22,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.23,
                tonKmBasedCo2Intensity: 0.24,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 調達量一覧API */
const paperSupplierMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.2012,
                amountBasedCo2Intensity: 0.2458,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.14,
                weightBasedCo2Intensity: 0.2752,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.2146,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1463,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takasaki",
            factoryName: "YY株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "Y・高崎（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1463,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.2146,
              },
              {
                classificationType: "dye",
                co2Emission: 400.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社 八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1263,
              },
            ],
            byClassification: [
              {
                classificationType: "privatePower",
                co2Emission: 1050.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1346,
              },
              {
                classificationType: "waste",
                co2Emission: 500.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1467,
              },
              {
                classificationType: "water",
                co2Emission: 300.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.2346,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3825,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1300.15,
                weightBasedCo2Intensity: 0.1495,
                amountBasedCo2Intensity: 0.1896,
              },
            ],
            intensityType: "industry-average",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 加工工場カテゴリ1取得API */
const processSupplierMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: "process",
            co2Emission: 1000.15,
            weightBasedCo2Intensity: 0.4895,
            amountBasedCo2Intensity: 0.3843,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3896,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 1500.15,
            weightBasedCo2Intensity: 0.1258,
            amountBasedCo2Intensity: 0.1463,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3896,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takasaki",
            factoryName: "YY株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "Y・高崎（加工）",
            factoryType: "process",
            co2Emission: 2000.15,
            weightBasedCo2Intensity: 0.3045,
            amountBasedCo2Intensity: 0.2146,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.1465,
                amountBasedCo2Intensity: 0.2436,
              },
              {
                classificationType: "dye",
                co2Emission: 400.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社 八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 2000.15,
            weightBasedCo2Intensity: 0.3045,
            amountBasedCo2Intensity: 0.2346,
            byClassification: [
              {
                classificationType: "privatePower",
                co2Emission: 1050.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1346,
              },
              {
                classificationType: "waste",
                co2Emission: 500.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1467,
              },
              {
                classificationType: "water",
                co2Emission: 300.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.2346,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（加工）",
            factoryType: "process",
            co2Emission: 1500.15,
            weightBasedCo2Intensity: 0.3465,
            amountBasedCo2Intensity: 0.4436,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.2865,
                amountBasedCo2Intensity: 0.2736,
              },
            ],
            intensityType: "industry-average",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 調達量一覧APIのエラー:network */
const userProcurementNetworkErrorMock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 調達量一覧APIのエラー:timeout */
const userProcurementTimeoutErrorMock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 調達量一覧APIのエラー:ERR000002 */
const userProcurementERR000002Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 調達量一覧APIのエラー:ERR000003 */
const userProcurementERR000003Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** 調達量一覧APIのエラー:unknown */
const userProcurementUnknownErrorMock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 調達量一覧APIのエラー:ERR000000 */
const userProcurementERR000000Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 調達量一覧APIのエラー:ERR000001 */
const userProcurementERR000001Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 調達量一覧APIのエラー:ERR000004 */
const userProcurementERR000004Mock = [
  rest.get("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:network */
const userTempProcurementNetworkErrorMock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:timeout */
const userTempProcurementTimeoutErrorMock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:unknown */
const userTempProcurementUnknownErrorMock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR000000 */
const userTempProcurementERR000000Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR000001 */
const userTempProcurementERR000001Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR000004 */
const userTempProcurementERR000004Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR028000 */
const userTempProcurementERR028000Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({ errorCode: "ERR028000", message: "Mocked message" })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR000002 */
const userTempProcurementERR000002Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR000003 */
const userTempProcurementERR000003Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** ユーザ調達量一時保存APIのエラー:ERR028001 */
const userTempProcurementERR028001Mock = [
  rest.put("*/temp/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR028001",
        message: "Mocked message",
      })
    );
  }),
];

/** 調達量更新APIのエラー:network */
const userProcurementPutNetworkErrorMock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:timeout */
const userProcurementPutTimeoutErrorMock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:unknown */
const userProcurementPutUnknownErrorMock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:ERR000000 */
const userProcurementPutERR000000Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:ERR000001 */
const userProcurementPutERR000001Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:ERR000004 */
const userProcurementPutERR000004Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:ERR013000 */
const userProcurementPutERR013000Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({ errorCode: "ERR013000", message: "Mocked message" })
    );
  }),
];

/** 調達量更新APIのエラー:ERR000002 */
const userProcurementPutERR000002Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 調達量更新APIのエラー:ERR000003 */
const userProcurementPutERR000003Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** 調達量更新APIのエラー:ERR013001 */
const userProcurementPutERR013001Mock = [
  rest.put("*/procurements", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR013001",
        message: "Mocked message",
      })
    );
  }),
];
