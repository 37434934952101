import { createTheme, Theme } from "@mui/material";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import chroma from "chroma-js";

// makeStyles は @mui/styles にあり、 Theme は @mui/material にある
// @mui/styles は @mui/material の Theme のことを知らない
// makeStyles で Theme を扱えるように型を拡張する
// cf. https://mui.com/material-ui/guides/migration-v4/#types-property-quot-palette-quot-quot-spacing-quot-does-not-exist-on-type-defaulttheme
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Buttonの色でdefaultが@mui/materialに存在しないため追加する
declare module "@mui/material/styles" {
  interface Palette {
    default: {
      main: string;
    };
  }
  interface PaletteOptions {
    default?: {
      main?: string;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

const makeTypography = (
  fontSize: string,
  fontWeight?: "normal" | "bold" | number
): TypographyStyleOptions => {
  return {
    fontSize,
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
    ...(fontWeight !== undefined ? { fontWeight } : {}),
  };
};

export type AppTheme = {
  palette: {
    footer: string;
    factory: string;
    cardBoard: string;
    statusDeadline: string;
    statusRequested: string;
    statusSubmitted: string;
    statusInProgress: string;
    ghgBorder: string;
    ghgHeader: { default: string; hover: string };
    cate1Header: { default: string; hover: string };
    cate4Header: { default: string; hover: string };
    disabledBackground: string;
    headerBorder: string;
    csvIcon: string;
    confirm: string;
    procurementBackground: string;
    procurementLineColor: string;
    procurementBox: string;
    cate1ForUserCompanyBackground: string;
    cate4ForUserCompanyBackground: string;
    pieChart: {
      paper: string;
      process: string;
    };
    emissionUnitRank: {
      common: string;
      first: string;
      second: string;
      third: string;
    };
    emissionUnitSummary: string;
    category1Badge: string;
    category4Badge: string;
  };
} & Theme;

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      // NOTE: lgを基準にタブレットとPCのデザインを切り分ける
      xs: 0, // モバイル
      sm: 720, // 基準デスクトップの半分
      md: 1025, // 小さめのデスクトップ
      lg: 1440, // 基準のデスクトップ
      xl: 1920, // 大きめのデスクトップ
    },
  },
  spacing: (factor: number) => `${0.8 * factor}rem`,
  palette: {
    primary: { main: "#899B5A", contrastText: "#ffffff" },
    secondary: { main: "#EB612B" },
    text: { primary: "#30434F", secondary: "#18191A", disabled: "#717E86" },
    error: { main: "#EB612B" },
    background: { default: "#F4F3EF", paper: "#FFFFFF" },
    action: {
      selected: "#F4F3EF",
      selectedOpacity: 0.2,
    },
    success: { main: "#30434F" },
    default: { main: "#30434F" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          // 62.5% of 16px = 10px
          fontSize: "62.5%",
          // iOSで勝手に文字サイズを調節されることを防ぐ
          WebkitTextSizeAdjust: "100%",
          // Chromeで画像がぼやけることを防ぐ
          imageRendering: "-webkit-optimize-contrast",
          // 常にy軸方向のスクロールバーを表示し、画面のがたつきを防ぐ
          overflowY: "scroll",
        },

        // スクロールバーをカスタマイズ
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "::-webkit-scrollbar": {
          width: 8,
          height: 8,
          backgroundColor: "#00000010",
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "::-webkit-scrollbar-thumb": {
          width: 8,
          borderRadius: 100,
          backgroundColor: "#808080",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        // SSRが有効だとuseMediaQueryによって2回renderが起きてしまうのでこれを無効化
        noSsr: true,
      },
    },
  },
  typography: {
    htmlFontSize: 10,
    // https://material.io/design/typography/the-type-system.html#type-scale
    h1: makeTypography("2.4rem", "bold"),
    h2: makeTypography("2.2rem", "bold"),
    h3: makeTypography("1.8rem", "bold"),
    h4: makeTypography("1.6rem", "bold"),
    h5: makeTypography("1.4rem", "bold"),
    h6: makeTypography("1.2rem", "bold"),
    body1: makeTypography("1.6rem"),
    body2: makeTypography("1.4rem"),
    subtitle1: makeTypography("1.6rem"),
    subtitle2: makeTypography("1.4rem"),
    button: makeTypography("1.6rem", "bold"),
    caption: makeTypography("1.4rem"),
    overline: makeTypography("1.2rem"),
  },
});

export const theme: AppTheme = {
  ...muiTheme,
  palette: {
    ...muiTheme.palette,
    footer: "#B7B4A5",
    factory: "#C2A546",
    cardBoard: "#C2A546",
    statusDeadline: "#D36D45",
    statusRequested: "#717E86",
    statusSubmitted: "#899B5A",
    statusInProgress: "#5E89A2",
    ghgBorder: "#F1EFEA",
    ghgHeader: {
      default: chroma("#899B5A").alpha(0.5).hex(),
      hover: chroma("#899B5A").alpha(0.3).hex(),
    },
    cate1Header: {
      default: chroma("#EDE0D6").alpha(0.7).hex(),
      hover: chroma("#EDE0D6").alpha(0.5).hex(),
    },
    cate4Header: {
      default: chroma("#DDE2E2").alpha(0.7).hex(),
      hover: chroma("#DDE2E2").alpha(0.5).hex(),
    },
    disabledBackground: "#F0F0F0",
    headerBorder: "#DED8BF",
    csvIcon: "#97A0A6",
    confirm: "#717E86",
    procurementBackground: "#F4F3EF",
    procurementLineColor: "#97A0A6",
    procurementBox: "#DDCD97",
    cate1ForUserCompanyBackground: "#EDE0D6",
    cate4ForUserCompanyBackground: "#DDE2E2",
    pieChart: {
      paper: "#4479CB",
      process: "#59865D",
    },
    emissionUnitRank: {
      common: "#30434F",
      first: "#D3BE4C",
      second: "#9CA2B9",
      third: "#C6806B",
    },
    emissionUnitSummary: chroma("#EADDAF").alpha(0.3).hex(),
    category1Badge: "#C36D38",
    category4Badge: "#5E89A2",
  },
};
