import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { Annotate } from "src/app/components/atoms";
import {
  ChartAndTableView,
  DataField,
  SingleBarChart,
} from "src/app/components/organisms";
import { TotalEmission } from "src/app/models";

export type Category1TotalEmissionTemplateProps = {
  isProcessing: boolean;

  // グラフ用Props
  chartData: TotalEmission[];

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

export const Category1TotalEmissionTemplate: React.FC<
  Category1TotalEmissionTemplateProps
> = (props) => {
  return (
    <ChartAndTableView
      title="カテゴリ1　工場別（年）"
      subtitle={
        <Annotate>
          TORCH算定結果のみを表示。自社算定結果を入力した工場情報は表示されません。
        </Annotate>
      }
      chartElement={
        <SingleBarChart
          data={props.chartData}
          unit="(t-CO2eq/年)"
          barColor={["#8A9B5A", "#4B5E20"]}
          chartType="normal"
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};
