import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { UserApi } from "src/app/apis/user-api/userApi";
import { LoginPage, NavigationPage } from "src/app/components/pages";
import { messages, theme } from "src/app/configs";
import { DefaultYearProvider } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import {
  AuthRoute,
  AuthStateProvider,
  AuthStateProviderProps,
} from "src/lib/components/providers/AuthStateProvider";
import { ErrorBoundary } from "src/lib/components/providers/ErrorBoundary";
import { ReactQueryProvider } from "src/lib/components/providers/ReactQueryProvider";
import { SnackbarProvider } from "src/lib/components/providers/SnackbarProvider";
import { useScrollResetOnNavigation } from "src/lib/hooks";
import { useLogoutOtherTab } from "src/lib/hooks/useLogoutOtherTab";

const Navigator: React.FC = () => {
  useScrollResetOnNavigation();
  useLogoutOtherTab(messages.common.logoutOtherTab);

  return (
    <Routes>
      <Route path={paths.login} element={<LoginPage />} />
      <Route
        path="*"
        element={
          <AuthRoute path="*">
            <DefaultYearProvider>
              <NavigationPage />
            </DefaultYearProvider>
          </AuthRoute>
        }
      />
    </Routes>
  );
};

// NOTE: react routerのData APIを使うためには、BrowserRouterをcreateBrowserRouterで定義する必要がある
// https://reactrouter.com/en/main/routers/picking-a-router
const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Navigator />} />)
);

export const App: React.FC = () => {
  const authStateProviderProps: AuthStateProviderProps<AuthState> = {
    initAuthState: async (): Promise<AuthState | undefined> => {
      const userApi = new UserApi();
      try {
        const res = await userApi.getMe();
        return res;
      } catch (err) {
        return undefined;
      }
    },
    LoadingComponent: () => {
      return <div />;
    },
  };

  return (
    <ErrorBoundary>
      <ReactQueryProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <AuthStateProvider {...authStateProviderProps}>
                <RouterProvider router={router} />
              </AuthStateProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  );
};
