import {
  Box,
  Drawer as MuiDrawer,
  List,
  Theme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import Logo from "src/app/images/logo.svg";
import { Image } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    width: Drawer.width,
  },
  closeButtonContainer: {
    alignItems: "flex-end",
  },
}));

/**
 * Drawer の状態および操作関数を保持するオブジェクト
 */
export type DrawerHandle = {
  isOpened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  set: (isOpened: boolean) => void;
};

/**
 * DrawerHandle を取得する hook
 */
export const useDrawer = (initialState?: boolean): DrawerHandle => {
  const [isOpened, setIsOpened] = useState(initialState === true);
  return {
    isOpened,
    open: () => setIsOpened(true),
    close: () => setIsOpened(false),
    toggle: () => setIsOpened((x) => !x),
    set: setIsOpened,
  };
};

export type DrawerProps = DrawerHandle & {
  children?: React.ReactNode;
};

export const Drawer: React.FC<DrawerProps> & { width: number } = (props) => {
  const classes = useStyles();

  const isTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <MuiDrawer
      PaperProps={{
        className: classes.drawer,
      }}
      variant={!isTablet ? "persistent" : "temporary"}
      open={props.isOpened}
      onClose={props.close}
    >
      <Box pl={3} pt={4} pb={2}>
        <Image alt="Logo" src={Logo} height={21} />
      </Box>
      <List>{props.children}</List>
    </MuiDrawer>
  );
};
// ドロワーの幅を決め打つ
Drawer.width = 270;
