import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarProvider as NotistackSnackbarProvider,
  useSnackbar,
} from "notistack";
import React, { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // snackbarの閉じるボタンへのスタイル
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& > div > div:last-child": {
      // 絶対位置指定で右側に表示
      position: "absolute",
      right: theme.spacing(3),
      // 上下中央揃え
      top: 0,
      bottom: 0,
    },
    // snackbarのテキストへのスタイル
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& > div > div:first-child": {
      // ボタンに重ならないように十分なパディングを指定
      paddingRight: theme.spacing(5),
    },
  },
  success: {
    // importantが指定されているnotistackのスタイルを上書き
    backgroundColor: `${theme.palette.success.main} !important`,
    color: theme.palette.primary.contrastText,
  },
  error: {
    // importantが指定されているnotistackのスタイルを上書き
    backgroundColor: `${theme.palette.error.main} !important`,
    color: theme.palette.error.contrastText,
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
}));
type Props = {
  children?: React.ReactNode;
};

export const SnackbarProvider: React.FC<Props> = (props) => {
  const classes = useStyles();

  // Reactのref機能を使ってSnackbarProviderのインスタンスにアクセスできるようにする
  // notistackRef.currentがSnackbarProviderのインスタンスを表す
  // SnackbarProviderにはcloseSnackbarなどのsnackbar操作用関数がある
  // 参考: https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
  const notistackRef = React.useRef<NotistackSnackbarProvider>(null);

  return (
    <NotistackSnackbarProvider
      hideIconVariant
      ref={notistackRef}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        containerRoot: classes.container,
      }}
      // ナビゲーション部品に被らない位置に表示する
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={5000} // milliseconds
      // 閉じるボタン
      action={(key) => (
        <IconButton
          size="small"
          color="inherit"
          onClick={() => {
            if (notistackRef.current) {
              notistackRef.current.closeSnackbar(key);
            }
          }}
        >
          <Close color="inherit" />
        </IconButton>
      )}
    >
      {props.children}
    </NotistackSnackbarProvider>
  );
};

export interface CustomSnackbar {
  info(message: string, option?: OptionsObject): SnackbarKey;
  success(message: string, option?: OptionsObject): SnackbarKey;
  warning(message: string, option?: OptionsObject): SnackbarKey;
  error(message: string, option?: OptionsObject): SnackbarKey;
  close(key: SnackbarKey): void;
  closeAll(): void;
}

const enqueueSnackbarHelper =
  (
    snackbar: ProviderContext,
    variant: "default" | "info" | "success" | "warning" | "error"
  ) =>
  (message: string, options?: OptionsObject) => {
    return snackbar.enqueueSnackbar(
      message,
      Object.assign<OptionsObject, OptionsObject | undefined>(
        { variant: variant, preventDuplicate: true },
        options
      )
    );
  };

export const useCustomSnackbar = (): CustomSnackbar => {
  const snackbar = useSnackbar();
  const customSnackbar = useMemo<CustomSnackbar>(() => {
    return {
      info: enqueueSnackbarHelper(snackbar, "info"),
      success: enqueueSnackbarHelper(snackbar, "success"),
      warning: enqueueSnackbarHelper(snackbar, "warning"),
      error: enqueueSnackbarHelper(snackbar, "error"),
      close(key) {
        snackbar.closeSnackbar(key);
      },
      closeAll() {
        snackbar.closeSnackbar();
      },
    };
  }, [snackbar]);
  return customSnackbar;
};
