import {
  GetProcurementsQueryError,
  GetProcurementsQueryResult,
  PutProcurementsMutationBody,
  PutProcurementsMutationError,
  PutProcurementsMutationResult,
  getProcurements,
  putProcurements,
} from "src/app/apis/client/default";
import { GetProcurementsParams } from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class GetUserProcurementApiError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}

export class PutUserProcurementApiError extends ApiError<
  "not-authenticated" | "network" | "conflict" | "unknown"
> {}

export class UserProcurementApi {
  async getUserProcurement(
    req: GetProcurementsParams
  ): Promise<GetProcurementsQueryResult> {
    try {
      const res = await getProcurements(req);
      return res;
    } catch (err) {
      const { errorCode } = err as GetProcurementsQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetUserProcurementApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetUserProcurementApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetUserProcurementApiError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }

  async putUserProcurement(
    req: PutProcurementsMutationBody
  ): Promise<PutProcurementsMutationResult> {
    try {
      const res = await putProcurements(req);
      return res;
    } catch (err) {
      const { errorCode } = err as PutProcurementsMutationError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PutUserProcurementApiError("network", err);
        // NOTE : フロントエンド目線でハンドリングする価値がない（ユーザーにとってはunknownのエラーと同じ）
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "ERR013000":
          throw new PutUserProcurementApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new PutUserProcurementApiError("not-authenticated", err);
        case "ERR013001":
          throw new PutUserProcurementApiError("conflict", err);

        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
