export class CookieUtil {
  static isEnabled(): boolean {
    // いずれもランダムに生成した値
    const cookieName = "ef0abc06d0004f16ba822aab928f3029";
    const cookieValue = "df758cf92d28436fa34739a93581fa4f";
    const setCookie = `${cookieName}=${cookieValue}`;
    const clearCookie = `${cookieName}=; Max-Age=0`;

    // Cookie への読み書きを実施して有効かどうか確認する
    document.cookie = setCookie;
    const result =
      document.cookie
        .split("; ")
        .find((aCookie) => aCookie.startsWith(cookieName)) === setCookie;

    // 試し書きしたCookieは削除しておく
    document.cookie = clearCookie;
    return result;
  }
}
