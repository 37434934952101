import axios, { AxiosInstance } from "axios";

import { createApiLoggingInterceptor } from "./api-logging-interceptor";

export type ApiClientOptions = {
  baseURL: string;
};

export const createApiClient = (options: ApiClientOptions): AxiosInstance => {
  const instance = axios.create({
    baseURL: options.baseURL,
    timeout: 30 * 1000,
    withCredentials: true,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  instance.interceptors.response.use(...createApiLoggingInterceptor());

  return instance;
};
