import {
  Box,
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Dialog } from "src/app/components/atoms";
import { AppTheme, theme } from "src/app/configs";
import { Button, FlexBox } from "src/lib/components/atoms";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles<AppTheme>((theme) => ({
  title: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
  button: {
    maxWidth: 120,
    width: "100%",
    marginLeft: theme.spacing(2),
  },
  message: {
    // メッセージ中の改行をそのまま改行として表示
    whiteSpace: "pre-wrap",
  },
}));

export type ErrorDialogProps = {
  message: string;
  open: boolean;
  onClickClose: () => void;
};

const themeForConfirmButton = createTheme({
  ...theme,
  palette: { ...theme.palette, primary: { main: theme.palette.confirm } },
});

export const ErrorDialog: React.FC<ErrorDialogProps> = (props) => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.onClickClose}>
      <Box className={classes.title} px={3} py={1}>
        <Typography variant="h4" color="inherit">
          エラー
        </Typography>
      </Box>
      <Box p={3}>
        <Box>
          <Typography variant="body2" className={classes.message}>
            {props.message}
          </Typography>
        </Box>

        <FlexBox justifyContent="flex-end" mt={3}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeForConfirmButton}>
              <Button
                color="default"
                className={classes.button}
                label="閉じる"
                variant="outlined"
                onClick={props.onClickClose}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        </FlexBox>
      </Box>
    </Dialog>
  );
};
