type ValidationFunction = (value: string) => boolean;

export const validateAll = (
  ...funcs: ValidationFunction[]
): ValidationFunction => {
  return (value: string) => funcs.every((f) => f(value));
};

export const isNotEmpty = (): ValidationFunction => {
  return (value: string) => value !== "";
};

export const isMinLength = (min: number): ValidationFunction => {
  return (value: string) => value.length >= min;
};

export const isMaxLength = (max: number): ValidationFunction => {
  return (value: string) => value.length <= max;
};

export const isMatches = (pattern: RegExp): ValidationFunction => {
  return (value: string) => pattern.test(value);
};

export const isNumber = (): ValidationFunction => {
  return (value: string) => {
    return (
      /^[+-]?([1-9][0-9]*|0)(\.[0-9]+)?$/.test(value) && !isNaN(Number(value))
    );
  };
};

export const isInteger = (): ValidationFunction => {
  return (value: string) => {
    const num = Number(value);
    return num === Math.floor(num) && !isNaN(num);
  };
};

export const isLessThanOrEqualTo = (max: number): ValidationFunction => {
  return (value: string) => {
    return Number(value) <= max;
  };
};

export const isLessThan = (max: number): ValidationFunction => {
  return (value: string) => {
    return Number(value) < max;
  };
};
export const isGreaterThanOrEqualTo = (min: number): ValidationFunction => {
  return (value: string) => {
    return Number(value) >= min;
  };
};

export const isGreaterThan = (min: number): ValidationFunction => {
  return (value: string) => {
    return Number(value) > min;
  };
};

export const isNotIn = (bannedValue: string[]): ValidationFunction => {
  return (value: string) => {
    return !bannedValue.includes(value);
  };
};

export const isIn = (values: readonly string[]): ValidationFunction => {
  return (value: string) => {
    return values.includes(value);
  };
};

type FormatFunction = (value: string) => string;

export const formatAll = (...funcs: FormatFunction[]): FormatFunction => {
  return (value: string) => funcs.reduce((value, f) => f(value), value);
};

export const toTrim = (): FormatFunction => (value: string) => value.trim();
export const toExclude =
  (pattern: RegExp): FormatFunction =>
  (value: string) =>
    value.replace(pattern, "");
