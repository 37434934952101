import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Navigate } from "react-router-dom";

import {
  GetScope3ByFactoryQueryResult,
  OldGetScope3ByStandardQueryResult,
} from "src/app/apis/client/default";
import { AppTab, AppTabItem } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  ImageHeading,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  UserTotalEmissionPage,
  UserChartAndTableType,
  UserEmissionByFactoryPage,
  UserEmissionByStandardPage,
} from "src/app/components/pages";
import { AppTheme } from "src/app/configs";
import { paths } from "src/app/navigation/paths";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  category1: {
    maxWidth: 120,
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category1Badge,
  },
  category4: {
    maxWidth: 120,
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category4Badge,
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
}));

//TODO:画面実装時にOldから置き換える
export type UserCategoryNavigationTemplateProps = {
  isProcessing: boolean;
  yearDropdownProps: YearDropdownProps;
  byStandardData?: OldGetScope3ByStandardQueryResult;
  tabItemProps: Record<
    UserChartAndTableType,
    {
      selected: boolean;
      onClick: () => void;
    }
  >;
  byFactoryData?: GetScope3ByFactoryQueryResult;
  invalidUrl?: boolean;
};

export const UserCategoryNavigationTemplate: React.FC<
  UserCategoryNavigationTemplateProps
> = (props) => {
  const classes = useStyles();

  return (
    <Content>
      <FlexBox justifyContent="space-between">
        <ImageHeading title="段ボール" variant="cardBoard" />
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FlexBox
              className={classes.category1}
              justifyContent="center"
              alignItems="center"
              mb={1}
            >
              <Typography className={classes.typography} variant="h4">
                カテゴリ1
              </Typography>
            </FlexBox>
            <AppTab>
              <AppTabItem
                disableBadge={true}
                label="年間排出量"
                {...props.tabItemProps.category1TotalEmission}
              />
              <AppTabItem
                disableBadge={true}
                label="ケース規格別排出量"
                {...props.tabItemProps.category1EmissionByCase}
              />
              <AppTabItem
                disableBadge={true}
                label="自社工場別排出量"
                {...props.tabItemProps.category1EmissionByFactory}
              />
            </AppTab>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FlexBox
              className={classes.category4}
              justifyContent="center"
              alignItems="center"
              mb={1}
            >
              <Typography className={classes.typography} variant="h4">
                カテゴリ4
              </Typography>
            </FlexBox>
            <AppTab>
              <AppTabItem
                disableBadge={true}
                label="年間排出量"
                {...props.tabItemProps.category4TotalEmission}
              />
              <AppTabItem
                disableBadge={true}
                label="ケース規格別排出量"
                {...props.tabItemProps.category4EmissionByCase}
              />
              <AppTabItem
                disableBadge={true}
                label="自社工場別排出量"
                {...props.tabItemProps.category4EmissionByFactory}
              />
            </AppTab>
          </Grid>
        </Grid>
        {/* コンテンツの切替 */}
      </Box>
      <Box mt={3}>
        {props.tabItemProps.category1TotalEmission.selected && (
          <UserTotalEmissionPage
            isProcessing={props.isProcessing}
            data={props.byStandardData}
          />
        )}
        {props.tabItemProps.category4TotalEmission.selected && (
          <UserTotalEmissionPage
            isProcessing={props.isProcessing}
            data={props.byStandardData}
          />
        )}
        {props.tabItemProps.category1EmissionByFactory.selected && (
          <UserEmissionByFactoryPage
            isProcessing={props.isProcessing}
            data={props.byFactoryData}
          />
        )}
        {props.tabItemProps.category4EmissionByFactory.selected && (
          <UserEmissionByFactoryPage
            isProcessing={props.isProcessing}
            data={props.byFactoryData}
          />
        )}
        {props.tabItemProps.category1EmissionByCase.selected && (
          <UserEmissionByStandardPage
            isProcessing={props.isProcessing}
            data={props.byStandardData}
          />
        )}
        {props.tabItemProps.category4EmissionByCase.selected && (
          <UserEmissionByStandardPage
            isProcessing={props.isProcessing}
            data={props.byStandardData}
          />
        )}
        {props.invalidUrl && <Navigate to={paths.user.dashboard.root} />}
      </Box>
      <FullScreenSpinner open={props.isProcessing} />
    </Content>
  );
};
