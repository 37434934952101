import { loadConfig } from "src/lib/configs/loadConfig";

export const envConfig = {
  envName: loadConfig("REACT_APP_ENV_NAME").toString(),
  api: {
    baseUrl: loadConfig("REACT_APP_API_URL").toString(),
    timeout: loadConfig("REACT_APP_API_TIMEOUT").toNumber(),
  },
  enableApiMock: loadConfig("REACT_APP_ENABLE_API_MOCK").toBoolean(),
  apiMockDelayTime: loadConfig("REACT_APP_API_MOCK_DELAY_TIME").toNumber(),
  version: loadConfig("REACT_APP_VERSION").toString(),
  displayYear: loadConfig("REACT_APP_DISPLAY_YEAR").toString(),
};
