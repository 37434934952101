import { Box, Typography, Card } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 800,
  },
}));
export type TitledCardProps = {
  title?: string;
  children?: React.ReactNode;
};

export const TitledCard: React.FC<TitledCardProps> = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Box p={{ xs: 2, sm: 4, md: 6 }}>
        <Box>
          <Typography variant="h1">{props.title}</Typography>
        </Box>
        {props.children}
      </Box>
    </Card>
  );
};
