import { v4 as uuid } from "uuid";

import {
  FuelType,
  MaterialType,
  WasteType,
  TransportFuelType,
} from "src/app/apis/model";
import {
  fuelTypes,
  wasteTypes,
  materialTypes,
  transportFuelTypes,
} from "src/app/codes";
import { keysToObject } from "src/lib/utils/objectUtil";

export type Field = {
  value: string;
  error?: boolean;
};

export type GhgInputCategory4TabType = "transport";

export type GhgInputCategory4Form = {
  transport: TransportForm;
};

export const initGhgInputCategory4Form = (): GhgInputCategory4Form => ({
  transport: initTransportForm(),
});

export type GhgInputCategory1TabType = "energy" | "waste" | "manufacturing";

export type GhgInputCategory1Form = {
  energy: EnergyForm;
  waste: WasteForm;
  manufacturing: ManufacturingForm;
};

export const initGhgInputCategory1Form = (): GhgInputCategory1Form => ({
  energy: initEnergyForm(),
  waste: initWasteForm(),
  manufacturing: initManufacturingForm(),
});

export type EnergyForm = {
  electricPowerPurchases: ElectricPowerPurchaseForm[];
  steamPurchase: {
    isChecked: boolean;
    steamAmount: Field;
    emissionFactor: { field: Field; isChecked: boolean };
  };
  boiler: {
    isChecked: boolean;
    fuelConsumptions: Record<FuelType, Field>;
    occurredSteam: Field;
  };
  coGenerator: {
    isChecked: boolean;
    fuelConsumptions: Record<FuelType, Field>;
    occurredSteamForSteam: Field;
    occurredSteamForElectricPower: Field;
    occurredElectricPower: Field;
  };
  generator: {
    isChecked: boolean;
    fuelConsumptions: Record<FuelType, Field>;
    occurredElectricPower: Field;
  };
  solar: {
    isChecked: boolean;
    occurredElectricPower: Field;
  };
  hydroPower: {
    isChecked: boolean;
    occurredElectricPower: Field;
  };
  energySale: {
    isChecked: boolean;
    steam: Field;
    electricPower: Field;
  };
  greenCertificates: {
    isChecked: boolean;
    certificates: CertificateForm[];
  };
  jCredits: {
    isChecked: boolean;
    certificates: CertificateForm[];
  };
};
export type ElectricPowerPurchaseForm = {
  key: string;
  emissionFactor: Field;
  amount: Field;
  source: Field;
};
export type CertificateForm = {
  key: string;
  powerType: Field;
  amount: Field;
};
export const initEnergyForm = (): EnergyForm => ({
  electricPowerPurchases: [initElectricPowerPurchaseForm()],
  steamPurchase: {
    isChecked: false,
    steamAmount: { value: "" },
    emissionFactor: { field: { value: "" }, isChecked: false },
  },
  boiler: {
    isChecked: false,
    fuelConsumptions: keysToObject(fuelTypes, { value: "" }),
    occurredSteam: { value: "" },
  },
  coGenerator: {
    isChecked: false,
    fuelConsumptions: keysToObject(fuelTypes, { value: "" }),
    occurredSteamForSteam: { value: "" },
    occurredSteamForElectricPower: { value: "" },
    occurredElectricPower: { value: "" },
  },
  generator: {
    isChecked: false,
    fuelConsumptions: keysToObject(fuelTypes, { value: "" }),
    occurredElectricPower: { value: "" },
  },
  solar: {
    isChecked: false,
    occurredElectricPower: { value: "" },
  },
  hydroPower: {
    isChecked: false,
    occurredElectricPower: { value: "" },
  },
  energySale: {
    isChecked: false,
    steam: { value: "" },
    electricPower: { value: "" },
  },
  greenCertificates: {
    isChecked: false,
    certificates: [initCertificateForm()],
  },
  jCredits: {
    isChecked: false,
    certificates: [initCertificateForm()],
  },
});
export const initElectricPowerPurchaseForm = (): ElectricPowerPurchaseForm => {
  return {
    key: uuid(),
    emissionFactor: { value: "" },
    amount: { value: "" },
    source: { value: "" },
  };
};
export const initCertificateForm = (): CertificateForm => {
  return {
    key: uuid(),
    powerType: { value: "" },
    amount: { value: "" },
  };
};

export type WasteDetailForm = {
  method: Field;
  amount: Field;
};
export type WasteForm = {
  wastes: Record<WasteType, WasteDetailForm>;
  totalInHouseIncinerationCo2Emission: Field;
  drainageMethod: Field;
  inHouseDrainageElectricPower: Field;
  outSourceDrainageAmount: Field;
};
export const initWasteDetailForm = (): WasteDetailForm => {
  return {
    method: { value: "" },
    amount: { value: "" },
  };
};
export const initWasteForm = (): WasteForm => ({
  wastes: keysToObject(wasteTypes, initWasteDetailForm()),
  totalInHouseIncinerationCo2Emission: { value: "0" },
  drainageMethod: { value: "" },
  inHouseDrainageElectricPower: { value: "0" },
  outSourceDrainageAmount: { value: "0" },
});

export type MachineForm = {
  key: string;
  machineName: Field;
  steamConsumption: Field;
  electricPowerConsumption: Field;
  industrialWaterConsumption: Field;
  groundWaterConsumption: Field;
  materialConsumptions: Record<MaterialType, Field>;
  paperDeals: { productType: string; field: Field }[];
};

export type ManufacturingForm = {
  materialCo2EmissionFactor: Record<
    MaterialType,
    { field: Field; isChecked: boolean }
  >;
  machines: MachineForm[];
  amount?: Field;
};

export const initManufacturingForm = (
  productTypes?: readonly string[]
): ManufacturingForm => ({
  materialCo2EmissionFactor: keysToObject(materialTypes, {
    field: { value: "" },
    isChecked: false,
  }),
  machines: [initMachineForm(productTypes)],
  amount: { value: "" },
});
export const initMachineForm = (
  productTypes?: readonly string[]
): MachineForm => {
  return {
    key: uuid(),
    machineName: { value: "" },
    steamConsumption: { value: "" },
    electricPowerConsumption: { value: "" },
    industrialWaterConsumption: { value: "" },
    groundWaterConsumption: { value: "" },
    materialConsumptions: keysToObject(materialTypes, { value: "" }),
    paperDeals:
      productTypes === undefined
        ? []
        : productTypes?.map((productType) => ({
            productType: productType,
            field: { value: "" },
          })),
  };
};

export type TransportForm = {
  transportFuelConsumptions: Record<TransportFuelType, Field>;
  shipTransportation: {
    isChecked: boolean;
  };
  transportByRail: {
    isChecked: boolean;
    transportByRails: TransportByRailForm[];
  };
  amount: Field;
};

export type TransportByRailForm = {
  key: string;
  destinationId: Field;
  railTransportAmount?: Field;
  railDistance: Field;
  truckDistance: Field;
};

export type DeliveryForm = {
  destinationId: string;
  factoryName?: string;
};

export const initTransportForm = (): TransportForm => ({
  transportFuelConsumptions: keysToObject(transportFuelTypes, { value: "" }),
  shipTransportation: {
    isChecked: false,
  },
  transportByRail: {
    isChecked: false,
    transportByRails: [initTransportByRailForm()],
  },
  amount: { value: "" },
});

export const initTransportByRailForm = (): TransportByRailForm => {
  return {
    key: uuid(),
    destinationId: { value: "" },
    railTransportAmount: { value: "" },
    railDistance: { value: "" },
    truckDistance: { value: "" },
  };
};
