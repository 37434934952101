import { DateTime } from "luxon";

import {
  GetPaperFactoryCategory1QueryError,
  GetPaperFactoryCategory1QueryResult,
  GetProcessFactoryCategory1QueryError,
  GetProcessFactoryCategory1QueryResult,
  getPaperFactoryCategory1,
  getProcessFactoryCategory1,
} from "src/app/apis/client/default";
import {
  GetPaperFactoryCategory1200FactoriesItemByClassificationItem,
  GetPaperFactoryCategory1200FactoriesItemByProductItem,
  GetPaperFactoryCategory1Params,
  GetProcessFactoryCategory1200FactoriesItemByClassificationItem,
  GetProcessFactoryCategory1Params,
} from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export interface GetCategory1ApiRequest {
  year: DateTime;
}
export class GetCategory1ApiError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}

const convertPaperByProduct = (
  byProduct: GetPaperFactoryCategory1200FactoriesItemByProductItem[]
) => {
  return byProduct.map((it) => ({
    productType: it.productType,
    productName: it.productName,
    productShortName: it.productShortName,
    sortOrder: it.sortOrder,
    co2Emission: it.co2Emission,
    weightBasedCo2Intensity: it.weightBasedCo2Intensity,
    amountBasedCo2Intensity: it.amountBasedCo2Intensity,
  }));
};

const convertPaperByClassification = (
  byClassification: GetPaperFactoryCategory1200FactoriesItemByClassificationItem[]
) => {
  return byClassification.map((it) => ({
    classificationType: it.classificationType,
    co2Emission: it.co2Emission,
    weightBasedCo2Intensity: it.weightBasedCo2Intensity,
    amountBasedCo2Intensity: it.amountBasedCo2Intensity,
  }));
};

const convertProcessByClassification = (
  byClassification: GetProcessFactoryCategory1200FactoriesItemByClassificationItem[]
) => {
  return byClassification.map((it) => ({
    classificationType: it.classificationType,
    co2Emission: it.co2Emission,
    weightBasedCo2Intensity: it.weightBasedCo2Intensity,
    amountBasedCo2Intensity: it.amountBasedCo2Intensity,
  }));
};

export class PaperCategory1Api {
  async getCategory1(
    req: GetPaperFactoryCategory1Params
  ): Promise<GetPaperFactoryCategory1QueryResult> {
    try {
      const res = await getPaperFactoryCategory1(req);

      return {
        companyId: res.companyId,
        year: res.year,
        factories: res.factories.map((factory) => ({
          ...factory,
          byProduct: convertPaperByProduct(factory.byProduct),
          byClassification: convertPaperByClassification(
            factory.byClassification
          ),
        })),
      };
    } catch (err) {
      const { errorCode } = err as GetPaperFactoryCategory1QueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetCategory1ApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetCategory1ApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetCategory1ApiError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}

export class ProcessCategory1Api {
  async getCategory1(
    req: GetProcessFactoryCategory1Params
  ): Promise<GetProcessFactoryCategory1QueryResult> {
    try {
      const res = await getProcessFactoryCategory1(req);

      return {
        companyId: res.companyId,
        year: res.year,
        factories: res.factories.map((factory) => ({
          ...factory,
          byClassification: convertProcessByClassification(
            factory.byClassification
          ),
        })),
      };
    } catch (err) {
      const { errorCode } = err as GetProcessFactoryCategory1QueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetCategory1ApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetCategory1ApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetCategory1ApiError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
