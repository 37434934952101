import { LaunchRounded } from "@mui/icons-material";
import { Box, Theme, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { InHouseCategory1InputPage } from "./GhgInputPage/InHouseCategory1InputPage";
import { InHouseCategory4InputPage } from "./GhgInputPage/InHouseCategory4InputPage";

import { AuthApi } from "src/app/apis/auth-api/authApi";
import { DrawerItem } from "src/app/components/molecules";
import {
  Drawer,
  Footer,
  Header,
  useDrawer,
} from "src/app/components/organisms";
import {
  CategoryNavigationPage,
  FactoryListPage,
  UserIntensityPage,
  UserDashboardPage,
  UserProcurementAmountInputPage,
  UserCategoryNavigationPage,
  GhgInputCategory1Page,
  GhgInputCategory4Page,
} from "src/app/components/pages";
import { useDefaultYear } from "src/app/hooks/useYearDropdown";
import cardBoard from "src/app/images/icon/cardBoard.svg";
import orangeCardBoard from "src/app/images/icon/orangeCardBoard.svg";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { Redirect } from "src/app/utils/Redirect";
import { Container, Image } from "src/lib/components/atoms";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";

const useStyles = makeStyles((theme) => ({
  shiftContent: {
    marginLeft: Drawer.width,
  },
  drawerTransition: {
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

export type NavigationProps = {};

export const NavigationPage: React.FC<NavigationProps> = (props) => {
  const [authState, setAuthState] = useAuthState<AuthState>();
  const classes = useStyles();

  const isTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  const drawerHandle = useDrawer(!isTablet);

  const navigate = useNavigate();
  const logout = async () => {
    const authApi = new AuthApi();
    try {
      await authApi.logout();
    } catch (err) {
      console.error(err);
    }
    setAuthState(undefined);
    navigate(paths.login, { replace: true });
  };

  const { pathname } = useLocation();
  const bindMenuProps = (path: string | string[]) => {
    return {
      onClick: () => navigate(path instanceof Array ? path[0] : path),
      selected:
        path instanceof Array
          ? path.some((it) => pathname.startsWith(it))
          : pathname.startsWith(path),
    };
  };

  const [defaultYear] = useDefaultYear();

  const year = defaultYear.toFormat("yyyy");

  return (
    <Container>
      <Header
        className={clsx(
          // ドロワーが開いたとき、コンテンツ領域もそれに応じて右にスライドさせる
          classes.drawerTransition,
          drawerHandle.isOpened && !isTablet && classes.shiftContent
        )}
        drawerHandle={drawerHandle}
        userName={authState === undefined ? "-" : authState.userName}
        onClickLogo={() => navigate(paths.input.root)}
        onClickLogout={logout}
      />

      <Drawer {...drawerHandle}>
        {authState?.role !== "user-company" && (
          <>
            <DrawerItem
              title="段ボール"
              icon={
                <Image alt="cardBoard" src={cardBoard} width={24} height={24} />
              }
              selectedIcon={
                <Image
                  alt="orangeCardBoard"
                  src={orangeCardBoard}
                  width={24}
                  height={24}
                />
              }
              container
              {...bindMenuProps([
                paths.input.root,
                paths.category1.root,
                paths.category4.root,
              ])}
            >
              <DrawerItem
                title="GHG算出情報入力"
                {...bindMenuProps(paths.input.root)}
              />
              <DrawerItem
                title="GHG算出結果"
                {...bindMenuProps([paths.category1.root, paths.category4.root])}
              />
            </DrawerItem>
            <DrawerItem
              title="お問い合わせ"
              icon={<LaunchRounded className={classes.icon} />}
              selectedIcon={<LaunchRounded color="secondary" />}
              onClick={() => {
                window.open("https://forms.office.com/r/aHjNJZckkr", "_blank");
              }}
            />
          </>
        )}
        {authState?.role === "user-company" && (
          <DrawerItem
            title="段ボール"
            icon={
              <Image alt="cardBoard" src={cardBoard} width={24} height={24} />
            }
            selectedIcon={
              <Image
                alt="orangeCardBoard"
                src={orangeCardBoard}
                width={24}
                height={24}
              />
            }
            container
            {...bindMenuProps([
              //paths.user.dashboard.root,
              // paths.user.category1.root,
              // paths.user.category4.root,
              paths.user.procurement.root,
              // paths.user.intensity.root,
            ])}
          >
            {/* <DrawerItem
              title="ダッシュボード"
              {...bindMenuProps([
                paths.user.dashboard.root,
                paths.user.category1.root,
                paths.user.category4.root,
              ])}
            /> */}
            <DrawerItem
              title="ユーザー調達量入力"
              {...bindMenuProps([paths.user.procurement.root])}
            />
            {/* <DrawerItem
              title="サプライヤー別の排出原単位の比較"
              {...bindMenuProps([paths.user.intensity.root])}
            /> */}
          </DrawerItem>
        )}
      </Drawer>

      <Box
        // 縦方向に一杯に広げる。これによりフッターが下に押しやられれ、画面最下部に表示される
        flex={1}
        className={clsx(
          // ドロワーが開いたとき、コンテンツ領域もそれに応じて右にスライドさせる
          classes.drawerTransition,
          drawerHandle.isOpened && !isTablet && classes.shiftContent
        )}
      >
        <Routes>
          {/* サプライヤー企業 */}
          {/* サプライヤー企業：工場一覧画面 */}
          <Route
            path={paths.input.root}
            element={<Navigate to={paths.input.year.replace(":year", year)} />}
          />
          {authState?.role !== "user-company" && (
            <Route path={paths.input.year} element={<FactoryListPage />} />
          )}
          {/* サプライヤー企業：自社算定排出原単位入力（カテゴリ１） */}
          <Route
            path={paths.input.inHouse.factoryId}
            element={<Redirect to={paths.input.inHouse.category1} />}
          />
          {authState?.role !== "user-company" && (
            <Route
              path={paths.input.inHouse.category1}
              //自社算定排出原単位入力（カテゴリ１）画面実装
              element={<InHouseCategory1InputPage />}
            />
          )}

          {/* サプライヤー企業：自社算定排出原単位入力（カテゴリ４） */}
          <Route
            path={paths.input.inHouse.factoryId}
            element={<Redirect to={paths.input.inHouse.category4} />}
          />
          {authState?.role !== "user-company" && (
            <Route
              path={paths.input.inHouse.category4}
              //自社算定排出原単位入力（カテゴリ４）画面実装
              element={<InHouseCategory4InputPage />}
            />
          )}

          {/* サプライヤー企業：GHG算出情報入力（カテゴリ1・一次データ） */}
          <Route
            path={paths.input.torch.factoryId + "/category1"}
            element={<Redirect to={paths.input.torch.category1} />}
          />
          {authState?.role !== "user-company" && (
            <Route
              path={`${paths.input.torch.category1}`}
              element={<GhgInputCategory1Page />}
            />
          )}

          {/* サプライヤー企業：GHG算出情報入力（カテゴリ4・一次データ） */}
          <Route
            path={paths.input.torch.factoryId + "/category4"}
            element={<Redirect to={paths.input.torch.category4} />}
          />
          {authState?.role !== "user-company" && (
            <Route
              path={`${paths.input.torch.category4}`}
              element={<GhgInputCategory4Page />}
            />
          )}
          {/* サプライヤー企業：可視化画面 */}
          <Route
            path={paths.category1.root}
            element={
              <Redirect to={paths.category1.year.replace(":year", year)} />
            }
          />
          <Route
            path={paths.category4.root}
            element={
              <Redirect to={paths.category4.year.replace(":year", year)} />
            }
          />
          <Route
            path={paths.category1.year}
            element={
              <Redirect
                to={paths.category1.tab.replace(":tab", "total-emission")}
              />
            }
          />
          <Route
            path={paths.category4.year}
            element={
              <Redirect
                to={paths.category4.tab.replace(":tab", "total-emission")}
              />
            }
          />
          {authState?.role !== "user-company" && (
            <>
              <Route
                path={`${paths.category1.tab}/*`}
                element={<CategoryNavigationPage />}
              />
              <Route
                path={`${paths.category4.tab}/*`}
                element={<CategoryNavigationPage />}
              />
            </>
          )}

          {/* ユーザー企業 */}
          {/* ユーザー企業：ダッシュボード */}
          <Route
            path={paths.user.dashboard.root}
            element={
              <Navigate to={paths.user.dashboard.year.replace(":year", year)} />
            }
          />
          {authState?.role === "user-company" && (
            <Route
              path={paths.user.dashboard.year}
              element={<UserDashboardPage />}
            />
          )}

          {/* ユーザー企業：可視化画面 */}
          <Route
            path={paths.user.category1.root}
            element={
              <Redirect to={paths.user.category1.year.replace(":year", year)} />
            }
          />
          <Route
            path={paths.user.category4.root}
            element={
              <Redirect to={paths.user.category4.year.replace(":year", year)} />
            }
          />
          <Route
            path={paths.user.category1.year}
            element={
              <Redirect
                to={paths.user.category1.tab.replace(":tab", "total-emission")}
              />
            }
          />
          <Route
            path={paths.user.category4.year}
            element={
              <Redirect
                to={paths.user.category4.tab.replace(":tab", "total-emission")}
              />
            }
          />
          {authState?.role === "user-company" && (
            <>
              <Route
                path={`${paths.user.category1.tab}/*`}
                element={<UserCategoryNavigationPage />}
              />
              <Route
                path={`${paths.user.category4.tab}/*`}
                element={<UserCategoryNavigationPage />}
              />
            </>
          )}

          {/* ユーザー企業：調達量入力画面 */}
          <Route
            path={paths.user.procurement.root}
            element={
              <Redirect
                to={paths.user.procurement.year.replace(":year", year)}
              />
            }
          />
          {authState?.role === "user-company" && (
            <Route
              path={paths.user.procurement.year}
              element={<UserProcurementAmountInputPage />}
            />
          )}

          {/* ユーザー企業：排出原単位比較画面 */}
          <Route
            path={paths.user.intensity.root}
            element={
              <Redirect to={paths.user.intensity.year.replace(":year", year)} />
            }
          />
          {authState?.role === "user-company" && (
            <Route
              path={paths.user.intensity.year}
              element={<UserIntensityPage />}
            />
          )}

          {/* ユーザー企業・サプライヤー企業共通 */}
          <Route
            path="*"
            element={
              authState?.role === "user-company" ? (
                <Redirect to={paths.user.procurement.root} />
              ) : (
                <Redirect to={paths.input.root} />
              )
            }
          />
        </Routes>
      </Box>

      <Box
        className={clsx(
          // ドロワーが開いたとき、コンテンツ領域もそれに応じて右にスライドさせる
          classes.drawerTransition,
          drawerHandle.isOpened && !isTablet && classes.shiftContent
        )}
      >
        <Footer />
      </Box>
    </Container>
  );
};
