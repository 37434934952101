import { DateTime } from "luxon";

import {
  Category4Api,
  GetCategory4ApiError,
} from "src/app/apis/category4-api/category4Api";
import { messages } from "src/app/configs";
import { AuthState, Category4FactoryDataForDisplay } from "src/app/models";
import { ToDateTimeYearFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

export type Category4DataForDisplay = {
  companyId: string;
  year: DateTime;
  factories: Category4FactoryDataForDisplay[];
};

export const useCategory4Data = (
  year?: string
): {
  isProcessing: boolean;
  category4DataForDisplay?: Category4DataForDisplay;
} => {
  const snackbar = useCustomSnackbar();
  const [, setAuthState] = useAuthState<AuthState>();

  const { isProcessing, data } = useAsyncEffect(async () => {
    if (year === undefined) {
      return;
    }
    const category4Api = new Category4Api();

    try {
      const res = await category4Api.getCategory4({
        year: year,
      });
      return { ...res, year: ToDateTimeYearFromString(res.year) };
    } catch (err) {
      if (!(err instanceof GetCategory4ApiError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          break;
        case "network":
          snackbar.error(messages.common.network, {
            preventDuplicate: true,
          });
          break;
        case "unknown":
          snackbar.error(messages.common.unknown, {
            preventDuplicate: true,
          });
          break;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }, [year]);

  return { isProcessing, category4DataForDisplay: data };
};
