import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import debounce from "debounce";
import { init, EChartsOption, EChartsType } from "echarts";
import React, { useEffect, useRef } from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";

const ResizeObserver = window.ResizeObserver ?? ResizeObserverPolyfill;

export interface EchartsProps {
  className?: string;
  option: EChartsOption;
}

const useStyles = makeStyles(() => ({
  container: {
    // モバイル版 chrome でグラフをタップすると、グラフ全体が青く点滅することを防ぐ
    // https://developer.mozilla.org/ja/docs/Web/CSS/-webkit-tap-highlight-color
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "-webkit-tap-highlight-color": "transparent",
  },
}));

export const Echarts: React.FC<EchartsProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  useEffect(() => {
    const targetDom = ref.current;
    if (targetDom === null) {
      throw new Error("ref will be not null");
    }

    let chart: EChartsType | undefined = undefined;

    const resizeWithDebounce = debounce(() => {
      chart?.resize();
    }, 100);

    // div の resize イベントを拾ってグラフを再描画する
    // 領域が確保されないうちに初期表示をしない
    // chart がすでにある場合にのみ resize を行う（chart のアニメーションを握りつぶさないようにするため）
    const observer = new ResizeObserver(() => {
      if (targetDom.offsetHeight === 0) {
        return;
      }
      if (chart === undefined) {
        chart = init(targetDom, undefined, { renderer: "svg" });
        chart.setOption(props.option, true);
        return;
      }
      resizeWithDebounce();
    });
    observer.observe(targetDom);
    return () => {
      observer.unobserve(targetDom);
      chart?.dispose();
    };
  }, [props.option]);

  return <div className={clsx(classes.container, props.className)} ref={ref} />;
};
