import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { Category1TotalEmissionTemplate } from "src/app/components/templates";
import {
  PaperCategory1DataForDisplay,
  ProcessCategory1DataForDisplay,
} from "src/app/hooks";
import { TotalEmission } from "src/app/models";
import { sumCategory1EmissionForTotal } from "src/app/utils/computeForVisuals";

export type Category1TotalEmissionPageProps = {
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay;
  isProcessing: boolean;
};

const convertCategory1DataToChartProps = (
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const chartData: TotalEmission[] =
    category1Data?.factories !== undefined
      ? sumCategory1EmissionForTotal(category1Data.factories)
      : [];

  return { chartData };
};

const convertCategory1DataToTableProps = (
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 2.25,
    },
    {
      field: "co2Emission",
      headerName: "年間GHG排出量(t-CO2eq/年)",
      flex: 1,
      renderCell: (params) => getEmission(params),
    },
  ];

  const tableData = [];
  if (category1Data?.factories !== undefined) {
    for (const factory of sumCategory1EmissionForTotal(
      category1Data?.factories
    )) {
      tableData.push({
        id: factory.id,
        factoryName: factory.dataName,
        co2Emission: Math.round(factory.value),
      });
    }
  }

  const fileName = `カテゴリ１合計（年）_${category1Data?.year.toFormat(
    "yyyy"
  )}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const Category1TotalEmissionPage: React.FC<
  Category1TotalEmissionPageProps
> = (props) => {
  const chartProps = convertCategory1DataToChartProps(props.category1Data);
  const tableProps = convertCategory1DataToTableProps(props.category1Data);

  return (
    <Category1TotalEmissionTemplate
      isProcessing={props.isProcessing}
      {...chartProps}
      {...tableProps}
    />
  );
};

const getEmission = (params: GridRenderCellParams) => {
  return (
    <Box display="flex">
      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : ""}
      </Box>
    </Box>
  );
};
