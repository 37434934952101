import { Card } from "@mui/material";
import React from "react";

import { theme } from "src/app/configs";

export type NestedCardProps = {
  className?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
};

export const NestedCard: React.FC<NestedCardProps> = (props) => {
  return (
    <Card
      className={props.className}
      sx={{
        padding: theme.spacing(3),
        backgroundColor: props.backgroundColor,
      }}
    >
      {props.children}
    </Card>
  );
};
