import { ReactElement } from "react";

import { Text } from "src/app/components/atoms";
import { RowInputField } from "src/app/components/molecules";
import { AutoCompleteType, InputModeOptions } from "src/lib/components/atoms";

export type RowTextFieldProps = {
  label: string | ReactElement;
  errorMessage?: string;
  maxLength?: number;
  secure?: boolean;
  autoComplete?: AutoCompleteType;
  inputMode?: InputModeOptions;
  value?: string;
  hasError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  valiant?: "h5" | "h6";
  extraElement?: ReactElement;
  disabledMessageAllocation?: boolean;
};

export const RowTextField: React.FC<RowTextFieldProps> = (props) => {
  return (
    <RowInputField
      label={props.label}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      valiant={props.valiant}
      extraElement={props.extraElement}
      disabledMessageAllocation={props.disabledMessageAllocation}
    >
      <Text
        maxLength={props.maxLength}
        secure={props.secure}
        autoComplete={props.autoComplete}
        inputMode={props.inputMode}
        value={props.value}
        hasError={props.hasError}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </RowInputField>
  );
};
