import { useEffect, useState } from "react";

/**
 * @deprecated
 * 代わりにuseQueryを使用する
 */
export const useAsyncEffect = <T>(
  asyncEffect: () => Promise<T | undefined>,
  deps: unknown[]
) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    let canceled = false;

    void (async () => {
      setIsProcessing(true);
      try {
        const res = await asyncEffect();
        if (!canceled) {
          setData(res);
        }
      } finally {
        if (!canceled) {
          setIsProcessing(false);
        }
      }
    })();

    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    data,
    isProcessing,
    setData,
    setIsProcessing,
  };
};
