import { Grid, Box, Typography } from "@mui/material";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { CompanyType } from "src/app/apis/model";
import { AppCard, LargeButton } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  RowTextField,
  SectionHeading,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  GhgInputHeading,
} from "src/app/components/organisms";
import { InHouseCategory4InputForm } from "src/app/components/pages/GhgInputPage/InHouseCategory4InputPage";
import { AmountBasedCo2Intensity } from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { FlexBox } from "src/lib/components/atoms";

type InHouseCategory4InputTemplateProps = {
  yearDropdownProps: YearDropdownProps;
  factoryName?: string;
  companyType?: Exclude<CompanyType, "user-company">;
  isProcessing: boolean;
  isEditable: boolean;
  form: InHouseCategory4InputForm;
  setForm: Dispatch<SetStateAction<InHouseCategory4InputForm>>;
  onClickBack: () => void;
  submitDialogProps: ConfirmationDialogProps;
  submitButtonProps: {
    onClick: () => void;
    isProcessing: boolean;
  };
  unSubmitDialogProps: ConfirmationDialogProps;
  unSubmitButtonProps: {
    onClick: () => void;
    isProcessing: boolean;
  };
};

export const InHouseCategory4InputTemplate: React.FC<
  InHouseCategory4InputTemplateProps
> = (props) => {
  const bindTextField = useBindTextField(props.form, (categoryAction) => {
    props.setForm(
      produce<InHouseCategory4InputForm>((form) => {
        if (categoryAction instanceof Function) {
          form.tonKmBasedCo2Intensity =
            categoryAction(form).tonKmBasedCo2Intensity;
        } else {
          form.tonKmBasedCo2Intensity = categoryAction.tonKmBasedCo2Intensity;
        }
      })
    );
  });

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography variant="h1">GHG算出情報入力（カテゴリ４）</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>

      <FlexBox mt={2}>
        <GhgInputHeading
          title={props.factoryName ?? ""}
          onClickBack={props.onClickBack}
        />
      </FlexBox>

      <Box mt={3}>
        <AppCard>
          <SectionHeading title="工場ごとの自社算定結果">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <RowTextField
                  label={"排出原単位 [kg-CO2eq/tkm]"}
                  {...bindTextField({
                    validator: AmountBasedCo2Intensity.Rule,
                    selector: (form) => form.tonKmBasedCo2Intensity,
                    isRequired: true,
                  })}
                  errorMessage={AmountBasedCo2Intensity.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={7} />
            </Grid>
          </SectionHeading>

          <FlexBox mt={3} justifyContent="center">
            {props.isEditable ? (
              <LargeButton
                label="確定"
                color="primary"
                variant="contained"
                {...props.submitButtonProps}
              />
            ) : (
              <LargeButton
                label="確定取消"
                color="secondary"
                variant="contained"
                {...props.unSubmitButtonProps}
              />
            )}
          </FlexBox>
        </AppCard>
      </Box>

      <FullScreenSpinner open={props.isProcessing} />
      <ConfirmationDialog {...props.submitDialogProps} />
      <ConfirmationDialog {...props.unSubmitDialogProps} />
    </Content>
  );
};
