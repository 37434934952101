import { Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme } from "src/app/configs";
import FactoryImage from "src/app/images/factory.png";
import FactoryImage2x from "src/app/images/factory@2x.png";
import { FlexBox, Image } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  image: {
    width: 35,
    height: 35,
    borderRadius: "5px",
    boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  },
  link: {
    marginLeft: theme.spacing(1.5),
    color: theme.palette.factory,
    fontSize: theme.typography.body2.fontSize,
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
}));

export type GhgInputHeadingProps = {
  title: string;
  onClickBack: () => void;
};

export const GhgInputHeading: React.FC<GhgInputHeadingProps> = (props) => {
  const classes = useStyles();
  return (
    <FlexBox alignItems="center">
      <Image
        alt="factory"
        className={classes.image}
        src={FactoryImage}
        src2x={FactoryImage2x}
      />
      <Link
        className={classes.link}
        variant="caption"
        onClick={props.onClickBack}
      >
        入力依頼一覧
      </Link>
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.arrow}
      >
        {" > "}
      </Typography>
      <Typography color="textPrimary" className={classes.title} variant="h3">
        {props.title}
      </Typography>
    </FlexBox>
  );
};
