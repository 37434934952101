/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type FuelType = (typeof FuelType)[keyof typeof FuelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FuelType = {
  lng: "lng",
  cityGas: "cityGas",
  lightOil: "lightOil",
  aHeavyOil: "aHeavyOil",
  cHeavyOil: "cHeavyOil",
  coal: "coal",
  rdf: "rdf",
  rpf: "rpf",
  biomass: "biomass",
  lpg: "lpg",
  kerosene: "kerosene",
} as const;
