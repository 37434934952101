import { Typography, Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactElement } from "react";

import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    borderLeft: `4px solid ${theme.palette.text.disabled}`,
    color: theme.palette.text.disabled,
    fontWeight: "bold",
  },
}));

export type SubSectionProps = {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
} & Omit<BoxProps, "title">;

export const SubSectionHeading: React.FC<SubSectionProps> = (props) => {
  const classes = useStyles();

  const { title, subtitle, children, ...restProps } = props;
  return (
    <Box {...restProps}>
      {/* このBoxがないと、Typographyが横いっぱいに広がってしまい、飾りが意図した位置に表示されない */}
      <FlexBox>
        <Typography className={classes.heading} variant="body1">
          {title}
        </Typography>
      </FlexBox>
      {subtitle !== undefined && <Box mt={1}>{subtitle}</Box>}
      <Box pt={2}>{children}</Box>
    </Box>
  );
};
