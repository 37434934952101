/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

/**
 * PaperMaterialType(子要素:Co2EmissionFactorType),ProcessMaterialTypeの親要素
 */
export type MaterialType = (typeof MaterialType)[keyof typeof MaterialType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialType = {
  usedPaper: "usedPaper",
  paperChip: "paperChip",
  internalVirginPulp: "internalVirginPulp",
  externalVirginPulp: "externalVirginPulp",
  sizingAgent: "sizingAgent",
  strengtheningAgent: "strengtheningAgent",
  aluminiumSulfate: "aluminiumSulfate",
  dye: "dye",
  starch: "starch",
  ink: "ink",
  varnish: "varnish",
} as const;
