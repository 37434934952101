import { Box, BoxProps } from "@mui/material";
import React from "react";

export interface FlexBoxProps extends BoxProps {
  children?: React.ReactNode;
}

export const FlexBox: React.FC<FlexBoxProps> = (props) => {
  const newProps = Object.assign<BoxProps, BoxProps>(
    {
      display: "flex",
    },
    props
  );
  return <Box {...newProps}>{props.children}</Box>;
};
