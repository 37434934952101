import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { CompanyType } from "src/app/apis/model";
import { Annotate } from "src/app/components/atoms";
import {
  Category1IntensityByClassificationChart,
  ChartAndTableView,
  DataField,
} from "src/app/components/organisms/";
import { Category1IntensityByClassificationType } from "src/app/models";

export type Category1IntensityByClassificationTemplateProps = {
  isProcessing: boolean;
  unit: string;
  companyType: Exclude<CompanyType, "user-company">;

  // グラフ用Props
  chartData: Category1IntensityByClassificationType[];

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

export const Category1IntensityByClassificationTemplate: React.FC<
  Category1IntensityByClassificationTemplateProps
> = (props) => {
  return (
    <ChartAndTableView
      title="カテゴリ1　工場別 × 分類別（プロセス排出原単位）"
      subtitle={
        <Annotate>
          TORCH算定結果のみを表示。自社算定結果を入力した工場情報は表示されません。
        </Annotate>
      }
      chartElement={
        <Category1IntensityByClassificationChart
          data={props.chartData}
          unit={props.unit}
          companyType={props.companyType}
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};
