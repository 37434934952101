import { Box, BoxProps, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { ReactElement } from "react";

import { AppTheme } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  container: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.ghgBorder}`,
  },
  titleArea: {
    backgroundColor: theme.palette.ghgBorder,
    minHeight: 54,
  },
}));

export type TitledAreaProps = {
  title: ReactElement | string;
  subtitle?: ReactElement | string;
  collapse?: boolean;
} & Omit<BoxProps, "title">;

export const TitledArea: React.FC<TitledAreaProps> = (props) => {
  const classes = useStyles();
  const { className, title, children, ...restProps } = props;
  return (
    <Box {...restProps} className={clsx(classes.container, props.className)}>
      <FlexBox px={3} py={1} className={classes.titleArea} alignItems="center">
        {title}
      </FlexBox>
      <Collapse in={props.collapse !== true}>
        {props.subtitle !== undefined && (
          <Box mt={1} pl={4}>
            {props.subtitle}
          </Box>
        )}
        <Box p={3}>{children}</Box>
      </Collapse>
    </Box>
  );
};
