/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetUsersMe200,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
} from "../model";
import { rest } from "msw";
import { faker } from "@faker-js/faker";
import { CompanyType } from "../model";
import { customMutator } from "../custom-mutator";
import type { ErrorType } from "../custom-mutator";

/**
 * セッションに紐づくユーザの情報を取得する
 * @summary ユーザ取得API
 */
export const getUsersMe = (signal?: AbortSignal) => {
  return customMutator<GetUsersMe200>({
    url: `/users/me`,
    method: "get",
    signal,
  });
};

export const getGetUsersMeQueryKey = () => [`/users/me`];

export type GetUsersMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersMe>>
>;
export type GetUsersMeQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetUsersMe = <
  TData = Awaited<ReturnType<typeof getUsersMe>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getUsersMe>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersMe>>> = ({
    signal,
  }) => getUsersMe(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUsersMe>>, TError, TData>(
    { queryKey, queryFn, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getGetUsersMeMock = () => ({
  companyId: faker.random.word(),
  loginId: faker.random.word(),
  userId: faker.random.word(),
  userName: faker.random.word(),
  role: faker.helpers.arrayElement(Object.values(CompanyType)),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
});

export const getUsersMSW = () => [
  rest.get("*/users/me", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetUsersMeMock())
    );
  }),
];
