import React from "react";

export type FastFlexBoxProps = {
  className?: string;
  flexDirection?: "row" | "column";
  alignItems?: "center" | "flex-start" | "flex-end";
  justifyContent?: "center" | "flex-start" | "flex-end";
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  borderRadius?: number;
  children?: React.ReactNode;
};

export const FastFlexBox: React.FC<FastFlexBoxProps> = (props) => {
  const { className, ...style } = props;
  return (
    <div className={className} style={{ display: "flex", ...style }}>
      {props.children}
    </div>
  );
};
