import { Box, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import { SectionHeading } from "src/app/components/molecules";
import { ProcurementInput } from "src/app/components/organisms";
import { ProcurementsForm } from "src/app/components/pages";
import { messages } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

export type ProcurementListProps = {
  formByUserFactory: ProcurementsForm["userFactories"][number];
  setForm: Dispatch<SetStateAction<ProcurementsForm | undefined>>;
  disabled: boolean;
};

export const ProcurementList: React.FC<ProcurementListProps> = React.memo(
  (props) => {
    const formByUserFactory = props.formByUserFactory;

    return (
      <SectionHeading title={formByUserFactory.factoryName}>
        {formByUserFactory.routes.length === 0 ? (
          <FlexBox my={2} justifyContent="center">
            <Typography variant="body1">
              {messages.userProcurementAmount.nothing}
            </Typography>
          </FlexBox>
        ) : (
          formByUserFactory.routes.map((route) => {
            return (
              <Box mb={2.5} key={route.routeId}>
                <ProcurementInput
                  formByRoute={route}
                  setForm={props.setForm}
                  factoryId={formByUserFactory.factoryId}
                  routeId={route.routeId}
                  disabled={
                    props.disabled || route.supplierFactories.length === 0
                  }
                />
              </Box>
            );
          })
        )}
      </SectionHeading>
    );
  }
);
