import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

import { AppTheme } from "src/app/configs";
import {
  AutoCompleteType,
  InputModeOptions,
  TextInput,
} from "src/lib/components/atoms";

export type TextProps = {
  maxLength?: number;
  secure?: boolean;
  autoComplete?: AutoCompleteType;
  inputMode?: InputModeOptions;
  value?: string;
  hasError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  label: {
    backgroundColor: theme.palette.background.paper,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& input": {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      fontSize: theme.typography.body2.fontSize,
      height: "19px",
    },
  },
  disabled: {
    backgroundColor: theme.palette.disabledBackground,
  },
}));

export const Text: React.FC<TextProps> = (props) => {
  const classes = useStyles();

  return (
    <TextInput
      maxLength={props.maxLength}
      secure={props.secure}
      autoComplete={props.autoComplete}
      inputMode={props.inputMode}
      value={props.value}
      hasError={props.hasError}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant="outlined"
      className={clsx(classes.label, props.disabled && classes.disabled)}
      disabled={props.disabled}
    />
  );
};
