import { Checkbox, Box, Typography } from "@mui/material";

import { FlexBox } from "src/lib/components/atoms";

// TitledAreaのタイトル部分に表示する部品 (チェックボックス付き)
type TitledAreaContentsProps = {
  title: string;
  onClick: () => void;
  checked: boolean;
  disabled: boolean;
};

export const TitledAreaContents: React.FC<TitledAreaContentsProps> = (
  props
) => {
  return (
    <FlexBox alignItems="center">
      <Checkbox
        onClick={props.onClick}
        size="small"
        checked={props.checked}
        disabled={props.disabled}
        color="secondary"
      />
      <Box ml={1}>
        <Typography variant="h4">{props.title}</Typography>
      </Box>
    </FlexBox>
  );
};
