import { GridCellValue } from "@mui/x-data-grid";

import {
  GetScope3ByFactoryQueryResult,
  OldGetScope3ByStandardQueryResult,
} from "src/app/apis/client/default";
import { CompanyType } from "src/app/apis/model";
import {
  Category4FactoryDataForDisplay,
  PaperFactoryCategory1FactoryDataForDisplay,
  ProcessFactoryCategory1FactoryDataForDisplay,
  TotalEmission,
} from "src/app/models";
import { sum } from "src/lib/utils/mathUtil";

// 年間合計算出処理
export const sumCategory1EmissionForTotal = (
  factories:
    | PaperFactoryCategory1FactoryDataForDisplay[]
    | ProcessFactoryCategory1FactoryDataForDisplay[]
): (TotalEmission & { id: string })[] => {
  const data = [];
  for (const factory of factories) {
    if (factory.intensityType === "torch-based") {
      data.push({
        id: factory.factoryId,
        dataName: factory.factoryNameForSelf,
        value: sum(...factory.byClassification.map((it) => it.co2Emission)),
      });
    }
  }
  return data;
};

export const sumCategory4EmissionForTotal = (
  factories: Category4FactoryDataForDisplay[]
): (TotalEmission & { id: string })[] => {
  const data = [];
  for (const factory of factories) {
    if (factory.intensityType === "torch-based") {
      data.push({
        id: factory.factoryId,
        dataName: factory.factoryNameForSelf,
        value: sum(factory.co2Emission),
      });
    }
  }
  return data;
};

// 可視化画面向け 単位取得
export const getUnitForCo2Intensity = (companyType: CompanyType): string => {
  if (companyType === "paper-supplier") {
    return "kg-CO2eq/t";
  } else {
    return "kg-CO2eq/m2";
  }
};

// 可視化画面向け 工程取得
export const getProcessNameForVisual = (
  companyType: "paper-supplier" | "process-supplier",
  category: "category1" | "category4"
): string => {
  const processNameTable = {
    category1: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "paper-supplier": "製紙工程",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "process-supplier": "加工工程",
    },
    category4: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "paper-supplier": "輸送工程",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "process-supplier": "輸送工程",
    },
  };
  return processNameTable[category][companyType];
};

// 可視化画面向け プロダクト名取得
export const getProductName = (
  companyType: "paper-supplier" | "process-supplier"
): string => {
  return companyType === "paper-supplier" ? "製品" : "規格";
};

export const formatEmission = (value: number) => {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
};

export const formatIntensity = (value: number) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
};

// 可視化画面向け 企業種別名取得
export const getCompanyTypeName = (companyType: CompanyType) => {
  const companyTypeName = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "paper-supplier": "製紙",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "process-supplier": "加工",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "user-company": "ユーザー",
  };

  return companyTypeName[companyType];
};

// 文字列に数値がある場合のソート関数
export const compareFunc = (a: GridCellValue, b: GridCellValue) => {
  const sa = String(a).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  const sb = String(b).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  return sa < sb ? -1 : sa > sb ? 1 : 0;
};

//TODO:画面実装時にOldから置き換える
// 規格別用データ整形
export const convertByStandardDataToChartProps = (
  data?: OldGetScope3ByStandardQueryResult
) => {
  if (data === undefined) {
    return [];
  }

  const res = data.bySupplier.map((it) => {
    const standards = it.bySupplierFactory.flatMap(
      (supplierFactory) => supplierFactory.byStandard
    );

    const standardTypes = [
      ...new Set(standards.map((standard) => standard.standardType)),
    ].sort(compareFunc);

    return {
      companyId: it.companyId,
      companyType: it.companyType,
      companyName: it.companyName,
      category1Total: sum(
        ...standards.map((standard) => standard.category1Co2Emission)
      ),
      category4Total: sum(
        ...standards.map((standard) => standard.category4Co2Emission)
      ),
      byStandard: standardTypes.map((standardType) => {
        const filteredStandards = standards.filter(
          (type) => standardType === type.standardType
        );
        return {
          ...filteredStandards[0],
          category1Total: sum(
            ...filteredStandards.map(
              (standard) => standard.category1Co2Emission
            )
          ),
          category4Total: sum(
            ...filteredStandards.map(
              (standard) => standard.category4Co2Emission
            )
          ),
        };
      }),
    };
  });

  return res;
};

// 自社工場別用データ整形
export const convertByFactoryDataToChartProps = (
  data?: GetScope3ByFactoryQueryResult
) => {
  if (data === undefined) {
    return [];
  }

  const res = data.bySupplier.map((it) => {
    const userFactories = it.bySupplierFactory.flatMap(
      (supplierFactory) => supplierFactory.byUserFactory
    );

    const factoryIds = [
      ...new Set(userFactories.map((userFactory) => userFactory.factoryId)),
    ].sort();

    return {
      companyId: it.companyId,
      companyType: it.companyType,
      companyName: it.companyName,
      category1Total: sum(
        ...userFactories.map((userFactory) => userFactory.category1Co2Emission)
      ),
      category4Total: sum(
        ...userFactories.map((userFactory) => userFactory.category4Co2Emission)
      ),
      byUserFactory: factoryIds.map((factoryId) => {
        const filteredUserFactories = userFactories.filter(
          (id) => factoryId === id.factoryId
        );
        return {
          ...filteredUserFactories[0],
          category1Total: sum(
            ...filteredUserFactories.map(
              (factory) => factory.category1Co2Emission
            )
          ),
          category4Total: sum(
            ...filteredUserFactories.map(
              (factory) => factory.category4Co2Emission
            )
          ),
        };
      }),
    };
  });

  return res;
};

// 調達量入力、比較画面向け
export const concatFactoryName = (
  supplierFactories: {
    factoryId?: string;
    factoryNameForOthers?: string;
    companyId?: string;
    companyName?: string;
  }[]
) => {
  if (supplierFactories.length === 0) {
    return "-";
  }

  // 重複のない企業ID
  const uniqueCompanyId = [
    ...new Set(
      supplierFactories.map((supplierFactory) => {
        return supplierFactory.companyId;
      })
    ),
  ];

  const displayNameArray = uniqueCompanyId.map((companyId) => {
    const factoriesByCompany = supplierFactories.filter(
      (supplierFactory) => supplierFactory.companyId === companyId
    );
    if (factoriesByCompany.find((it) => it.factoryId === "other")) {
      return "ケース買いその他";
    }
    // 企業IDで絞り込んでいるため固定で1つ目の要素の企業名を使う
    const companyName = factoriesByCompany[0].companyName;
    // 工場名の配列
    const factoriesNameByCompany = factoriesByCompany.map(
      (factoryNameByCompany) => {
        return factoryNameByCompany.factoryNameForOthers;
      }
    );
    // 前提として、企業名と工場名が1対1の際は、factoryNameForOthersは「-」としてDBに登録される(「北海道 北海道工場」にならないようにするため)
    // 工場名が全て「-」の場合は、企業名のみ返す
    const effectiveFactoriesByCompany = factoriesNameByCompany.filter(
      (item) => item !== "-"
    );
    if (effectiveFactoriesByCompany.join() === "") {
      return companyName;
    }
    return companyName + " " + effectiveFactoriesByCompany.join(", ");
  });
  return displayNameArray.join(" / ");
};
