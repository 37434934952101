import { GetApp } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  AppCard,
  DropdownProps,
  NestedCard,
  RankBadge,
} from "src/app/components/atoms";
import {
  EmissionUnitSummary,
  FactoryAndStandardDropdown,
  ImageHeading,
  SectionHeading,
  YearDropdown,
  RouteListTable,
  YearDropdownProps,
  RouteListRowType,
  FullScreenSpinner,
  Content,
} from "src/app/components/molecules";
import { AppTheme, messages } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

export type UserIntensityTemplateProps = {
  yearDropdownProps: YearDropdownProps;
  factoryDropdownProps: DropdownProps;
  standardDropdownProps: DropdownProps;
  isProcessing: boolean;
  data: {
    routeId: string;
    total: number;
    category1Total: number;
    category4Total: number;
    tableData: RouteListRowType[];
  }[];
  getCsv: () => void;
  isNoData: boolean;
  isApiError: boolean;
};
const useStyles = makeStyles<AppTheme>((theme) => ({
  appCard: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.procurementBackground,
  },
  link: {
    cursor: "pointer",
    fontSize: theme.typography.body1.fontSize,
  },
  linkIcon: {
    verticalAlign: "middle",
  },
}));

export const UserIntensityTemplate: React.FC<UserIntensityTemplateProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Content>
      <FlexBox justifyContent="space-between">
        <ImageHeading title="段ボール" variant="cardBoard" />
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <AppCard className={classes.appCard}>
        <Box>
          <SectionHeading title="サプライヤー別の排出原単位の比較" />
          {props.factoryDropdownProps.items.length !== 0 &&
            props.standardDropdownProps.items.length !== 0 && (
              <FlexBox justifyContent="space-between">
                <FactoryAndStandardDropdown
                  factory={props.factoryDropdownProps}
                  standard={props.standardDropdownProps}
                />
                <Box alignSelf="end">
                  <Link
                    onClick={props.getCsv}
                    color="textPrimary"
                    className={classes.link}
                    sx={{
                      textDecoration: "none",
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      ":hover": { textDecoration: "underline" },
                    }}
                  >
                    <GetApp fontSize="small" className={classes.linkIcon} />
                    全行CSVでタウンロード
                  </Link>
                </Box>
              </FlexBox>
            )}

          {props.data.length === 0 &&
            !props.isProcessing &&
            !props.isApiError && (
              <FlexBox justifyContent="center" mt={3}>
                <Typography>{messages.userIntensity.noDataMatch}</Typography>
              </FlexBox>
            )}
          {props.isNoData && (
            <FlexBox justifyContent="center" mt={3}>
              <Typography>{messages.userIntensity.noDataError}</Typography>
            </FlexBox>
          )}
          {props.data.map((data, index) => {
            return (
              <Box mt={3} key={data.routeId}>
                <NestedCard>
                  <FlexBox mb={3}>
                    <FlexBox
                      width="8.0rem"
                      height="6.0rem"
                      justifyContent="flex-start"
                      pr={2}
                      alignItems="center"
                    >
                      <FlexBox
                        width="6.0rem"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <RankBadge rank={index + 1} />
                      </FlexBox>
                    </FlexBox>
                    <EmissionUnitSummary
                      total={data.total}
                      category1={data.category1Total}
                      category4={data.category4Total}
                    />
                  </FlexBox>
                  <RouteListTable data={data.tableData} />
                </NestedCard>
              </Box>
            );
          })}
        </Box>
      </AppCard>
      <FullScreenSpinner open={props.isProcessing} />
    </Content>
  );
};
