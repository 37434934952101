import { Dialog as MuiDialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "4px",
    // モーダルが横に間延びしすぎることを防ぐ
    maxWidth: 440,
  },
}));

export const Dialog: React.FC<DialogProps> = (props) => {
  const classes = useStyles();
  return (
    <MuiDialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        className: classes.paper,
      }}
    >
      {props.children}
    </MuiDialog>
  );
};
