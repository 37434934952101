/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type ClassificationType =
  (typeof ClassificationType)[keyof typeof ClassificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClassificationType = {
  privateSteam: "privateSteam",
  privatePower: "privatePower",
  purchasedPower: "purchasedPower",
  purchasedSteam: "purchasedSteam",
  certificate: "certificate",
  waste: "waste",
  drainage: "drainage",
  water: "water",
  mainMaterial: "mainMaterial",
  chemicals: "chemicals",
  dye: "dye",
  starch: "starch",
  ink: "ink",
  varnish: "varnish",
} as const;
