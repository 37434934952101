import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { Dropdown, DropdownProps } from "src/app/components/atoms";
import {
  ChartAndTableView,
  DataField,
  SingleBarChart,
} from "src/app/components/organisms";
import { TotalEmission } from "src/app/models";
import { FlexBox } from "src/lib/components/atoms";

export type Category4IntensityByDestinationTemplateProps = {
  isProcessing: boolean;

  // グラフ用Propsの追加
  chartData: TotalEmission[];
  factoryDropdownProps: DropdownProps;

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

const useStyles = makeStyles((theme) => ({
  dropDown: { maxWidth: 150, width: "100%" },
}));

export const Category4IntensityByDestinationTemplate: React.FC<
  Category4IntensityByDestinationTemplateProps
> = (props) => {
  const classes = useStyles();

  return (
    <ChartAndTableView
      title="カテゴリ4　工場 × 納品先別（原単位）"
      dropdownElement={
        <FlexBox alignItems="center">
          <Typography variant="h4">工場</Typography>
          <Box className={classes.dropDown} ml={1}>
            <Dropdown {...props.factoryDropdownProps} />
          </Box>
        </FlexBox>
      }
      chartElement={
        <SingleBarChart
          data={props.chartData}
          unit={"(kg-CO2eq/tkm)"}
          barColor={["#8678B4", "#47397F"]}
          chartType="normal"
          intensity
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};
