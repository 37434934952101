import makeStyles from "@mui/styles/makeStyles";
import {
  DataGrid,
  GridColumns,
  GridLocaleText,
  GridRowModel,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid";

import { theme } from "src/app/configs";

const useStyles = makeStyles(() => ({
  dataGridRows: {
    backgroundColor: "#FAFAFA",
    lineHeight: "51px",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&.Mui-selected": {
      backgroundColor: "#FAFAFA !important",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&.Mui-selected:hover": {
      backgroundColor: "inherit !important",
    },

    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiDataGrid-cell": {
      borderRight: `1px solid rgba(224, 224, 224, 1)`,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiDataGrid-cell--withRenderer": {
      display: "block",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiDataGrid-cell:last-of-type": {
      borderRight: 0,
    },
  },
}));

type GridSlotsComponentReplace = {
  Toolbar: React.JSXElementConstructor<any> | null;
  ExportIcon: React.JSXElementConstructor<any>;
};

type CustomDataGridProps = {
  components?: GridSlotsComponentReplace;
  componentsProps?: GridSlotsComponentsProps;
  rows: readonly GridRowModel[];
  columns: GridColumns;
  localeText?: Partial<GridLocaleText>;
  variant: "ghgHeader" | "cate1Header" | "cate4Header";
};

export const CustomDataGrid = (props: CustomDataGridProps) => {
  const classes = useStyles(props);
  const headerColor = theme.palette[props.variant];

  return (
    <DataGrid
      getRowClassName={() => classes.dataGridRows}
      components={props.components}
      componentsProps={props.componentsProps}
      rows={props.rows}
      columns={props.columns}
      localeText={props.localeText}
      autoHeight
      hideFooter
      disableColumnMenu
      disableSelectionOnClick
      sx={{
        border: 0,
        fontSize: theme.typography.body2.fontSize,
        // defaultのスタイルを無効化する
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },

        // header部分のスタイリング
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeader:hover": {
          backgroundColor: headerColor.hover,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: headerColor.default,
          borderRight: `2px solid ${theme.palette.primary.contrastText}`,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeader:last-of-type": {
          borderRight: 0,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
        },

        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& .MuiDataGrid-cellContent": {
          pointerEvents: "none",
        },
      }}
    />
  );
};
