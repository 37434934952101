import BigNumber from "bignumber.js";
import { JsonArray, JsonObject, JsonValue } from "type-fest";
import { v4 as uuid } from "uuid";

import {
  EnergyForm,
  Field,
  initCertificateForm,
  initElectricPowerPurchaseForm,
  initEnergyForm,
  initMachineForm,
  initManufacturingForm,
  initTransportByRailForm,
  initTransportForm,
  initWasteForm,
  ManufacturingForm,
  TransportForm,
  WasteDetailForm,
  WasteForm,
} from "./ghgInputForm";

import {
  GenericEnergyInput,
  GenericManufacturingInput,
  GenericTransportInput,
  GenericWasteInput,
} from "src/app/apis/ghg-input-api/ghgInputType";
import {
  MaterialType,
  PowerType,
  WasteMethods,
  FuelType,
  WasteType,
  TransportFuelType,
} from "src/app/apis/model";
import {
  fuelTypes,
  wasteTypes,
  materialTypes,
  transportFuelTypes,
} from "src/app/codes";
import { objectToEntries } from "src/lib/utils/objectUtil";

export const convertEnergyLocalStorageToForm = (
  energyInput: JsonObject | undefined
): EnergyForm => {
  if (energyInput === undefined) {
    return initEnergyForm();
  }
  return {
    boiler: {
      isChecked: validateBoolean((energyInput as any)?.boiler?.isChecked),
      fuelConsumptions: Object.fromEntries(
        fuelTypes.map((fuelType) => {
          const obj = [
            fuelType,
            validateField(
              (energyInput as any)?.boiler?.fuelConsumptions?.[fuelType]
            ),
          ];
          return obj;
        })
      ) as Record<FuelType, Field>,
      occurredSteam: validateField((energyInput as any)?.boiler?.occurredSteam),
    },
    generator: {
      isChecked: validateBoolean((energyInput as any)?.generator?.isChecked),
      fuelConsumptions: Object.fromEntries(
        fuelTypes.map((fuelType) => {
          const obj = [
            fuelType,
            validateField(
              (energyInput as any)?.generator?.fuelConsumptions?.[fuelType]
            ),
          ];
          return obj;
        })
      ) as Record<FuelType, Field>,
      occurredElectricPower: validateField(
        (energyInput as any)?.generator?.occurredElectricPower
      ),
    },
    coGenerator: {
      isChecked: validateBoolean((energyInput as any)?.coGenerator?.isChecked),
      fuelConsumptions: Object.fromEntries(
        fuelTypes.map((fuelType) => {
          const obj = [
            fuelType,
            validateField(
              (energyInput as any)?.coGenerator?.fuelConsumptions?.[fuelType]
            ),
          ];
          return obj;
        })
      ) as Record<FuelType, Field>,
      occurredSteamForSteam: validateField(
        (energyInput as any)?.coGenerator?.occurredSteamForSteam
      ),
      occurredSteamForElectricPower: validateField(
        (energyInput as any)?.coGenerator?.occurredSteamForElectricPower
      ),
      occurredElectricPower: validateField(
        (energyInput as any)?.coGenerator?.occurredElectricPower
      ),
    },
    solar: {
      isChecked: validateBoolean((energyInput as any)?.solar?.isChecked),
      occurredElectricPower: validateField(
        (energyInput as any)?.solar?.occurredElectricPower
      ),
    },
    hydroPower: {
      isChecked: validateBoolean((energyInput as any)?.hydroPower?.isChecked),
      occurredElectricPower: validateField(
        (energyInput as any)?.hydroPower?.occurredElectricPower
      ),
    },
    energySale: {
      isChecked: validateBoolean((energyInput as any)?.energySale?.isChecked),
      steam: validateField((energyInput as any)?.energySale?.steam),
      electricPower: validateField(
        (energyInput as any)?.energySale?.electricPower
      ),
    },
    electricPowerPurchases:
      energyInput?.electricPowerPurchases !== undefined
        ? validateArray((energyInput as any)?.electricPowerPurchases).map(
            (electricPowerPurchase: any) => ({
              ...initElectricPowerPurchaseForm(),
              ...electricPowerPurchase,
            })
          )
        : [initElectricPowerPurchaseForm()],
    steamPurchase: {
      isChecked: validateBoolean(
        (energyInput as any)?.steamPurchase?.isChecked
      ),
      emissionFactor: {
        field: validateField(
          (energyInput as any)?.steamPurchase?.emissionFactor?.field
        ),
        isChecked: validateBoolean(
          (energyInput as any)?.steamPurchase?.emissionFactor?.isChecked
        ),
      },
      steamAmount: validateField(
        (energyInput as any)?.steamPurchase?.steamAmount
      ),
    },
    greenCertificates: {
      isChecked: validateBoolean(
        (energyInput as any)?.greenCertificates?.isChecked
      ),
      certificates:
        (energyInput as any)?.greenCertificates?.certificates !== undefined
          ? validateArray(
              (energyInput as any).greenCertificates.certificates
            )?.map((certificate: any) => ({
              ...initCertificateForm(),
              ...certificate,
            }))
          : [initCertificateForm()],
    },
    jCredits: {
      isChecked: validateBoolean((energyInput as any)?.jCredits?.isChecked),
      certificates:
        (energyInput as any)?.jCredits?.certificates !== undefined
          ? validateArray((energyInput as any).jCredits.certificates)?.map(
              (certificate: any) => ({
                ...initCertificateForm(),
                ...certificate,
              })
            )
          : [initCertificateForm()],
    },
  };
};

export const convertEnergyFormToInput = (
  energyForm: EnergyForm
): GenericEnergyInput<BigNumber> | undefined => {
  return {
    boiler: energyForm.boiler.isChecked
      ? {
          fuelConsumptions: objectToEntries(
            energyForm.boiler.fuelConsumptions
          ).map(([fuelType, amount]) => ({
            fuelType,
            amount: new BigNumber(amount.value),
          })),
          occurredSteam: new BigNumber(energyForm.boiler.occurredSteam.value),
        }
      : undefined,
    generator: energyForm.generator.isChecked
      ? {
          fuelConsumptions: objectToEntries(
            energyForm.generator.fuelConsumptions
          ).map(([fuelType, amount]) => ({
            fuelType,
            amount: new BigNumber(amount.value),
          })),
          occurredElectricPower: new BigNumber(
            energyForm.generator.occurredElectricPower.value
          ),
        }
      : undefined,
    coGenerator: energyForm.coGenerator.isChecked
      ? {
          fuelConsumptions: objectToEntries(
            energyForm.coGenerator.fuelConsumptions
          ).map(([fuelType, amount]) => ({
            fuelType,
            amount: new BigNumber(amount.value),
          })),
          occurredSteamForSteam: new BigNumber(
            energyForm.coGenerator.occurredSteamForSteam.value
          ),
          occurredSteamForElectricPower: new BigNumber(
            energyForm.coGenerator.occurredSteamForElectricPower.value
          ),
          occurredElectricPower: new BigNumber(
            energyForm.coGenerator.occurredElectricPower.value
          ),
        }
      : undefined,
    solar: energyForm.solar.isChecked
      ? {
          occurredElectricPower: new BigNumber(
            energyForm.solar.occurredElectricPower.value
          ),
        }
      : undefined,
    hydroPower: energyForm.hydroPower.isChecked
      ? {
          occurredElectricPower: new BigNumber(
            energyForm.hydroPower.occurredElectricPower.value
          ),
        }
      : undefined,
    energySale: energyForm.energySale.isChecked
      ? {
          steam: new BigNumber(energyForm.energySale.steam.value),
          electricPower: new BigNumber(
            energyForm.energySale.electricPower.value
          ),
        }
      : undefined,
    electricPowerPurchases: energyForm.electricPowerPurchases.map(
      (electricPowerPurchase) => ({
        emissionFactor: new BigNumber(
          electricPowerPurchase.emissionFactor.value
        ),
        amount: new BigNumber(electricPowerPurchase.amount.value),
      })
    ),
    steamPurchase: energyForm.steamPurchase.isChecked
      ? {
          emissionFactor: !energyForm.steamPurchase.emissionFactor.isChecked
            ? new BigNumber(energyForm.steamPurchase.emissionFactor.field.value)
            : undefined,
          amount: new BigNumber(energyForm.steamPurchase.steamAmount.value),
        }
      : undefined,
    greenCertificates: energyForm.greenCertificates.isChecked
      ? energyForm.greenCertificates.certificates.map((certificate) => ({
          powerType: certificate.powerType.value as PowerType,
          amount: new BigNumber(certificate.amount.value),
        }))
      : undefined,
    jCredits: energyForm.jCredits.isChecked
      ? energyForm.jCredits.certificates.map((certificate) => ({
          powerType: certificate.powerType.value as PowerType,
          amount: new BigNumber(certificate.amount.value),
        }))
      : undefined,
  };
};

export const convertWasteLocalStorageToForm = (
  wasteInput: JsonObject | undefined
): WasteForm => {
  if (wasteInput === undefined) {
    return initWasteForm();
  }
  return {
    wastes: Object.fromEntries(
      wasteTypes.map((wasteType) => {
        const obj = [
          wasteType,
          {
            method: validateField(
              (wasteInput as any).wastes?.[wasteType]?.method
            ),
            amount: validateField(
              (wasteInput as any)?.wastes?.[wasteType]?.amount
            ),
          },
        ];
        return obj;
      })
    ) as Record<WasteType, WasteDetailForm>,
    totalInHouseIncinerationCo2Emission: validateFieldNotBlank(
      wasteInput?.totalInHouseIncinerationCo2Emission
    ),
    drainageMethod: validateField(wasteInput?.drainageMethod),
    inHouseDrainageElectricPower: validateFieldNotBlank(
      wasteInput?.inHouseDrainageElectricPower
    ),
    outSourceDrainageAmount: validateFieldNotBlank(
      wasteInput?.outSourceDrainageAmount
    ),
  };
};

export const convertWasteFormToInput = (
  wasteForm: WasteForm
): GenericWasteInput<BigNumber> | undefined => {
  return {
    wastes: objectToEntries(wasteForm.wastes)
      .filter(([, wasteDetail]) => wasteDetail.amount.value !== "")
      .map(([wasteType, wasteDetail]) => ({
        wasteType,
        method: wasteDetail.method.value as WasteMethods,
        amount: new BigNumber(wasteDetail.amount.value),
      })),
    totalInHouseIncinerationCo2Emission: new BigNumber(
      wasteForm.totalInHouseIncinerationCo2Emission.value
    ),
    inHouseDrainageElectricPower:
      wasteForm.drainageMethod.value === "inHouseDrainage"
        ? new BigNumber(wasteForm.inHouseDrainageElectricPower.value)
        : undefined,
    outSourceDrainageAmount:
      wasteForm.drainageMethod.value === "outSourceDrainage"
        ? new BigNumber(wasteForm.outSourceDrainageAmount.value)
        : undefined,
  };
};

export const convertManufacturingLocalStorageToForm = (
  manufacturingInput: JsonObject | undefined,
  productTypes?: readonly string[]
): ManufacturingForm => {
  if (manufacturingInput === undefined) {
    return initManufacturingForm(productTypes);
  }

  return {
    materialCo2EmissionFactor: Object.fromEntries(
      materialTypes.map((materialType) => {
        const obj = [
          materialType,
          {
            isChecked: validateBoolean(
              (manufacturingInput as any)?.materialCo2EmissionFactor?.[
                materialType
              ]?.isChecked
            ),
            field: validateField(
              (manufacturingInput as any)?.materialCo2EmissionFactor?.[
                materialType
              ]?.field
            ),
          },
        ];
        return obj;
      })
    ) as Record<MaterialType, { field: Field; isChecked: boolean }>,
    machines:
      manufacturingInput?.machines !== undefined
        ? validateArray((manufacturingInput as any).machines)?.map(
            (machine: any) => ({
              key: uuid(),
              machineName: { value: machine.machineName?.value ?? "" },
              steamConsumption: validateField(machine.steamConsumption),
              electricPowerConsumption: validateField(
                machine.electricPowerConsumption
              ),
              industrialWaterConsumption: validateField(
                machine.industrialWaterConsumption
              ),
              groundWaterConsumption: validateField(
                machine.groundWaterConsumption
              ),

              materialConsumptions: Object.fromEntries(
                materialTypes.map((materialType) => {
                  const obj = [
                    materialType,
                    validateField(machine.materialConsumptions?.[materialType]),
                  ];
                  return obj;
                })
              ) as Record<MaterialType, Field>,

              paperDeals:
                productTypes === undefined
                  ? []
                  : productTypes.map((it) => {
                      const value = machine.paperDeals
                        .find((paper: any) => it === paper.productType)
                        ?.field?.value.toString();
                      const error = machine.paperDeals.find(
                        (paper: any) => it === paper.productType
                      )?.field?.error;

                      return {
                        productType: it,
                        field: {
                          value: value !== undefined ? value : "",
                          error: error,
                        },
                      };
                    }),
            })
          )
        : [initMachineForm(productTypes)],
    amount: validateField(manufacturingInput.amount),
  };
};

export const convertManufacturingFormToInput = (
  manufacturingForm: ManufacturingForm
): GenericManufacturingInput<BigNumber> => {
  return {
    materialCo2EmissionFactor: objectToEntries(
      manufacturingForm.materialCo2EmissionFactor
    )
      .filter(([, co2EmissionFactor]) => co2EmissionFactor.field.value !== "")
      .map(([materialType, co2EmissionFactor]) => ({
        materialType,
        co2EmissionFactor: new BigNumber(co2EmissionFactor.field.value),
      })),
    machines: manufacturingForm.machines.map((machine) => ({
      machineName: machine.machineName.value,
      steamConsumption: new BigNumber(
        // 加工のときは入力されないので0扱いする
        machine.steamConsumption.value === ""
          ? "0"
          : machine.steamConsumption.value
      ),
      electricPowerConsumption: new BigNumber(
        // 加工のときは入力されないので0扱いする
        machine.electricPowerConsumption.value === ""
          ? "0"
          : machine.electricPowerConsumption.value
      ),
      industrialWaterConsumption: new BigNumber(
        machine.industrialWaterConsumption.value
      ),
      groundWaterConsumption: new BigNumber(
        machine.groundWaterConsumption.value
      ),
      materialConsumptions: objectToEntries(machine.materialConsumptions)
        .filter(([, amount]) => amount.value !== "")
        .map(([materialType, amount]) => ({
          materialType,
          amount: new BigNumber(amount.value),
        })),
      paperDeals: machine.paperDeals
        .filter((it) => it.field.value !== "")
        .map((it) => ({
          paperType: it.productType,
          amount: new BigNumber(it.field.value),
        })),
    })),
    amount:
      manufacturingForm.amount !== undefined &&
      manufacturingForm.amount.value !== ""
        ? new BigNumber(manufacturingForm.amount.value)
        : undefined,
  };
};

export const convertTransportLocalStorageToForm = (
  transportInput: JsonObject | undefined
): TransportForm => {
  if (transportInput === undefined) {
    // 一時保存も登録もされていない場合は納入先の数分入力フォームを作成するため、納入先一覧を引数に渡す
    return initTransportForm();
  }

  return {
    transportFuelConsumptions: Object.fromEntries(
      transportFuelTypes.map((transportFuelType) => {
        const obj = [
          transportFuelType,
          validateField(
            (transportInput as any)?.transportFuelConsumptions?.[
              transportFuelType
            ]
          ),
        ];
        return obj;
      })
    ) as Record<TransportFuelType, Field>,
    shipTransportation: {
      isChecked: validateBoolean(
        (transportInput as any)?.shipTransportation?.isChecked
      ),
    },
    transportByRail: {
      isChecked: validateBoolean(
        (transportInput as any)?.transportByRail?.isChecked
      ),
      transportByRails:
        transportInput.transportByRail !== undefined
          ? validateArray(
              (transportInput as any).transportByRail.transportByRails
            ).map((transportByRail: any) => ({
              key: uuid(),
              destinationId: validateField(transportByRail.destinationId),
              railTransportAmount: validateField(
                transportByRail.railTransportAmount
              ),
              railDistance: validateField(transportByRail.railDistance),
              truckDistance: validateField(transportByRail.truckDistance),
            }))
          : [initTransportByRailForm()],
    },
    amount: validateField(transportInput.amount),
  };
};

export const convertTransportFormToInput = (
  transportForm: TransportForm
): GenericTransportInput<BigNumber> | undefined => {
  return {
    transportFuelConsumptions: objectToEntries(
      transportForm.transportFuelConsumptions
    )
      .filter(([, amount]) => amount.value !== "")
      .map(([transportFuelType, amount]) => ({
        transportFuelType,
        amount: new BigNumber(amount.value),
      })),
    transportByRail: transportForm.transportByRail.isChecked
      ? transportForm.transportByRail.transportByRails.map(
          (transportByRail) => ({
            destinationId: transportByRail.destinationId.value,
            railTransportAmount: transportByRail.railTransportAmount
              ? new BigNumber(transportByRail.railTransportAmount.value)
              : undefined,
            railDistance: new BigNumber(transportByRail.railDistance.value),
            truckDistance: new BigNumber(transportByRail.truckDistance.value),
          })
        )
      : undefined,
    amount: new BigNumber(transportForm.amount.value),
  };
};

const validateField = (json: JsonValue | undefined): Field => {
  if (
    json instanceof Array ||
    typeof json === "string" ||
    typeof json === "number" ||
    typeof json === "boolean" ||
    typeof json === "undefined" ||
    json === null
  ) {
    return { value: "" };
  }
  return {
    value: typeof json.value === "string" ? json.value : "",
    error: typeof json.error === "boolean" ? json.error : false,
  };
};

const validateFieldNotBlank = (json: JsonValue | undefined): Field => {
  if (
    json instanceof Array ||
    typeof json === "string" ||
    typeof json === "number" ||
    typeof json === "boolean" ||
    typeof json === "undefined" ||
    json === null
  ) {
    return { value: "0" };
  }
  return {
    value: typeof json.value === "string" ? json.value : "0",
    error: typeof json.error === "boolean" ? json.error : false,
  };
};

export const validateBoolean = (json: JsonValue | undefined): boolean => {
  if (
    json instanceof Array ||
    typeof json === "object" ||
    typeof json === "string" ||
    typeof json === "number" ||
    typeof json === "undefined" ||
    json === null
  ) {
    return false;
  }
  return typeof json === "boolean" ? json : false;
};

export const validateArray = (json: JsonValue | undefined): JsonArray => {
  if (
    typeof json === "string" ||
    typeof json === "number" ||
    typeof json === "boolean" ||
    typeof json === "undefined" ||
    json === null
  ) {
    return [];
  }
  return Array.isArray(json) ? json : [];
};
