/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import { rest } from "msw";

import { InputStatus, MaterialType } from "src/app/apis/model";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GhgInputCategory1Mock = () => [
  /**
   * GHG入力依頼詳細取得API
   */
  ...getInputRequestMock(),

  /**
   * 製品・規格情報取得API
   * CO2排出係数一覧API
   */
  ...getProductsMock,
  ...getEmissionFactorMock,

  /**
   * ケースNo.1、ケースNo.2
   * 製紙工場カテゴリ1取得API
   */
  // ...getPaperFactoryCategory1Mock(),

  /**
   * ケースNo.3
   * 加工工場カテゴリ1取得API
   */
  ...getProcessFactoryCategory1Mock(),

  /**
   * ケースNo.11
   * GHG入力依頼詳細取得API
   */
  // ...getInputRequestCancelMock(),
  // ...deleteCalculationMock(),

  /**
   * ケースNo.12
   * 製紙工場カテゴリ1取得API:network
   */
  // ...getPaperFactoryCategory1NetworkMock(),

  /**
   * ケースNo.13
   * 製紙工場カテゴリ1取得API:timeout
   */
  // ...getPaperFactoryCategory1TimeoutMock(),

  /**
   * ケースNo.14
   * 製紙工場カテゴリ1取得API:unknown
   */
  // ...getPaperFactoryCategory1UnknownMock(),

  /**
   * ケースNo.15
   * 製紙工場カテゴリ1取得API:ERR000000
   */
  // ...getPaperFactoryCategory1ERR000000Mock(),

  /**
   * ケースNo.16
   * 製紙工場カテゴリ1取得API:ERR000001
   */
  // ...getPaperFactoryCategory1ERR000001Mock(),

  /**
   * ケースNo.17
   * 製紙工場カテゴリ1取得API:ERR000002
   */
  // ...getPaperFactoryCategory1ERR000002Mock(),

  /**
   * ケースNo.18
   * 製紙工場カテゴリ1取得API:ERR000003
   */
  // ...getPaperFactoryCategory1ERR000003Mock(),

  /**
   * ケースNo.19
   * 製紙工場カテゴリ1取得API:ERR000004
   */
  // ...getPaperFactoryCategory1ERR000004Mock(),

  /**
   * ケースNo.20
   * 加工工場カテゴリ1取得API:network
   */
  // ...getProcessFactoryCategory1NetworkMock(),

  /**
   * ケースNo.21
   * 加工工場カテゴリ1取得API:timeout
   */
  // ...getProcessFactoryCategory1TimeoutMock(),

  /**
   * ケースNo.22
   * 加工工場カテゴリ1取得API:unknown
   */
  // ...getProcessFactoryCategory1UnknownMock(),

  /**
   * ケースNo.23
   * 加工工場カテゴリ1取得API:ERR000000
   */
  // ...getProcessFactoryCategory1ERR000000Mock(),

  /**
   * ケースNo.24
   * 加工工場カテゴリ1取得API:ERR000001
   */
  // ...getProcessFactoryCategory1ERR000001Mock(),

  /**
   * ケースNo.25
   * 加工工場カテゴリ1取得API:ERR000002
   */
  // ...getProcessFactoryCategory1ERR000002Mock(),

  /**
   * ケースNo.26
   * 加工工場カテゴリ1取得API:ERR000003
   */
  // ...getProcessFactoryCategory1ERR000003Mock(),

  /**
   * ケースNo.27
   * 加工工場カテゴリ1取得API:ERR000004
   */
  // ...getProcessFactoryCategory1ERR000004Mock(),

  /**
   * ケースNo.28
   * 計算結果削除API:ERR020003
   */
  // ...getInputRequestCancelMock(),
  // ...deleteCalculationERR020003Mock(),
];

const getInputRequestMock = () => {
  return [
    rest.get("*/input-requests/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: DateTime.fromFormat("2020", "yyyy"),
          factoryId: "hokkaido",
          factoryName: "XX株式会社　北海道工場",
          factoryNameForSelf: "北海道工場",
          factoryNameForOthers: "X・北海道（製紙）",
          factoryType: "paper",
          expirationTime: DateTime.fromISO("2020-11-01T12:00:00.000"),
          beginTime: DateTime.fromISO("2020-10-01T00:00:00.000"),
          status: InputStatus.requested,
          category1: {},
          category4: {},
        })
      );
    }),
  ];
};

const getPaperFactoryCategory1Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          companyId: "companyId_01",
          year: DateTime.fromFormat("2020", "yyyy"),
          factories: [
            {
              factoryId: "hokkaido",
              factoryName: "XX株式会社　北海道工場",
              factoryNameForSelf: "北海道工場",
              factoryNameForOthers: "X・北海道（製紙）",
              factoryType: "paper",
              byProduct: [],
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
            {
              factoryId: "sendai",
              factoryName: "XX株式会社　仙台工場",
              factoryNameForSelf: "仙台工場",
              factoryNameForOthers: "X・仙台（製紙）",
              factoryType: "paper",
              byProduct: [],
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
            {
              factoryId: "takasaki",
              factoryName: "YY株式会社　高崎工場",
              factoryNameForSelf: "高崎工場",
              factoryNameForOthers: "Y・高崎（製紙）",
              factoryType: "paper",
              byProduct: [],
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
          ],
        })
      );
    }),
  ];
};

const getProcessFactoryCategory1Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          companyId: "companyId_02",
          year: DateTime.fromFormat("2020", "yyyy"),
          factories: [
            {
              factoryId: "hokkaido",
              factoryName: "XX株式会社　北海道工場",
              factoryNameForSelf: "北海道工場",
              factoryNameForOthers: "X・北海道（製紙）",
              factoryType: "paper",
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
            {
              factoryId: "sendai",
              factoryName: "XX株式会社　仙台工場",
              factoryNameForSelf: "仙台工場",
              factoryNameForOthers: "X・仙台（製紙）",
              factoryType: "paper",
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
            {
              factoryId: "takasaki",
              factoryName: "YY株式会社　高崎工場",
              factoryNameForSelf: "高崎工場",
              factoryNameForOthers: "Y・高崎（製紙）",
              factoryType: "paper",
              byClassification: [],
              intensityType: "in-house",
              version: "0",
              deleteFlag: false,
            },
          ],
        })
      );
    }),
  ];
};

const getPaperFactoryCategory1NetworkMock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "network", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1TimeoutMock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "timeout", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1UnknownMock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "unknown", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1ERR000000Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000000", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1ERR000001Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000001", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1ERR000002Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000002", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1ERR000003Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000003", message: "Mock Message" })
      );
    }),
  ];
};

const getPaperFactoryCategory1ERR000004Mock = () => {
  return [
    rest.get("*/category1/paper", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(403, "Mocked status"),
        ctx.json({ errorCode: "ERR000004", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1NetworkMock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "network", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1TimeoutMock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "timeout", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1UnknownMock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "unknown", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1ERR000000Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000000", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1ERR000001Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000001", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1ERR000002Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000002", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1ERR000003Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000003", message: "Mock Message" })
      );
    }),
  ];
};

const getProcessFactoryCategory1ERR000004Mock = () => {
  return [
    rest.get("*/category1/process", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(403, "Mocked status"),
        ctx.json({ errorCode: "ERR000004", message: "Mock Message" })
      );
    }),
  ];
};

const deleteCalculationMock = () => {
  return [
    rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
      return res(ctx.delay(1000), ctx.status(204, "Mocked status"));
    }),
  ];
};

const deleteCalculationERR020003Mock = () => {
  return [
    rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(409, "Mocked status"),
        ctx.json({ errorCode: "ERR020003", message: "Mock Message" })
      );
    }),
  ];
};

const paperNames = [
  "一般中芯",
  "強化芯",
  "Cライナー",
  "Kライナー",
  "白ライナー",
  "コートボール原紙",
  "その他",
];

const paperShortNames = [
  "一般中芯",
  "強化芯",
  "Cライナー",
  "Kライナー",
  "白ライナー",
  "コートボール原紙",
  "その他",
];

const paperTypes = [
  "generalCorrugationMedium",
  "reinforcedCorrugationMedium",
  "cLinear",
  "kLinear",
  "whiteLinear",
  "coatBoard",
  "others",
];

const getProductsMock = [
  rest.get("*/product/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: DateTime.fromFormat("2020", "yyyy"),
        factoryId: "hokkaido",
        products: paperNames.map((it, i) => ({
          productType: paperTypes[i],
          productName: it,
          productShortName: paperShortNames[i],
          sortOrder: i,
          materialTypes: [MaterialType.usedPaper],
          version: 0,
        })),
      })
    );
  }),
];
const getEmissionFactorMock = [
  rest.get("*/emission-factor/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        year: "2020",
        emissionFactorList: [
          {
            target: "lng",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "cityGas",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "lightOil",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "aHeavyOil",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "cHeavyOil",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "coal",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "rdf",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "rpf",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "biomass",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "lpg",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "kerosene",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "inHouseIncineration",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "incineration",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "landfill",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "ashLandfill",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "recycle",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "outSourceDrainage",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "industrialWater",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "groundWater",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "starch",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "ink",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "varnish",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "purchaseSteam",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "usedPaper",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "paperChip",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "internalVirginPulp",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "externalVirginPulp",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "sizingAgent",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "strengtheningAgent",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "aluminiumSulfate",
            co2EmissionFactor: 1,
            version: 0,
          },
          {
            target: "dye",
            co2EmissionFactor: 1,
            version: 0,
          },
        ],
      })
    );
  }),
];

const getInputRequestCancelMock = () => {
  return [
    rest.get("*/input-requests/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: DateTime.fromFormat("2020", "yyyy"),
          factoryId: "hokkaido",
          factoryName: "XX株式会社　北海道工場",
          factoryNameForSelf: "北海道工場",
          factoryNameForOthers: "X・北海道（製紙）",
          factoryType: "paper",
          expirationTime: DateTime.fromISO("2020-11-01T12:00:00.000"),
          beginTime: DateTime.fromISO("2020-10-01T00:00:00.000"),
          status: InputStatus.submitted,
          category1: {},
          category4: {},
        })
      );
    }),
  ];
};
