import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { ClassificationType, CompanyType } from "src/app/apis/model";
import { Category1EmissionByClassificationTemplate } from "src/app/components/templates";
import {
  PaperCategory1DataForDisplay,
  ProcessCategory1DataForDisplay,
} from "src/app/hooks";
import {
  Category1EmissionByClassificationType,
  PaperFactoryCategory1FactoryDataForDisplay,
  ProcessFactoryCategory1FactoryDataForDisplay,
} from "src/app/models";

export type Category1EmissionByClassificationPageProps = {
  companyType: Exclude<CompanyType, "user-company">;
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay;
  isProcessing: boolean;
};

const convertCategory1DataToChartProps = (
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const getValue = (
    data:
      | PaperFactoryCategory1FactoryDataForDisplay
      | ProcessFactoryCategory1FactoryDataForDisplay,
    name: ClassificationType
  ) => {
    return (
      data.byClassification.find((it) => it.classificationType === name)
        ?.co2Emission ?? 0
    );
  };

  const chartData: Category1EmissionByClassificationType[] = [];
  if (category1Data?.factories !== undefined) {
    for (const factory of category1Data.factories) {
      if (factory.intensityType === "torch-based") {
        chartData.push({
          factoryName: factory.factoryNameForSelf,
          energy: {
            privateSteam: getValue(factory, "privateSteam"),
            privatePower: getValue(factory, "privatePower"),
            purchasedPower: getValue(factory, "purchasedPower"),
            purchasedSteam: getValue(factory, "purchasedSteam"),
            certificate: getValue(factory, "certificate"),
          },
          disposal: {
            waste: getValue(factory, "waste"),
            drainage: getValue(factory, "drainage"),
          },
          water: { water: getValue(factory, "water") },
          materials: {
            mainMaterial: getValue(factory, "mainMaterial"),
            chemicals: getValue(factory, "chemicals"),
            dye: getValue(factory, "dye"),
            starch: getValue(factory, "starch"),
            ink: getValue(factory, "ink"),
            varnish: getValue(factory, "varnish"),
          },
        });
      }
    }
  }

  return { chartData };
};

const convertCategory1DataToTableProps = (
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 1,
    },
    {
      field: "middleClassification",
      headerName: "中分類",
      flex: 1,
    },
    {
      field: "classification",
      headerName: "小分類",
      flex: 1,
    },
    {
      field: "co2Emission",
      headerName: "年間GHG排出量(t-CO2eq/年)",
      flex: 1.5,
      renderCell: (params) => getEmission(params),
    },
  ];

  const tableData = [];
  if (category1Data?.factories !== undefined) {
    const factories = category1Data.factories;

    for (const factory of factories) {
      if (factory.intensityType === "torch-based") {
        for (const it of factory.byClassification) {
          tableData.push({
            id: factory.factoryId + it.classificationType,
            factoryName: factory.factoryNameForSelf,
            middleClassification: it.middleClassificationName,
            classification: it.classificationName,
            co2Emission: Math.round(it.co2Emission),
          });
        }
      }
    }
  }

  const fileName = `カテゴリ１分類別（年）_${category1Data?.year.toFormat(
    "yyyy"
  )}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const Category1EmissionByClassificationPage: React.FC<
  Category1EmissionByClassificationPageProps
> = (props) => {
  const chartProps = convertCategory1DataToChartProps(props.category1Data);
  const tableProps = convertCategory1DataToTableProps(props.category1Data);

  return (
    <Category1EmissionByClassificationTemplate
      companyType={props.companyType}
      isProcessing={props.isProcessing}
      {...chartProps}
      {...tableProps}
    />
  );
};

const getEmission = (params: GridRenderCellParams) => {
  return (
    <Box display="flex">
      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : ""}
      </Box>
    </Box>
  );
};
