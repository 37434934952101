import React from "react";

import { InputField } from "src/app/components/molecules";
import {
  AutoCompleteType,
  InputModeOptions,
  TextInput,
} from "src/lib/components/atoms";

export type TextFieldProps = {
  maxLength?: number;
  label?: string;
  secure?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
  inputMode?: InputModeOptions;
  autoComplete?: AutoCompleteType;
  labelClass?: string;
  placeholder?: string;
  hasBadge?: boolean;
  required?: boolean;
};

export const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <InputField
      label={props.label}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      hasBadge={props.hasBadge}
      required={props.required}
    >
      <TextInput
        hasError={props.hasError}
        variant="outlined"
        secure={props.secure}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        disabled={props.disabled}
        maxLength={props.maxLength}
        inputMode={props.inputMode}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
      />
    </InputField>
  );
};
