/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type PowerType = (typeof PowerType)[keyof typeof PowerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PowerType = {
  solar: "solar",
  wind: "wind",
  hydroelectric: "hydroelectric",
  biomass: "biomass",
  others: "others",
} as const;
