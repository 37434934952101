import { Grid, Box, Typography } from "@mui/material";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { CompanyType, GetProduct200ProductsItem } from "src/app/apis/model";
import { AppCard, LargeButton } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  RowTextField,
  SectionHeading,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  GhgInputHeading,
} from "src/app/components/organisms";
import { InHouseCategory1InputForm } from "src/app/components/pages/GhgInputPage/InHouseCategory1InputPage";
import { AmountBasedCo2Intensity } from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { FlexBox } from "src/lib/components/atoms";

type InHouseCategory1InputTemplateProps = {
  yearDropdownProps: YearDropdownProps;
  factoryName?: string;
  companyType?: Exclude<CompanyType, "user-company">;
  isProcessing: boolean;
  products: GetProduct200ProductsItem[];
  isEditable: boolean;
  form: InHouseCategory1InputForm;
  setForm: Dispatch<SetStateAction<InHouseCategory1InputForm>>;
  onClickBack: () => void;
  submitDialogProps: ConfirmationDialogProps;
  submitButtonProps: {
    onClick: () => void;
    isProcessing: boolean;
  };
  unSubmitDialogProps: ConfirmationDialogProps;
  unSubmitButtonProps: {
    onClick: () => void;
    isProcessing: boolean;
  };
};

export const InHouseCategory1InputTemplate: React.FC<
  InHouseCategory1InputTemplateProps
> = (props) => {
  const bindTextField = useBindTextField(props.form, (categoryAction) => {
    props.setForm(
      produce<InHouseCategory1InputForm>((form) => {
        if (categoryAction instanceof Function) {
          form.paperDeals = categoryAction(form).paperDeals;
          form.amountBasedCo2Intensity =
            categoryAction(form).amountBasedCo2Intensity;
        } else {
          form.paperDeals = categoryAction.paperDeals;
          form.amountBasedCo2Intensity = categoryAction.amountBasedCo2Intensity;
        }
      })
    );
  });

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography variant="h1">GHG算出情報入力（カテゴリ１）</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>

      <FlexBox mt={2}>
        <GhgInputHeading
          title={props.factoryName ?? ""}
          onClickBack={props.onClickBack}
        />
      </FlexBox>

      <Box mt={3}>
        <AppCard>
          <SectionHeading title="工場ごとの自社算定結果">
            {props.companyType === "paper-supplier" && (
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  {props.products
                    .sort((p1, p2) => p1.sortOrder - p2.sortOrder)
                    .map((product, index) => {
                      return (
                        props.products.length / 2 > index && (
                          <RowTextField
                            key={product.productType}
                            label={`${product.productName} [kg-CO2eq/t]`}
                            {...bindTextField({
                              validator: AmountBasedCo2Intensity.Rule,
                              selector: (form) =>
                                form.paperDeals?.find(
                                  (it) => it.productType === product.productType
                                )?.field,
                              isRequired: true,
                            })}
                            errorMessage={AmountBasedCo2Intensity.Message}
                            disabled={!props.isEditable}
                            inputMode="numeric"
                          />
                        )
                      );
                    })}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  {props.products.map((product, index) => {
                    return (
                      props.products.length / 2 <= index && (
                        <RowTextField
                          key={product.productType}
                          label={`${product.productName} [kg-CO2eq/t]`}
                          {...bindTextField({
                            validator: AmountBasedCo2Intensity.Rule,
                            selector: (form) => {
                              return form.paperDeals?.find(
                                (it) => it.productType === product.productType
                              )?.field;
                            },
                            isRequired: true,
                          })}
                          errorMessage={AmountBasedCo2Intensity.Message}
                          disabled={!props.isEditable}
                          inputMode="numeric"
                        />
                      )
                    );
                  })}
                </Grid>
                <Grid item xs={1} />
              </Grid>
            )}

            {props.companyType === "process-supplier" && (
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <RowTextField
                    label={"排出原単位 [kg-CO2eq/m2]"}
                    {...bindTextField({
                      validator: AmountBasedCo2Intensity.Rule,
                      selector: (form) => form.amountBasedCo2Intensity,
                      isRequired: true,
                    })}
                    errorMessage={AmountBasedCo2Intensity.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                </Grid>
                <Grid item xs={7} />
              </Grid>
            )}
          </SectionHeading>

          <FlexBox mt={3} justifyContent="center">
            {props.isEditable ? (
              <LargeButton
                label="確定"
                color="primary"
                variant="contained"
                {...props.submitButtonProps}
              />
            ) : (
              <LargeButton
                label="確定取消"
                color="secondary"
                variant="contained"
                {...props.unSubmitButtonProps}
              />
            )}
          </FlexBox>
        </AppCard>
      </Box>

      <FullScreenSpinner open={props.isProcessing} />
      <ConfirmationDialog {...props.submitDialogProps} />
      <ConfirmationDialog {...props.unSubmitDialogProps} />
    </Content>
  );
};
