import { rest } from "msw";

// eslint-disable-next-line no-restricted-imports
import { Category1Category4EmissionIntensityMock } from "./Category1Category4EmissionIntensityMock";
import { FactoryListMock } from "./FactoryListMock";
import { UserProcurementAmountInputMock } from "./UserProcurementAmountInputMock";
import { GhgInputCategory1Mock } from "./ghg-Input/GhgInputCategory1Mock";
import { GhgInputCategory4Mock } from "./ghg-Input/GhgInputCategory4Mock";
import { InHouseCategory1InputMock } from "./ghg-Input/InHouseCategory1InputMock";
import { InHouseCategory4InputMock } from "./ghg-Input/InHouseCategory4InputMock";

import { CompanyType } from "src/app/apis/model";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCustomMSW = () => [
  rest.get("*/users/me", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "company001",
        loginId: "user001",
        userId: "xxx-xxxxxxx",
        userName: "ペーパー太郎",
        role: CompanyType["paper-supplier"],
        version: 1,
      })
    );
  }),

  // GHG算出結果
  ...Category1Category4EmissionIntensityMock(),
  // ユーザ調達量入力
  ...UserProcurementAmountInputMock(),
  // 自社算定排出原単位入力（カテゴリ１）
  ...InHouseCategory1InputMock(),
  // GHG算出情報入力（カテゴリ1・一次データ）
  ...GhgInputCategory1Mock(),
  // GHG算出情報入力（カテゴリ4・一次データ）
  ...GhgInputCategory4Mock(),
  // 入力依頼一覧（工場一覧）
  ...FactoryListMock(),
  //　自社算定排出原単位入力（カテゴリ４）
  ...InHouseCategory4InputMock(),
];
