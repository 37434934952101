import { GridColDef } from "@mui/x-data-grid";
import { bindTextField, StringType, useForm } from "@somq14/use-form";
import { useLocation } from "react-router-dom";

import { OldGetScope3ByStandardQueryResult } from "src/app/apis/client/default";
import { CompanyType } from "src/app/apis/model";
import {
  RadioButtonItem,
  RadioButtonProps,
} from "src/app/components/molecules";
import { UserEmissionByStandardTemplate } from "src/app/components/templates";
import { UserEmissionByStandardForDisplay } from "src/app/models";
import {
  compareFunc,
  convertByStandardDataToChartProps,
} from "src/app/utils/computeForVisuals";

export type UserByStandardPageProps = {
  data?: OldGetScope3ByStandardQueryResult;
  isProcessing: boolean;
};

const convertDataToChartProps = (
  selectedSupplier: string,
  data?: OldGetScope3ByStandardQueryResult,
  category?: "category1" | "category4"
) => {
  const userStandardData = convertByStandardDataToChartProps(data);

  const chartData: UserEmissionByStandardForDisplay[] = userStandardData
    .filter((it) => it.companyType === selectedSupplier)
    .map((userStandard) => {
      return {
        companyId: userStandard.companyId,
        companyType: userStandard.companyType,
        companyName: userStandard.companyName,
        byStandard: userStandard.byStandard.map((standard) => {
          return {
            standardType: standard.standardType,
            standardName: standard.standardName,
            standardShortName: standard.standardShortName,
            total:
              category === "category1"
                ? standard.category1Total
                : standard.category4Total,
          };
        }),
      };
    });

  return { chartData };
};
//TODO:画面実装時にOldから置き換える
const convertDataToTableProps = (
  data?: OldGetScope3ByStandardQueryResult,
  category?: "category1" | "category4"
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "standardName",
      headerName: "規格",
      flex: 2,
      sortComparator: compareFunc,
    },
    {
      field: "supplierCompanyName",
      headerName: "サプライヤー企業",
      flex: 3,
    },
    {
      field: "supplierFactoryName",
      headerName: "サプライヤー工場",
      flex: 3,
    },
    {
      field: "co2Emission",
      headerName: "GHG総排出量(t-CO2eq/年)",
      flex: 3,
      valueFormatter: (params) =>
        typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : "",
    },
  ];

  const tableData = [];
  if (data !== undefined) {
    for (const bySupplier of data.bySupplier) {
      for (const bySupplierFactory of bySupplier.bySupplierFactory) {
        for (const byUserFactory of bySupplierFactory.byStandard) {
          const co2Emission =
            category === "category1"
              ? byUserFactory.category1Co2Emission
              : byUserFactory.category4Co2Emission;

          if (co2Emission !== undefined) {
            tableData.push({
              id:
                byUserFactory.standardType +
                bySupplier.companyId +
                bySupplierFactory.factoryId,
              standardName: byUserFactory.standardShortName,
              supplierCompanyName: bySupplier.companyName,
              supplierFactoryName: bySupplierFactory.factoryName,
              co2Emission: Math.round(co2Emission),
            });
          }
        }
      }
    }
  }

  const fileName =
    category === "category1"
      ? `カテゴリ１ケース規格別_${data?.year}`
      : `カテゴリ４ケース規格別_${data?.year}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const UserEmissionByStandardPage: React.FC<UserByStandardPageProps> = (
  props
) => {
  const companyType: RadioButtonItem<CompanyType>[] = [
    { value: "paper-supplier", label: "製紙" },
    { value: "process-supplier", label: "加工" },
  ];
  const form = useForm<{ selectedSupplierType: string }>({
    selectedSupplierType: {
      type: StringType,
      initial: companyType[0].value,
    },
  });
  const companyTypeRadioButtonProps: RadioButtonProps = {
    items: companyType,
    ...bindTextField(form.fields.selectedSupplierType),
  };
  const location = useLocation();

  const category = location.pathname.startsWith("/user/category1/")
    ? "category1"
    : "category4";

  const tableProps = convertDataToTableProps(props.data, category);
  const chartProps = convertDataToChartProps(
    form.fields.selectedSupplierType.value,
    props.data,
    category
  );

  return (
    <UserEmissionByStandardTemplate
      isProcessing={props.isProcessing}
      category={category}
      {...tableProps}
      {...chartProps}
      radioButtonProps={companyTypeRadioButtonProps}
    />
  );
};
