import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dispatch, SetStateAction } from "react";
import { Navigate } from "react-router-dom";

import { CompanyType } from "src/app/apis/model";
import { AppTab, AppTabItem, LargeButton } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  ErrorDialog,
  ErrorDialogProps,
  GhgInputHeading,
  SaveAndRegisterButtonProps,
  TransitionConfirmationDialog,
} from "src/app/components/organisms";
import {
  DeliveryForm,
  GhgInputCategory4Form,
  GhgInputCategory4TabType,
} from "src/app/components/pages";
import { GhgTransportInputTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  infoMessage: {
    color: theme.palette.text.disabled,
  },
}));

export type GhgInputCategory4TemplateProps = {
  deliveryList: DeliveryForm[];
  form: GhgInputCategory4Form;
  setForm: Dispatch<SetStateAction<GhgInputCategory4Form>>;

  isProcessing: boolean;
  isEditable: boolean;
  isUnsavedForm: boolean;
  basePath: string;

  factoryName?: string;
  onClickBack: () => void;
  yearDropdownProps: YearDropdownProps;
  showUnSubmitButton: boolean;
  submitButtonProps: {
    onClick: () => void;
    disabled: boolean;
    isProcessing: boolean;
  };
  submitDialogProps: ConfirmationDialogProps;
  unSubmitButtonProps: {
    onClick: () => void;
    disabled: boolean;
    isProcessing: boolean;
  };
  unSubmitDialogProps: ConfirmationDialogProps;
  submitButtonMessage?: string;

  tabItemProps: Record<
    GhgInputCategory4TabType,
    {
      checked: boolean;
      selected: boolean;
      onClick: () => void;
    }
  >;

  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];

  companyType: Exclude<CompanyType, "user-company">;

  errorDialogProps: ErrorDialogProps;
  invalidUrl?: boolean;
};

export const GhgInputCategory4Template: React.FC<
  GhgInputCategory4TemplateProps
> = (props) => {
  const classes = useStyles();

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography variant="h1">GHG算出情報入力（カテゴリ4）</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>

      <FlexBox mt={2}>
        <GhgInputHeading
          title={props.factoryName ?? ""}
          onClickBack={props.onClickBack}
        />
      </FlexBox>

      <Box position="relative" mt={2}>
        <AppTab>
          <AppTabItem label="輸送" {...props.tabItemProps.transport} />
        </AppTab>
        <FlexBox
          position="absolute"
          right={0}
          bottom={8}
          flexDirection="column"
          alignItems="flex-end"
        >
          {props.showUnSubmitButton ? (
            <LargeButton
              label="確定取消"
              color="secondary"
              variant="contained"
              {...props.unSubmitButtonProps}
            />
          ) : (
            <LargeButton
              label="確定"
              color="primary"
              variant="contained"
              {...props.submitButtonProps}
            />
          )}
          <Typography variant="overline" className={classes.infoMessage}>
            {props.submitButtonMessage}
          </Typography>
        </FlexBox>
      </Box>

      <Box mt={3}>
        {props.tabItemProps.transport.selected && (
          <GhgTransportInputTemplate
            deliveryList={props.deliveryList}
            transport={props.form.transport}
            setForm={props.setForm}
            companyType={props.companyType}
            isEditable={props.isEditable}
            isProcessing={props.isProcessing}
            saveButtonProps={props.saveButtonProps}
            registerButtonProps={props.registerButtonProps}
          />
        )}
        {props.invalidUrl && <Navigate to={`${props.basePath}/transport`} />}
      </Box>

      <FullScreenSpinner open={props.isProcessing} />
      <TransitionConfirmationDialog
        when={props.isUnsavedForm}
        message={messages.ghgInput.confirmNavigation}
      />

      <ConfirmationDialog {...props.submitDialogProps} />
      <ConfirmationDialog {...props.unSubmitDialogProps} />
      <ErrorDialog {...props.errorDialogProps} />
    </Content>
  );
};
