import { useEffect } from "react";

import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";

const key = "cross-tab-event";
const value = "logout";

export const useLogoutOtherTab = (message: string) => {
  const [authState, setAuthState] = useAuthState();
  const snackbar = useCustomSnackbar();

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key !== key) {
        return;
      }
      if (e.newValue === value) {
        if (authState !== undefined) {
          snackbar.error(message);
          setAuthState(undefined);
        }
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [authState, message, setAuthState, snackbar]);
};

export const logoutOtherTab = () => {
  localStorage.removeItem(key);
  localStorage.setItem(key, value);
};
