import { BoxProps, Box } from "@mui/material";
import React from "react";

export type FormProps = Omit<BoxProps, "component" | "onSubmit"> & {
  onSubmit: () => void;
};
export const Form: React.FC<FormProps> = (props) => {
  const { children, onSubmit, ...boxProps } = props;

  return (
    <Box
      component="form"
      onSubmit={(event: React.FormEvent<HTMLElement>) => {
        // htmlデフォルトのフォーム送信機能を無効化する
        event.preventDefault();
        onSubmit();
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
