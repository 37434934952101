/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type MiddleClassificationType =
  (typeof MiddleClassificationType)[keyof typeof MiddleClassificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MiddleClassificationType = {
  energy: "energy",
  disposal: "disposal",
  water: "water",
  materials: "materials",
} as const;
