import { CheckCircleRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme } from "src/app/configs";

const useStyles = makeStyles<AppTheme>((theme) => ({
  tab: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.headerBorder,
  },
  tabItem: {
    // 子要素を絶対座標位置指定するため
    position: "relative",
    // 上部の角を丸めて優しい印象にする
    borderRadius: "4px 4px 0px 0px",
    // 各タブの幅を確保する
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  // 選択されたタブの下部に目印として配置するオブジェクトのスタイル
  // ボタンに対する絶対座標指定で配置する
  selectedBorder: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: -2, // 下に少しめり込ませる
    height: 4,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.text.primary,
  },
  selectedLabel: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  label: {
    color: theme.palette.text.disabled,
  },
  // チェックマークはボタンの右上あたりに絶対座標指定する
  badge: {
    position: "absolute",
    top: 0,
    right: 0,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

export type AppTabProps = {
  children?: React.ReactNode;
};
export const AppTab: React.FC<AppTabProps> = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.tab} component="nav">
      {props.children}
    </Box>
  );
};

export type AppTabItemProps = {
  label: string;
  selected?: boolean;
  checked?: boolean;
  disableBadge?: boolean;
  onClick?: () => void;
};

export const AppTabItem: React.FC<AppTabItemProps> = (props) => {
  const classes = useStyles();
  return (
    <Button className={classes.tabItem} component="a" onClick={props.onClick}>
      <Typography
        className={props.selected ? classes.selectedLabel : classes.label}
      >
        {props.label}
      </Typography>

      {!props.disableBadge &&
        (props.checked ? (
          <CheckCircleRounded className={classes.badge} color="secondary" />
        ) : (
          <CheckCircleRounded className={classes.badge} color="disabled" />
        ))}
      {props.selected && <Box className={classes.selectedBorder} />}
    </Button>
  );
};
