import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme, theme } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme, EmissionUnitSummaryProps>((theme) => ({
  summary: {
    borderRadius: 5,
  },
  total: {
    fontSize: "4.0rem",
    fontWeight: "bold",
    lineHeight: 1,
  },
  category1: {
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category1Badge,
  },
  category4: {
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category4Badge,
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
}));

export type EmissionUnitSummaryProps = {
  total: number;
  category1: number;
  category4: number;
};

export const EmissionUnitSummary: React.FC<EmissionUnitSummaryProps> = (
  props
) => {
  const classes = useStyles(props);
  return (
    <FlexBox
      px={4}
      py={0.5}
      width="100%"
      bgcolor={theme.palette.emissionUnitSummary}
      alignItems="center"
      className={classes.summary}
    >
      <FlexBox width="46%" alignItems="flex-end" flexWrap="wrap">
        <Typography variant="body1">合計</Typography>
        <Box mx={2}>
          <Typography className={classes.total}>
            {props.total.toFixed(3)}
          </Typography>
        </Box>
        <Typography variant="body2">t-CO2eq/枚</Typography>
      </FlexBox>

      <FlexBox width="27%" alignItems="center" flexWrap="wrap">
        <FlexBox
          px={3}
          justifyContent="center"
          alignItems="center"
          className={classes.category1}
        >
          <Typography variant="body2" className={classes.typography}>
            カテゴリ1
          </Typography>
        </FlexBox>
        <Box ml={1}>
          <Typography variant="body1">{props.category1.toFixed(3)}</Typography>
        </Box>
      </FlexBox>

      <FlexBox width="27%" alignItems="center" flexWrap="wrap">
        <FlexBox
          px={3}
          justifyContent="center"
          alignItems="center"
          className={classes.category4}
        >
          <Typography variant="body2" className={classes.typography}>
            カテゴリ4
          </Typography>
        </FlexBox>
        <Box ml={1}>
          <Typography variant="body1">{props.category4.toFixed(3)}</Typography>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};
