import { AxiosError, AxiosRequestConfig, isAxiosError } from "axios";

import { createApiClient } from "./api-client";
import { ApiError } from "./api-error";
import { isApiResponse } from "./api-response";

import { envConfig } from "src/app/configs";

const instance = createApiClient({
  baseURL: envConfig.api.baseUrl,
});

export const customMutator = async <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  try {
    const { data } = await instance.request<T>(config);
    return data;
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!isAxiosError(err)) {
      throw new ApiError("unknown", "unexpected error", err);
    }

    if (err.response === undefined) {
      // NOTE:
      switch (err.code) {
        case AxiosError.ECONNABORTED:
          throw new ApiError("network", err.message, err);
        default:
          throw new ApiError("timeout", err.message, err);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = err.response;
    if (!isApiResponse(data)) {
      throw new ApiError(
        "unknown",
        "The server response did not have errorCode or message property.",
        err
      );
    }

    throw new ApiError(data.errorCode ?? "unknown", data.message, err);
  }
};

export type ErrorType<T> = T extends { errorCode?: string }
  ? ApiError<Exclude<T["errorCode"], undefined>>
  : ApiError<never>;
