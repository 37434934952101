import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { InputStatus } from "src/app/apis/model";
import { theme } from "src/app/configs";

const statusColor = {
  requested: theme.palette.statusRequested,
  submitted: theme.palette.statusSubmitted,
  confirmed: theme.palette.statusDeadline,
  expired: theme.palette.statusDeadline,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "in-progress": theme.palette.statusInProgress,
};
const statusLabel = {
  requested: "未確定",
  submitted: "確定済み",
  confirmed: "締め切り",
  expired: "締め切り",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "in-progress": "対応中",
};

const useStyles = makeStyles<Theme, FactoryStatusProps>((theme) => ({
  typography: {
    color: theme.palette.primary.contrastText,
  },
}));

export type FactoryStatusProps = {
  status: InputStatus;
};

export const FactoryStatus: React.FC<FactoryStatusProps> = (props) => {
  const label = statusLabel[props.status];
  const classes = useStyles(props);
  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          minWidth: 84,
          borderRadius: 20,
          backgroundColor: statusColor[props.status],
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.25),
        }}
      >
        <Typography className={classes.typography} variant="h5">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
