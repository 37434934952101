import { JsonObject } from "type-fest";

import {
  GhgInputCategory1TabType,
  GhgInputCategory4TabType,
} from "src/app/components/pages";
import { ApiError } from "src/lib/apis";

export type LocalStorage = {
  energyInput?: JsonObject;
  wasteInput?: JsonObject;
  manufacturingInput?: JsonObject;
  transportInput?: JsonObject;
};

export type GetLocalStorageResponse = LocalStorage;

export type GetLocalStorageRequest = {
  factoryId: string;
  year: string;
};
export class GetLocalStorageError extends ApiError<
  "can-not-use" | "cookie-disabled" | "unknown"
> {}

export type SetLocalStorageResponse = LocalStorage;
export type SetLocalStorageRequest = LocalStorage & {
  year: string;
  factoryId: string;
  targetTab: GhgInputCategory1TabType | GhgInputCategory4TabType;
};

export class SetLocalStorageError extends ApiError<
  "can-not-use" | "cookie-disabled" | "unknown"
> {}
export interface LocalStorageApi {
  get(req: GetLocalStorageRequest): Promise<GetLocalStorageResponse>;
  set(req: SetLocalStorageRequest): Promise<SetLocalStorageResponse>;
}
