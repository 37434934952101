import { LocalStorageApi } from "./localStorageApi";
import { LocalStorageImpl } from "./localStorageApiImpl";
import { LocalStorageMock } from "./localStorageMock";

import { envConfig } from "src/app/configs";

export const getLocalStorageApi = (): LocalStorageApi => {
  return envConfig.enableApiMock
    ? new LocalStorageMock()
    : new LocalStorageImpl();
};

export * from "./localStorageApi";
