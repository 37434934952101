/* eslint-disable @typescript-eslint/no-unused-vars */
import { rest } from "msw";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FactoryListMock = () => [
  /**
   * ケースNo.1
   * サプライヤ（製紙）の場合、画面を初期表示する
   */
  ...paperInputRequestsMock,
  ...paperSupplierMock,
  ...paperCategory4Mock,
  /**
   * ケースNo.2
   * サプライヤ（加工）の場合、画面を初期表示する
   */
  // ...processInputRequestsMock,
  // ...processSupplierMock,
  // ...processCategory4Mock,
  /**
   * ケースNo.3
   * 製紙工場カテゴリ1取得APIのエラー:network
   */
  // ...paperInputRequestsMock,
  // ...paperCategory4Mock,
  // ...category1PaperNetworkErrorMock,
  /**
   * ケースNo.4
   * 製紙工場カテゴリ1取得APIのエラー:timeout
   */
  // ...category1PaperTimeoutErrorMock,
  /**
   * ケースNo.5
   * 製紙工場カテゴリ1取得APIのエラー:unknown
   */
  // ...category1PaperUnknownErrorMock,
  /**
   * ケースNo.6
   * 製紙工場カテゴリ1取得APIのエラー:ERR000000
   */
  // ...category1PaperERR000000Mock,
  /**
   * ケースNo.7
   * 製紙工場カテゴリ1取得APIのエラー:ERR000001
   */
  // ...category1PaperERR000001Mock,
  /**
   * ケースNo.8
   * 製紙工場カテゴリ1取得APIのエラー:ERR000004
   */
  // ...category1PaperERR000004Mock,
  /**
   * ケースNo.9
   * 製紙工場カテゴリ1取得APIのエラー:ERR000002
   */
  // ...category1PaperERR000002Mock,
  /**
   * ケースNo.10
   * 製紙工場カテゴリ1取得APIのエラー:ERR000003
   */
  // ...category1PaperERR000003Mock,
  /**
   * ケースNo.11
   * 加工工場カテゴリ1取得APIのエラー:network
   */
  // ...processInputRequestsMock,
  // ...processCategory4Mock,
  // ...category1ProcessNetworkErrorMock,
  /**
   * ケースNo.12
   * 加工工場カテゴリ1取得APIのエラー:timeout
   */
  //...category1ProcessTimeoutErrorMock,
  /**
   * ケースNo.13
   * 加工工場カテゴリ1取得APIのエラー:unknown
   */
  //...category1ProcessUnknownErrorMock,
  /**
   * ケースNo.14
   * 加工工場カテゴリ1取得APIのエラー:ERR000000
   */
  //...category1ProcessERR000000Mock,
  /**
   * ケースNo.15
   * 加工工場カテゴリ1取得APIのエラー:ERR000001
   */
  //...category1ProcessERR000001Mock,
  /**
   * ケースNo.16
   * 加工工場カテゴリ1取得APIのエラー:ERR000004
   */
  // ...category1ProcessERR000004Mock,
  /**
   * ケースNo.17
   * 加工工場カテゴリ1取得APIのエラー:ERR000002
   */
  //...category1ProcessERR000002Mock,
  /**
   * ケースNo.18
   * 加工工場カテゴリ1取得APIのエラー:ERR000003
   */
  //...category1ProcessERR000003Mock,
  /**
   * ケースNo.19
   * カテゴリ4取得APIのエラー:network
   */
  // ...paperInputRequestsMock,
  // ...paperSupplierMock,
  // ...category4NetworkErrorMock,
  /**
   * ケースNo.20
   * カテゴリ4取得APIのエラー:timeout
   */
  //...category4TimeoutErrorMock,
  /**
   * ケースNo.21
   * カテゴリ4取得APIのエラー:unknown
   */
  //...category4UnknownErrorMock,
  /**
   * ケースNo.22
   * カテゴリ4取得APIのエラー:ERR000000
   */
  //...category4ERR000000Mock,
  /**
   * ケースNo.23
   * カテゴリ4取得APIのエラー:ERR000001
   */
  //...category4ERR000001Mock,
  /**
   * ケースNo.24
   * カテゴリ4取得APIのエラー:ERR000004
   */
  // ...category4ERR000004Mock,
  /**
   * ケースNo.25
   * カテゴリ4取得APIのエラー:ERR000002
   */
  //...category4ERR000002Mock,
  /**
   * ケースNo.26
   * カテゴリ4取得APIのエラー:ERR000003
   */
  //...category4ERR000003Mock,
  /**
   * ケースNo.27
   * 計算結果削除APIのエラー:ERR020002
   */
  // ...paperInputRequestsMock,
  // ...paperSupplierMock,
  // ...paperCategory4Mock,
  // ...deleteCalculationERR020002Mock,
  /**
   * ケースNo.28
   * 計算結果削除APIのエラー:ERR020003
   */
  // ...deleteCalculationERR020003Mock,
  /**
   * ケースNo.29
   * GHG入力依頼一覧APIのエラー:network
   */
  // ...paperInputRequestsNetworkErrorMock,
  // ...paperCategory4Mock,
  // ...paperSupplierMock,
  /**
   * ケースNo.30
   * GHG入力依頼一覧APIのエラー:timeout
   */
  // ...paperInputRequestsTimeoutErrorMock,
  /**
   * ケースNo.31
   * GHG入力依頼一覧APIのエラー:unknown
   */
  // ...paperInputRequestsUnknownErrorMock,
  /**
   * ケースNo.32
   * GHG入力依頼一覧APIのエラー:ERR000000
   */
  // ...paperInputRequestsERR000000Mock,
  /**
   * ケースNo.33
   * GHG入力依頼一覧APIのエラー:ERR000001
   */
  // ...paperInputRequestsERR000001Mock,
  /**
   * ケースNo.34
   * GHG入力依頼一覧APIのエラー:ERR000004
   */
  // ...paperInputRequestsERR000004Mock,
  /**
   * ケースNo.35
   * GHG入力依頼一覧APIのエラー:ERR000002
   */
  // ...paperInputRequestsERR000002Mock,
  /**
   * ケースNo.36
   * GHG入力依頼一覧APIのエラー:ERR000003
   */
  // ...paperInputRequestsERR000003Mock,
];

/** サプライヤ（製紙）の場合、GHG入力依頼一覧API */
const paperInputRequestsMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        expirationTime: "2021-11-01T12:00:00.000",
        beginTime: "2021-10-01T00:00:00.000",
        requests: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            status: "requested",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            status: "submitted",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "takasaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            status: "confirmed",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            status: "expired",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            status: "in-progress",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {},
          },
        ],
      })
    );
  }),
];

/** サプライヤ（製紙）の場合、製紙工場カテゴリ1取得API */
const paperSupplierMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 100,
                weightBasedCo2Intensity: 200,
                amountBasedCo2Intensity: 300.1235,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "reinforcedCorrugationMedium",
                productName: "強化芯",
                productShortName: "強化芯",
                sortOrder: 1,
                co2Emission: 100,
                weightBasedCo2Intensity: 200,
                amountBasedCo2Intensity: 200.1235,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "cLinear",
                productName: "Cライナー",
                productShortName: "Cライナー",
                sortOrder: 1,
                co2Emission: 100,
                weightBasedCo2Intensity: 200,
                amountBasedCo2Intensity: 100.1235,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "kLinear",
                productName: "Kライナー",
                productShortName: "Kライナー",
                sortOrder: 1,
                co2Emission: 100,
                weightBasedCo2Intensity: 200,
                amountBasedCo2Intensity: 500.1235,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "kLinear",
                productName: "Kライナー",
                productShortName: "Kライナー",
                sortOrder: 1,
                co2Emission: 100,
                weightBasedCo2Intensity: 200,
                amountBasedCo2Intensity: 500.1235,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/**サプライヤ（製紙）の場合、カテゴリ4取得API */
const paperCategory4Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            co2Emission: 1500.15,
            tonKmBasedCo2Intensity: 0.01,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.01,
                tonKmBasedCo2Intensity: 0.02,
              },
              {
                destinationId: "b",
                factoryId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.03,
                tonKmBasedCo2Intensity: 0.04,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.05,
                tonKmBasedCo2Intensity: 0.06,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 0.6,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.11,
                tonKmBasedCo2Intensity: 0.12,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.13,
                tonKmBasedCo2Intensity: 0.14,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.15,
                tonKmBasedCo2Intensity: 0.16,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            co2Emission: 2250,
            tonKmBasedCo2Intensity: 0.8,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.21,
                tonKmBasedCo2Intensity: 0.22,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.23,
                tonKmBasedCo2Intensity: 0.24,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.25,
                tonKmBasedCo2Intensity: 0.26,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** サプライヤ（加工）の場合、GHG入力依頼一覧API */
const processInputRequestsMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        expirationTime: "2021-11-01T12:00:00.000",
        beginTime: "2021-10-01T00:00:00.000",
        requests: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: "process",
            status: "confirmed",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            status: "submitted",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
          {
            factoryId: "takasaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（加工）",
            factoryType: "process",
            status: "requested",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            status: "in-progress",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（加工）",
            factoryType: "process",
            status: "expired",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
        ],
      })
    );
  }),
];

/**サプライヤ（加工）の場合、 加工工場カテゴリ1取得API */
const processSupplierMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: "process",
            co2Emission: 100,
            weightBasedCo2Intensity: 200,
            amountBasedCo2Intensity: 300.1235,
            byClassification: [
              {
                classificationType: "mainMaterial",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 100,
            weightBasedCo2Intensity: 200,
            amountBasedCo2Intensity: 200.1235,
            byClassification: [
              {
                classificationType: "mainMaterial",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（加工）",
            factoryType: "process",
            co2Emission: 100,
            weightBasedCo2Intensity: 200,
            amountBasedCo2Intensity: 100.1235,
            byClassification: [
              {
                classificationType: "mainMaterial",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 100,
            weightBasedCo2Intensity: 200,
            amountBasedCo2Intensity: 500.1235,
            byClassification: [
              {
                classificationType: "mainMaterial",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（加工）",
            factoryType: "process",
            co2Emission: 100,
            weightBasedCo2Intensity: 200,
            amountBasedCo2Intensity: 500.1235,
            byClassification: [
              {
                classificationType: "mainMaterial",
                co2Emission: 400,
                weightBasedCo2Intensity: 500,
                amountBasedCo2Intensity: 600,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/**サプライヤ（加工）の場合、カテゴリ4取得API */
const processCategory4Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: "process",
            co2Emission: 1500.15,
            tonKmBasedCo2Intensity: 0.01,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.01,
                tonKmBasedCo2Intensity: 0.02,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.03,
                tonKmBasedCo2Intensity: 0.04,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.05,
                tonKmBasedCo2Intensity: 0.06,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 0.6,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.11,
                tonKmBasedCo2Intensity: 0.12,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.13,
                tonKmBasedCo2Intensity: 0.14,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.15,
                tonKmBasedCo2Intensity: 0.16,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（加工）",
            factoryType: "process",
            co2Emission: 2250,
            tonKmBasedCo2Intensity: 0.8,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.21,
                tonKmBasedCo2Intensity: 0.22,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.23,
                tonKmBasedCo2Intensity: 0.24,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.25,
                tonKmBasedCo2Intensity: 0.26,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（加工）",
            factoryType: "process",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:network */
const category1PaperNetworkErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:timeout */
const category1PaperTimeoutErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:unknown */
const category1PaperUnknownErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000000 */
const category1PaperERR000000Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000001 */
const category1PaperERR000001Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000004 */
const category1PaperERR000004Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000002 */
const category1PaperERR000002Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000003 */
const category1PaperERR000003Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:network */
const category1ProcessNetworkErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:timeout */
const category1ProcessTimeoutErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:unknown */
const category1ProcessUnknownErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000000 */
const category1ProcessERR000000Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000001 */
const category1ProcessERR000001Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000004 */
const category1ProcessERR000004Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000002 */
const category1ProcessERR000002Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000003 */
const category1ProcessERR000003Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** カテゴリ4取得APIのエラー:network */
const category4NetworkErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:timeout */
const category4TimeoutErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:unknown */
const category4UnknownErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000000 */
const category4ERR000000Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000001 */
const category4ERR000001Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000004 */
const category4ERR000004Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000002 */
const category4ERR000002Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000003 */
const category4ERR000003Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** 計算結果削除APIのエラー:ERR020002 */
const deleteCalculationERR020002Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR020002",
        message: "Mocked message",
      })
    );
  }),
];

/** 計算結果削除APIのエラー:ERR020003 */
const deleteCalculationERR020003Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR020003",
        message: "Mocked message",
      })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:network */
const paperInputRequestsNetworkErrorMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:timeout */
const paperInputRequestsTimeoutErrorMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:unknown */
const paperInputRequestsUnknownErrorMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:ERR000000 */
const paperInputRequestsERR000000Mock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:ERR000001 */
const paperInputRequestsERR000001Mock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:ERR000004 */
const paperInputRequestsERR000004Mock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:ERR000002 */
const paperInputRequestsERR000002Mock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** GHG入力依頼一覧APIのエラー:ERR000003 */
const paperInputRequestsERR000003Mock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];
