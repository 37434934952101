import { useCallback, useRef } from "react";
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction,
} from "react-router-dom";

import { ConfirmationDialog } from "./ConfirmationDialog";

import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { exhaustiveSwitchCase } from "src/lib/apis";

export type TransitionConfirmationDialogProps = {
  when: boolean;
  message: string;
};

export const TransitionConfirmationDialog: React.FC<
  TransitionConfirmationDialogProps
> = (props) => {
  const navigate = useNavigate();

  // useBlockerのコールバックと確認ダイアログのコールバックとのデータ受け渡しに使うref
  // Ref は State と似ていてコンポーネントの状態を保持できるが、
  // State とは異なり、Ref の更新は同期的に行われ、再レンダーを引き起こすこともない
  //
  // 次の流れでこのコンポーネントで動作する
  //
  // 0. ref が undefined で初期化されている
  // 1. ユーザが画面遷移を試みる
  // 2. useBlockerのコールバックが呼び出されるが、ここで画面遷移はせず、代わりに Dialog を開く
  //    のちほど画面遷移を再度実施できるように、その内容を ref に保存しておく
  // 3. Dialog が開き、ユーザが OK を押す
  // 4. useConfirmationDialog のコールバックが呼び出される
  //    ref に保存しておいた遷移内容をもとに、再度、画面遷移を実施する
  //    画面遷移が承認されたため、 ref の isAccepted フラグを true に更新しておく
  // 5. useBlockerのコールバックが再度呼び出される
  //    ref の isAccepted フラグが true のため、実際に画面遷移が実施される
  const historyOperationRef = useRef<{
    pathname: string;
    action: "PUSH" | "POP" | "REPLACE";
    isAccepted: boolean;
  }>();

  const { openDialog, dialogProps } = useConfirmationDialog(
    props.message,
    () => {
      const operation = historyOperationRef.current;
      if (operation === undefined) {
        // ここには来ないはず
        return;
      }

      historyOperationRef.current = {
        ...operation,
        isAccepted: true,
      };
      switch (operation.action) {
        case "PUSH":
          navigate(operation.pathname);
          break;
        case "POP":
          navigate(-1);
          break;
        case "REPLACE":
          navigate(operation.pathname, { replace: true });
          break;
        default:
          throw exhaustiveSwitchCase(operation.action);
      }
    }
  );

  // NOTE: trueを返す時にnavigateさせない
  const shouldBlock = useCallback<unstable_BlockerFunction>(
    ({ nextLocation, historyAction }) => {
      if (!props.when) {
        return false;
      }

      if (historyOperationRef.current?.isAccepted) {
        historyOperationRef.current = undefined;
        return false;
      }

      historyOperationRef.current = {
        pathname: nextLocation.pathname,
        action: historyAction,
        isAccepted: false,
      };
      openDialog();
      return true;
    },
    [openDialog, props.when]
  );
  useBlocker(shouldBlock);

  return <ConfirmationDialog {...dialogProps} />;
};
