import BigNumber from "bignumber.js";

import {
  FuelType,
  MaterialType,
  TransportFuelType,
  WasteMethods,
} from "src/app/apis/model";
import {
  fuelTypes,
  wasteMethods,
  materialTypes,
  // transportFuelTypes,
} from "src/app/codes";
import { EmissionFactorModel } from "src/app/models";
import { BaseException } from "src/lib/exception/exception";

export class ConverterException extends BaseException {}

export class ConverterProvider {
  /**
   * 燃料別排出係数を作成する。
   * @param emissionFactorList 排出係数リスト
   * @returns 燃料別排出係数
   */
  convertToFuelEmissionFactor(
    emissionFactorList: EmissionFactorModel[]
  ): FuelEmissionFactor {
    return this.convertEmissionFactor(emissionFactorList, fuelTypes);
  }

  /**
   * 廃棄物別排出係数を作成する。
   * @param emissionFactorList 排出係数リスト
   * @returns 廃棄物別排出係数
   */
  convertToWasteEmissionFactor(
    emissionFactorList: EmissionFactorModel[]
  ): WasteEmissionFactor {
    return this.convertEmissionFactor(emissionFactorList, wasteMethods);
  }

  /**
   * 排水排出係数を作成する。
   * @param emissionFactorList 排出係数リスト
   * @returns 排水排出係数
   */
  convertToDrainageEmissionFactor(
    emissionFactorList: EmissionFactorModel[]
  ): BigNumber {
    const target = "outSourceDrainage";
    const res =
      emissionFactorList.find((item) => item.target === target)
        ?.co2EmissionFactor ?? undefined;

    if (res === undefined) {
      throw new ConverterException(
        undefined,
        `can not convert cause ${target} not found`
      );
    }

    return res;
  }

  /**
   * 水別排出係数を作成する。
   * @param emissionFactorList 排出係数リスト
   * @returns 水別排出係数
   */
  convertToWaterEmissionFactor(
    emissionFactorList: EmissionFactorModel[]
  ): WaterEmissionFactor {
    return this.convertEmissionFactor(emissionFactorList, [
      "industrialWater",
      "groundWater",
    ]);
  }

  /**
   * マテリアル別排出係数を作成する。
   * @param emissionFactorList 排出係数リスト
   * @returns マテリアル別排出係数
   */
  convertToMaterialEmissionFactor(
    emissionFactorList: EmissionFactorModel[]
  ): MaterialEmissionFactor {
    return this.convertEmissionFactor(emissionFactorList, materialTypes);
  }

  convertEmissionFactor<T extends string>(
    emissionFactorList: EmissionFactorModel[],
    emissionFactorTypes: readonly T[]
  ): Record<T, BigNumber> {
    const res: Partial<Record<T, BigNumber>> = {};
    for (const factorType of emissionFactorTypes) {
      res[factorType] = emissionFactorList.find(
        (item) => item.target === factorType
      )?.co2EmissionFactor;

      if (res[factorType] === undefined) {
        throw new ConverterException(
          undefined,
          `can not convert cause ${factorType} not found`
        );
      }
    }

    return res as Record<T, BigNumber>;
  }
}

export type FuelEmissionFactor = Record<FuelType, BigNumber>;
export type WasteEmissionFactor = Record<WasteMethods, BigNumber>;
export type WaterEmissionFactor = {
  industrialWater: BigNumber;
  groundWater: BigNumber;
};
export type MaterialEmissionFactor = Record<MaterialType, BigNumber>;
export type TransportFuelEmissionFactor = Record<TransportFuelType, BigNumber>;
