import BigNumber from "bignumber.js";
import { DateTime } from "luxon";

import { TransportForm } from "./ghgInputForm";
import {
  convertTransportFormToInput,
  validateBoolean,
} from "./ghgInputFormConverter";

import {
  CalculationCategory4Api,
  PostCalculationCategory4Error,
} from "src/app/apis/calculation-category4-api/calculationCategory4Api";
import {
  GetDeliveryQueryResult,
  GetProcurementQueryResult,
  GetProductQueryResult,
} from "src/app/apis/client/default";
import {
  DeliveryApi,
  GetDeliveryApiError,
} from "src/app/apis/delivery-api/deliveryApi";
import {
  EmissionFactorApi,
  GetEmissionFactorError,
} from "src/app/apis/emission-factor-api/emissionFactorApi";
import {
  FactoryApi,
  GetFactoryApiError,
} from "src/app/apis/factory-api/factoryApi";
import { getLocalStorageApi } from "src/app/apis/local-storage-api";
import {
  FactoryType,
  GetFactory200,
  IntensityType,
  TransportFuelType,
} from "src/app/apis/model";
import {
  GetProcurementBySupplierError,
  ProcurementBySupplierApi,
} from "src/app/apis/procurement-by-supplier-api/procurementBySupplierApi";
import {
  GetProductApiRequest,
  GetProductError,
  ProductApi,
} from "src/app/apis/product-api/productApi";
import { transportFuelTypes } from "src/app/codes";
import { envConfig } from "src/app/configs";
import {
  EmissionFactorModel,
  ProcurementBySupplierModel,
  TransportFuelConsumptionModel,
  TransportInputModel,
} from "src/app/models";
import { Category4Provider, ConverterException } from "src/app/scope3-logic";
import {
  ToBigNumberFromNumber,
  ToDateTimeFromString,
  ToNumberFromBigNumber,
  ToStringFromDateTime,
  ToStringYearFromDateTime,
} from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { parseNumber } from "src/lib/utils/numberUtil";

export class ServiceError<T> {
  type: T;
  cause?: unknown;

  constructor(type: T, cause?: unknown) {
    this.type = type;
    this.cause = cause;
  }
}

export class GhgInputCategory4ServiceSubmitError extends ServiceError<
  | "factory-not-found"
  | "deliveries-not-found"
  | "product-not-found"
  | "emission-factor-not-found"
  | "weight-factor-incorrect"
  | "weight-factor-is-zero"
  | "procurement-by-supplier-not-found"
  | "expired"
  | "conflict"
  | "not-authenticated"
  | "network"
  | "unknown"
> {}

export class GhgInputCategory4ServiceGetDeliveryError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputCategory4ServiceGetProductError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputCategory4Service {
  //NOTE: カテゴリ4TORCH算出情報入力用確定部品
  async submitCategory4TorchBased(input: {
    year: DateTime;
    factoryId: string;
    factoryType: Exclude<FactoryType, "user">;
    companyId: string;
    category4Version: number;
  }): Promise<void> {
    // ローカルストレージから値を取得
    const localStorageApi = getLocalStorageApi();
    const localStorageInput = await localStorageApi.get({
      factoryId: input.factoryId,
      year: input.year.toFormat("yyyy"),
    });

    if (!validateBoolean(localStorageInput.transportInput?.isRegistered)) {
      // 前提条件が満たされてされていないとき
      // ボタンの非活性制御によりここにはこないはず
      throw new GhgInputCategory4ServiceSubmitError("unknown", undefined);
    }

    if (
      (localStorageInput as any)?.transportInput.version !== envConfig.version
    ) {
      // ローカルストレージのバージョンがアプリのバージョンとあっていないとき
      throw new GhgInputCategory4ServiceSubmitError("unknown", undefined);
    }

    // マスタデータの取得
    let deliveries: GetDeliveryQueryResult;
    let emissionFactorList: EmissionFactorModel[] = [];
    let procurementBySupplierRes: GetProcurementQueryResult;
    try {
      // 納入先取得
      deliveries = await this.getDelivery({ from: input.factoryId });

      // 排出係数取得
      const emissionFactorApi = new EmissionFactorApi();
      const res = await emissionFactorApi.get({ year: input.year });

      emissionFactorList = res.emissionFactorList.map((it) => ({
        year: ToDateTimeFromString(res.year),
        target: it.target,
        co2EmissionFactor: ToBigNumberFromNumber(it.co2EmissionFactor),
        version: it.version,
      }));

      // 経路区間別調達量取得
      const procurementBySupplierApi = new ProcurementBySupplierApi();
      procurementBySupplierRes =
        await procurementBySupplierApi.getProcurementBySupplier({
          year: input.year,
          factoryId: input.factoryId,
        });
    } catch (err) {
      if (
        !(err instanceof GhgInputCategory4ServiceGetProductError) &&
        !(err instanceof GhgInputCategory4ServiceGetDeliveryError) &&
        !(err instanceof GetEmissionFactorError) &&
        !(err instanceof GetProcurementBySupplierError)
      ) {
        throw new GhgInputCategory4ServiceSubmitError("unknown", err);
      }

      if (err.type === "factory-not-found") {
        throw err instanceof GhgInputCategory4ServiceGetProductError
          ? new GhgInputCategory4ServiceSubmitError("product-not-found")
          : new GhgInputCategory4ServiceSubmitError("deliveries-not-found");
      }

      switch (err.type) {
        case "not-authenticated":
          throw new GhgInputCategory4ServiceSubmitError(
            "not-authenticated",
            err
          );
        case "procurement-by-supplier-not-found":
          throw new GhgInputCategory4ServiceSubmitError(
            "procurement-by-supplier-not-found",
            err
          );
        case "network":
          throw new GhgInputCategory4ServiceSubmitError("network", err);
        case "unknown":
          throw new GhgInputCategory4ServiceSubmitError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }

    // 計算の前準備
    const category4Provider = new Category4Provider();
    const transportInput: TransportInputModel = convertTransportFormToInput(
      localStorageInput.transportInput as TransportForm
    ) ?? {
      transportFuelConsumptions: [],
      amount: new BigNumber(0),
    };

    // Category4
    // 納入先一覧
    const inputDeliveries: {
      destinationId: string;
      landDistance: number;
      seaDistance: number;
      transportByRail?: {
        railDistance: BigNumber;
        truckDistance: BigNumber;
        railTransportAmount?: BigNumber;
        isOther: boolean;
      };
    }[] = deliveries.to.map((delivery) => {
      const useTransportByRail = transportInput.transportByRail?.find(
        (it) => it.destinationId === delivery.factoryId
      );

      return {
        destinationId: delivery.factoryId,
        landDistance: delivery.landDistance,
        seaDistance: delivery.seaDistance,
        transportByRail:
          useTransportByRail !== undefined
            ? {
                railDistance: useTransportByRail.railDistance,
                truckDistance: useTransportByRail.truckDistance,
                railTransportAmount: useTransportByRail.railTransportAmount,
                isOther: false,
              }
            : undefined,
      };
    });
    // その他（納入先が一致しない）のtransportByRailの要素を追加
    transportInput.transportByRail?.forEach((rail) => {
      const existsInDeliveries = inputDeliveries.some(
        (delivery) => delivery.destinationId === rail.destinationId
      );

      if (!existsInDeliveries) {
        inputDeliveries.push({
          destinationId: rail.destinationId,
          landDistance: 0,
          seaDistance: 0,
          transportByRail: {
            railDistance: rail.railDistance,
            truckDistance: rail.truckDistance,
            railTransportAmount: rail.railTransportAmount,
            isOther: true, // 一致しない場合はtrue
          },
        });
      }
    });

    const fuelConsumptions: TransportFuelConsumptionModel[] =
      transportInput.transportFuelConsumptions;

    const fuelEmissionFactors: Record<TransportFuelType, BigNumber> =
      Object.fromEntries([
        ...transportFuelTypes.map((transportFuelType) => {
          const factor = emissionFactorList.find(
            (it) => it.target === transportFuelType
          );
          if (factor === undefined) {
            throw new GhgInputCategory4ServiceSubmitError(
              "emission-factor-not-found"
            );
          }
          return [transportFuelType, factor.co2EmissionFactor];
        }),
      ]);

    const railEmissionFactor = emissionFactorList.find(
      (it) => it.target === "rail"
    )?.co2EmissionFactor;
    if (railEmissionFactor === undefined) {
      throw new GhgInputCategory4ServiceSubmitError(
        "emission-factor-not-found"
      );
    }
    let factory: GetFactory200;
    try {
      const factoryApi = new FactoryApi();
      factory = await factoryApi.get({
        factoryId: input.factoryId,
      });
    } catch (err) {
      if (!(err instanceof GetFactoryApiError)) {
        throw new GhgInputCategory4ServiceSubmitError("unknown", err);
      }
      switch (err.type) {
        case "factory-not-found":
          throw new GhgInputCategory4ServiceSubmitError(
            "factory-not-found",
            err
          );
        case "not-authenticated":
          throw new GhgInputCategory4ServiceSubmitError(
            "not-authenticated",
            err
          );
        case "network":
          throw new GhgInputCategory4ServiceSubmitError("network", err);
        case "unknown":
          throw new GhgInputCategory4ServiceSubmitError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }

    const defaultTransport: {
      defaultLandDistance: BigNumber;
      defaultSeaDistance: BigNumber;
    } = {
      defaultLandDistance: ToBigNumberFromNumber(factory.defaultLandDistance),
      defaultSeaDistance: ToBigNumberFromNumber(factory.defaultSeaDistance),
    };

    // 経路区間別調達量
    const inputProcurementBySupplier: ProcurementBySupplierModel[] =
      procurementBySupplierRes.routes.map((it) => ({
        ...it,
        year: ToDateTimeFromString(procurementBySupplierRes.year),
        factoryId: procurementBySupplierRes.factoryId,
        weightBySupplierFactory: ToBigNumberFromNumber(
          it.weightBySupplierFactory
        ),
      }));

    // 計算して登録
    try {
      const category4Result = category4Provider.compute({
        factoryId: input.factoryId,
        deliveries: inputDeliveries.map((delivery) => ({
          ...delivery,
          seaDistance: ToBigNumberFromNumber(delivery.seaDistance),
          landDistance: ToBigNumberFromNumber(delivery.landDistance),
        })),
        fuelConsumptions,
        fuelEmissionFactors,
        railEmissionFactor,
        defaultTransport,
        inputProcurementBySupplier,
        transportTotalAmount: transportInput.amount,
      });

      const calculationApi = new CalculationCategory4Api();
      await calculationApi.postCalculationCategory4(
        {
          year: ToStringYearFromDateTime(input.year),
          companyId: input.companyId,
          factoryId: input.factoryId,
          co2Emission: ToNumberFromBigNumber(category4Result.co2Emission),
          tonKmBasedCo2Intensity: undefined,

          byDestination: category4Result.byDestination.map((byDestination) => ({
            destinationId: byDestination.destinationId,
            weightBasedCo2Intensity:
              byDestination.weightBasedCo2Intensity !== undefined
                ? ToNumberFromBigNumber(byDestination.weightBasedCo2Intensity)
                : undefined,
            tonKmBasedCo2Intensity:
              byDestination.tonKmBasedCo2Intensity !== undefined
                ? ToNumberFromBigNumber(byDestination.tonKmBasedCo2Intensity)
                : undefined,
          })),
          submissionTime: ToStringFromDateTime(DateTime.now().toUTC()),
          intensityType: IntensityType["torch-based"],
          version: input.category4Version,
          appVersion: parseNumber(envConfig.version),
        },
        { intensityType: "torch-based" }
      );
    } catch (err) {
      if (
        !(err instanceof PostCalculationCategory4Error) &&
        !(err instanceof ConverterException)
      ) {
        throw new GhgInputCategory4ServiceSubmitError("unknown", err);
      }

      if (err instanceof ConverterException) {
        throw new GhgInputCategory4ServiceSubmitError(
          "emission-factor-not-found",
          err
        );
      }

      const errType = err.type;
      switch (errType) {
        case "not-authenticated":
          throw new GhgInputCategory4ServiceSubmitError(
            "not-authenticated",
            err
          );
        case "network":
          throw new GhgInputCategory4ServiceSubmitError("network", err);
        case "unknown":
          throw new GhgInputCategory4ServiceSubmitError("unknown", err);
        case "expired":
          throw new GhgInputCategory4ServiceSubmitError("expired", err);
        case "conflict":
          throw new GhgInputCategory4ServiceSubmitError("conflict", err);
        case "factory-not-found":
          throw new GhgInputCategory4ServiceSubmitError(
            "factory-not-found",
            err
          );
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }
  }

  /**
   * 納入先一覧を取得する
   */
  async getDelivery(input: { from: string }): Promise<GetDeliveryQueryResult> {
    try {
      const deliveryApi = new DeliveryApi();
      const deliveryList = await deliveryApi.getDelivery({
        from: input.from,
      });
      return deliveryList;
    } catch (err) {
      if (!(err instanceof GetDeliveryApiError)) {
        throw new GhgInputCategory4ServiceGetDeliveryError("unknown", err);
      }
      switch (err.type) {
        case "factory-not-found":
          throw new GhgInputCategory4ServiceGetDeliveryError(
            "factory-not-found",
            err
          );
        case "not-authenticated":
          throw new GhgInputCategory4ServiceGetDeliveryError(
            "not-authenticated",
            err
          );
        case "network":
          throw new GhgInputCategory4ServiceGetDeliveryError("network", err);
        case "unknown":
          throw new GhgInputCategory4ServiceGetDeliveryError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }

  /**
   * 製品・規格一覧を取得する
   */
  async getProduct(
    input: GetProductApiRequest
  ): Promise<GetProductQueryResult> {
    try {
      const productApi = new ProductApi();
      const productList = await productApi.getProduct(input);
      return productList;
    } catch (err) {
      if (!(err instanceof GetProductError)) {
        throw new GhgInputCategory4ServiceGetProductError("unknown", err);
      }
      switch (err.type) {
        case "not-authenticated":
          throw new GhgInputCategory4ServiceGetProductError(
            "not-authenticated",
            err
          );
        case "factory-not-found":
          throw new GhgInputCategory4ServiceGetProductError(
            "factory-not-found",
            err
          );
        case "network":
          throw new GhgInputCategory4ServiceGetProductError("network", err);
        case "unknown":
          throw new GhgInputCategory4ServiceGetProductError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }
}
