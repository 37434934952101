import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
  },
}));

export type PageContainerProps = {
  className?: string;
  children?: React.ReactNode;
};

export const PageContainer: React.FC<PageContainerProps> = (props) => {
  const classes = useStyles();
  return (
    <FlexBox
      className={props.className}
      pt={2}
      pl={6}
      pr={6}
      pb={2}
      flexDirection="column"
      alignItems="center"
    >
      <Box className={classes.main}>{props.children}</Box>
    </FlexBox>
  );
};
