import {
  ClassificationType,
  FactoryType,
  MaterialType,
  ProductType,
  StandardType,
} from "src/app/apis/model";

export const materialClassificationMaster: {
  factoryType: FactoryType;
  classificationType: ClassificationType;
  materialTypes: MaterialType[];
}[] = [
  {
    factoryType: "paper",
    classificationType: "mainMaterial",
    materialTypes: [
      "usedPaper",
      "internalVirginPulp",
      "externalVirginPulp",
      "paperChip",
    ],
  },
  {
    factoryType: "paper",
    classificationType: "chemicals",
    materialTypes: ["sizingAgent", "aluminiumSulfate", "strengtheningAgent"],
  },
  {
    factoryType: "paper",
    classificationType: "dye",
    materialTypes: ["dye"],
  },
  {
    factoryType: "process",
    classificationType: "starch",
    materialTypes: ["starch"],
  },
  {
    factoryType: "process",
    classificationType: "ink",
    materialTypes: ["ink"],
  },
  {
    factoryType: "process",
    classificationType: "varnish",
    materialTypes: ["varnish"],
  },
];

const getProductStandard = (
  factoryType: "process"
): {
  factoryType: "process";
  productType: StandardType;
  productName: string;
  productShortName: string;
  materialTypes: ["starch", "ink", "varnish"];
}[] => {
  return [
    {
      factoryType: factoryType,
      productType: "productStandard1",
      productName: "135ml6P×4ラップラウンドカートン",
      productShortName: "規格1",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard2",
      productName: "250ml×24ラップラウンドカートン",
      productShortName: "規格2",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard3",
      productName: "350ml×24らくもてケース",
      productShortName: "規格3",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard4",
      productName: "500ml×24らくもてケース",
      productShortName: "規格4",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard5",
      productName: "350ml6P×24らくもてケース",
      productShortName: "規格5",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard6",
      productName: "500ml6P×24らくもてケース",
      productShortName: "規格6",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard7",
      productName: "350ml×24ラップラウンドカートン",
      productShortName: "規格7",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard8",
      productName: "500ml×24ラップラウンドカートン",
      productShortName: "規格8",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard9",
      productName: "350ml×12ラップラウンドカートン",
      productShortName: "規格9",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard10",
      productName: "500ml×12ラップラウンドカートン",
      productShortName: "規格10",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard11",
      productName: "350ml4P×3ラップラウンドショートフラップカートン",
      productShortName: "規格11",
      materialTypes: ["starch", "ink", "varnish"],
    },
    {
      factoryType: factoryType,
      productType: "productStandard12",
      productName: "350ml4P×6らくもてケース",
      productShortName: "規格12",
      materialTypes: ["starch", "ink", "varnish"],
    },
  ];
};

export const productMaster: {
  factoryType: FactoryType;
  productType: ProductType;
  productName: string;
  productShortName: string;
  materialTypes: readonly MaterialType[];
}[] = [
  {
    factoryType: "paper",
    productType: "generalCorrugationMedium",
    productName: "一般中芯",
    productShortName: "一般中芯",
    materialTypes: ["usedPaper", "sizingAgent", "aluminiumSulfate"],
  },
  {
    factoryType: "paper",
    productType: "reinforcedCorrugationMedium",
    productName: "強化芯",
    productShortName: "強化芯",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "strengtheningAgent",
    ],
  },
  {
    factoryType: "paper",
    productType: "cLinear",
    productName: "Cライナー",
    productShortName: "Cライナー",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "dye",
      "strengtheningAgent",
    ],
  },
  {
    factoryType: "paper",
    productType: "kLinear",
    productName: "Kライナー",
    productShortName: "Kライナー",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "dye",
      "internalVirginPulp",
      "externalVirginPulp",
      "paperChip",
      "strengtheningAgent",
    ],
  },
  {
    factoryType: "paper",
    productType: "whiteLinear",
    productName: "白ライナー",
    productShortName: "白ライナー",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "dye",
      "internalVirginPulp",
      "externalVirginPulp",
      "paperChip",
      "strengtheningAgent",
    ],
  },
  {
    factoryType: "paper",
    productType: "coatBoard",
    productName: "コートボール原紙",
    productShortName: "コートボール原紙",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "dye",
      "internalVirginPulp",
      "externalVirginPulp",
      "paperChip",
      "strengtheningAgent",
    ],
  },
  {
    factoryType: "paper",
    productType: "others",
    productName: "その他",
    productShortName: "その他",
    materialTypes: [
      "usedPaper",
      "sizingAgent",
      "aluminiumSulfate",
      "dye",
      "internalVirginPulp",
      "externalVirginPulp",
      "paperChip",
      "strengtheningAgent",
    ],
  },
  ...getProductStandard("process"),
];
