import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

import { FlexBox } from "src/lib/components/atoms";

export interface InputFieldProps {
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  hasBadge?: boolean;
  required?: boolean;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: theme.spacing(0.5),
    border: "solid",
    borderWidth: "0.1rem",
    borderRadius: "0.6rem",
  },
  required: {
    borderColor: theme.palette.error.main,
  },
  optional: {
    borderColor: theme.palette.text.primary,
  },
  label: { color: theme.palette.text.primary },
}));

export const InputField: React.FC<InputFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <FlexBox flexDirection="row" alignItems="center" mb={1}>
        {props.hasBadge && (
          <Box
            mr={1}
            className={clsx(
              classes.badge,
              props.required ? classes.required : classes.optional
            )}
          >
            {props.required ? (
              <Typography variant="caption" color="error">
                必須
              </Typography>
            ) : (
              <Typography variant="caption">任意</Typography>
            )}
          </Box>
        )}
        <Typography className={classes.label}>{props.label}</Typography>
      </FlexBox>
      {props.children}
      <Box mt={{ sm: 0.5 }} minHeight={20}>
        {props.hasError && (
          <Typography variant="body2" color="error">
            {props.errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
