export const messages = {
  common: {
    unknown:
      "処理中にエラーが発生しました。しばらく経ってからもう一度お試しください。",
    network:
      "通信にエラーが発生しました。インターネット接続を確認してください。",
    sessionTimeout:
      "セッションの有効期限が切れました。もう一度ログインしてください。",
    logoutOtherTab:
      "別のウィンドウでログインが行われたため自動ログアウトされました。",
  },
  login: {
    authenticationFailed:
      "ログインに失敗しました。企業コード、ログインID、パスワードを確認してください。",
    cookieDisabled:
      "クッキーが無効化されているため、このサイトをご利用いただけません。ブラウザの設定を変更し、クッキーを有効にしてください。",
    companyIdInvalid: "英数字記号で入力してください",
    loginIdInvalid: "英数字記号で入力してください",
    passwordInvalid: "英数字記号で入力してください",
  },
  ghgInput: {
    factoryNotFound: "ご指定の工場は存在しません。",
    inputRequestNotFound:
      "ご指定の年・工場について、GHG算出情報入力はできません。",
    confirmNavigation:
      "別の画面に移動すると現在の入力内容が破棄されます。\nよろしいですか？",
    manufacturing: {
      noMachine: "マシンがありません",
      deleteMachine: (machineIndex: number) =>
        `マシン No.${machineIndex + 1} を削除しますか？`,
    },
    energy: "チェックを外すと現在の入力内容が破棄されます。\nよろしいですか？",
    transport: {
      noDelivery: "ご指定の工場の納入先は存在しません。",
      noProductStandard: "規格情報が登録されていません。",
    },
    transportCheck:
      "チェックを外すと現在の入力内容が破棄されます。\nよろしいですか？",
    submissionButtonDescription: {
      requested: "※すべての項目を登録すると確定できます",
      expired: "※入力期間を過ぎたため確定できません",
      submitted: "※入力期間内に限り取り消しできます",
      confirmed: "※入力期間後に取り消しはできません",
    },
    save: {
      success: "正常に一時保存されました。",
      conflict:
        "他の操作がこのタブに行われたため、一時保存に失敗しました。画面を再読み込みしてください。",
      expired: "入力期間を過ぎているため一時保存できませんでした。",
    },
    registration: {
      validationError: "入力内容に誤りがあります。入力内容をご確認ください。",
      success: "正常に登録されました。",
      expired: "入力期間を過ぎているため登録できませんでした。",
      conflict:
        "他の操作がこのタブに行われたため、登録に失敗しました。画面を再読み込みしてください。",
    },
    submit: {
      confirm: "現在の入力内容で確定しますか？",
      unSaved: "登録されていない変更があります。登録してください。",
      success: "正常に確定されました。",
      alreadyExist:
        "他の操作と競合したため確定に失敗しました。画面を再読み込みしてください。",
      expired: "入力期間を過ぎているため確定できませんでした。",
      weightFactorIncorrect:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S0001）",
      weightFactorIsZero:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S0002）",
      emissionFactorNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S0003）",
      machineCountIncorrect:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9000）",
      factoryNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9001）",
      productNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9002）",
      deliveriesNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9003）",
      procurementBySupplierNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9004）",
      amountNotFound:
        "計算中に不具合が発生しました。\n管理者に下記のエラーコードでお問い合わせください。\n（ERR_S9005）",
    },
    unSubmit: {
      confirm: "入力内容の確定を取り消ししますか？",
      success: "正常に確定が取り消しされました。",
      conflict:
        "他の操作と競合したため確定の取り消しに失敗しました。画面を再読み込みしてください。",
      expired: "入力期間を過ぎているため確定を取り消しできませんでした。",
    },
  },
  ghgRequest: {
    nothing: "入力依頼はありません。",
    apiError: "入力依頼一覧を取得できませんでした。",
  },
  ghgOutput: {
    nationalAverage: "サプライヤー平均",
  },
  userProcurementAmount: {
    nothing: "調達経路データがありません。",
    save: { success: "正常に一時保存されました。" },
    registration: {
      validationError: "入力内容に誤りがあります。入力内容をご確認ください。",
      success: "正常に登録されました。",
      conflict:
        "他の操作が行われたため、登録に失敗しました。画面を再読み込みしてください。",
    },
    unRegistration: {
      confirm: "入力内容の登録を取り消ししますか？",
      success: "正常に登録が取り消しされました。",
    },
  },
  userIntensity: {
    noDataError: "Coming Soon...",
    noDataMatch: "該当するデータはありません",
  },
  ghgVisual: {
    noData: "指定した年度のデータはありません。",
  },
  pdf: {
    noData: "取得するPDFがありません。",
  },
  localStorage: {
    canNotUse: "ローカルストレージを利用できません。",
    cookieDisabled:
      "クッキーが無効化されているため、このサイトをご利用いただけません。ブラウザの設定を変更し、クッキーを有効にしてください。",
  },
};

/* eslint-disable no-template-curly-in-string */
export const domainMessages = {
  select: "${0}を選択してください",
  input: "${0}を入力してください",
  inputNumber: "${0}以上${1}以下の値を入力してください",
  inputNumberExclusiveUpper: "${0}以上${1}未満の値を入力してください",
  inputInteger: "${0}以上${1}以下の整数を入力してください",
};

export type MessageId = keyof typeof domainMessages;

/**
 * メッセージ取得関数。
 *
 * @param messageId メッセージ設計書上のメッセージID
 * @param values メッセージに埋め込む文字列
 * @returns 整形済みメッセージ
 */
export function msg(messageId: MessageId, ...values: string[]): string {
  let res: string = domainMessages[messageId];
  for (let i = 0; i < values.length; i++) {
    res = res.replaceAll(`\${${i}}`, values[i]);
  }
  return res;
}
