import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { FactoryType, ProductPurpose } from "src/app/apis/model";
import { FastFlexBox, NestedCard } from "src/app/components/atoms";
import { ProcurementsTextField } from "src/app/components/molecules";
import {
  UserFactoriesForm,
  SupplierFactoryForm,
  ProcurementsForm,
} from "src/app/components/pages";
import { AppTheme, theme } from "src/app/configs";
import { ProcurementAmount } from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { concatFactoryName } from "src/app/utils/computeForVisuals";

export type ProcurementInputProps = {
  formByRoute: UserFactoriesForm["routes"][number];
  setForm: Dispatch<SetStateAction<ProcurementsForm | undefined>>;
  factoryId: string;
  routeId: string;
  disabled: boolean;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  productName: {
    marginLeft: theme.spacing(1),
  },
  contrastTypography: {
    color: theme.palette.primary.contrastText,
  },
  routeArea: {
    marginTop: theme.spacing(3),
  },
  dottedLine: {
    borderRightStyle: "dotted",
    borderWidth: "2px",
    borderColor: theme.palette.procurementBox,
  },
  supplierHeadlineBox: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  paperBox: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.procurementBox,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  processBox: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.procurementBox,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  paperSupplierValueBox: {
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    borderLeftColor: theme.palette.procurementBox,
  },
  processingSupplierLabel: {
    color: theme.palette.procurementBox,
  },
  procurementAmountLabel: {
    color: theme.palette.primary.main,
  },
  registerButtonBox: {
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    borderTopColor: theme.palette.procurementLineColor,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
  },
  paddingX1: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  paddingY05: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export const ProcurementInput: React.FC<ProcurementInputProps> = React.memo(
  (props) => {
    const classes = useStyles();

    const formByRoute = props.formByRoute;

    const bindTextField = useBindTextField(formByRoute, (action) =>
      props.setForm(
        produce<ProcurementsForm>((form) => {
          const factoryIndex = form.userFactories.findIndex(
            (it) => it.factoryId === props.factoryId
          );
          if (factoryIndex === -1) {
            return;
          }
          const routeIndex = form.userFactories[factoryIndex].routes.findIndex(
            (it) => it.routeId === props.routeId
          );
          if (routeIndex === -1) {
            return;
          }
          if (action instanceof Function) {
            form.userFactories[factoryIndex].routes[routeIndex] = action(
              form.userFactories[factoryIndex].routes[routeIndex]
            );
          } else {
            form.userFactories[factoryIndex].routes[routeIndex] = action;
          }
        })
      )
    );

    // 製紙工場の製品名取得
    const getPaperProductName = (
      supplierFactories: SupplierFactoryForm[],
      productPurpose: ProductPurpose
    ) => {
      const targetFactories = supplierFactories.find((supplierFactory) => {
        return (
          supplierFactory.factoryType === "paper" &&
          supplierFactory.productPurpose === productPurpose
        );
      });
      return targetFactories === undefined ? "-" : targetFactories.productName;
    };

    // 製紙工場名取得
    const getPaperFactoryName = (
      supplierFactories: SupplierFactoryForm[],
      productPurpose: ProductPurpose
    ) => {
      // 製紙工場であることと製品・規格用途で工場を絞る
      const paperFactories = supplierFactories.filter((supplierFactory) => {
        return (
          supplierFactory.factoryType === "paper" &&
          supplierFactory.productPurpose === productPurpose
        );
      });
      return concatFactoryName(paperFactories);
    };

    // 製紙以外の工場名取得
    const getOtherFactoryName = (
      supplierFactories: SupplierFactoryForm[],
      factoryType: FactoryType
    ) => {
      // 工場種別で工場を絞る
      const otherFactories = supplierFactories.filter((supplierFactory) => {
        return supplierFactory.factoryType === factoryType;
      });
      return concatFactoryName(otherFactories);
    };

    return (
      <NestedCard>
        <FastFlexBox flexDirection="row">
          <FastFlexBox
            width="16rem"
            backgroundColor={theme.palette.primary.main}
            borderRadius={20}
            justifyContent="center"
            className={classes.paddingY05}
          >
            <Typography
              variant="subtitle1"
              className={classes.contrastTypography}
            >
              {formByRoute.productShortName}-{formByRoute.routeName}
            </Typography>
          </FastFlexBox>
          <FastFlexBox alignItems="center" className={classes.productName}>
            <Typography variant="h3">{formByRoute.productName}</Typography>
          </FastFlexBox>
        </FastFlexBox>

        <FastFlexBox flexDirection="column" className={classes.routeArea}>
          <FastFlexBox>
            <FastFlexBox
              alignItems="center"
              width="25%"
              backgroundColor={theme.palette.procurementBox}
              className={`${classes.supplierHeadlineBox} ${classes.paddingX1} ${classes.paddingY05}`}
            >
              <Typography
                variant="subtitle2"
                className={classes.contrastTypography}
              >
                サプライヤー（製紙）
              </Typography>
            </FastFlexBox>
            <FastFlexBox
              width="75%"
              className={`${classes.paperBox} ${classes.paddingY05}`}
            >
              <FastFlexBox
                width="33.3333%"
                flexDirection="column"
                className={`${classes.paddingX1} ${classes.paddingY05}`}
              >
                <Typography
                  variant="h5"
                  className={classes.processingSupplierLabel}
                >
                  中芯（
                  {getPaperProductName(
                    formByRoute.supplierFactories,
                    "corrugationMedium"
                  )}
                  ）
                </Typography>
                <FastFlexBox alignItems="center" height="100%">
                  <Typography variant="caption">
                    {getPaperFactoryName(
                      formByRoute.supplierFactories,
                      "corrugationMedium"
                    )}
                  </Typography>
                </FastFlexBox>
              </FastFlexBox>
              <FastFlexBox
                width="33.3333%"
                flexDirection="column"
                className={`${classes.paperSupplierValueBox} ${classes.paddingX1} ${classes.paddingY05}`}
              >
                <Typography
                  variant="h5"
                  className={classes.processingSupplierLabel}
                >
                  表ライナー（
                  {getPaperProductName(
                    formByRoute.supplierFactories,
                    "frontLinear"
                  )}
                  ）
                </Typography>
                <FastFlexBox alignItems="center" height="100%">
                  <Typography variant="caption">
                    {getPaperFactoryName(
                      formByRoute.supplierFactories,
                      "frontLinear"
                    )}
                  </Typography>
                </FastFlexBox>
              </FastFlexBox>
              <FastFlexBox
                width="33.3333%"
                flexDirection="column"
                className={`${classes.paperSupplierValueBox} ${classes.paddingX1} ${classes.paddingY05}`}
              >
                <Typography
                  variant="h5"
                  className={classes.processingSupplierLabel}
                >
                  裏ライナー（
                  {getPaperProductName(
                    formByRoute.supplierFactories,
                    "backLinear"
                  )}
                  ）
                </Typography>
                <FastFlexBox alignItems="center" height="100%">
                  <Typography variant="caption">
                    {getPaperFactoryName(
                      formByRoute.supplierFactories,
                      "backLinear"
                    )}
                  </Typography>
                </FastFlexBox>
              </FastFlexBox>
            </FastFlexBox>
          </FastFlexBox>

          <FastFlexBox
            width="12.5%"
            height={theme.spacing(1.5)}
            className={classes.dottedLine}
          />

          <FastFlexBox>
            <FastFlexBox
              alignItems="center"
              width="25%"
              backgroundColor={theme.palette.procurementBox}
              className={`${classes.supplierHeadlineBox} ${classes.paddingX1} ${classes.paddingY05}`}
            >
              <Typography
                variant="subtitle2"
                className={classes.contrastTypography}
              >
                サプライヤー（加工）
              </Typography>
            </FastFlexBox>
            <FastFlexBox
              alignItems="center"
              width="75%"
              className={`${classes.processBox} ${classes.paddingX1} ${classes.paddingY05}`}
            >
              <Typography variant="caption">
                {getOtherFactoryName(formByRoute.supplierFactories, "process")}
              </Typography>
            </FastFlexBox>
          </FastFlexBox>
        </FastFlexBox>

        <FastFlexBox
          justifyContent="flex-end"
          className={classes.registerButtonBox}
        >
          <ProcurementsTextField
            label="調達枚数 [枚/年]"
            valiant="h4"
            {...bindTextField({
              validator: ProcurementAmount.Rule,
              selector: (form) => form.amount,
              isRequired: true,
            })}
            maxLength={ProcurementAmount.MaxLength}
            errorMessage={ProcurementAmount.Message}
            disabled={props.disabled}
            inputMode="numeric"
          />
        </FastFlexBox>
      </NestedCard>
    );
  }
);
