import { DateTime } from "luxon";

import {
  DeleteCalculationMutationResult,
  PostCalculationMutationError,
  PostCalculationMutationResult,
  postCalculation,
  deleteCalculation,
  DeleteCalculationMutationError,
} from "src/app/apis/client/default";
import { IntensityCategory, PostCalculationBody } from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PostCalculationError extends ApiError<
  "expired" | "not-authenticated" | "network" | "unknown"
> {}

export class DeleteCalculationError extends ApiError<
  "not-authenticated" | "expired" | "conflict" | "network" | "unknown"
> {}

export type DeleteCalculationRequest = {
  intensityCategory: IntensityCategory;
  version: number;
  year: DateTime;
  factoryId: string;
};

export class CalculationApi {
  async postCalculation(
    req: PostCalculationBody
  ): Promise<PostCalculationMutationResult> {
    try {
      await postCalculation(req);
    } catch (err) {
      const e = err as PostCalculationMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostCalculationError("network", err);
        case "ERR000002":
        case "ERR000003":
          throw new PostCalculationError("not-authenticated", err);
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "ERR019001":
        case "ERR019002":
        case "unknown":
          // ERR019001・ERR019002はフロントエンドでは unknown として処理
          throw new PostCalculationError("unknown", err);
        case "ERR019003":
          throw new PostCalculationError("expired", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }

  async deleteCalculation(
    req: DeleteCalculationRequest
  ): Promise<DeleteCalculationMutationResult> {
    try {
      await deleteCalculation(req.year.toFormat("yyyy"), req.factoryId, {
        intensityCategory: req.intensityCategory,
        version: req.version,
      });
    } catch (err) {
      const e = err as DeleteCalculationMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new DeleteCalculationError("network", err);
        case "ERR000002":
        case "ERR000003":
          throw new DeleteCalculationError("not-authenticated", err);
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "ERR020000":
        case "ERR020001":
        case "ERR020004":
        case "ERR020005":
        case "ERR020006":
        case "ERR020007":
        case "unknown":
          throw new DeleteCalculationError("unknown", err);
        case "ERR020002":
          throw new DeleteCalculationError("expired", err);
        case "ERR020003":
          throw new DeleteCalculationError("conflict", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
