export const paths = {
  login: "/login",
  user: {
    dashboard: {
      root: "/user/dashboard",
      year: "/user/dashboard/:year",
    },
    category1: {
      root: "/user/category1",
      year: "/user/category1/:year",
      tab: "/user/category1/:year/:tab",
    },
    category4: {
      root: "/user/category4",
      year: "/user/category4/:year",
      tab: "/user/category4/:year/:tab",
    },
    procurement: {
      root: "/user/procurement",
      year: "/user/procurement/:year",
    },
    intensity: {
      root: "/user/intensity",
      year: "/user/intensity/:year",
    },
  },
  input: {
    root: "/input",
    year: "/input/:year",
    factoryId: "/input/:year/:factoryId",
    inHouse: {
      factoryId: "/input/:year/:factoryId/in-house",
      category1: "/input/:year/:factoryId/in-house/category1",
      category4: "/input/:year/:factoryId/in-house/category4",
    },
    torch: {
      factoryId: "/input/:year/:factoryId/torch-based",
      category1: "/input/:year/:factoryId/torch-based/category1/:tab",
      category4: "/input/:year/:factoryId/torch-based/category4/:tab",
    },
  },

  category1: {
    root: "/category1",
    year: "/category1/:year",
    tab: "/category1/:year/:tab",
  },
  category4: {
    root: "/category4",
    year: "/category4/:year",
    tab: "/category4/:year/:tab",
  },
} as const;
