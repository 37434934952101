import { useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Field } from "./ghgInputForm";
import { validateInHouseCategory4Form } from "./ghgInputFormValidator";

import {
  CalculationApi,
  DeleteCalculationError,
} from "src/app/apis/calculation-api/calculationApi";
import {
  CalculationCategory4Api,
  PostCalculationCategory4Error,
} from "src/app/apis/calculation-category4-api/calculationCategory4Api";
import {
  Category4Api,
  GetCategory4ApiError,
} from "src/app/apis/category4-api/category4Api";
import {
  CompanyType,
  IntensityCategory,
  IntensityType,
} from "src/app/apis/model";
import { YearDropdownProps } from "src/app/components/molecules";
import { InHouseCategory4InputTemplate } from "src/app/components/templates/InHouseCategory4InputTemplate";
import { envConfig, messages } from "src/app/configs";
import { useConfirmationDialog } from "src/app/hooks";
import { useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import {
  ToStringFromDateTime,
  ToStringYearFromDateTime,
} from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";
import { parseNumber } from "src/lib/utils/numberUtil";

type InHouseCategory4InputPagePathParam = {
  year: string;
  factoryId: string;
};

export type InHouseCategory4InputForm = {
  tonKmBasedCo2Intensity?: Field;
};

const initInHouseCategory4InputForm = (): InHouseCategory4InputForm => {
  return {
    tonKmBasedCo2Intensity: { value: "" },
  };
};

export const InHouseCategory4InputPage: React.FC = () => {
  const params = useParams() as InHouseCategory4InputPagePathParam;

  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const yearDropDownProps = useYearDropdown();
  const [authState, setAuthState] = useAuthState<AuthState>();
  const {
    state: { factoryName },
  } = useLocation();

  const [form, setForm] = useState<InHouseCategory4InputForm>(
    initInHouseCategory4InputForm()
  );
  const updateForm: Dispatch<SetStateAction<InHouseCategory4InputForm>> =
    useCallback((form) => {
      setForm(form);
    }, []);

  const previousPagePath: string = paths.input.year.replace(
    ":year",
    yearDropDownProps?.year.toFormat("yyyy") ?? ""
  );

  /** 初期値取得処理 */
  const initData = async () => {
    if (yearDropDownProps?.year === undefined) {
      return;
    }

    let version = 0;

    let category4ApiRes = undefined;
    try {
      // SDA015：カテゴリ4取得API
      const category4Api = new Category4Api();
      const category4ApiResult = category4Api.getCategory4({
        year: yearDropDownProps.year.toFormat("yyyy"),
      });

      category4ApiRes = (await category4ApiResult).factories.find(
        (info) => info.factoryId === params.factoryId
      );

      if (category4ApiRes !== undefined) {
        version = category4ApiRes.version;
      }

      if (category4ApiRes !== undefined && !category4ApiRes.deleteFlag) {
        setForm({
          tonKmBasedCo2Intensity: {
            value:
              category4ApiRes.tonKmBasedCo2Intensity === undefined
                ? ""
                : category4ApiRes.tonKmBasedCo2Intensity.toString(),
          },
        });
      } else {
        setForm(initInHouseCategory4InputForm());
        category4ApiRes = undefined;
      }
    } catch (err) {
      if (!(err instanceof GetCategory4ApiError)) {
        snackbar.error(messages.common.unknown);
        return;
      }

      const errType = err.type;
      switch (errType) {
        case "not-authenticated":
          navigate(paths.login);
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          return;
        case "network":
          navigate(previousPagePath);
          snackbar.error(messages.common.network);
          return;
        case "unknown":
          navigate(previousPagePath);
          snackbar.error(messages.common.unknown);
          return;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }

    return { category4ApiRes, version };
  };

  /** [初期表示]イベント */
  const {
    data: apiRes,
    isProcessing: isLoading,
    setData: setApiRes,
  } = useAsyncEffect(async () => {
    // 初期値取得処理
    return await initData();
  }, [yearDropDownProps?.year]);

  /** [確定]イベント */
  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        apiRes?.category4ApiRes !== undefined
      ) {
        return;
      }

      // バリデーション処理
      const { isValid, inputForm } = validateInHouseCategory4Form(
        form,
        authState.role
      );
      setForm((form) => ({ ...form, ...inputForm }));
      if (!isValid) {
        snackbar.error(messages.ghgInput.registration.validationError);
        return;
      }

      try {
        if (inputForm.tonKmBasedCo2Intensity !== undefined) {
          const calculationCategory4Api = new CalculationCategory4Api();
          // SDA006：計算結果登録（カテゴリ４）API
          await calculationCategory4Api.postCalculationCategory4(
            {
              year: ToStringYearFromDateTime(yearDropDownProps.year),
              companyId: authState.companyId,
              factoryId: params.factoryId,
              tonKmBasedCo2Intensity: parseNumber(
                inputForm.tonKmBasedCo2Intensity.value
              ),
              byDestination: [],
              submissionTime: ToStringFromDateTime(DateTime.now()),
              intensityType: IntensityType["in-house"],
              version: apiRes?.version ?? 0,
              appVersion: parseNumber(envConfig.version),
            },
            { intensityType: "in-house" }
          );
        }

        snackbar.success(messages.ghgInput.submit.success);

        setApiRes(await initData());
      } catch (err) {
        if (!(err instanceof PostCalculationCategory4Error)) {
          snackbar.error(messages.common.unknown);
          return;
        }

        const errType = err.type;
        switch (errType) {
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          case "not-authenticated":
            navigate(paths.login);
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "factory-not-found":
            snackbar.error(messages.ghgInput.factoryNotFound);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.registration.expired);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.registration.conflict);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  /**  [確定取消]イベント */
  const { mutate: onUnSubmit, isLoading: isUnSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        apiRes?.category4ApiRes === undefined
      ) {
        return;
      }

      try {
        const calculationApi = new CalculationApi();
        // SDA003:計算結果削除API
        await calculationApi.deleteCalculation({
          intensityCategory: IntensityCategory.category4,
          version: apiRes.version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        snackbar.success(messages.ghgInput.unSubmit.success);

        setApiRes(await initData());
      } catch (err) {
        if (!(err instanceof DeleteCalculationError)) {
          snackbar.error(messages.common.unknown);
          return;
        }

        switch (err.type) {
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          case "not-authenticated":
            navigate(paths.login);
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "expired":
            snackbar.error(messages.ghgInput.unSubmit.expired);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.unSubmit.conflict);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  /** 確定の確認ダイアログ */
  const { dialogProps: submitDialogProps, openDialog: openSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.submit.confirm, onSubmit);

  /** 確定取消の確認ダイアログ */
  const { dialogProps: unSubmitDialogProps, openDialog: openUnSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.unSubmit.confirm, onUnSubmit);

  const isProcessing = isLoading || isSubmitting || isUnSubmitting;

  const isEditable = apiRes?.category4ApiRes === undefined;

  return (
    <InHouseCategory4InputTemplate
      yearDropdownProps={yearDropDownProps as YearDropdownProps}
      factoryName={factoryName}
      companyType={authState?.role as Exclude<CompanyType, "user-company">}
      isProcessing={isProcessing}
      isEditable={isEditable}
      form={form}
      setForm={updateForm}
      onClickBack={() => navigate(previousPagePath)}
      submitDialogProps={submitDialogProps}
      submitButtonProps={{
        onClick: openSubmitDialog,
        isProcessing: isSubmitting,
      }}
      unSubmitDialogProps={unSubmitDialogProps}
      unSubmitButtonProps={{
        onClick: openUnSubmitDialog,
        isProcessing: isUnSubmitting,
      }}
    />
  );
};
