import { Box } from "@mui/material";
import { ReactElement } from "react";

import { AppCard, NestedCard } from "src/app/components/atoms";
import { SectionHeading, Content } from "src/app/components/molecules";

export type ChartAndTableViewUserProps = {
  cardTitle: string;
  tableElement: ReactElement;
  backgroundColor?: string;
  children?: React.ReactNode;
};

export const ChartAndTableViewUser: React.FC<ChartAndTableViewUserProps> = (
  props
) => {
  return (
    <AppCard backgroundColor={props.backgroundColor}>
      <Content>
        <SectionHeading title={props.cardTitle}>
          <Box mb={3}>{props.children}</Box>
          <NestedCard>{props.tableElement}</NestedCard>
        </SectionHeading>
      </Content>
    </AppCard>
  );
};
