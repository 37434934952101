import produce, { Draft } from "immer";
import { Dispatch, SetStateAction, useCallback } from "react";

import { Field } from "src/app/components/pages";
import { isNotEmpty } from "src/lib/utils/validationUtil";

export const useBindTextField = <T>(
  form: T,
  setForm: Dispatch<SetStateAction<T>>
) =>
  useCallback(
    (params: {
      selector: (form: T | Draft<T>) => Field | Draft<Field> | undefined;
      validator: (value: string) => boolean;
      isRequired: boolean;
    }) => {
      const { selector, validator, isRequired } = params;

      const target = selector(form);
      return {
        value: target?.value ?? "",
        hasError: target?.error === true ?? false,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          setForm(
            produce<T>((form) => {
              const target = selector(form);
              if (target !== undefined) {
                target.value = event.target.value;
              }
            })
          );
        },
        onBlur: () => {
          setForm(
            produce<T>((form) => {
              const target = selector(form);
              if (target !== undefined) {
                switch (isRequired) {
                  case true:
                    const validateRequired = isNotEmpty();
                    target.error =
                      !validateRequired(target.value) ||
                      !validator(target.value);
                    break;
                  case false:
                    target.error = !validator(target.value);
                    break;
                }
              }
            })
          );
        },
      };
    },
    [form, setForm]
  );
