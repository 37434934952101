import {
  GetUsersMeQueryError,
  GetUsersMeQueryResult,
  getUsersMe,
} from "src/app/apis/client/users";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class GetMeError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}

export class UserApi {
  async getMe(): Promise<GetUsersMeQueryResult> {
    try {
      const res = await getUsersMe();
      return {
        companyId: res.companyId,
        loginId: res.loginId,
        userId: res.userId,
        userName: res.userName,
        role: res.role,
        version: res.version,
      };
    } catch (err) {
      const e = err as GetUsersMeQueryError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetMeError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetMeError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetMeError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
