import { KeyboardArrowDownRounded } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { FocusEventHandler } from "react";

import { AppTheme, theme } from "src/app/configs";
import { DropDownInput, DropDownItem } from "src/lib/components/atoms";

export type DropdownProps = {
  items: DropDownItem[];
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  hasError?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  label: {
    backgroundColor: theme.palette.background.paper,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
  },
  color: { color: theme.palette.text.primary },
  disabled: {
    backgroundColor: theme.palette.disabledBackground,
  },
}));

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const classes = useStyles();
  return (
    <DropDownInput
      items={props.items}
      value={props.value}
      hasError={props.hasError}
      displayEmpty
      variant="outlined"
      IconComponent={KeyboardArrowDownRounded}
      onChange={props.onChange}
      onBlur={props.onBlur}
      classes={{
        root: clsx(classes.label, props.disabled && classes.disabled),
        icon: classes.color,
      }}
      disabled={props.disabled}
      sx={[
        {
          height: 35,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "& .MuiSelect-select": {
            // NOTE: MUI の既存のパディングを上書きするために important をつける
            px: "0px !important",
            py: "0px !important",
          },
        },
      ]}
      MenuProps={{
        sx: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "&& .Mui-selected": {
            backgroundColor: theme.palette.background.default,
          },
        },
      }}
    />
  );
};
