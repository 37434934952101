import { Box, Typography } from "@mui/material";
import React from "react";

import { IntensityType } from "src/app/apis/model";
import { theme } from "src/app/configs";

export type FactoryIntensityProps = {
  intensityType: IntensityType;
};

const intensityTypeColor = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "in-house": theme.palette.category1Badge,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "torch-based": theme.palette.category4Badge,
};
const intensityTypeLabel = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "in-house": "自社算定",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "torch-based": "TORCH",
};

export const FactoryIntensity: React.FC<FactoryIntensityProps> = (props) => {
  return (
    (props.intensityType === "in-house" ||
      props.intensityType === "torch-based") && (
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          width: 64,
          lineHeight: 1,
          borderRadius: 20,
          border: `1px solid ${intensityTypeColor[props.intensityType]}`,
          color: intensityTypeColor[props.intensityType],
          backgroundColor: "#FFFFFF",
          paddingTop: theme.spacing(0.25),
          paddingBottom: theme.spacing(0.25),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          alignSelf: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 10 }}>
          {intensityTypeLabel[props.intensityType]}
        </Typography>
      </Box>
    )
  );
};
