export type ApiResponse = {
  errorCode?: string;
  message: string;
};

export const isApiResponse = (data: unknown): data is ApiResponse =>
  typeof data === "object" &&
  data !== null &&
  (("errorCode" in data && typeof data.errorCode === "string") ||
    !("errorCode" in data)) &&
  "message" in data &&
  typeof data.message === "string";
