import { Button as MButton, CircularProgress, Typography } from "@mui/material";
import React from "react";

export interface ButtonProps {
  label: string;
  variant: "text" | "contained" | "outlined";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default";
  enableSubmit?: boolean;
  onClick?: () => void;
  isProcessing?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  disabled?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <MButton
      variant={props.variant}
      type={props.enableSubmit ? "submit" : "button"}
      disableElevation
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      color={props.color}
      className={props.className}
      fullWidth
    >
      {props.isProcessing ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <Typography variant="button">{props.label}</Typography>
      )}
    </MButton>
  );
};
