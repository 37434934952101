/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

/**
 * 原単位カテゴリ
 */
export type IntensityCategory =
  (typeof IntensityCategory)[keyof typeof IntensityCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntensityCategory = {
  category1: "category1",
  category4: "category4",
} as const;
