import { GetApp } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef, GridRowsProp, GridToolbarExport } from "@mui/x-data-grid";
import { useState } from "react";

import { CustomDataGrid } from "src/app/components/atoms";
import { AppTheme } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  downloadIcon: {
    color: theme.palette.text.primary,

    // defaultのスタイルを無効化する
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
  searchField: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1),
      fontSize: theme.typography.body2.fontSize,
      height: "16px",
    },
    maxWidth: 240,
    backgroundColor: theme.palette.background.paper,
  },
  searchIcon: {
    color: theme.palette.action.active,
    marginLeft: "5px",
  },
  icon: {
    color: theme.palette.text.primary,
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0.5),
    border: `solid 1px ${theme.palette.csvIcon}`,
    borderRadius: "4px",
  },
}));

type CustomToolbarProps = {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  fileName: string;
};

const CustomToolbar = (props: CustomToolbarProps) => {
  const classes = useStyles();

  return (
    <FlexBox pt={2} pb={2} flexDirection="row" justifyContent="space-between">
      <GridToolbarExport
        csvOptions={{ fileName: props.fileName, utf8WithBom: true }}
        className={classes.downloadIcon}
        printOptions={{ disableToolbarButton: true }}
      />
      <TextField
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        className={classes.searchField}
        placeholder="キーワードを検索"
        InputProps={{
          startAdornment: (
            <SearchIcon fontSize="small" className={classes.searchIcon} />
          ),
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value !== "" ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </FlexBox>
  );
};

type DataFieldProps = {
  data: GridRowsProp;
  columns: GridColDef[];
  fileName: string;
  variant?: "ghgHeader" | "cate1Header" | "cate4Header";
};

export const DataField = (props: DataFieldProps) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");

  const filteredRows = props.data.filter((row) => {
    return Object.keys(row)
      .filter((key) => {
        return key !== "id";
      })
      .some((field) => {
        return row[field].toString().includes(searchText);
      });
  });
  return (
    <CustomDataGrid
      variant={props.variant === undefined ? "ghgHeader" : props.variant}
      components={{
        Toolbar: CustomToolbar,
        ExportIcon: () => <GetApp className={classes.icon} />,
      }}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event: { target: { value: string } }) =>
            setSearchText(event.target.value),
          clearSearch: () => setSearchText(""),
          fileName: props.fileName,
        },
      }}
      rows={filteredRows}
      columns={props.columns}
      localeText={{
        toolbarExport: "",
        toolbarExportCSV: "CSVをダウンロード",
        noRowsLabel: "該当データはありません",
      }}
    />
  );
};
