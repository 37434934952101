import {
  PostCalculationCategory1PaperMutationError,
  PostCalculationCategory1PaperMutationResult,
  postCalculationCategory1Paper,
} from "src/app/apis/client/default";
import { PostCalculationCategory1PaperParams } from "src/app/apis/model";
import { PostCalculationCategory1PaperBody } from "src/app/apis/model/postCalculationCategory1PaperBody";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PostCalculationCategory1PaperError extends ApiError<
  | "conflict"
  | "expired"
  | "not-authenticated"
  | "factory-not-found"
  | "network"
  | "unknown"
> {}

export class CalculationCategory1PaperApi {
  async postCalculationCategory1Paper(
    req: PostCalculationCategory1PaperBody,
    params: PostCalculationCategory1PaperParams
  ): Promise<PostCalculationCategory1PaperMutationResult> {
    try {
      await postCalculationCategory1Paper(req, params);
    } catch (err) {
      const e = err as PostCalculationCategory1PaperMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostCalculationCategory1PaperError("network", err);
        case "ERR000002":
        case "ERR000003":
          throw new PostCalculationCategory1PaperError(
            "not-authenticated",
            err
          );
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "unknown":
          throw new PostCalculationCategory1PaperError("unknown", err);
        case "ERR024001":
        case "ERR024002":
        case "ERR024005":
          throw new PostCalculationCategory1PaperError(
            "factory-not-found",
            err
          );
        case "ERR024003":
          throw new PostCalculationCategory1PaperError("expired", err);
        case "ERR024004":
          throw new PostCalculationCategory1PaperError("conflict", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
