import { FocusEventHandler } from "react";

import { Dropdown } from "src/app/components/atoms";
import { RowInputField } from "src/app/components/molecules";
import { DropDownItem } from "src/lib/components/atoms";

type RowDropdownFieldProps = {
  label: string;
  items: DropDownItem[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  valiant?: "h5" | "h6";
};

export const RowDropdownField: React.FC<RowDropdownFieldProps> = (props) => {
  return (
    <RowInputField
      label={props.label}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      valiant={props.valiant}
    >
      <Dropdown
        items={props.items}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        disabled={props.disabled}
        hasError={props.hasError}
      />
    </RowInputField>
  );
};
