import BigNumber from "bignumber.js";

import {
  ConverterProvider,
  FuelEmissionFactor,
  WasteEmissionFactor,
} from "./converter.provider";

import {
  ElectricPowerPurchaseModel,
  EmissionFactorModel,
  EnergyInputModel,
  FuelConsumptionModel,
  GreenCertificateModel,
  JCreditModel,
  WasteInputModel,
} from "src/app/models";
import { total } from "src/lib/utils/bignumberUtil";

export class EmissionMathProvider {
  constructor(private readonly converter: ConverterProvider) {}
  /**
   * 工場単位の排出量と排出係数を計算する
   * @param ghgInput GHG入力情報
   * @param emissionFactorList 排出係数リスト
   * @returns 工場単位で把握したGHG排出量合計
   */
  totalGhgEmissionByFactory(
    requiredEnergyInput: Required<EnergyInputModel>,
    requiredWasteInput: Required<WasteInputModel>,
    emissionFactorList: EmissionFactorModel[],
    electricPowerRatio?: BigNumber
  ): {
    factor: {
      ownSteamEmissionFactor?: BigNumber;
      ownElectricEmissionFactor?: BigNumber;
      factoryElectricEmissionFactorWithoutCertificates?: BigNumber;
    };
    emission: {
      totalGhgWasteEmissionByFactory: BigNumber;
      totalGhgDrainageEmissionByFactory: BigNumber;
    };
  } {
    const fuelEmissionFactor =
      this.converter.convertToFuelEmissionFactor(emissionFactorList);

    // 設備ごとの排出量合計
    const emissionsByEnergyEquipment = this.totalFuelEmissionByMachine(
      {
        boiler: requiredEnergyInput.boiler.fuelConsumptions,
        generator: requiredEnergyInput.generator.fuelConsumptions,
        coGenerator: requiredEnergyInput.coGenerator.fuelConsumptions,
      },
      fuelEmissionFactor
    );

    // コジェネ2次発生エネルギー
    const coGeneratorSecondaryEmission = this.calcCoGeneratorSecondaryEmission({
      steamForSteam: requiredEnergyInput.coGenerator.occurredSteamForSteam,
      steamForElectricPower:
        requiredEnergyInput.coGenerator.occurredSteamForElectricPower,
      totalEmission: emissionsByEnergyEquipment.coGenerator,
      electricPowerRatio,
    });

    // 自家蒸気排出係数
    const ownSteamEmissionFactor = this.calcOwnSteamOrElectricEmissionFactor({
      emissions: [
        coGeneratorSecondaryEmission.steamEmission,
        emissionsByEnergyEquipment.boiler,
      ],
      generatedEnergies: [
        requiredEnergyInput.coGenerator.occurredSteamForSteam,
        requiredEnergyInput.boiler.occurredSteam,
      ],
    });

    // 自家発電力排出係数
    const ownElectricEmissionFactor = this.calcOwnSteamOrElectricEmissionFactor(
      {
        emissions: [
          coGeneratorSecondaryEmission.electricEmission,
          emissionsByEnergyEquipment.generator,
        ],
        generatedEnergies: [
          requiredEnergyInput.coGenerator.occurredElectricPower,
          requiredEnergyInput.generator.occurredElectricPower,
          requiredEnergyInput.solar.occurredElectricPower,
          requiredEnergyInput.hydroPower.occurredElectricPower,
        ],
      }
    );

    // 購入電力割合
    const electricPower = {
      electricPowerPurchases: requiredEnergyInput.electricPowerPurchases,
      coGeneratorElectricPower:
        requiredEnergyInput.coGenerator.occurredElectricPower,
      generatorElectricPower:
        requiredEnergyInput.generator.occurredElectricPower,
      solarElectricPower: requiredEnergyInput.solar.occurredElectricPower,
      hydroPowerElectricPower:
        requiredEnergyInput.hydroPower.occurredElectricPower,
      electricPowerSale: requiredEnergyInput.energySale.electricPower,
    };
    const ratioOfPurchase = this.ratioOfElectricPowerPurchases(electricPower);

    // 工場別電力排出係数（証書未考慮）
    const factoryElectricEmissionFactorWithoutCertificates =
      this.calcElectricEmissionFactorByFactoryWithoutCertificates(
        ownElectricEmissionFactor,
        electricPower,
        ratioOfPurchase
      );

    // 工場別電力排出係数（証書考慮）
    const factoryElectricEmissionFactor =
      this.calcElectricEmissionFactorByFactory({
        electricPowers: electricPower,
        greenCertificates: requiredEnergyInput.greenCertificates,
        jCredits: requiredEnergyInput.jCredits,
        factorWithoutCertificates:
          factoryElectricEmissionFactorWithoutCertificates,
      });

    // 工場単位で把握したGHG排出量合計
    const wasteEmissionFactor =
      this.converter.convertToWasteEmissionFactor(emissionFactorList);
    const drainageEmissionFactor =
      this.converter.convertToDrainageEmissionFactor(emissionFactorList);
    const totalGhgEmissionByFactory = this.totalGhgEmissionByFactoryHelper(
      requiredWasteInput,
      {
        wasteEmissionFactor,
        drainageEmissionFactor,
        factoryElectricEmissionFactor,
      }
    );

    return {
      factor: {
        ownSteamEmissionFactor: ownSteamEmissionFactor,
        ownElectricEmissionFactor: ownElectricEmissionFactor,
        factoryElectricEmissionFactorWithoutCertificates:
          factoryElectricEmissionFactorWithoutCertificates,
      },
      emission: {
        totalGhgWasteEmissionByFactory: totalGhgEmissionByFactory.wasteEmission,
        totalGhgDrainageEmissionByFactory:
          totalGhgEmissionByFactory.drainageEmission,
      },
    };
  }

  /**
   * 設備ごとの燃料に関する排出量を取得する。
   * @param input 設備別投入燃料
   * @param emissionFactor 排出係数
   * @returns 設備別排出量
   */
  totalFuelEmissionByMachine(
    input: {
      boiler: FuelConsumptionModel[];
      generator: FuelConsumptionModel[];
      coGenerator: FuelConsumptionModel[];
    },
    emissionFactor: FuelEmissionFactor
  ): {
    boiler: BigNumber;
    generator: BigNumber;
    coGenerator: BigNumber;
  } {
    return {
      boiler: this.totalFuelEmission(input.boiler, emissionFactor),
      generator: this.totalFuelEmission(input.generator, emissionFactor),
      coGenerator: this.totalFuelEmission(input.coGenerator, emissionFactor),
    };
  }

  /**
   * 燃料に関する排出量を合計する。
   * @param input 燃料別投入量
   * @param emissionFactor 排出量係数
   * @returns 排出量合計
   */
  totalFuelEmission(
    input: FuelConsumptionModel[],
    emissionFactor: FuelEmissionFactor
  ): BigNumber {
    let totalFuelEmission: BigNumber = new BigNumber(0);
    for (const item of input) {
      const factor = emissionFactor[item.fuelType];

      const emission: BigNumber = item.amount.times(factor);
      totalFuelEmission = totalFuelEmission.plus(emission);
    }

    return totalFuelEmission;
  }

  /**
   * コジェネの2次エネルギー別排出量を計算する。
   * @param input エネルギー情報
   * @param totalEmission 排出量
   * @returns 2次エネルギー別排出量
   */
  calcCoGeneratorSecondaryEmission(input: {
    steamForSteam: BigNumber;
    steamForElectricPower: BigNumber;
    totalEmission: BigNumber;
    electricPowerRatio?: BigNumber;
  }): { steamEmission: BigNumber; electricEmission: BigNumber } {
    // 加工・プレプリのときは、電力と蒸気それぞれのエネルギー量 [MJ] を考え、その比で排出量を按分する
    if (input.electricPowerRatio !== undefined) {
      return {
        steamEmission: input.totalEmission.times(
          BigNumber.max(0, new BigNumber(1).minus(input.electricPowerRatio))
        ),
        electricEmission: input.totalEmission.times(input.electricPowerRatio),
      };
    }

    // 製紙のときは、発電に使用した蒸気量[t]と熱として利用した蒸気量[t]を考え、その比で排出量を按分する
    const coGeneratorSteamEmission: BigNumber = input.totalEmission
      .times(input.steamForSteam)
      .dividedBy(input.steamForElectricPower.plus(input.steamForSteam));

    const coGeneratorElectricEmission: BigNumber = input.totalEmission
      .times(input.steamForElectricPower)
      .dividedBy(input.steamForElectricPower.plus(input.steamForSteam));

    return {
      steamEmission: coGeneratorSteamEmission.isFinite()
        ? coGeneratorSteamEmission
        : new BigNumber(0),
      electricEmission: coGeneratorElectricEmission.isFinite()
        ? coGeneratorElectricEmission
        : new BigNumber(0),
    };
  }

  /**
   * 自家電力・蒸気排出係数を計算する。
   * @param input エネルギー設備別排出量
   * @returns 自家電力・蒸気排出係数
   */
  calcOwnSteamOrElectricEmissionFactor(input: {
    emissions: BigNumber[];
    generatedEnergies: BigNumber[];
  }): BigNumber | undefined {
    const totalEmission: BigNumber = total(...input.emissions);
    const totalEnergy: BigNumber = total(...input.generatedEnergies);
    const factor = totalEmission.dividedBy(totalEnergy);

    return factor.isFinite() ? factor : undefined;
  }

  /**
   * 購入電力割合を計算する。
   * @param input 利用電力
   * @returns 購入電力割合
   */
  ratioOfElectricPowerPurchases(input: ElectricPowers): BigNumber {
    const usedAmount = this.totalElectricPowerForUseOfFactory(input);
    if (usedAmount.isZero()) {
      return new BigNumber(1);
    }
    const electricPurchases: BigNumber[] = input.electricPowerPurchases.map(
      (item) => item.amount
    );
    const purchaseAmount = total(...electricPurchases);
    const ratio = purchaseAmount.dividedBy(usedAmount);
    return ratio;
  }

  /**
   * 自社工場利用電力量を計算する。
   * @param input 利用電力
   * @returns 自社電力
   */
  totalElectricPowerForUseOfFactory(input: ElectricPowers): BigNumber {
    const electricPurchases: BigNumber[] = input.electricPowerPurchases.map(
      (item) => item.amount
    );
    const powers = total(
      ...electricPurchases,
      input.coGeneratorElectricPower,
      input.generatorElectricPower,
      input.solarElectricPower,
      input.hydroPowerElectricPower
    );

    // 販売電力が発電・購入量を超えた場合、自社電力量を負数にしない
    return BigNumber.max(powers.minus(input.electricPowerSale), 0);
  }

  /**
   * 工場別電力排出係数（証書未考慮）を計算する。
   * @param input 自家発電係数、外部購入・販売電力
   * @returns 工場別電力排出係数（証書未考慮）
   */
  calcElectricEmissionFactorByFactoryWithoutCertificates(
    ownElectricFactor: BigNumber | undefined,
    electricPowers: ElectricPowers,
    ratioOfPurchase: BigNumber
  ): BigNumber | undefined {
    const totalAmount = this.totalElectricPowerForUseOfFactory(electricPowers);

    const co2Emission = total(
      ownElectricFactor === undefined
        ? new BigNumber(0)
        : ownElectricFactor.times(
            // 自家電力の使用量[kWh]
            totalAmount.times(new BigNumber(1).minus(ratioOfPurchase))
          ),
      ...electricPowers.electricPowerPurchases.map((it) =>
        it.amount.times(it.emissionFactor)
      )
    );

    return totalAmount.isZero() ? undefined : co2Emission.div(totalAmount);
  }

  /**
   * 工場別電力排出係数（証書考慮）を計算する。
   * @param input 自家発電係数、外部購入・販売電力、証書
   * @returns 工場別電力排出係数（証書考慮）
   */
  calcElectricEmissionFactorByFactory(input: {
    electricPowers: ElectricPowers;
    greenCertificates: GreenCertificateModel[];
    jCredits: JCreditModel[];
    factorWithoutCertificates: BigNumber | undefined;
  }): BigNumber | undefined {
    if (input.factorWithoutCertificates === undefined) {
      return undefined;
    }
    const usedAmount = this.totalElectricPowerForUseOfFactory(
      input.electricPowers
    );
    const greenCertificates = input.greenCertificates.map(
      (item) => item.amount
    );
    const jCredits = input.jCredits.map((item) => item.amount);
    const amountCertificates = total(...greenCertificates, ...jCredits);

    // 証書購入量が利用電力量を超えた場合は、係数を負数にしない
    const factor = input.factorWithoutCertificates.times(
      BigNumber.max(usedAmount.minus(amountCertificates), 0).dividedBy(
        usedAmount
      )
    );
    return factor.isFinite() ? factor : undefined;
  }

  /**
   * 廃棄物の排出量合計を計算する。
   * @param input 廃棄物情報
   * @returns 廃棄物の排出量
   */
  totalEmissionOfWasteType(
    input: Required<WasteInputModel>,
    factor: WasteEmissionFactor
  ): BigNumber {
    let totalWasteEmission: BigNumber = new BigNumber(0);
    for (const waste of input.wastes) {
      if (waste.method !== "inHouseIncineration") {
        const wasteFactor = factor[waste.method];

        const emission: BigNumber = waste.amount.times(wasteFactor);
        totalWasteEmission = totalWasteEmission.plus(emission);
      }
    }
    totalWasteEmission = totalWasteEmission.plus(
      input.totalInHouseIncinerationCo2Emission
    );
    return totalWasteEmission;
  }

  /**
   * 工場単位で把握したGHG排出量合計を計算する。
   */
  totalGhgEmissionByFactoryHelper(
    input: Required<WasteInputModel>,
    factor: {
      wasteEmissionFactor: WasteEmissionFactor;
      drainageEmissionFactor: BigNumber;
      factoryElectricEmissionFactor: BigNumber | undefined;
    }
  ): {
    wasteEmission: BigNumber;
    drainageEmission: BigNumber;
  } {
    const wasteEmission = this.totalEmissionOfWasteType(
      input,
      factor.wasteEmissionFactor
    );
    const outSourceDrainageEmission = input.outSourceDrainageAmount.times(
      factor.drainageEmissionFactor
    );

    if (factor.factoryElectricEmissionFactor === undefined) {
      return {
        wasteEmission: wasteEmission,
        drainageEmission: outSourceDrainageEmission,
      };
    }

    const inHouseDrainageEmission = input.inHouseDrainageElectricPower.times(
      factor.factoryElectricEmissionFactor
    );

    return {
      wasteEmission: wasteEmission,
      drainageEmission: inHouseDrainageEmission.plus(outSourceDrainageEmission),
    };
  }
}

export type ElectricPowers = {
  electricPowerPurchases: ElectricPowerPurchaseModel[];
  coGeneratorElectricPower: BigNumber;
  generatorElectricPower: BigNumber;
  solarElectricPower: BigNumber;
  hydroPowerElectricPower: BigNumber;
  electricPowerSale: BigNumber;
};
