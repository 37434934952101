import { LaunchRounded } from "@mui/icons-material";
import { Grid, Box, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { CompanyType } from "src/app/apis/model";
import { AppCard, Annotate, Dropdown, Text } from "src/app/components/atoms";
import {
  RowTextField,
  SectionHeading,
  TitledArea,
  TitledAreaContents,
} from "src/app/components/molecules";
import {
  SaveAndRegisterButton,
  SaveAndRegisterButtonProps,
  TransportTableInput,
  TransportTableRows,
  ConfirmationDialog,
} from "src/app/components/organisms";
import {
  DeliveryForm,
  GhgInputCategory4Form,
  initTransportByRailForm,
  initTransportForm,
  TransportForm,
} from "src/app/components/pages";
import { AppTheme, messages } from "src/app/configs";
import {
  ElectricPowerAmount,
  TransportFuelAmount,
  TransportByRailDestination,
  TransportByRailDistance,
  TransportFuelAmountInCheckBox,
  ProcurementWeightBySupplierFactory,
} from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { DropDownItem, FlexBox } from "src/lib/components/atoms";

export type GhgTransportInputTemplateProps = {
  deliveryList: DeliveryForm[];
  transport: TransportForm;
  setForm: Dispatch<SetStateAction<GhgInputCategory4Form>>;
  isEditable: boolean;
  isProcessing: boolean;
  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];
  companyType: Exclude<CompanyType, "user-company">;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
  infoMessage: {
    color: theme.palette.text.disabled,
    lineHeight: 1,
  },
  infoLinkIcon: {
    verticalAlign: "middle",
    marginLeft: 3,
    marginRight: 2,
    marginBottom: 2,
    width: 12,
    height: 12,
    color: "#24A6AD",
  },
  link: {
    color: "#24A6AD",
  },
}));

// 納入先が「その他」のKey
export const OTHER_KEY = "other";

export const GhgTransportInputTemplate: React.FC<
  GhgTransportInputTemplateProps
> = (props) => {
  const classes = useStyles();

  const bindTextField = useBindTextField(props.transport, (transportAction) =>
    props.setForm(
      produce<GhgInputCategory4Form>((form) => {
        let transport;
        if (transportAction instanceof Function) {
          transport = transportAction(form.transport);
        } else {
          transport = transportAction;
        }

        // 納入先が「その他」の場合、鉄道輸送量を設定
        const transportByRails = transport.transportByRail.transportByRails.map(
          (it) => {
            return {
              ...it,
              railTransportAmount:
                it.destinationId.value === OTHER_KEY
                  ? it.railTransportAmount
                  : { value: "", error: false },
            };
          }
        );

        form.transport = {
          ...transport,
          transportByRail: {
            ...transport.transportByRail,
            transportByRails,
          },
        };
      })
    )
  );

  const transportDestinationDropdownItems: DropDownItem[] =
    props.deliveryList.map((delivers) => {
      return {
        value: delivers.destinationId,
        displayName: delivers.factoryName ?? "dummy",
      };
    });
  transportDestinationDropdownItems.push({
    value: OTHER_KEY,
    displayName: "その他",
  });

  const bindTransportType = (index: number, inputName: "transportByRail") => {
    const bind = bindTextField({
      selector: (form) => form[inputName].transportByRails[index].destinationId,
      validator: TransportByRailDestination.Rule,
      isRequired: false,
    });
    // 納入先プルダウンの選択肢を一意にする
    const transportDestinationArray =
      props.transport.transportByRail.transportByRails.map((item, mapIndex) => {
        if (index === mapIndex || item.destinationId.value === OTHER_KEY) {
          return "";
        }
        return item.destinationId.value;
      });
    const transportSelectItems = transportDestinationDropdownItems.filter(
      (item) => !transportDestinationArray.includes(item.value)
    );
    return {
      ...bind,
      items: transportSelectItems,
      disabled: !props.isEditable,
    };
  };

  const onClickCheckbox = (item: "transportByRail" | "shipTransportation") => {
    return () =>
      props.setForm(
        produce<GhgInputCategory4Form>((form) => {
          switch (item) {
            case "transportByRail":
              form.transport[item] = {
                ...initTransportForm()[item],
                isChecked: !form.transport[item].isChecked,
              };
              break;
            case "shipTransportation":
              form.transport[item] = {
                ...initTransportForm()[item],
                isChecked: !form.transport[item].isChecked,
              };
              form.transport.transportFuelConsumptions.cHeavyOil = {
                ...initTransportForm().transportFuelConsumptions.cHeavyOil,
                error: false,
              };
          }
        })
      );
  };

  const onClickAddButton = (inputName: "transportByRail") => {
    return () =>
      props.setForm(
        produce((form) => {
          form.transport[inputName].transportByRails.push(
            initTransportByRailForm()
          );
        })
      );
  };

  const onClickRemoveButton = (key: string) => {
    return () => {
      props.setForm(
        produce((form) => {
          const array = form.transport.transportByRail.transportByRails;
          const index = array.findIndex((it) => it.key === key);
          if (index !== -1) {
            array.splice(index, 1);
          }
        })
      );
    };
  };

  const {
    openDialog: openTransportByRailDialog,
    dialogProps: transportByRailDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.transportCheck,
    onClickCheckbox("transportByRail")
  );

  const {
    openDialog: openShipTransportationDialog,
    dialogProps: shipTransportationDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.transportCheck,
    onClickCheckbox("shipTransportation")
  );

  // TransportTableInputのための関数
  const bindTransportRailAmount = (
    index: number,
    inputName: "transportByRail"
  ) => {
    const isOther =
      props.transport[inputName].transportByRails[index].destinationId.value ===
      OTHER_KEY;
    const bind = bindTextField({
      selector: (form) =>
        form[inputName].transportByRails[index].railTransportAmount,
      validator: TransportFuelAmount.Rule,
      isRequired: isOther,
    });
    return {
      ...bind,
      maxLength: TransportFuelAmount.MaxLength,
      disabled: !props.isEditable || !isOther,
    };
  };

  const bindTransportRailDistanceAmount = (
    index: number,
    inputName: "transportByRail"
  ) => {
    const bind = bindTextField({
      selector: (form) => form[inputName].transportByRails[index].railDistance,
      validator: TransportByRailDistance.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      maxLength: TransportByRailDistance.MaxLength,
      disabled: !props.isEditable,
    };
  };

  const bindTransportTruckDistanceAmount = (
    index: number,
    inputName: "transportByRail"
  ) => {
    const bind = bindTextField({
      selector: (form) => form[inputName].transportByRails[index].truckDistance,
      validator: TransportByRailDistance.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      maxLength: TransportByRailDistance.MaxLength,
      disabled: !props.isEditable,
    };
  };

  return (
    <AppCard>
      <SaveAndRegisterButton
        justifyContent="flex-end"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />

      <SectionHeading
        title="年間輸送燃料消費量"
        subtitle={
          <Annotate>
            庸車に委託し燃料把握が難しい場合は省エネ法ベース（燃費法 or
            トンキロ法）で算出の上、「軽油」欄にご入力をお願いします。
          </Annotate>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <RowTextField
              label="ガソリン [kL/年]"
              {...bindTextField({
                validator: TransportFuelAmount.Rule,
                selector: (form) => form.transportFuelConsumptions.gasoline,
                isRequired: true,
              })}
              maxLength={TransportFuelAmount.MaxLength}
              errorMessage={TransportFuelAmount.Message}
              disabled={!props.isEditable}
              inputMode="numeric"
            />
            <RowTextField
              label="軽油 [kL/年]"
              {...bindTextField({
                validator: TransportFuelAmount.Rule,
                selector: (form) => form.transportFuelConsumptions.lightOil,
                isRequired: true,
              })}
              maxLength={TransportFuelAmount.MaxLength}
              errorMessage={TransportFuelAmount.Message}
              disabled={!props.isEditable}
              inputMode="numeric"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <RowTextField
              label="電力 [kWh/年]"
              {...bindTextField({
                validator: ElectricPowerAmount.Rule,
                selector: (form) =>
                  form.transportFuelConsumptions.electricPower,
                isRequired: true,
              })}
              maxLength={ElectricPowerAmount.MaxLength}
              errorMessage={ElectricPowerAmount.Message}
              disabled={!props.isEditable}
              inputMode="numeric"
            />
            <RowTextField
              label={
                <span>
                  CNG [千m<sup>3</sup>/年]
                </span>
              }
              {...bindTextField({
                validator: TransportFuelAmount.Rule,
                selector: (form) => form.transportFuelConsumptions.cng,
                isRequired: true,
              })}
              maxLength={TransportFuelAmount.MaxLength}
              errorMessage={TransportFuelAmount.Message}
              disabled={!props.isEditable}
              inputMode="numeric"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </SectionHeading>

      <Box pt={2}>
        <TitledArea
          title={
            <TitledAreaContents
              title="船舶輸送がある"
              onClick={
                props.transport.shipTransportation.isChecked
                  ? openShipTransportationDialog
                  : onClickCheckbox("shipTransportation")
              }
              checked={props.transport.shipTransportation.isChecked}
              disabled={!props.isEditable}
            />
          }
          subtitle={
            <Annotate>
              混載の場合は、トンキロ法をベースに算定してください。詳しくは
              <Link
                href="https://www.enecho.meti.go.jp/category/saving_and_new/saving/enterprise/transport/institution/index.html"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                こちら
                <LaunchRounded className={classes.infoLinkIcon} />
              </Link>
              をご確認ください。
            </Annotate>
          }
          collapse={!props.transport.shipTransportation.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <RowTextField
                label="C重油 [kL/年]"
                {...bindTextField({
                  validator: TransportFuelAmountInCheckBox.Rule,
                  selector: (form) => form.transportFuelConsumptions.cHeavyOil,
                  isRequired: true,
                })}
                maxLength={TransportFuelAmountInCheckBox.MaxLength}
                errorMessage={TransportFuelAmountInCheckBox.Message}
                disabled={!props.isEditable}
                inputMode="numeric"
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...shipTransportationDialogProps} />
      </Box>

      <Box pt={2} mb={5}>
        <TitledArea
          title={
            <TitledAreaContents
              title="鉄道輸送がある"
              onClick={
                props.transport.transportByRail.isChecked
                  ? openTransportByRailDialog
                  : onClickCheckbox("transportByRail")
              }
              checked={props.transport.transportByRail.isChecked}
              disabled={!props.isEditable}
            />
          }
          subtitle={
            <Annotate>対象納入先への片道輸送距離をご入力ください。</Annotate>
          }
          collapse={!props.transport.transportByRail.isChecked}
        >
          {props.deliveryList.length === 0 && !props.isProcessing ? (
            // 本来発生しないと思うが納入先0件の場合はメッセージを表示する
            <FlexBox justifyContent="center" className={classes.disabled}>
              <Typography>{messages.ghgInput.transport.noDelivery}</Typography>
            </FlexBox>
          ) : (
            <Box ml={1}>
              <TransportTableInput
                firstInputColumn="納入先名"
                fourthInputColumn="鉄道輸送量 [t]"
                secondInputColumn="鉄道輸送距離 [km]"
                thirdInputColumn="鉄道輸送前後のトラック輸送距離 [km]"
                onClickAddButton={onClickAddButton("transportByRail")}
                isEditable={props.isEditable}
              >
                <Box ml={2}>
                  {props.transport.transportByRail.transportByRails.map(
                    (transportByRail, index) => (
                      <TransportTableRows
                        firstInput={
                          <Dropdown
                            {...bindTransportType(index, "transportByRail")}
                          />
                        }
                        firstInputErrorMessage={
                          TransportByRailDestination.Message
                        }
                        firstInputHasError={
                          bindTransportType(index, "transportByRail").hasError
                        }
                        fourthInput={
                          <Text
                            {...bindTransportRailAmount(
                              index,
                              "transportByRail"
                            )}
                            inputMode="numeric"
                          />
                        }
                        fourthInputErrorMessage={TransportFuelAmount.Message}
                        fourthInputHasError={
                          bindTransportRailAmount(index, "transportByRail")
                            .hasError
                        }
                        secondInput={
                          <Text
                            {...bindTransportRailDistanceAmount(
                              index,
                              "transportByRail"
                            )}
                            inputMode="numeric"
                          />
                        }
                        secondInputErrorMessage={
                          TransportByRailDistance.Message
                        }
                        secondInputHasError={
                          bindTransportRailDistanceAmount(
                            index,
                            "transportByRail"
                          ).hasError
                        }
                        thirdInput={
                          <Text
                            {...bindTransportTruckDistanceAmount(
                              index,
                              "transportByRail"
                            )}
                            inputMode="numeric"
                          />
                        }
                        thirdInputErrorMessage={TransportByRailDistance.Message}
                        thirdInputHasError={
                          bindTransportTruckDistanceAmount(
                            index,
                            "transportByRail"
                          ).hasError
                        }
                        onClickRemoveButton={onClickRemoveButton(
                          transportByRail.key
                        )}
                        isLastRow={
                          props.transport.transportByRail.transportByRails
                            .length === 1
                        }
                        isEditable={props.isEditable}
                        key={transportByRail.key}
                      />
                    )
                  )}
                </Box>
              </TransportTableInput>
            </Box>
          )}
        </TitledArea>

        <ConfirmationDialog {...transportByRailDialogProps} />
      </Box>

      <SectionHeading title="年間納入量" mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <RowTextField
              label={
                props.companyType === "paper-supplier"
                  ? "総納入量 [t/年]"
                  : "総納入量 [m2/年]"
              }
              {...bindTextField({
                validator: ProcurementWeightBySupplierFactory.Rule,
                selector: (form) => form.amount,
                isRequired: true,
              })}
              maxLength={ProcurementWeightBySupplierFactory.MaxLength}
              errorMessage={ProcurementWeightBySupplierFactory.Message}
              disabled={!props.isEditable}
              inputMode="numeric"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </SectionHeading>

      <Box mt={3}>
        <SaveAndRegisterButton
          justifyContent="center"
          saveButtonProps={props.saveButtonProps}
          registerButtonProps={props.registerButtonProps}
        />
      </Box>
    </AppCard>
  );
};
