import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { Category4EmissionByFactoryTemplate } from "src/app/components/templates";
import { Category4DataForDisplay } from "src/app/hooks";
import { TotalEmission } from "src/app/models";
import { sumCategory4EmissionForTotal } from "src/app/utils/computeForVisuals";

export type Category4EmissionByFactoryPageProps = {
  category4Data?: Category4DataForDisplay;
  isProcessing: boolean;
};

const convertCategory4DataToChartProps = (
  category4Data?: Category4DataForDisplay
) => {
  const chartData: TotalEmission[] =
    category4Data?.factories !== undefined
      ? sumCategory4EmissionForTotal(category4Data.factories)
      : [];

  return { chartData };
};

const convertCategory4DataToTableProps = (
  category4Data?: Category4DataForDisplay
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 2.25,
    },
    {
      field: "co2Emission",
      headerName: "年間GHG排出量(t-CO2eq/年)",
      flex: 1,
      renderCell: (params) => getEmission(params),
    },
  ];

  const tableData = [];
  if (category4Data?.factories !== undefined) {
    for (const factory of sumCategory4EmissionForTotal(
      category4Data.factories
    )) {
      tableData.push({
        id: factory.id,
        factoryName: factory.dataName,
        co2Emission: Math.round(factory.value),
      });
    }
  }

  const fileName = `カテゴリ４合計（年）_${category4Data?.year.toFormat(
    "yyyy"
  )}`;
  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const Category4EmissionByFactoryPage: React.FC<
  Category4EmissionByFactoryPageProps
> = (props) => {
  const chartProps = convertCategory4DataToChartProps(props.category4Data);
  const tableProps = convertCategory4DataToTableProps(props.category4Data);

  return (
    <Category4EmissionByFactoryTemplate
      isProcessing={props.isProcessing}
      {...chartProps}
      {...tableProps}
    />
  );
};

const getEmission = (params: GridRenderCellParams) => {
  return (
    <Box display="flex">
      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : ""}
      </Box>
    </Box>
  );
};
