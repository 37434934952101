/* eslint-disable @typescript-eslint/no-unused-vars */
import { rest } from "msw";

import { messages } from "src/app/configs";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Category1Category4EmissionIntensityMock = () => [
  /**
   * サプライヤ（製紙）の場合、製紙工場カテゴリ1取得APIを呼び出される
   */
  ...paperSupplierMock,
  /**
   * サプライヤ（加工）の場合、加工工場カテゴリ1取得APIを呼び出される
   */
  // ...processSupplierMock,
  /**
   * カテゴリ4取得APIを呼び出される
   */
  ...category4Mock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:network
   */
  // ...category1PaperNetworkErrorMock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:timeout
   */
  //...category1PaperTimeoutErrorMock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:unknown
   */
  //...category1PaperUnknownErrorMock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:ERR000000
   */
  //...category1PaperERR000000Mock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:ERR000001
   */
  //...category1PaperERR000001Mock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:ERR000004
   */
  // ...category1PaperERR000004Mock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:ERR000002
   */
  //...category1PaperERR000002Mock,
  /**
   * 製紙工場カテゴリ1取得APIのエラー:ERR000003
   */
  //...category1PaperERR000003Mock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:network
   */
  //...category1ProcessNetworkErrorMock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:timeout
   */
  //...category1ProcessTimeoutErrorMock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:unknown
   */
  //...category1ProcessUnknownErrorMock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:ERR000000
   */
  //...category1ProcessERR000000Mock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:ERR000001
   */
  //...category1ProcessERR000001Mock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:ERR000004
   */
  // ...category1ProcessERR000004Mock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:ERR000002
   */
  //...category1ProcessERR000002Mock,
  /**
   * 加工工場カテゴリ1取得APIのエラー:ERR000003
   */
  //...category1ProcessERR000003Mock,
  /**
   * カテゴリ4取得APIのエラー:network
   */
  ...category4NetworkErrorMock,
  /**
   * カテゴリ4取得APIのエラー:timeout
   */
  //...category4TimeoutErrorMock,
  /**
   * カテゴリ4取得APIのエラー:unknown
   */
  //...category4UnknownErrorMock,
  /**
   * カテゴリ4取得APIのエラー:ERR000000
   */
  //...category4ERR000000Mock,
  /**
   * カテゴリ4取得APIのエラー:ERR000001
   */
  //...category4ERR000001Mock,
  /**
   * カテゴリ4取得APIのエラー:ERR000004
   */
  // ...category4ERR000004Mock,
  /**
   * カテゴリ4取得APIのエラー:ERR000002
   */
  //...category4ERR000002Mock,
  /**
   * カテゴリ4取得APIのエラー:ERR000003
   */
  //...category4ERR000003Mock,
];

const category4Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            co2Emission: 1500.15,
            tonKmBasedCo2Intensity: 0.01,
            byDestination: [],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 0.6,
            byDestination: [],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takazaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            co2Emission: 2250,
            tonKmBasedCo2Intensity: 0.8,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.21,
                tonKmBasedCo2Intensity: 0.22,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.23,
                tonKmBasedCo2Intensity: 0.24,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.25,
                tonKmBasedCo2Intensity: 0.26,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            co2Emission: 2400,
            tonKmBasedCo2Intensity: 0.3,
            byDestination: [
              {
                destinationId: "a",
                factoryName: "XX株式会社 a工場",
                factoryNameForSelf: "a工場",
                factoryNameForOthers: "X・a（製紙）",
                factoryType: "paper",
                weightBasedCo2Intensity: 0.31,
                tonKmBasedCo2Intensity: 0.32,
              },
              {
                destinationId: "b",
                factoryName: "XX株式会社　b工場",
                factoryNameForSelf: "b工場",
                factoryNameForOthers: "X・b（加工）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.33,
                tonKmBasedCo2Intensity: 0.34,
              },
              {
                destinationId: "c",
                factoryName: "XX株式会社　c工場",
                factoryNameForSelf: "c工場",
                factoryNameForOthers: "X・c（プレプリント）",
                factoryType: "process",
                weightBasedCo2Intensity: 0.35,
                tonKmBasedCo2Intensity: 0.36,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 製紙工場カテゴリ1取得API */
const paperSupplierMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.2012,
                amountBasedCo2Intensity: 0.2458,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.14,
                weightBasedCo2Intensity: 0.2752,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.2146,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1463,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takasaki",
            factoryName: "YY株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "Y・高崎（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1463,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.2146,
              },
              {
                classificationType: "dye",
                co2Emission: 400.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社 八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.1258,
                amountBasedCo2Intensity: 0.1263,
              },
            ],
            byClassification: [
              {
                classificationType: "privatePower",
                co2Emission: 1050.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1346,
              },
              {
                classificationType: "waste",
                co2Emission: 500.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1467,
              },
              {
                classificationType: "water",
                co2Emission: 300.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.2346,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            byProduct: [
              {
                productType: "generalCorrugationMedium",
                productName: "一般中芯",
                productShortName: "一般中芯",
                sortOrder: 1,
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3825,
              },
            ],
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1300.15,
                weightBasedCo2Intensity: 0.1495,
                amountBasedCo2Intensity: 0.1896,
              },
            ],
            intensityType: "industry-average",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 加工工場カテゴリ1取得API */
const processSupplierMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北海道工場",
            factoryNameForOthers: "X・北海道（加工）",
            factoryType: "process",
            co2Emission: 1000.15,
            weightBasedCo2Intensity: 0.4895,
            amountBasedCo2Intensity: 0.3843,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3896,
              },
            ],
            intensityType: "in-house",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場",
            factoryNameForOthers: "X・仙台（加工）",
            factoryType: "process",
            co2Emission: 1500.15,
            weightBasedCo2Intensity: 0.1258,
            amountBasedCo2Intensity: 0.1463,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1000.15,
                weightBasedCo2Intensity: 0.4895,
                amountBasedCo2Intensity: 0.3896,
              },
            ],
            intensityType: "idea",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "takasaki",
            factoryName: "YY株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "Y・高崎（加工）",
            factoryType: "process",
            co2Emission: 2000.15,
            weightBasedCo2Intensity: 0.3045,
            amountBasedCo2Intensity: 0.2146,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 2000.15,
                weightBasedCo2Intensity: 0.1465,
                amountBasedCo2Intensity: 0.2436,
              },
              {
                classificationType: "dye",
                co2Emission: 400.15,
                weightBasedCo2Intensity: 0.3045,
                amountBasedCo2Intensity: 0.3154,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社 八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（加工）",
            factoryType: "process",
            co2Emission: 2000.15,
            weightBasedCo2Intensity: 0.3045,
            amountBasedCo2Intensity: 0.2346,
            byClassification: [
              {
                classificationType: "privatePower",
                co2Emission: 1050.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1346,
              },
              {
                classificationType: "waste",
                co2Emission: 500.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.1467,
              },
              {
                classificationType: "water",
                co2Emission: 300.15,
                weightBasedCo2Intensity: 0.2045,
                amountBasedCo2Intensity: 0.2346,
              },
            ],
            intensityType: "torch-based",
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（加工）",
            factoryType: "process",
            co2Emission: 1500.15,
            weightBasedCo2Intensity: 0.3465,
            amountBasedCo2Intensity: 0.4436,
            byClassification: [
              {
                classificationType: "privateSteam",
                co2Emission: 1500.15,
                weightBasedCo2Intensity: 0.2865,
                amountBasedCo2Intensity: 0.2736,
              },
            ],
            intensityType: "industry-average",
            version: 0,
            deleteFlag: false,
          },
        ],
      })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:network */
const category1PaperNetworkErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:timeout */
const category1PaperTimeoutErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:unknown */
const category1PaperUnknownErrorMock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000000 */
const category1PaperERR000000Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000001 */
const category1PaperERR000001Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000004 */
const category1PaperERR000004Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000002 */
const category1PaperERR000002Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 製紙工場カテゴリ1取得APIのエラー:ERR000003 */
const category1PaperERR000003Mock = [
  rest.get("*/category1/paper", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:network */
const category1ProcessNetworkErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:timeout */
const category1ProcessTimeoutErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:unknown */
const category1ProcessUnknownErrorMock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000000 */
const category1ProcessERR000000Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000001 */
const category1ProcessERR000001Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000004 */
const category1ProcessERR000004Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000002 */
const category1ProcessERR000002Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** 加工工場カテゴリ1取得APIのエラー:ERR000003 */
const category1ProcessERR000003Mock = [
  rest.get("*/category1/process", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

/** カテゴリ4取得APIのエラー:network */
const category4NetworkErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "network", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:timeout */
const category4TimeoutErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "timeout", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:unknown */
const category4UnknownErrorMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({ errorCode: "unknown", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000000 */
const category4ERR000000Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000000", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000001 */
const category4ERR000001Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({ errorCode: "ERR000001", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000004 */
const category4ERR000004Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({ errorCode: "ERR000004", message: "Mocked message" })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000002 */
const category4ERR000002Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

/** カテゴリ4取得APIのエラー:ERR000003 */
const category4ERR000003Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];
