import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { EChartsOption } from "echarts";
import React, { useMemo } from "react";

import { AppTheme } from "src/app/configs";
import { TotalEmission } from "src/app/models";
import {
  formatEmission,
  formatIntensity,
} from "src/app/utils/computeForVisuals";
import { Echarts } from "src/lib/components/atoms";
import { useAspectRatio } from "src/lib/hooks/useAspectRatio";

const useStyles = makeStyles(() => ({
  chart: {
    width: "100%",
    height: "100%",
  },
}));

export type SingleBarChartProps = {
  data: TotalEmission[];
  unit: string;
  barColor: string[];
  chartType: "normal" | "sub";
  intensity?: boolean;
};

export const SingleBarChart: React.FC<SingleBarChartProps> = (props) => {
  const theme = useTheme<AppTheme>();
  const chartOption = useMemo<EChartsOption>(() => {
    const option: EChartsOption = {
      textStyle: {
        fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
        fontSize:
          props.chartType === "normal"
            ? theme.typography.body2.fontSize
            : theme.typography.overline.fontSize,
      },
      grid: {
        // グラフの周りに軸ラベルのための領域を確保
        top: 40,
        left: 0,
        right: 0,
        bottom: 8,
        containLabel: true,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: "category",
        data: props.data.map((d) => {
          return {
            value: d.dataName,
            textStyle: {
              fontSize:
                props.chartType === "normal"
                  ? theme.typography.body2.fontSize
                  : theme.typography.overline.fontSize,
            },
          };
        }),
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          margin: 16,
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        name: props.unit,
        type: "value",
        axisLabel: {
          formatter: (value: number) => {
            return value.toLocaleString();
          },
          fontSize:
            props.chartType === "normal"
              ? theme.typography.body2.fontSize
              : theme.typography.overline.fontSize,
        },
        nameTextStyle: {
          align: "left",
        },
      },
      series: {
        type: "bar",
        data: props.data.map((d) => {
          return {
            value: d.value,
            itemStyle: { color: props.barColor[0] },
            label: {
              show: props.chartType === "normal" ? true : false,
              textStyle: { fontWeight: "normal" },
              position: "top",
              formatter: (data) => {
                return props.intensity
                  ? formatIntensity(data.value as number)
                  : formatEmission(data.value as number);
              },
            },
          };
        }),
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
      barMaxWidth: props.chartType === "normal" ? 32 : 20,
    };
    return option;
  }, [props, theme]);

  const ratio = props.chartType === "normal" ? 2 : 1.1;
  const classes = useStyles();
  const ref = useAspectRatio(ratio);
  return (
    <Box width="100%" {...{ ref }}>
      <Echarts className={classes.chart} option={chartOption} />
    </Box>
  );
};
