import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { LargeButton } from "src/app/components/atoms";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  buttonMargin: {
    marginLeft: theme.spacing(3),
  },
}));

export type SaveAndRegisterButtonProps = {
  saveButtonProps: {
    isProcessing?: boolean;
    disabled?: boolean;
    onClick?: () => void;
  };
  registerButtonProps: {
    isProcessing?: boolean;
    disabled?: boolean;
    onClick?: () => void;
  };
} & BoxProps;

export const SaveAndRegisterButton: React.FC<SaveAndRegisterButtonProps> = (
  props
) => {
  const classes = useStyles();
  const { saveButtonProps, registerButtonProps, ...restProps } = props;
  return (
    <FlexBox {...restProps}>
      <LargeButton
        label="一時保存"
        variant="outlined"
        color="primary"
        {...saveButtonProps}
      />
      <LargeButton
        className={classes.buttonMargin}
        label="登録"
        variant="contained"
        color="primary"
        {...registerButtonProps}
      />
    </FlexBox>
  );
};
