import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme, theme } from "src/app/configs";
import CardBoardImage from "src/app/images/cardBoard.png";
import CardBoardImage2x from "src/app/images/cardBoard@2x.png";
import FactoryImage from "src/app/images/factory.png";
import FactoryImage2x from "src/app/images/factory@2x.png";
import { FlexBox, Image } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme, ImageHeadingProps>((theme) => ({
  image: {
    borderRadius: "5px",
    boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
  },
}));

export type ImageHeadingProps = {
  className?: string;
  variant: "factory" | "cardBoard";
  title: string;
};

const imageTable = {
  factory: {
    alt: "factory",
    src: FactoryImage,
    src2x: FactoryImage2x,
  },
  cardBoard: {
    alt: "cardBoard",
    src: CardBoardImage,
    src2x: CardBoardImage2x,
  },
};

export const ImageHeading: React.FC<ImageHeadingProps> = (props) => {
  const classes = useStyles(props);
  return (
    <FlexBox className={props.className} alignItems="center">
      <Image className={classes.image} {...imageTable[props.variant]} />
      <Typography
        variant="h2"
        sx={{
          marginLeft: theme.spacing(1.5),
          color: theme.palette[props.variant],
        }}
      >
        {props.title}
      </Typography>
    </FlexBox>
  );
};
