import React from "react";
import { useParams } from "react-router";
import { Params, Navigate } from "react-router-dom";

export interface RedirectProps {
  to: string;
  state?: any;
}

/**
 * react router v5のRedirectをNavigateに書き換えるとパラメータ情報がそのまま使用されてしまう
 * 例) from /input/2021から<Navigate to="/input/:year" />をすると/input/:yearに遷移されてしまう
 * そのため既存のNavigateをwrapしてRedirectを作成
 * https://medium.com/@maoznir/redirect-component-for-react-router-v6-c3b4821ecce
 */
export const Redirect: React.FC<RedirectProps> = ({ to, ...rest }) => {
  const params = useParams();
  return <Navigate to={updateTo(to, params)} {...rest} replace />;
};

const updateTo = (to: string, params: Readonly<Params<string>>) => {
  const entries = Object.entries(params);
  let path = `${to}`;

  entries.forEach(([key, value]) => {
    path = path.replace(`:${key}`, `${value}`);
  });

  return path;
};
