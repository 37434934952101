import {
  GetFactoryQueryError,
  GetFactoryQueryResult,
  getFactory,
} from "src/app/apis/client/default";
import { ApiError } from "src/lib/apis";

export type GetFactoryApiRequest = {
  factoryId: string;
};
export class GetFactoryApiError extends ApiError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}
export class FactoryApi {
  async get(req: GetFactoryApiRequest): Promise<GetFactoryQueryResult> {
    try {
      const res = await getFactory(req.factoryId);

      return {
        companyId: res.companyId,
        companyType: res.companyType,
        factoryId: res.factoryId,
        factoryType: res.factoryType,
        companyName: res.companyName,
        factoryName: res.factoryName,
        shortNameForSelf: res.shortNameForSelf,
        shortNameForOthers: res.shortNameForOthers,
        defaultLandDistance: res.defaultLandDistance,
        defaultSeaDistance: res.defaultSeaDistance,
        sortOrder: res.sortOrder,
        version: res.version,
      };
    } catch (err) {
      const { errorCode } = err as GetFactoryQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetFactoryApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetFactoryApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetFactoryApiError("not-authenticated", err);
        case "ERR023000":
          throw new GetFactoryApiError("factory-not-found", err);
      }
    }
  }
}
