import { AddCircle, Cancel } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactElement } from "react";

import { AppTheme } from "src/app/configs";
import { Button, FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  header: {
    backgroundColor: theme.palette.ghgHeader.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: "4px",
  },
  errorMessage: {
    lineHeight: 1,
  },
}));

// レコード数を増減できる表状の入力項目（本体）
export type EnergyTableInputProps = {
  firstInputColumn: string;
  secondInputColumn: string;
  onClickAddButton: () => void;
  isEditable?: boolean;
  children?: React.ReactNode;
};
export const EnergyTableInput: React.FC<EnergyTableInputProps> = (props) => {
  const classes = useStyles();

  return (
    <Box width="62%">
      <Grid container className={classes.header}>
        <Grid item xs={5}>
          <Box ml={2}>
            <Typography variant="h5">{props.firstInputColumn}</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box ml={2}>
            <Typography variant="h5">{props.secondInputColumn}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Box pt={2}>{props.children}</Box>
      <Grid container>
        <Grid item xs={5}>
          <Box ml={2}>
            <Button
              color="default"
              label="行を追加"
              variant="outlined"
              startIcon={<AddCircle />}
              onClick={props.onClickAddButton}
              disabled={!props.isEditable}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// レコード数を増減できる表状の入力項目（1レコード分）
type EnergyTableRowsProps = {
  firstInput: ReactElement;
  firstInputErrorMessage: string;
  firstInputHasError?: boolean;
  secondInput: ReactElement;
  secondInputErrorMessage: string;
  secondInputHasError?: boolean;
  onClickRemoveButton: () => void;
  isLastRow?: boolean;
  isEditable: boolean;
};
export const EnergyTableRows: React.FC<EnergyTableRowsProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xs={5}>
        <Box ml={2}>{props.firstInput}</Box>
      </Grid>
      <Grid item xs={5}>
        <Box ml={2}>{props.secondInput}</Box>
      </Grid>
      <Grid item xs={2}>
        {!props.isLastRow && (
          <FlexBox justifyContent="center">
            <IconButton
              onClick={props.onClickRemoveButton}
              size="small"
              disabled={!props.isEditable}
            >
              <Cancel />
            </IconButton>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={5}>
        {props.firstInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start" ml={2}>
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.firstInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={5}>
        {props.secondInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start" ml={2}>
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.secondInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={2}>
        {/* エラーがないときも高さを確保するためminHeightを指定 */}
        <Box minHeight={16} />
      </Grid>
    </Grid>
  );
};
