import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  itemListIcon: {
    minWidth: 32,
  },
  text: {
    fontWeight: "bold",
  },
  selectedMenu: {
    color: theme.palette.secondary.main,
  },
  openedMenu: {
    backgroundColor: theme.palette.action.selected,
  },
}));

export type DrawerItemProps = {
  title: string;
  icon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  // 子要素を持つメニューかどうか
  container?: boolean;
  selected?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

/**
 * Drawer に表示するメニュー項目
 */
export const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const classes = useStyles();

  // 子要素を持つメニューについて、その開閉状態を表す
  const [open, setOpen] = useState(props.container && props.selected);
  const toggle = () => setOpen((x) => !x);

  return (
    <>
      <ListItem
        className={clsx(
          props.selected && classes.selectedMenu,
          open && classes.openedMenu
        )}
        onClick={props.container ? toggle : props.onClick}
      >
        <ListItemIcon className={classes.itemListIcon}>
          {props.selected ? props.selectedIcon : props.icon}
        </ListItemIcon>

        <ListItemText>
          <Typography
            className={clsx(!props.container && props.selected && classes.text)}
          >
            {props.title}
          </Typography>
        </ListItemText>
        {props.container && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>

      {/* 子要素を持つメニューのとき、子メニューをレンダーする */}
      {props.container && (
        <Collapse in={open}>
          <List
            component="div"
            disablePadding
            className={clsx(open && classes.openedMenu)}
          >
            {props.children}
          </List>
        </Collapse>
      )}
    </>
  );
};
