import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ContainerProps {
  /**
   * falseのとき、このコンポーネントの高さは少なくともビューポートの高さ以上であり、
   * その子要素の高さに応じて変化する。
   *
   * true のとき、このコンポーネントの高さはビューポートの高さと同一になる。
   */
  fixedHeight?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = (props) => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const listener = () => setHeight(window.innerHeight);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  const containerHeight = props.fixedHeight
    ? {
        height: height,
      }
    : {
        minHeight: height,
      };

  return (
    <Box
      display="flex"
      flexDirection="column"
      {...containerHeight}
      className={props.className}
    >
      {props.children}
    </Box>
  );
};
