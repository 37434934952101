import {
  GetProductQueryError,
  GetProductQueryResult,
  getProduct,
} from "src/app/apis/client/default";
import { FactoryId } from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class GetProductError extends ApiError<
  "not-authenticated" | "network" | "unknown" | "factory-not-found"
> {}

export type GetProductApiRequest = {
  year: string;
  factoryId: FactoryId;
};

export class ProductApi {
  async getProduct(req: GetProductApiRequest): Promise<GetProductQueryResult> {
    try {
      const res = await getProduct(req.year, req.factoryId);
      return res;
    } catch (err) {
      const { errorCode } = err as GetProductQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetProductError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetProductError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetProductError("not-authenticated", err);
        case "ERR018000":
          throw new GetProductError("factory-not-found", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
