import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactElement } from "react";

import { FlexBox } from "src/lib/components/atoms";

export interface RowInputFieldProps {
  label?: string | ReactElement;
  hasError?: boolean;
  errorMessage?: string;
  valiant?: "h5" | "h6";
  extraElement?: ReactElement;
  disabledMessageAllocation?: boolean;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    overflowWrap: "break-word",
    flex: 1,
    whiteSpace: "pre-line",
  },
  errorMessage: {
    lineHeight: 1,
  },
}));

export const RowInputField: React.FC<RowInputFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <FlexBox alignItems="center">
        <Typography
          variant={props.valiant !== undefined ? props.valiant : "h5"}
          className={classes.label}
        >
          {props.label}
        </Typography>
        <Box flex={1}>{props.children}</Box>
      </FlexBox>
      <FlexBox>
        <Box flex={1} />
        {/* エラーがないときも高さを確保するためminHeightを指定 */}
        <FlexBox
          flex={1}
          minHeight={
            props.disabledMessageAllocation || props.extraElement !== undefined
              ? 0
              : 16
          }
          alignItems="flex-start"
        >
          {props.hasError && (
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.errorMessage}
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
      {props.extraElement !== undefined && (
        <FlexBox>
          <Box flex={1} />
          <FlexBox flex={1}>{props.extraElement}</FlexBox>
        </FlexBox>
      )}
    </Box>
  );
};
