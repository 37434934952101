import { GridColDef } from "@mui/x-data-grid";
import { useLocation } from "react-router";

import { OldGetScope3ByStandardQueryResult } from "src/app/apis/client/default";
import { UserTotalEmissionTemplate } from "src/app/components/templates";
import { SuppliersForTotalEmission } from "src/app/models";
import {
  convertByStandardDataToChartProps,
  getCompanyTypeName,
} from "src/app/utils/computeForVisuals";
import { sum } from "src/lib/utils/mathUtil";

//TODO:画面実装時にOldから置き換える
export type UserTotalEmissionPageProps = {
  data?: OldGetScope3ByStandardQueryResult;
  isProcessing: boolean;
};

//TODO:画面実装時にOldから置き換える
const convertDataToChartProps = (
  data?: OldGetScope3ByStandardQueryResult,
  category?: "category1" | "category4"
) => {
  const standardData = convertByStandardDataToChartProps(data);

  const chartData: SuppliersForTotalEmission[] =
    data !== undefined
      ? standardData
          .map((it) => {
            return {
              companyId: it.companyId,
              companyType: it.companyType,
              companyName: it.companyName,
              total:
                category === "category1"
                  ? it.category1Total
                  : it.category4Total,
            };
          })
          .sort((a, b) => {
            return b.total - a.total;
          })
      : [];

  return { chartData };
};

//TODO:画面実装時にOldから置き換える
const convertDataToTableProps = (
  data?: OldGetScope3ByStandardQueryResult,
  category?: "category1" | "category4"
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "supplierType",
      headerName: "種別",
      flex: 2,
    },
    {
      field: "supplierName",
      headerName: "サプライヤー企業名",
      flex: 3,
    },
    {
      field: "supplierFactoryName",
      headerName: "サプライヤー工場名",
      flex: 3,
    },
    {
      field: "co2Emission",
      headerName: "GHG総排出量(t-CO2eq/年)",
      flex: 3,
      valueFormatter: (params) =>
        typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : "",
    },
  ];

  const tableData = [];
  if (data?.bySupplier !== undefined) {
    for (const supplier of data.bySupplier) {
      for (const factory of supplier.bySupplierFactory) {
        tableData.push({
          id: supplier.companyId + supplier.companyId + factory.factoryId,
          supplierType: getCompanyTypeName(supplier.companyType),
          supplierName: supplier.companyName,
          supplierFactoryName: factory.factoryName,
          co2Emission: Math.round(
            sum(
              ...factory.byStandard.map((standard) => {
                return category === "category1"
                  ? standard.category1Co2Emission
                  : standard.category4Co2Emission;
              })
            )
          ),
        });
      }
    }
  }

  const fileName =
    category === "category1"
      ? `カテゴリ１合計_${data?.year}`
      : `カテゴリ４合計_${data?.year}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const UserTotalEmissionPage: React.FC<UserTotalEmissionPageProps> = (
  props
) => {
  const location = useLocation();

  const category = location.pathname.startsWith("/user/category1/")
    ? "category1"
    : "category4";
  const chartProps = convertDataToChartProps(props.data, category);
  const tableProps = convertDataToTableProps(props.data, category);

  return (
    <UserTotalEmissionTemplate
      isProcessing={props.isProcessing}
      category={category}
      {...chartProps}
      {...tableProps}
    />
  );
};
