import { LaunchRounded } from "@mui/icons-material";
import { Box, Grid, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { Dispatch, ReactElement, SetStateAction } from "react";

import {
  CompanyType,
  DrainageMethods,
  WasteType,
  WasteMethods,
} from "src/app/apis/model";
import { paperOnlyWasteTypes, wasteTypes } from "src/app/codes";
import {
  AppCard,
  Annotate,
  Dropdown,
  DropdownProps,
  Text,
  TextProps,
} from "src/app/components/atoms";
import {
  RowDropdownField,
  RowTextField,
  SectionHeading,
  SubSectionHeading,
} from "src/app/components/molecules";
import {
  SaveAndRegisterButton,
  SaveAndRegisterButtonProps,
} from "src/app/components/organisms";
import { GhgInputCategory1Form, WasteForm } from "src/app/components/pages";
import { AppTheme } from "src/app/configs";
import {
  Co2Amount,
  DrainageMethod,
  ElectricPowerAmount,
  WasteAmount,
  WasteMethod,
  WaterAmount,
} from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { DropDownItem, FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  header: {
    backgroundColor: theme.palette.ghgHeader.default,
    padding: theme.spacing(2),
    borderRadius: "4px",
  },
  // RowTowInputField用の装飾
  label: {
    color: theme.palette.text.primary,
    overflowWrap: "break-word",
    flex: 1,
  },
  infoMessage: {
    color: theme.palette.text.disabled,
    lineHeight: 1.3,
  },
  infoIcon: {
    verticalAlign: "middle",
    width: 12,
    height: 12,
    marginTop: 2,
  },
  infoLinkIcon: {
    verticalAlign: "middle",
    marginLeft: 3,
    marginRight: 2,
    marginBottom: 2,
    width: 12,
    height: 12,
    color: "#24A6AD",
  },
  errorMessage: {
    lineHeight: 1,
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  link: {
    color: "#24A6AD",
  },
}));

const wasteDropdownItems: DropDownItem<WasteMethods>[] = [
  {
    value: "inHouseIncineration",
    displayName: "自家焼却",
  },
  {
    value: "incineration",
    displayName: "外部委託：焼却処理",
  },
  {
    value: "landfill",
    displayName: "外部委託：埋立処理",
  },
  {
    value: "ashLandfill",
    displayName: "外部委託：埋立処理（焼却灰）",
  },
  {
    value: "recycle",
    displayName: "リサイクル",
  },
];

const wasteDropdownItemsAndNotApplicable: DropDownItem<
  WasteMethods | "notApplicable"
>[] = [
  ...wasteDropdownItems,
  {
    value: "notApplicable",
    displayName: "該当なし",
  },
];

const drainageDropdownItems: DropDownItem<DrainageMethods>[] = [
  {
    value: "inHouseDrainage",
    displayName: "自家処理",
  },
  {
    value: "outSourceDrainage",
    displayName: "自家処理以外",
  },
];

export type GhgWasteInputTemplateProps = {
  waste: WasteForm;
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  isEditable: boolean;
  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];
  companyType: Exclude<CompanyType, "user-company">;
};

export const GhgWasteInputTemplate: React.FC<GhgWasteInputTemplateProps> = (
  props
) => {
  const classes = useStyles();

  const bindTextField = useBindTextField(props.waste, (wasteAction) =>
    props.setForm(
      produce<GhgInputCategory1Form>((form) => {
        if (wasteAction instanceof Function) {
          form.waste = wasteAction(form.waste);
        } else {
          form.waste = wasteAction;
        }
      })
    )
  );

  const isNotApplicableType = (wasteType: WasteType) => {
    return paperOnlyWasteTypes.some((value) => value === wasteType);
  };
  const bindMethod = (wasteType: WasteType) => {
    const bind = bindTextField({
      selector: (form) => form.wastes[wasteType].method,
      validator: WasteMethod.Rule,
      isRequired: false,
    });
    const onChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
      bind.onChange(e);
      if (e.target.value === "inHouseIncineration") {
        props.setForm(
          produce((form) => {
            form.waste.wastes[wasteType].amount.value = "0";
            form.waste.wastes[wasteType].amount.error = false;
          })
        );
      } else if (e.target.value === "notApplicable") {
        props.setForm(
          produce((form) => {
            form.waste.wastes[wasteType].amount.value = "";
            form.waste.wastes[wasteType].amount.error = false;
          })
        );
      } else {
        props.setForm(
          produce((form) => {
            for (const wasteType of wasteTypes) {
              if (
                form.waste.wastes[wasteType].method.value ===
                "inHouseIncineration"
              ) {
                return;
              }
            }
            form.waste.totalInHouseIncinerationCo2Emission.value = "0";
            form.waste.totalInHouseIncinerationCo2Emission.error = false;
          })
        );
      }
    };
    return {
      ...bind,
      onChange: onChangeMethod,
      items: isNotApplicableType(wasteType)
        ? wasteDropdownItemsAndNotApplicable
        : wasteDropdownItems,
      disabled: !props.isEditable,
    };
  };

  const bindAmount = (wasteType: WasteType) => {
    const bind = bindTextField({
      selector: (form) => form.wastes[wasteType].amount,
      validator: WasteAmount.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      maxLength: WasteAmount.MaxLength,
      disabled:
        !props.isEditable ||
        props.waste.wastes[wasteType].method.value === "inHouseIncineration" ||
        props.waste.wastes[wasteType].method.value === "notApplicable",
    };
  };

  const isEditableTotalInHouseIncinerationCo2Emission = () => {
    for (const wasteType of wasteTypes) {
      if (
        props.waste.wastes[wasteType].method.value === "inHouseIncineration"
      ) {
        return true;
      }
    }
    return false;
  };

  const bindDrainage = () => {
    const bind = bindTextField({
      selector: (form) => form.drainageMethod,
      validator: DrainageMethod.Rule,
      isRequired: false,
    });
    const onChangeDrainage = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "inHouseDrainage") {
        props.setForm(
          produce((form) => {
            form.waste.outSourceDrainageAmount.value = "0";
            form.waste.outSourceDrainageAmount.error = false;
          })
        );
      }
      if (e.target.value === "outSourceDrainage") {
        props.setForm(
          produce((form) => {
            form.waste.inHouseDrainageElectricPower.value = "0";
            form.waste.inHouseDrainageElectricPower.error = false;
          })
        );
      }
      bind.onChange(e);
    };
    return {
      ...bind,
      onChange: onChangeDrainage,
    };
  };

  return (
    <AppCard>
      <SaveAndRegisterButton
        justifyContent="flex-end"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />
      <SectionHeading title="廃棄物">
        <SubSectionHeading title="廃棄物処理方法">
          <Grid container className={classes.header}>
            <Grid item xs={4}>
              <Typography variant="h5">廃棄物</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">処理方法</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                委託処理の廃棄物種別の年間発生量
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="スクリーン粕① [t/年]"
                dropdownProps={bindMethod("screenLees1")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("screenLees1")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="スクリーン粕② [t/年]"
                dropdownProps={bindMethod("screenLees2")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("screenLees2")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="スクリーン粕③ [t/年]"
                dropdownProps={bindMethod("screenLees3")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("screenLees3")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="パルパー粕① [t/年]"
                dropdownProps={bindMethod("pulper1")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("pulper1")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="パルパー粕② [t/年]"
                dropdownProps={bindMethod("pulper2")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("pulper2")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            {props.companyType === "paper-supplier" && (
              <RowTwoInputField
                label="パルパー粕③ [t/年]"
                dropdownProps={bindMethod("pulper3")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("pulper3")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
            <RowTwoInputField
              label="排水処理汚泥 [t/年]"
              dropdownProps={bindMethod("sludge")}
              dropdownErrorMessage={WasteMethod.Message}
              textProps={bindAmount("sludge")}
              textErrorMessage={WasteAmount.Message}
            />
            <RowTwoInputField
              label="焼却灰 [t/年]"
              dropdownProps={bindMethod("ash")}
              dropdownErrorMessage={WasteMethod.Message}
              textProps={bindAmount("ash")}
              textErrorMessage={WasteAmount.Message}
            />
            <RowTwoInputField
              label="製造ロス（段原紙） [t/年]"
              dropdownProps={bindMethod("basePaperLoss")}
              dropdownErrorMessage={WasteMethod.Message}
              textProps={bindAmount("basePaperLoss")}
              textErrorMessage={WasteAmount.Message}
            />

            {props.companyType === "process-supplier" && (
              <RowTwoInputField
                label="製造ロス（段ボール端材） [t/年]"
                dropdownProps={bindMethod("offCutLoss")}
                dropdownErrorMessage={WasteMethod.Message}
                textProps={bindAmount("offCutLoss")}
                textErrorMessage={WasteAmount.Message}
              />
            )}
          </Box>
        </SubSectionHeading>

        <SubSectionHeading title="GHG年間発生量" mt={2}>
          <Grid container>
            <Grid item xs={12} lg={5}>
              <RowTextField
                label={
                  <span>
                    GHG年間発生量 [t-CO2eq/年]
                    <br />
                    (自家焼却処理のみ)
                  </span>
                }
                disabled={
                  !props.isEditable ||
                  !isEditableTotalInHouseIncinerationCo2Emission()
                }
                {...bindTextField({
                  selector: (form) => form.totalInHouseIncinerationCo2Emission,
                  validator: Co2Amount.Rule,
                  isRequired: true,
                })}
                maxLength={Co2Amount.MaxLength}
                errorMessage={Co2Amount.Message}
                inputMode="numeric"
                extraElement={
                  <Typography
                    variant="overline"
                    className={classes.infoMessage}
                  >
                    詳しくは
                    <Link
                      href="https://ghg-santeikohyo.env.go.jp/files/manual/chpt2_4-8.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >
                      こちら
                      <LaunchRounded className={classes.infoLinkIcon} />
                    </Link>
                    をご確認ください。
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </SubSectionHeading>
      </SectionHeading>

      <SectionHeading title="排水" mt={3}>
        <SubSectionHeading title="排水処理方法">
          <Grid container>
            <Grid item xs={12} lg={5}>
              <RowDropdownField
                label="排水処理方法"
                items={drainageDropdownItems}
                disabled={!props.isEditable}
                {...bindDrainage()}
                errorMessage={DrainageMethod.Message}
              />
              <RowTextField
                label={
                  <span>
                    年間処理量 [千m<sup>3</sup>/年]
                  </span>
                }
                disabled={
                  !props.isEditable ||
                  !(props.waste.drainageMethod.value === "outSourceDrainage")
                }
                {...bindTextField({
                  selector: (form) => form.outSourceDrainageAmount,
                  validator:
                    props.waste.drainageMethod.value === "outSourceDrainage"
                      ? WaterAmount.Rule
                      : (value: string) => true,
                  isRequired:
                    props.waste.drainageMethod.value === "outSourceDrainage"
                      ? true
                      : false,
                })}
                maxLength={WaterAmount.MaxLength}
                errorMessage={WaterAmount.Message}
                inputMode="numeric"
              />
              <RowTextField
                label="電力 [kWh/年]"
                disabled={
                  !props.isEditable ||
                  !(props.waste.drainageMethod.value === "inHouseDrainage")
                }
                {...bindTextField({
                  selector: (form) => form.inHouseDrainageElectricPower,
                  validator:
                    props.waste.drainageMethod.value === "inHouseDrainage"
                      ? ElectricPowerAmount.Rule
                      : (value: string) => true,
                  isRequired:
                    props.waste.drainageMethod.value === "inHouseDrainage"
                      ? true
                      : false,
                })}
                maxLength={ElectricPowerAmount.MaxLength}
                errorMessage={ElectricPowerAmount.Message}
                inputMode="numeric"
                disabledMessageAllocation
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} lg={2.5} />
            <Grid item xs={6} lg={9.5}>
              <Annotate>
                排水処理設備での所要電力量を「製造工程」にてマシンごとの電力投入量に加味している場合は、「0」でご入力お願いします。
              </Annotate>
            </Grid>
          </Grid>
        </SubSectionHeading>
      </SectionHeading>

      <SaveAndRegisterButton
        mt={3}
        justifyContent="center"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />
    </AppCard>
  );
};

type RowTwoInputFieldProps = {
  label?: string | ReactElement;
  dropdownProps: DropdownProps;
  dropdownErrorMessage?: string;
  textProps?: TextProps;
  textErrorMessage?: string;
};

const RowTwoInputField: React.FC<RowTwoInputFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.gridContainer}>
      <Grid item xs={4}>
        <Typography variant="h5">{props.label}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box width="75%">
          <Dropdown {...props.dropdownProps} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box width="75%">
          <Text {...props.textProps} inputMode="numeric" />
        </Box>
      </Grid>
      <Grid item xs={4}>
        {/* エラーがないときも高さを確保するためminHeightを指定 */}
        <Box minHeight={16} />
      </Grid>
      <Grid item xs={4}>
        {props.dropdownProps.hasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.dropdownErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={4}>
        {props.textProps?.hasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.textErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
    </Grid>
  );
};
