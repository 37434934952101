import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactElement } from "react";

import { NestedCard } from "src/app/components/atoms";
import noDataImage from "src/app/images/noData.png";
import noDataImage2x from "src/app/images/noData@2x.png";
import { FlexBox, Image } from "src/lib/components/atoms";

export type PieChartCardProps = {
  titleElement?: ReactElement;
  chartElement: ReactElement;
  noData: boolean;
  isProcessing: boolean;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
}));

export const PieChartCard: React.FC<PieChartCardProps> = (props) => {
  const classes = useStyles();

  return (
    <NestedCard className={clsx(classes.card, props.className)}>
      {props.titleElement}
      {!props.isProcessing && props.noData ? (
        <FlexBox alignItems="center">
          <Image
            alt="NoData"
            src={noDataImage}
            src2x={noDataImage2x}
            width="100%"
          />
        </FlexBox>
      ) : (
        props.chartElement
      )}
    </NestedCard>
  );
};
