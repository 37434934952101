import {
  getDelivery,
  GetDeliveryQueryError,
  GetDeliveryQueryResult,
} from "src/app/apis/client/default";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export type GetDeliveryApiRequest = {
  from: string;
};

export class GetDeliveryApiError extends ApiError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}
export class DeliveryApi {
  async getDelivery(
    req: GetDeliveryApiRequest
  ): Promise<GetDeliveryQueryResult> {
    try {
      const res = await getDelivery(req.from);
      return {
        from: res.from,
        to: res.to.map((factory) => {
          return {
            factoryId: factory.factoryId,
            factoryName: factory.factoryName,
            factoryNameForSelf: factory.factoryNameForSelf,
            factoryNameForOthers: factory.factoryNameForOthers,
            factoryType: factory.factoryType,
            landDistance: factory.landDistance,
            seaDistance: factory.seaDistance,
            version: factory.version,
          };
        }),
      };
    } catch (err) {
      const { errorCode } = err as GetDeliveryQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetDeliveryApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetDeliveryApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetDeliveryApiError("not-authenticated", err);
        case "ERR009000":
          throw new GetDeliveryApiError("factory-not-found", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
