import { Schedule } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import React from "react";

import { AppCard } from "src/app/components/atoms";
import {
  FactoryListTable,
  ImageHeading,
  YearDropdown,
  YearDropdownProps,
  Content,
} from "src/app/components/molecules";
import { InputRequest } from "src/app/models";
import { ToDateTimeFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  color: { color: theme.palette.text.disabled },
}));
export type FactoryProps = {
  expirationTime?: string;
  beginTime?: string;
  requests?: InputRequest[];
  isProcessing: boolean;
  yearDropdownProps: YearDropdownProps;
  year: DateTime;
};

export const FactoryListTemplate: React.FC<FactoryProps> = (props) => {
  const classes = useStyles();
  const beginTimeForDisplay =
    props.beginTime === undefined || props.beginTime.length === 0
      ? "----/--/-- --:--"
      : ToDateTimeFromString(props.beginTime).toFormat("yyyy/MM/dd HH:mm");
  const expirationTimeForDisplay =
    props.expirationTime === undefined || props.expirationTime.length === 0
      ? "----/--/-- --:--"
      : ToDateTimeFromString(props.expirationTime).toFormat("yyyy/MM/dd HH:mm");

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h1">GHG算出情報入力</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <AppCard>
        <ImageHeading title="入力依頼一覧" variant="factory" />
        <Box display="flex" alignItems="center" mt={4}>
          <Schedule className={classes.color} fontSize="small" />
          <Typography variant="h5" className={classes.color}>
            入力期間　{beginTimeForDisplay} ～ {expirationTimeForDisplay}
          </Typography>
        </Box>

        <Box mt={2}>
          <FactoryListTable
            requests={props.requests}
            isProcessing={props.isProcessing}
            year={props.year}
          />
        </Box>
      </AppCard>
    </Content>
  );
};
