import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";

import { FactoryType, InputStatus, IntensityType } from "src/app/apis/model";
import { FactoryStatus } from "src/app/components/atoms";
import { messages } from "src/app/configs";
import { InputRequest } from "src/app/models";
import { ToDateTimeFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { Button, FlexBox } from "src/lib/components/atoms";

export type FactoryListProps = {
  requests?: InputRequest[];
  isProcessing: boolean;
  year: DateTime;
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#FDFCFC",
  },
  thead: {
    backgroundColor: "#F0EDE8",
  },
  th: {
    borderRight: "solid #FFFFFF",
  },
  textStyle: {
    fontWeight: "bold",
    fontSize: theme.typography.body2.fontSize,
  },
  row: {
    backgroundColor: "#F0EBDE",
    borderTop: "solid #FFFFFF",
  },
  inHouse: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiTypography-button": {
      fontSize: "1.3rem",
    },
  },
  touch: {
    color: "#5E89A2",
    border: "1px solid #5E89A2",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "& .MuiTypography-button": {
      fontSize: "1.3rem",
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&:hover": { border: "1px solid #5E89A2" },
  },
}));

export const FactoryListTable: React.FC<FactoryListProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const year = props.year.toFormat("yyyy");
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell className={classes.th} rowSpan={2} width="220px">
              <FlexBox alignItems="center">
                <Typography variant="h5">工場</Typography>
              </FlexBox>
            </TableCell>
            <TableCell
              align="center"
              className={classes.th}
              rowSpan={2}
              width="120px"
            >
              <FlexBox alignItems="center">
                <Typography variant="h5">ステータス</Typography>
              </FlexBox>
            </TableCell>
            <TableCell className={classes.th} colSpan={2}>
              <FlexBox alignItems="center">
                <Typography variant="h5">カテゴリ1</Typography>
              </FlexBox>
            </TableCell>
            <TableCell className={classes.th} colSpan={2}>
              <FlexBox alignItems="center">
                <Typography variant="h5">カテゴリ4</Typography>
              </FlexBox>
            </TableCell>
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.th} width="128px">
              <FlexBox alignItems="center">
                <Typography variant="h5">確定日時</Typography>
              </FlexBox>
            </TableCell>
            <TableCell className={classes.th} width="220px">
              <FlexBox alignItems="center">
                <Typography variant="h5">入力</Typography>
              </FlexBox>
            </TableCell>
            <TableCell className={classes.th} width="128px">
              <FlexBox alignItems="center">
                <Typography variant="h5">確定日時</Typography>
              </FlexBox>
            </TableCell>
            <TableCell className={classes.th} width="220px">
              <FlexBox alignItems="center">
                <Typography variant="h5">入力</Typography>
              </FlexBox>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!props.isProcessing &&
            props.requests &&
            props.requests.length > 0 &&
            props.requests.map((request) => (
              <TableRow key={request.factoryNameForSelf}>
                <TableCell>
                  <Typography className={classes.textStyle}>
                    {request.factoryNameForSelf}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <FactoryStatus status={request.status} />
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  <Typography
                    variant="overline"
                    sx={{ letterSpacing: "normal" }}
                  >
                    {(request.category1.submissionTime !== undefined &&
                      request.status !== InputStatus["in-progress"]) ||
                    (request.category1.submissionTime !== undefined &&
                      request.status === InputStatus["in-progress"] &&
                      request.category1.intensityType !== undefined)
                      ? ToDateTimeFromString(
                          request.category1.submissionTime
                        ).toFormat("yyyy/MM/dd HH:mm")
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FlexBox justifyContent="center">
                    <FlexBox>
                      <Button
                        color="secondary"
                        label="自社算定"
                        variant="outlined"
                        onClick={() => {
                          navigate(
                            `/input/${year}/${request.factoryId}/in-house/category1`,
                            {
                              state: {
                                factoryName: request.factoryNameForSelf,
                              },
                            }
                          );
                        }}
                        disabled={
                          !(
                            request.status === InputStatus.requested ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category1.intensityType ===
                                IntensityType["in-house"] &&
                              request.category1.submissionTime !== undefined) ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category1.intensityType === undefined &&
                              request.category1.submissionTime === undefined) ||
                            (request.status === InputStatus.submitted &&
                              request.category1.intensityType ===
                                IntensityType["in-house"])
                          )
                        }
                        className={classes.inHouse}
                      />
                    </FlexBox>
                    <FlexBox pl="10px">
                      <Button
                        label="TORCH"
                        variant="outlined"
                        onClick={() => {
                          navigate(
                            `/input/${year}/${request.factoryId}/torch-based/category1/energy`
                          );
                        }}
                        disabled={
                          !(
                            request.status === InputStatus.requested ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category1.intensityType ===
                                IntensityType["torch-based"] &&
                              request.category1.submissionTime !== undefined) ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category1.intensityType === undefined &&
                              request.category1.submissionTime === undefined) ||
                            (request.status === InputStatus.submitted &&
                              request.category1.intensityType ===
                                IntensityType["torch-based"])
                          )
                        }
                        className={classes.touch}
                      />
                    </FlexBox>
                  </FlexBox>
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  <Typography
                    variant="overline"
                    sx={{ letterSpacing: "normal" }}
                  >
                    {(request.category4.submissionTime !== undefined &&
                      request.status !== InputStatus["in-progress"]) ||
                    (request.category4.submissionTime !== undefined &&
                      request.status === InputStatus["in-progress"] &&
                      request.category4.intensityType !== undefined)
                      ? ToDateTimeFromString(
                          request.category4.submissionTime
                        ).toFormat("yyyy/MM/dd HH:mm")
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FlexBox justifyContent="center">
                    <FlexBox>
                      <Button
                        color="secondary"
                        label="自社算定"
                        variant="outlined"
                        onClick={() => {
                          navigate(
                            `/input/${year}/${request.factoryId}/in-house/category4`,
                            {
                              state: {
                                factoryName: request.factoryNameForSelf,
                              },
                            }
                          );
                        }}
                        disabled={
                          !(
                            request.status === InputStatus.requested ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category4.intensityType ===
                                IntensityType["in-house"] &&
                              request.category4.submissionTime !== undefined) ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category4.intensityType === undefined &&
                              request.category4.submissionTime === undefined) ||
                            (request.status === InputStatus.submitted &&
                              request.category4.intensityType ===
                                IntensityType["in-house"])
                          )
                        }
                        className={classes.inHouse}
                      />
                    </FlexBox>
                    <FlexBox pl="10px">
                      <Button
                        label="TORCH"
                        variant="outlined"
                        onClick={() => {
                          navigate(
                            `/input/${year}/${request.factoryId}/torch-based/category4/transport`
                          );
                        }}
                        disabled={
                          !(
                            request.status === InputStatus.requested ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category4.intensityType ===
                                IntensityType["torch-based"] &&
                              request.category4.submissionTime !== undefined) ||
                            (request.status === InputStatus["in-progress"] &&
                              request.category4.intensityType === undefined &&
                              request.category4.submissionTime === undefined) ||
                            (request.status === InputStatus.submitted &&
                              request.category4.intensityType ===
                                IntensityType["torch-based"])
                          )
                        }
                        className={classes.touch}
                      />
                    </FlexBox>
                  </FlexBox>
                </TableCell>
              </TableRow>
            ))}

          {!props.isProcessing &&
            props.requests !== undefined &&
            props.requests.length === 0 && (
              <TableRow>
                <TableCell scope="row" colSpan={6}>
                  <Typography align="center">
                    {messages.ghgRequest.nothing}
                  </Typography>
                </TableCell>
              </TableRow>
            )}

          {!props.isProcessing && props.requests === undefined && (
            <TableRow>
              <TableCell scope="row" colSpan={6}>
                <Typography align="center">
                  {messages.ghgRequest.apiError}
                </Typography>
              </TableCell>
            </TableRow>
          )}

          {props.isProcessing && (
            <TableRow>
              <TableCell scope="row" colSpan={6}>
                <FlexBox justifyContent="center" alignItems="center">
                  <CircularProgress color="primary" size={24} />
                </FlexBox>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const getFactoryType = (factoryType: FactoryType) => {
  const toJapanese = {
    paper: "製紙",
    process: "加工",
    user: "ユーザ",
  };
  const japanese = toJapanese[factoryType];
  return japanese;
};
