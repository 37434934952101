// Errorをextendsしたいが、以下の問題があるため妥協する
// 参考: https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
export class ApiError<T> {
  type: T;
  cause?: unknown;

  constructor(type: T, cause?: unknown) {
    this.type = type;
    this.cause = cause;
  }
}

export interface ApiErrorResponse {
  errorCode?: string;
  errorMessage?: string;
  errorDetail?: unknown;
}

export const exhaustiveSwitchCase = (switchValue: never): never => {
  throw new Error(`never come here: ${switchValue}`);
};
