import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { ChartCard } from "src/app/components//molecules";
import {
  RadioButton,
  RadioButtonProps,
} from "src/app/components/molecules/RadioButton";
import {
  ChartAndTableViewUser,
  DataField,
  UserEmissionChart,
} from "src/app/components/organisms";
import { theme } from "src/app/configs";
import { UserEmissionByFactoryForDisplay } from "src/app/models";

export type UserEmissionByFactoryTemplateProps = {
  isProcessing: boolean;
  category: "category1" | "category4";

  // グラフ用Props
  chartData: UserEmissionByFactoryForDisplay[];
  radioButtonProps: RadioButtonProps;

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

export const UserEmissionByFactoryTemplate: React.FC<
  UserEmissionByFactoryTemplateProps
> = (props) => {
  return (
    <ChartAndTableViewUser
      cardTitle="自社工場別の総排出量内訳"
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
          variant={
            props.category === "category1" ? "cate1Header" : "cate4Header"
          }
        />
      }
      backgroundColor={
        props.category === "category1"
          ? theme.palette.cate1ForUserCompanyBackground
          : theme.palette.cate4ForUserCompanyBackground
      }
    >
      <ChartCard
        chartTitle="自社工場ごとのサプライヤー排出量合計"
        selectElement={<RadioButton {...props.radioButtonProps} />}
        chartElement={
          <UserEmissionChart
            byFactoryData={props.chartData}
            unit="t-CO2eq/年"
          />
        }
        noData={props.chartData.length === 0}
        isProcessing={props.isProcessing}
      />
    </ChartAndTableViewUser>
  );
};
