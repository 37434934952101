import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

import { Button } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 160,
    minHeight: 40,
  },
}));

export type LargeButtonProps = {
  label: string;
  variant: "text" | "contained" | "outlined";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default";
  enableSubmit?: boolean;
  onClick?: () => void;
  isProcessing?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  disabled?: boolean;
  className?: string;
};

export const LargeButton: React.FC<LargeButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Button
      label={props.label}
      variant={props.variant}
      color={props.color}
      className={clsx(classes.button, props.className)}
      enableSubmit={props.enableSubmit}
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      isProcessing={props.isProcessing}
    />
  );
};
