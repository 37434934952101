import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { bindTextField, FormHandle } from "@somq14/use-form";
import React from "react";

import { TextField } from "src/app/components/molecules";
import { Footer } from "src/app/components/organisms";
import { LoginForm } from "src/app/components/pages";
import { messages } from "src/app/configs";
import { CompanyId, LoginId, LoginPassword } from "src/app/domains";
import LoginCaption from "src/app/images/loginCaption.png";
import LoginCaption2x from "src/app/images/loginCaption@2x.png";
import LoginImage from "src/app/images/loginImage.png";
import LoginLogo from "src/app/images/loginLogo.png";
import LoginLogo2x from "src/app/images/loginLogo@2x.png";
import Logo from "src/app/images/logo.svg";
import { Button, FlexBox, Form, Image } from "src/lib/components/atoms";

export type LoginTemplateProps = {
  onClickLogin: () => void;
  isProcessing: boolean;
  form: FormHandle<LoginForm>;
};

const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "100vh",
    padding: theme.spacing(5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
  },
  image: {
    backgroundImage: `url(${LoginImage})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  button: {
    padding: theme.spacing(2),
    width: "50%",
  },
  loginTitle: {
    letterSpacing: "0.1em",
  },
  form: {
    minWidth: "400px",
  },
  caption: {
    marginTop: theme.spacing(1),
  },
}));

export const LoginTemplate: React.FC<LoginTemplateProps> = (props) => {
  const classes = useStyles();
  return (
    <FlexBox>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        className={classes.body}
        flex={1}
      >
        <FlexBox alignItems="start" width="100%">
          <Image width="120px" alt="logo" src={Logo} />
        </FlexBox>

        <FlexBox
          flexDirection="column"
          alignItems="center"
          className={classes.form}
          mt={15}
        >
          <Typography variant="h1" className={classes.loginTitle}>
            Log In
          </Typography>
          <Form onSubmit={props.onClickLogin} width="100%">
            <Box mt={6}>
              <TextField
                label="企業コード"
                maxLength={CompanyId.MaxLength}
                hasError={props.form.companyId.errors.length > 0}
                errorMessage={messages.login.companyIdInvalid}
                disabled={props.isProcessing}
                inputMode="text"
                {...bindTextField(props.form.companyId)}
              />
            </Box>
            <TextField
              label="ログインID"
              maxLength={LoginId.MaxLength}
              hasError={props.form.loginId.errors.length > 0}
              errorMessage={messages.login.loginIdInvalid}
              disabled={props.isProcessing}
              inputMode="text"
              autoComplete="username"
              {...bindTextField(props.form.loginId)}
            />
            <TextField
              label="パスワード"
              maxLength={LoginPassword.MaxLength}
              secure={true}
              hasError={props.form.password.errors.length > 0}
              errorMessage={messages.login.passwordInvalid}
              disabled={props.isProcessing}
              autoComplete="current-password"
              {...bindTextField(props.form.password)}
            />
            <FlexBox mt={3} justifyContent="center">
              <Button
                label="ログイン"
                variant="contained"
                color="primary"
                enableSubmit={true}
                className={classes.button}
                isProcessing={props.isProcessing}
              />
            </FlexBox>
          </Form>
        </FlexBox>
      </FlexBox>
      <FlexBox
        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
        flex={1}
      >
        <FlexBox
          position="relative"
          minHeight="100vh"
          flexDirection="column"
          alignItems="center"
          className={classes.image}
        >
          <FlexBox mt={15} flexDirection="column" alignItems="center">
            <Image
              width="136px"
              alt="loginLogo"
              src={LoginLogo}
              src2x={LoginLogo2x}
            />
            <Image
              className={classes.caption}
              width="220px"
              alt="LoginCaption"
              src={LoginCaption}
              src2x={LoginCaption2x}
            />
          </FlexBox>
          <Box position="absolute" bottom="0">
            <Footer />
          </Box>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
