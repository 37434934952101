import {
  ClassificationType,
  CompanyType,
  DrainageMethods,
  FactoryType,
  FuelType,
  InputStatus,
  MaterialType,
  MiddleClassificationType,
  PowerType,
  ProductPurpose,
  TransportFuelType,
  WasteMethods,
  WasteType,
} from "./apis/model";

export const companyTypes = Object.values(CompanyType);

export type Role = (typeof roles)[number];
export const roles = Object.values(CompanyType);

export const factoryTypes = Object.values(FactoryType);
export const inputStatus = Object.values(InputStatus);
export const fuelTypes = Object.values(FuelType);

export type PaperType = string;
export type StandardType = string;
export type ProductType = string;

type ExcludedProcessMaterialValues = "starch" | "ink" | "varnish";
type ExcludedPaperMaterialValues =
  | "sizingAgent"
  | "strengtheningAgent"
  | "aluminiumSulfate"
  | "dye";

export type PaperMaterialType = Exclude<
  MaterialType,
  ExcludedProcessMaterialValues
>;
export type ProcessMaterialType = Exclude<MaterialType, PaperMaterialType>;
export type Co2EmissionFactorType = Exclude<
  PaperMaterialType,
  ExcludedPaperMaterialValues
>;

export const materialTypes = Object.values(MaterialType);

export const paperMaterialTypes: PaperMaterialType[] = [
  MaterialType.usedPaper,
  MaterialType.paperChip,
  MaterialType.internalVirginPulp,
  MaterialType.externalVirginPulp,
  MaterialType.sizingAgent,
  MaterialType.strengtheningAgent,
  MaterialType.aluminiumSulfate,
  MaterialType.dye,
];

export const processMaterialTypes: ProcessMaterialType[] = [
  MaterialType.starch,
  MaterialType.ink,
  MaterialType.varnish,
];

export const co2EmissionFactorTypes: Co2EmissionFactorType[] = [
  MaterialType.usedPaper,
  MaterialType.paperChip,
  MaterialType.internalVirginPulp,
  MaterialType.externalVirginPulp,
];

export const wasteTypes = Object.values(WasteType);

type ExcludedProcessWasteValues = "offCutLoss";
type ExcludedPaperWasteValues =
  | "screenLees1"
  | "screenLees2"
  | "screenLees3"
  | "pulper1"
  | "pulper2"
  | "pulper3";
type ExcludedCommonWasteValues = "sludge" | "ash" | "basePaperLoss";

export type PaperWasteType = Exclude<WasteType, ExcludedProcessWasteValues>;
export type ProcessWasteType = Exclude<WasteType, ExcludedPaperWasteValues>;
export type PaperOnlyWasteType = Exclude<
  PaperWasteType,
  ExcludedCommonWasteValues
>;
export type ProcessOnlyWasteType = Exclude<
  ProcessWasteType,
  ExcludedCommonWasteValues
>;
export type CommonWasteType = Exclude<ProcessWasteType, ProcessOnlyWasteType>;

export const paperWasteTypes: PaperWasteType[] = [
  WasteType.sludge,
  WasteType.ash,
  WasteType.basePaperLoss,
  WasteType.screenLees1,
  WasteType.screenLees2,
  WasteType.screenLees3,
  WasteType.pulper1,
  WasteType.pulper2,
  WasteType.pulper3,
];

export const processWasteTypes: ProcessWasteType[] = [
  WasteType.sludge,
  WasteType.ash,
  WasteType.basePaperLoss,
  WasteType.offCutLoss,
];

export const paperOnlyWasteTypes: PaperOnlyWasteType[] = [
  WasteType.screenLees1,
  WasteType.screenLees2,
  WasteType.screenLees3,
  WasteType.pulper1,
  WasteType.pulper2,
  WasteType.pulper3,
];

export const processOnlyWasteTypes: ProcessOnlyWasteType[] = [
  WasteType.offCutLoss,
];

export const commonWasteTypes: CommonWasteType[] = [
  WasteType.sludge,
  WasteType.ash,
  WasteType.basePaperLoss,
];

export const wasteMethods = Object.values(WasteMethods);
export const drainageMethods = Object.values(DrainageMethods);

export const transportFuelTypes = Object.values(TransportFuelType);
export type ExceptElectricPowerTransportFuelType = Exclude<
  TransportFuelType,
  "electricPower"
>;

export const exceptElectricPowerTransportFuelTypes: ExceptElectricPowerTransportFuelType[] =
  [
    TransportFuelType.gasoline,
    TransportFuelType.lightOil,
    TransportFuelType.cng,
    TransportFuelType.cHeavyOil,
  ];

export const middleClassificationTypes = Object.values(
  MiddleClassificationType
);

export const classificationTypes = Object.values(ClassificationType);
export const powerTypes = Object.values(PowerType);
export const productPurposes = Object.values(ProductPurpose);

type ExcludedProcessClassificationValues =
  | "starch"
  | "ink"
  | "varnish"
  | "purchasedSteam";
type ExcludedPaperClassificationValues = "mainMaterial" | "chemicals" | "dye";

type PaperClassificationType = Exclude<
  ClassificationType,
  ExcludedProcessClassificationValues
>;
type ProcessClassificationType = Exclude<
  ClassificationType,
  ExcludedPaperClassificationValues
>;

export const paperClassificationTypes: PaperClassificationType[] = [
  ClassificationType.privateSteam,
  ClassificationType.privatePower,
  ClassificationType.purchasedPower,
  ClassificationType.certificate,
  ClassificationType.waste,
  ClassificationType.drainage,
  ClassificationType.water,
  ClassificationType.mainMaterial,
  ClassificationType.chemicals,
  ClassificationType.dye,
];

export const processClassificationTypes: ProcessClassificationType[] = [
  ClassificationType.privateSteam,
  ClassificationType.privatePower,
  ClassificationType.purchasedPower,
  ClassificationType.certificate,
  ClassificationType.waste,
  ClassificationType.drainage,
  ClassificationType.water,
  ClassificationType.purchasedSteam,
  ClassificationType.starch,
  ClassificationType.ink,
  ClassificationType.varnish,
];
