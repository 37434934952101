/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

/**
 * 原単位区分
 */
export type IntensityType = (typeof IntensityType)[keyof typeof IntensityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntensityType = {
  "in-house": "in-house",
  "torch-based": "torch-based",
  idea: "idea",
  "industry-average": "industry-average",
} as const;
