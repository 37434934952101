import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  GhgInputCategory1Service,
  GhgInputCategory1ServiceSubmitError,
} from "./ghgInputCategory1Service";
import {
  GhgInputCategory1Form,
  GhgInputCategory1TabType,
  initGhgInputCategory1Form,
} from "./ghgInputForm";
import {
  convertEnergyLocalStorageToForm,
  convertManufacturingLocalStorageToForm,
  convertWasteLocalStorageToForm,
  validateBoolean,
} from "./ghgInputFormConverter";
import {
  GhgInputService,
  GhgInputServiceGetDeliveryError,
  GhgInputServiceGetProductError,
  GhgInputServiceUnSubmitError,
  LocalStorageServiceRegisterError,
} from "./ghgInputService";

import {
  GetCategory1ApiError,
  PaperCategory1Api,
  ProcessCategory1Api,
} from "src/app/apis/category1-api/category1Api";
import {
  GetInputRequestApiError,
  InputRequestApi,
} from "src/app/apis/input-request-api/inputRequestApi";
import {
  getLocalStorageApi,
  GetLocalStorageError,
  GetLocalStorageResponse,
  LocalStorage,
  SetLocalStorageError,
} from "src/app/apis/local-storage-api";
import {
  FactoryType,
  GetInputRequests200,
  GetProduct200ProductsItem,
  IntensityType,
} from "src/app/apis/model";
import { versionCheck } from "src/app/components/pages/FactoryListPage";
import { GhgInputCategory1Template } from "src/app/components/templates";
import { envConfig, messages } from "src/app/configs";
import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { useErrorDialog } from "src/app/hooks/useErrorDialog";
import { useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { ghgInputCategory1TabNames } from "src/app/navigation/tabs";
import { ToStringYearFromDateTime } from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

type GhgInputPagePathParam = {
  factoryId: string;
  year: string;
  tab: string;
};

export const GhgInputCategory1Page: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const [authState, setAuthState] = useAuthState<AuthState>();

  const params = useParams<GhgInputPagePathParam>() as GhgInputPagePathParam;

  const targetTab = ["energy", "waste", "manufacturing"].includes(params.tab)
    ? (params.tab as GhgInputCategory1TabType)
    : "energy";

  // 入力依頼一覧（工場一覧）画面のリクエスト
  const previousPagePath = paths.input.year.replace(":year", params.year);

  const [form, setForm] = useState<GhgInputCategory1Form>(
    initGhgInputCategory1Form()
  );
  const [products, setProducts] = useState<GetProduct200ProductsItem[]>([]);
  const [manufacturingProducts, setManufacturingProducts] = useState<
    readonly string[]
  >([]);
  const [isUnsavedForm, setIsUnsavedForm] = useState<boolean>(false);
  const [localStorage, setLocalStorage] = useState<LocalStorage>();
  const [category1Version, setCategory1Version] = useState(0);

  const updateForm: Dispatch<SetStateAction<GhgInputCategory1Form>> =
    useCallback((form) => {
      setIsUnsavedForm(true);
      setForm(form);
    }, []);

  const yearDropDownProps = useYearDropdown();

  const { dialogProps: errorDialogProps, openDialog: openErrorDialog } =
    useErrorDialog();

  /** 工場カテゴリ1取得 */
  const getCategory1 = async () => {
    let version = 0;
    if (yearDropDownProps?.year !== undefined) {
      // サプライヤ（製紙）の場合
      if (authState?.role === "paper-supplier") {
        const paperCategory1Api = new PaperCategory1Api();
        // SDA013:製紙工場カテゴリ1取得API
        const result = await paperCategory1Api.getCategory1({
          year: yearDropDownProps.year.toFormat("yyyy"),
        });
        const factory = result.factories.find(
          (info) => info.factoryId === params.factoryId
        );
        if (factory !== undefined) {
          version = factory.version;
        }
      }
      // サプライヤ（加工）の場合
      if (authState?.role === "process-supplier") {
        const processCategory1Api = new ProcessCategory1Api();
        // SDA014:加工工場カテゴリ1取得API
        const result = await processCategory1Api.getCategory1({
          year: yearDropDownProps.year.toFormat("yyyy"),
        });
        const factory = result.factories.find(
          (info) => info.factoryId === params.factoryId
        );
        if (factory !== undefined) {
          version = factory.version;
        }
      }
      setCategory1Version(version);
    }
    return version;
  };

  /** 初期値取得処理 */
  const {
    data: apiRes,
    isProcessing: isLoading,
    setData: setApiRes,
  } = useAsyncEffect(async () => {
    if (yearDropDownProps?.year === undefined) {
      return;
    }

    const ghgInputService = new GhgInputService();
    let inputRequest: GetInputRequests200 | undefined;
    let localStorageInput: GetLocalStorageResponse | undefined;

    try {
      //ローカルストレージ取得
      const localStorageApi = getLocalStorageApi();
      // CDP003:一次データ一時情報取得
      localStorageInput = await localStorageApi.get({
        year: yearDropDownProps.year.toFormat("yyyy"),
        factoryId: params.factoryId,
      });
      setLocalStorage(localStorageInput);

      // SDA002:GHG入力依頼詳細取得API
      const inputRequestApi = new InputRequestApi();
      inputRequest = await inputRequestApi.getInputRequest({
        year: yearDropDownProps.year,
        factoryId: params.factoryId,
      });

      const version = await getCategory1();

      if (
        // 提出期限内で提出済みのステータスかつバージョンに変更がある場合
        (inputRequest.status === "submitted" ||
          inputRequest.category1.submissionTime !== undefined) &&
        !versionCheck(localStorageInput)
      ) {
        // 結果を削除し、再度入力依頼詳細を取得
        await ghgInputService.unSubmit({
          intensityCategory: "category1",
          version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        inputRequest = await inputRequestApi.getInputRequest({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });
      }
    } catch (err) {
      if (
        !(err instanceof GetInputRequestApiError) &&
        !(err instanceof GetCategory1ApiError) &&
        !(err instanceof GetLocalStorageError)
      ) {
        snackbar.error(messages.common.unknown);
        return;
      }
      const errType = err.type;
      switch (errType) {
        case "can-not-use":
          navigate(previousPagePath);
          snackbar.error(messages.localStorage.canNotUse);
          break;
        case "cookie-disabled":
          navigate(previousPagePath);
          snackbar.error(messages.localStorage.cookieDisabled);
          break;
        case "factory-not-found":
          navigate(previousPagePath);
          snackbar.error(messages.ghgInput.factoryNotFound);
          break;
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          break;
        case "network":
          if (err instanceof GetCategory1ApiError) {
            navigate(previousPagePath);
          }
          snackbar.error(messages.common.network);
          break;
        case "unknown":
          if (err instanceof GetCategory1ApiError) {
            navigate(previousPagePath);
          }
          snackbar.error(messages.common.unknown);
          break;
        case "input-requests-not-found":
          snackbar.error(messages.ghgInput.inputRequestNotFound);
          break;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }

    try {
      // 製品・規格一覧取得APIの呼び出し
      let products = undefined;
      let manufacturingProductTypeList = undefined;
      if (targetTab === "manufacturing") {
        // 「製造工程」タブを開いているときのみ呼び出す
        // SDA007:製品・規格情報取得API
        const res = await ghgInputService.getProduct({
          year: ToStringYearFromDateTime(yearDropDownProps.year),
          factoryId: params.factoryId,
        });
        products = res.products.sort((a, b) => a.sortOrder - b.sortOrder);

        // 製造工程タブ 年間生産量・受入量 入力項目リスト作成
        manufacturingProductTypeList =
          authState?.role === "paper-supplier"
            ? products.map((it) => it.productType)
            : [];

        setProducts(products);
        setManufacturingProducts(manufacturingProductTypeList);
      }

      // 入力フォームを生成
      setForm({
        energy: {
          ...convertEnergyLocalStorageToForm(localStorageInput?.energyInput),
        },
        waste: {
          ...convertWasteLocalStorageToForm(localStorageInput?.wasteInput),
        },
        manufacturing: {
          ...convertManufacturingLocalStorageToForm(
            localStorageInput?.manufacturingInput,
            manufacturingProductTypeList
          ),
        },
      });
      setIsUnsavedForm(false);
    } catch (err) {
      if (
        !(err instanceof GhgInputServiceGetProductError) &&
        !(err instanceof GhgInputServiceGetDeliveryError)
      ) {
        snackbar.error(messages.common.unknown);
        return;
      }
      const errType = err.type;
      switch (errType) {
        case "factory-not-found":
          navigate(previousPagePath);
          snackbar.error(messages.ghgInput.factoryNotFound);
          return;
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          return;
        case "network":
          snackbar.error(messages.common.network);
          return;
        case "unknown":
          snackbar.error(messages.common.unknown);
          return;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }
    return { inputRequest: inputRequest };
  }, [
    navigate,
    setAuthState,
    snackbar,
    params.factoryId,
    yearDropDownProps?.year,
    // タブが変化したら再読み込みする
    location.pathname,
  ]);

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        apiRes?.inputRequest === undefined ||
        apiRes.inputRequest.factoryType === "user"
      ) {
        return;
      }

      // 未登録内容チェック処理
      if (isUnsavedForm) {
        snackbar.error(messages.ghgInput.submit.unSaved);
        return;
      }

      try {
        const ghgInputService = new GhgInputCategory1Service();
        // CDP006:GHG算出情報確定・算定（カテゴリ１）
        await ghgInputService.submitCategory1TorchBased({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
          factoryType: apiRes.inputRequest.factoryType as Exclude<
            FactoryType,
            "user"
          >,
          companyId: authState.companyId,
          category1Version,
        });

        snackbar.success(messages.ghgInput.submit.success);

        const inputRequestApi = new InputRequestApi();
        // SDA002:GHG入力依頼詳細取得APIの呼び出し
        const res = await inputRequestApi.getInputRequest({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });
        setApiRes({ inputRequest: res });

        await getCategory1();
      } catch (err) {
        if (
          !(err instanceof GhgInputCategory1ServiceSubmitError) &&
          !(err instanceof GetInputRequestApiError)
        ) {
          snackbar.error(messages.common.unknown);
          return;
        }

        const errType = err.type;
        if (
          err instanceof GetInputRequestApiError &&
          errType === "factory-not-found"
        ) {
          navigate(previousPagePath);
          snackbar.error(messages.ghgInput.factoryNotFound);
          return;
        }

        switch (errType) {
          case "input-requests-not-found":
            snackbar.error(messages.ghgInput.inputRequestNotFound);
            return;
          case "product-not-found":
            openErrorDialog(messages.ghgInput.submit.productNotFound);
            return;
          case "amount-not-found":
            openErrorDialog(messages.ghgInput.submit.amountNotFound);
            return;
          case "deliveries-not-found":
            openErrorDialog(messages.ghgInput.submit.deliveriesNotFound);
            return;
          case "factory-not-found":
            openErrorDialog(messages.ghgInput.submit.factoryNotFound);
            return;
          case "emission-factor-not-found":
            openErrorDialog(messages.ghgInput.submit.emissionFactorNotFound);
            return;
          case "machine-count-incorrect":
            openErrorDialog(messages.ghgInput.submit.machineCountIncorrect);
            return;
          case "weight-factor-is-zero":
            openErrorDialog(messages.ghgInput.submit.weightFactorIsZero);
            return;
          case "weight-factor-incorrect":
            openErrorDialog(messages.ghgInput.submit.weightFactorIncorrect);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.submit.expired);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.submit.alreadyExist);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  const { dialogProps: submitDialogProps, openDialog: openSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.submit.confirm, onSubmit);

  const { mutate: onUnSubmit, isLoading: isUnSubmitting } = useMutation(
    async () => {
      if (yearDropDownProps === undefined || apiRes === undefined) {
        return;
      }

      try {
        const ghgInputService = new GhgInputService();
        // 登録削除処理
        await ghgInputService.unSubmit({
          intensityCategory: "category1",
          version: category1Version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        snackbar.success(messages.ghgInput.unSubmit.success);

        //GHG入力依頼詳細取得APIの呼び出し
        const inputRequestApi = new InputRequestApi();
        const res = await inputRequestApi.getInputRequest({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });
        setApiRes({ inputRequest: res });

        await getCategory1();
      } catch (err) {
        if (
          !(err instanceof GhgInputServiceUnSubmitError) &&
          !(err instanceof GetInputRequestApiError)
        ) {
          snackbar.error(messages.common.unknown);
          return;
        }
        const errType = err.type;
        switch (errType) {
          case "factory-not-found":
            navigate(previousPagePath);
            snackbar.error(messages.ghgInput.factoryNotFound);
            return;
          case "input-requests-not-found":
            snackbar.error(messages.ghgInput.inputRequestNotFound);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.unSubmit.expired);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.unSubmit.conflict);
            // 画面遷移によりタブを再読み込みする
            navigate(basePath);
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  const { dialogProps: unSubmitDialogProps, openDialog: openUnSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.unSubmit.confirm, onUnSubmit);

  const { mutate: onRegister, isLoading: isRegistering } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        apiRes === undefined ||
        authState === undefined ||
        authState.role === "user-company"
      ) {
        return;
      }

      try {
        const ghgInputService = new GhgInputService();
        // CDP00501:一次データ登録（カテゴリ1）
        const res = await ghgInputService.register({
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
          targetTab,
          form,
          setForm,
          companyType: authState.role,
          localStorage: localStorage,
        });

        snackbar.success(messages.ghgInput.registration.success);

        setIsUnsavedForm(false);
        setLocalStorage(res.res);

        window.scrollTo({ top: 0 });
      } catch (err) {
        if (!(err instanceof LocalStorageServiceRegisterError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "validation":
            snackbar.error(messages.ghgInput.registration.validationError);
            return;
          case "can-not-use":
            snackbar.error(messages.localStorage.canNotUse);
            return;
          case "cookie-disabled":
            snackbar.error(messages.localStorage.cookieDisabled);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  /** 一時保存処理 */
  const { mutate: onSave, isLoading: isSaving } = useMutation(async () => {
    if (apiRes === undefined || yearDropDownProps === undefined) {
      return;
    }

    try {
      const localStorageApi = getLocalStorageApi();
      // CDP004:一時保存
      const res = await localStorageApi.set({
        year: yearDropDownProps.year.toFormat("yyyy"),
        factoryId: params.factoryId,
        targetTab,
        energyInput: {
          ...form.energy,
          version:
            targetTab === "energy"
              ? envConfig.version
              : localStorage?.energyInput?.version ?? "1",
          isRegistered: targetTab === "energy" ? false : inputCompleted.energy,
        },
        wasteInput: {
          ...form.waste,
          version:
            targetTab === "waste"
              ? envConfig.version
              : localStorage?.wasteInput?.version ?? "1",
          isRegistered: targetTab === "waste" ? false : inputCompleted.waste,
        },
        manufacturingInput: {
          ...form.manufacturing,
          version:
            targetTab === "manufacturing"
              ? envConfig.version
              : localStorage?.manufacturingInput?.version ?? "1",
          isRegistered:
            targetTab === "manufacturing"
              ? false
              : inputCompleted.manufacturing,
        },
      });
      setIsUnsavedForm(false);
      setLocalStorage(res);

      snackbar.success(messages.ghgInput.save.success);
      window.scrollTo({ top: 0 });
    } catch (err) {
      if (!(err instanceof SetLocalStorageError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "can-not-use":
          snackbar.error(messages.localStorage.canNotUse);
          return;
        case "cookie-disabled":
          snackbar.error(messages.localStorage.cookieDisabled);
          return;
        case "unknown":
          snackbar.error(messages.common.unknown);
          return;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  });

  const isProcessing =
    isLoading || isSubmitting || isUnSubmitting || isRegistering || isSaving;

  const createInputCompleted = () => {
    if (
      (apiRes?.inputRequest?.status === "requested" &&
        apiRes?.inputRequest?.category1.submissionTime === undefined) ||
      apiRes?.inputRequest?.status === "submitted" ||
      apiRes?.inputRequest?.category1.submissionTime !== undefined
    ) {
      // バージョンに変更があれば false にする
      return {
        energy:
          localStorage?.energyInput?.version === envConfig.version
            ? validateBoolean(localStorage?.energyInput?.isRegistered)
            : false,
        waste:
          localStorage?.wasteInput?.version === envConfig.version
            ? validateBoolean(localStorage?.wasteInput?.isRegistered)
            : false,
        manufacturing:
          localStorage?.manufacturingInput?.version === envConfig.version
            ? validateBoolean(localStorage?.manufacturingInput?.isRegistered)
            : false,
      };
    }
    return {
      energy: validateBoolean(localStorage?.energyInput?.isRegistered),
      waste: validateBoolean(localStorage?.wasteInput?.isRegistered),
      manufacturing: validateBoolean(
        localStorage?.manufacturingInput?.isRegistered
      ),
    };
  };

  const inputCompleted = createInputCompleted();

  const isStatus = {
    requested:
      (apiRes?.inputRequest?.status === "requested" ||
        apiRes?.inputRequest?.status === "in-progress") &&
      apiRes?.inputRequest?.category1.submissionTime === undefined,
    submitted:
      apiRes?.inputRequest?.status === "submitted" ||
      apiRes?.inputRequest?.category1.submissionTime !== undefined,
    confirmed: apiRes?.inputRequest?.status === "confirmed",
    expired: apiRes?.inputRequest?.status === "expired",
  };

  const isEditable =
    !isProcessing &&
    isStatus.requested &&
    apiRes?.inputRequest?.category1.intensityType !== IntensityType["in-house"];

  if (yearDropDownProps === undefined) {
    snackbar.error(messages.ghgInput.inputRequestNotFound, {
      preventDuplicate: true,
    });
    return <Navigate to={previousPagePath} />;
  }

  const basePath = `/input/${yearDropDownProps.year.toFormat("yyyy")}/${
    params.factoryId
  }/torch-based/category1`;

  if (authState === undefined || authState.role === "user-company") {
    return <Navigate to="/login" />;
  }

  const tabList = Object.values(ghgInputCategory1TabNames);
  const invalidUrl = tabList.every(
    (val) => location.pathname.endsWith(`/${val}`) === false
  );

  return (
    <GhgInputCategory1Template
      form={form}
      setForm={updateForm}
      products={products}
      manufacturingProducts={manufacturingProducts}
      companyType={authState.role}
      isEditable={isEditable}
      isUnsavedForm={isUnsavedForm}
      isProcessing={isProcessing}
      basePath={basePath}
      factoryName={apiRes?.inputRequest?.factoryNameForSelf}
      onClickBack={() => navigate(previousPagePath)}
      yearDropdownProps={yearDropDownProps}
      showUnSubmitButton={isStatus.submitted || isStatus.confirmed}
      submitButtonProps={{
        onClick: openSubmitDialog,
        disabled: !(
          // 確定できる条件
          (
            !isProcessing &&
            inputCompleted.energy &&
            inputCompleted.waste &&
            inputCompleted.manufacturing &&
            isStatus.requested
          )
        ),
        isProcessing: isSubmitting,
      }}
      submitDialogProps={submitDialogProps}
      unSubmitButtonProps={{
        onClick: openUnSubmitDialog,
        disabled: !(
          // 確定取消できる条件
          (!isProcessing && isStatus.submitted)
        ),
        isProcessing: isUnSubmitting,
      }}
      unSubmitDialogProps={unSubmitDialogProps}
      submitButtonMessage={
        messages.ghgInput.submissionButtonDescription[
          apiRes?.inputRequest?.status === "in-progress"
            ? isStatus.requested
              ? "requested"
              : "submitted"
            : apiRes?.inputRequest?.status ?? "requested"
        ]
      }
      tabItemProps={{
        energy: {
          onClick: () => navigate(`${basePath}/energy`),
          selected: targetTab === "energy",
          checked: validateBoolean(inputCompleted.energy),
        },
        waste: {
          onClick: () => navigate(`${basePath}/waste`),
          selected: targetTab === "waste",
          checked: validateBoolean(inputCompleted.waste),
        },
        manufacturing: {
          onClick: () => navigate(`${basePath}/manufacturing`),
          selected: targetTab === "manufacturing",
          checked: validateBoolean(inputCompleted.manufacturing),
        },
      }}
      registerButtonProps={{
        onClick: onRegister,

        disabled: !(
          // 登録できる条件
          (!isProcessing && isStatus.requested)
        ),
        isProcessing: isRegistering,
      }}
      saveButtonProps={{
        onClick: onSave,
        disabled: !(
          // 一時保存できる条件
          (!isProcessing && isStatus.requested)
        ),
        isProcessing: isSaving,
      }}
      errorDialogProps={errorDialogProps}
      invalidUrl={invalidUrl}
    />
  );
};
