import { getCustomMSW } from "./customMock";

import { getAuthMSW } from "src/app/apis/client/auth";
import { getCategory4MSW } from "src/app/apis/client/category4";
import { getDefaultMSW } from "src/app/apis/client/default";
import { getUsersMSW } from "src/app/apis/client/users";

export const handlers = [
  // NOTE: 固定のレスポンスを返したい場合はMSWで生成された関数の前に定義する。
  ...getCustomMSW(),

  ...getUsersMSW(),
  ...getAuthMSW(),
  ...getDefaultMSW(),
  ...getCategory4MSW(),
];
