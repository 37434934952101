import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, RouteProps } from "react-router";

type UseAuthStateReturnType<AuthState> = [
  AuthState | undefined,
  (authState?: AuthState) => void
];

const AuthStateContext = createContext<unknown>([
  undefined,
  () => {
    console.error("no AuthStateProvider in parent components");
  },
]);

export const useAuthState = <
  AuthState,
>(): UseAuthStateReturnType<AuthState> => {
  return useContext(AuthStateContext) as UseAuthStateReturnType<AuthState>;
};

export interface AuthStateProviderProps<AuthState> {
  initAuthState: () => Promise<AuthState | undefined>;
  initAuthStateDeps?: unknown[];
  LoadingComponent: React.FC;
}

export const AuthStateProvider = <AuthState,>(
  props: PropsWithChildren<AuthStateProviderProps<AuthState>>
) => {
  const [authState, setAuthState] = useState<AuthState | undefined>(undefined);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  useEffect(() => {
    const body = async () => {
      try {
        const authState = await props.initAuthState();
        setAuthState(authState);
      } catch (err) {
        console.warn("fetchAuthState must not throw error", err);
        setAuthState(undefined);
      } finally {
        setIsInitialLoading(false);
      }
    };
    void body();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props.initAuthStateDeps || []);

  const useAuthStateValue = useMemo<UseAuthStateReturnType<AuthState>>(() => {
    return [authState, setAuthState];
  }, [authState]);

  if (isInitialLoading) {
    const { LoadingComponent } = props;
    return <LoadingComponent />;
  }

  return (
    <AuthStateContext.Provider value={useAuthStateValue}>
      {props.children}
    </AuthStateContext.Provider>
  );
};

/**
 * カスタマイズされたReactRouterのRouteコンポーネント
 * 認証状態が無効の場合はログイン画面に強制遷移させる
 */
export const AuthRoute: React.FC<
  RouteProps & { noAuthPath?: string; children: JSX.Element }
> = (props) => {
  const [authState] = useAuthState();

  if (authState === undefined) {
    return <Navigate to={props.noAuthPath ?? "/login"} />;
  }

  return props.children;
};
