/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetTemporaryStorage200,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  GetTemporaryStorage404,
  GetTemporaryStorageParams,
  PutTemporaryStorage200,
  PutTemporaryStorage409,
  PutTemporaryStorageBody,
  OldGetInputRequestsYear200,
  Year,
  GetInputRequestsYear200,
  GetDelivery200,
  GetDelivery404,
  FactoryId,
  GetCategory1200,
  GetCategory1Params,
  GetPaperFactoryCategory1200,
  GetPaperFactoryCategory1Params,
  GetProcessFactoryCategory1200,
  GetProcessFactoryCategory1Params,
  GetProcurement200,
  GetProcurement404,
  GetProcurements200,
  GetProcurementsParams,
  PutProcurements200,
  PutProcurements404,
  PutProcurements409,
  PutProcurementsBody,
  OldGetScope3ByStandard200,
  OldGetScope3ByStandard404,
  OldGetScope3ByStandardParams,
  GetScope3ByStandard200,
  GetScope3ByStandard404,
  GetScope3ByStandardParams,
  GetScope3ByFactory200,
  GetScope3ByFactory404,
  GetScope3ByFactoryParams,
  GetScope3ByRoute200,
  GetScope3ByRoute404,
  GetScope3ByRouteParams,
  GetPdfRoute404,
  GetProduct200,
  GetProduct404,
  PostCalculation404,
  PostCalculation412,
  PostCalculationBody,
  DeleteCalculation404,
  DeleteCalculation409,
  DeleteCalculation412,
  DeleteCalculationParams,
  OldDeleteCalculation404,
  OldDeleteCalculation412,
  GetInputRequests200,
  GetInputRequests404,
  OldGetInputRequests200,
  OldGetInputRequests404,
  GetEmissionFactor200,
  GetFactory200,
  GetFactory404,
  PostCalculationCategory1Paper404,
  PostCalculationCategory1Paper409,
  PostCalculationCategory1Paper412,
  PostCalculationCategory1PaperBody,
  PostCalculationCategory1PaperParams,
  PostCalculationCategory1Process404,
  PostCalculationCategory1Process409,
  PostCalculationCategory1Process412,
  PostCalculationCategory1ProcessBody,
  PostCalculationCategory1ProcessParams,
  PostCalculationCategory4404,
  PostCalculationCategory4409,
  PostCalculationCategory4412,
  PostCalculationCategory4Body,
  PostCalculationCategory4Params,
  GetIntensity200,
  PutTempProcurements200,
  PutTempProcurements404,
  PutTempProcurements409,
  PutTempProcurementsBody,
} from "../model";
import { rest } from "msw";
import { faker } from "@faker-js/faker";
import {
  FactoryType,
  InputStatus,
  IntensityType,
  ClassificationType,
  ProductPurpose,
  SupplierCompanyType,
  MaterialType,
  CompanyType,
  IntensityCategory,
} from "../model";
import { customMutator } from "../custom-mutator";
import type { ErrorType } from "../custom-mutator";

/**
 * フォームの入力値の一時保存機能に使用するAPI
キー文字列と紐づけて任意のJSONを保存できるAPI
値は企業ごとに保存される（同一企業のユーザはデータを共有する）
 * @summary 一時情報取得API
 */
export const getTemporaryStorage = (
  params: GetTemporaryStorageParams,
  signal?: AbortSignal
) => {
  return customMutator<GetTemporaryStorage200>({
    url: `/temporary-storage`,
    method: "get",
    params,
    signal,
  });
};

export const getGetTemporaryStorageQueryKey = (
  params: GetTemporaryStorageParams
) => [`/temporary-storage`, ...(params ? [params] : [])];

export type GetTemporaryStorageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTemporaryStorage>>
>;
export type GetTemporaryStorageQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetTemporaryStorage404
>;

export const useGetTemporaryStorage = <
  TData = Awaited<ReturnType<typeof getTemporaryStorage>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetTemporaryStorage404
  >
>(
  params: GetTemporaryStorageParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTemporaryStorage>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTemporaryStorageQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTemporaryStorage>>
  > = ({ signal }) => getTemporaryStorage(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getTemporaryStorage>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * フォームの入力値の一時保存機能に使用するAPI
キー文字列と紐づけて任意のJSONを保存できるAPI
値は企業ごとに保存される（同一企業のユーザはデータを共有する）
 * @summary 一時情報保存API
 */
export const putTemporaryStorage = (
  putTemporaryStorageBody: PutTemporaryStorageBody
) => {
  return customMutator<PutTemporaryStorage200>({
    url: `/temporary-storage`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: putTemporaryStorageBody,
  });
};

export type PutTemporaryStorageMutationResult = NonNullable<
  Awaited<ReturnType<typeof putTemporaryStorage>>
>;
export type PutTemporaryStorageMutationBody = PutTemporaryStorageBody;
export type PutTemporaryStorageMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PutTemporaryStorage409
>;

export const usePutTemporaryStorage = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PutTemporaryStorage409
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putTemporaryStorage>>,
    TError,
    { data: PutTemporaryStorageBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putTemporaryStorage>>,
    { data: PutTemporaryStorageBody }
  > = (props) => {
    const { data } = props ?? {};

    return putTemporaryStorage(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof putTemporaryStorage>>,
    TError,
    { data: PutTemporaryStorageBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * 工場一覧画面にて呼び出すAPI
 * @summary GHG入力依頼一覧
 */
export const oldGetInputRequestsYear = (year: Year, signal?: AbortSignal) => {
  return customMutator<OldGetInputRequestsYear200>({
    url: `/old/input-requests/${year}`,
    method: "get",
    signal,
  });
};

export const getOldGetInputRequestsYearQueryKey = (year: Year) => [
  `/old/input-requests/${year}`,
];

export type OldGetInputRequestsYearQueryResult = NonNullable<
  Awaited<ReturnType<typeof oldGetInputRequestsYear>>
>;
export type OldGetInputRequestsYearQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useOldGetInputRequestsYear = <
  TData = Awaited<ReturnType<typeof oldGetInputRequestsYear>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  year: Year,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof oldGetInputRequestsYear>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOldGetInputRequestsYearQueryKey(year);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof oldGetInputRequestsYear>>
  > = ({ signal }) => oldGetInputRequestsYear(year, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof oldGetInputRequestsYear>>,
    TError,
    TData
  >({ queryKey, queryFn, enabled: !!year, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 入力依頼一覧画面にて呼び出すAPI
・intensityTypeが返す値は"in-house" か "torch-based"
 * @summary GHG入力依頼一覧
 */
export const getInputRequestsYear = (year: Year, signal?: AbortSignal) => {
  return customMutator<GetInputRequestsYear200>({
    url: `/input-requests/${year}`,
    method: "get",
    signal,
  });
};

export const getGetInputRequestsYearQueryKey = (year: Year) => [
  `/input-requests/${year}`,
];

export type GetInputRequestsYearQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInputRequestsYear>>
>;
export type GetInputRequestsYearQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetInputRequestsYear = <
  TData = Awaited<ReturnType<typeof getInputRequestsYear>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  year: Year,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInputRequestsYear>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInputRequestsYearQueryKey(year);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInputRequestsYear>>
  > = ({ signal }) => getInputRequestsYear(year, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getInputRequestsYear>>,
    TError,
    TData
  >({ queryKey, queryFn, enabled: !!year, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 指定した工場から納入先となる工場の一覧を取得する。
ユーザが所属する企業の工場についてのみ情報を取得できる。
 * @summary 納入先一覧
 */
export const getDelivery = (from: FactoryId, signal?: AbortSignal) => {
  return customMutator<GetDelivery200>({
    url: `/delivery/${from}`,
    method: "get",
    signal,
  });
};

export const getGetDeliveryQueryKey = (from: FactoryId) => [
  `/delivery/${from}`,
];

export type GetDeliveryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDelivery>>
>;
export type GetDeliveryQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | GetDelivery404
>;

export const useGetDelivery = <
  TData = Awaited<ReturnType<typeof getDelivery>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetDelivery404
  >
>(
  from: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDelivery>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeliveryQueryKey(from);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDelivery>>> = ({
    signal,
  }) => getDelivery(from, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getDelivery>>,
    TError,
    TData
  >({ queryKey, queryFn, enabled: !!from, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * カテゴリ１情報を返却する。（主にグラフ利用）
サプライヤ企業のみ認可あり。
 * @summary カテゴリ１取得
 */
export const getCategory1 = (
  params: GetCategory1Params,
  signal?: AbortSignal
) => {
  return customMutator<GetCategory1200>({
    url: `/category1`,
    method: "get",
    params,
    signal,
  });
};

export const getGetCategory1QueryKey = (params: GetCategory1Params) => [
  `/category1`,
  ...(params ? [params] : []),
];

export type GetCategory1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategory1>>
>;
export type GetCategory1QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetCategory1 = <
  TData = Awaited<ReturnType<typeof getCategory1>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: GetCategory1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCategory1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategory1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategory1>>> = ({
    signal,
  }) => getCategory1(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCategory1>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * 製紙工場カテゴリ1GHG算出結果テーブルからデータを取得する。
サプライヤ企業のみに認可あり。
 * @summary 製紙工場カテゴリ1
 */
export const getPaperFactoryCategory1 = (
  params: GetPaperFactoryCategory1Params,
  signal?: AbortSignal
) => {
  return customMutator<GetPaperFactoryCategory1200>({
    url: `/category1/paper`,
    method: "get",
    params,
    signal,
  });
};

export const getGetPaperFactoryCategory1QueryKey = (
  params: GetPaperFactoryCategory1Params
) => [`/category1/paper`, ...(params ? [params] : [])];

export type GetPaperFactoryCategory1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaperFactoryCategory1>>
>;
export type GetPaperFactoryCategory1QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetPaperFactoryCategory1 = <
  TData = Awaited<ReturnType<typeof getPaperFactoryCategory1>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: GetPaperFactoryCategory1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaperFactoryCategory1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPaperFactoryCategory1QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaperFactoryCategory1>>
  > = ({ signal }) => getPaperFactoryCategory1(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPaperFactoryCategory1>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * 加工工場カテゴリ1GHG算出結果テーブルからデータを取得する。
サプライヤ企業のみに認可あり。
 * @summary 加工工場カテゴリ1
 */
export const getProcessFactoryCategory1 = (
  params: GetProcessFactoryCategory1Params,
  signal?: AbortSignal
) => {
  return customMutator<GetProcessFactoryCategory1200>({
    url: `/category1/process`,
    method: "get",
    params,
    signal,
  });
};

export const getGetProcessFactoryCategory1QueryKey = (
  params: GetProcessFactoryCategory1Params
) => [`/category1/process`, ...(params ? [params] : [])];

export type GetProcessFactoryCategory1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getProcessFactoryCategory1>>
>;
export type GetProcessFactoryCategory1QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetProcessFactoryCategory1 = <
  TData = Awaited<ReturnType<typeof getProcessFactoryCategory1>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: GetProcessFactoryCategory1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProcessFactoryCategory1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProcessFactoryCategory1QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProcessFactoryCategory1>>
  > = ({ signal }) => getProcessFactoryCategory1(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProcessFactoryCategory1>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * ログインしているサプライヤ企業に紐づく調達量情報を取得するAPI
サプライヤ企業のみ認可あり
パスパラメータの年と工場IDに紐づく情報を経路区間別調達量TBLから取得する
取得した経路区間別調達量の年とユーザ企業IDに紐づく情報を調達量TBLから取得し、一時保存フラグがFALSEの場合のみ成功のレスポンスを返す
 * @summary 調達量取得（サプライヤ）
 */
export const getProcurement = (
  year: Year,
  factoryId: FactoryId,
  signal?: AbortSignal
) => {
  return customMutator<GetProcurement200>({
    url: `/procurement-by-supplier/${year}/${factoryId}`,
    method: "get",
    signal,
  });
};

export const getGetProcurementQueryKey = (year: Year, factoryId: FactoryId) => [
  `/procurement-by-supplier/${year}/${factoryId}`,
];

export type GetProcurementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProcurement>>
>;
export type GetProcurementQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetProcurement404
>;

export const useGetProcurement = <
  TData = Awaited<ReturnType<typeof getProcurement>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetProcurement404
  >
>(
  year: Year,
  factoryId: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProcurement>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProcurementQueryKey(year, factoryId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcurement>>> = ({
    signal,
  }) => getProcurement(year, factoryId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProcurement>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(year && factoryId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 経路毎の調達量および経路区間別の調達量を更新する。
ユーザ企業のみ認可あり。
 * @summary 調達量一覧
 */
export const getProcurements = (
  params: GetProcurementsParams,
  signal?: AbortSignal
) => {
  return customMutator<GetProcurements200>({
    url: `/procurements`,
    method: "get",
    params,
    signal,
  });
};

export const getGetProcurementsQueryKey = (params: GetProcurementsParams) => [
  `/procurements`,
  ...(params ? [params] : []),
];

export type GetProcurementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProcurements>>
>;
export type GetProcurementsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetProcurements = <
  TData = Awaited<ReturnType<typeof getProcurements>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: GetProcurementsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProcurements>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcurementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcurements>>> = ({
    signal,
  }) => getProcurements(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProcurements>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * 経路毎の調達量を更新する。
ユーザ企業のみ認可あり。
 * @summary 調達量更新
 */
export const putProcurements = (putProcurementsBody: PutProcurementsBody) => {
  return customMutator<PutProcurements200>({
    url: `/procurements`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: putProcurementsBody,
  });
};

export type PutProcurementsMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProcurements>>
>;
export type PutProcurementsMutationBody = PutProcurementsBody;
export type PutProcurementsMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PutProcurements404
  | PutProcurements409
>;

export const usePutProcurements = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PutProcurements404
    | PutProcurements409
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProcurements>>,
    TError,
    { data: PutProcurementsBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProcurements>>,
    { data: PutProcurementsBody }
  > = (props) => {
    const { data } = props ?? {};

    return putProcurements(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof putProcurements>>,
    TError,
    { data: PutProcurementsBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * ケース規格別排出量を返却する。
ユーザ企業のみ認可あり。
 * @summary 旧規格別SCOPE3取得
 */
export const oldGetScope3ByStandard = (
  params: OldGetScope3ByStandardParams,
  signal?: AbortSignal
) => {
  return customMutator<OldGetScope3ByStandard200>({
    url: `/old/scope3/by-standard`,
    method: "get",
    params,
    signal,
  });
};

export const getOldGetScope3ByStandardQueryKey = (
  params: OldGetScope3ByStandardParams
) => [`/old/scope3/by-standard`, ...(params ? [params] : [])];

export type OldGetScope3ByStandardQueryResult = NonNullable<
  Awaited<ReturnType<typeof oldGetScope3ByStandard>>
>;
export type OldGetScope3ByStandardQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | OldGetScope3ByStandard404
>;

export const useOldGetScope3ByStandard = <
  TData = Awaited<ReturnType<typeof oldGetScope3ByStandard>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | OldGetScope3ByStandard404
  >
>(
  params: OldGetScope3ByStandardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof oldGetScope3ByStandard>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOldGetScope3ByStandardQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof oldGetScope3ByStandard>>
  > = ({ signal }) => oldGetScope3ByStandard(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof oldGetScope3ByStandard>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * ケース規格別排出情報を返却する。
ユーザ企業のみ認可あり。
 * @summary 規格別SCOPE3取得
 */
export const getScope3ByStandard = (
  params: GetScope3ByStandardParams,
  signal?: AbortSignal
) => {
  return customMutator<GetScope3ByStandard200>({
    url: `/scope3/by-standard`,
    method: "get",
    params,
    signal,
  });
};

export const getGetScope3ByStandardQueryKey = (
  params: GetScope3ByStandardParams
) => [`/scope3/by-standard`, ...(params ? [params] : [])];

export type GetScope3ByStandardQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScope3ByStandard>>
>;
export type GetScope3ByStandardQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetScope3ByStandard404
>;

export const useGetScope3ByStandard = <
  TData = Awaited<ReturnType<typeof getScope3ByStandard>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetScope3ByStandard404
  >
>(
  params: GetScope3ByStandardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScope3ByStandard>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetScope3ByStandardQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScope3ByStandard>>
  > = ({ signal }) => getScope3ByStandard(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getScope3ByStandard>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * 工場別排出量を返却する。
ユーザ企業のみ認可あり。
 * @summary 工場別SCOPE3取得
 */
export const getScope3ByFactory = (
  params: GetScope3ByFactoryParams,
  signal?: AbortSignal
) => {
  return customMutator<GetScope3ByFactory200>({
    url: `/scope3/by-factory`,
    method: "get",
    params,
    signal,
  });
};

export const getGetScope3ByFactoryQueryKey = (
  params: GetScope3ByFactoryParams
) => [`/scope3/by-factory`, ...(params ? [params] : [])];

export type GetScope3ByFactoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScope3ByFactory>>
>;
export type GetScope3ByFactoryQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetScope3ByFactory404
>;

export const useGetScope3ByFactory = <
  TData = Awaited<ReturnType<typeof getScope3ByFactory>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetScope3ByFactory404
  >
>(
  params: GetScope3ByFactoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScope3ByFactory>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetScope3ByFactoryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScope3ByFactory>>
  > = ({ signal }) => getScope3ByFactory(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getScope3ByFactory>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * 経路別原単位を返却する。
ユーザ企業のみ認可あり。
 * @summary 経路別SCOPE3取得
 */
export const getScope3ByRoute = (
  params: GetScope3ByRouteParams,
  signal?: AbortSignal
) => {
  return customMutator<GetScope3ByRoute200>({
    url: `/scope3/by-route`,
    method: "get",
    params,
    signal,
  });
};

export const getGetScope3ByRouteQueryKey = (params: GetScope3ByRouteParams) => [
  `/scope3/by-route`,
  ...(params ? [params] : []),
];

export type GetScope3ByRouteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScope3ByRoute>>
>;
export type GetScope3ByRouteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetScope3ByRoute404
>;

export const useGetScope3ByRoute = <
  TData = Awaited<ReturnType<typeof getScope3ByRoute>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetScope3ByRoute404
  >
>(
  params: GetScope3ByRouteParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScope3ByRoute>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetScope3ByRouteQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScope3ByRoute>>
  > = ({ signal }) => getScope3ByRoute(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getScope3ByRoute>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * DynamoDBに登録してあるバイナリデータをPDF形式で返却する
 * @summary 経路一覧PDF取得
 */
export const getPdfRoute = (signal?: AbortSignal) => {
  return customMutator<Blob>({
    url: `/pdf/route`,
    method: "get",
    responseType: "blob",
    signal,
  });
};

export const getGetPdfRouteQueryKey = () => [`/pdf/route`];

export type GetPdfRouteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPdfRoute>>
>;
export type GetPdfRouteQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | GetPdfRoute404
>;

export const useGetPdfRoute = <
  TData = Awaited<ReturnType<typeof getPdfRoute>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetPdfRoute404
  >
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPdfRoute>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPdfRouteQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPdfRoute>>> = ({
    signal,
  }) => getPdfRoute(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPdfRoute>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * サプライヤ企業の工場が生産する製品・規格情報を取得する
 * @summary 製品・規格情報取得
 */
export const getProduct = (
  year: Year,
  factoryId: FactoryId,
  signal?: AbortSignal
) => {
  return customMutator<GetProduct200>({
    url: `/product/${year}/${factoryId}`,
    method: "get",
    signal,
  });
};

export const getGetProductQueryKey = (year: Year, factoryId: FactoryId) => [
  `/product/${year}/${factoryId}`,
];

export type GetProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProduct>>
>;
export type GetProductQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | GetProduct404
>;

export const useGetProduct = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetProduct404
  >
>(
  year: Year,
  factoryId: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProductQueryKey(year, factoryId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({
    signal,
  }) => getProduct(year, factoryId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProduct>>, TError, TData>(
    { queryKey, queryFn, enabled: !!(year && factoryId), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 提出されたユーザ入力を基にフロントエンドでカテゴリ1, 4の値を計算し、本APIを用いてGHG排出量計算結果テーブルに計算結果のレコードを作成・更新する。
サプライヤ企業のみ認可あり。
 * @summary 計算結果登録
 */
export const postCalculation = (postCalculationBody: PostCalculationBody) => {
  return customMutator<void>({
    url: `/calculation`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: postCalculationBody,
  });
};

export type PostCalculationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCalculation>>
>;
export type PostCalculationMutationBody = PostCalculationBody;
export type PostCalculationMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PostCalculation404
  | PostCalculation412
>;

export const usePostCalculation = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PostCalculation404
    | PostCalculation412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCalculation>>,
    TError,
    { data: PostCalculationBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCalculation>>,
    { data: PostCalculationBody }
  > = (props) => {
    const { data } = props ?? {};

    return postCalculation(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCalculation>>,
    TError,
    { data: PostCalculationBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * サプライヤ企業のみ認可あり。

ユーザ入力の提出が取り消された場合に、 本APIを用いて以下テーブルの当該計算結果レコードを削除する。

・認証済ユーザのrole：paper-supplier かつ クエリパラメータのintensityCategory：category1
　製紙工場カテゴリ1GHG算出結果

・認証済ユーザのrole：process-supplier かつ クエリパラメータのintensityCategory：category1
　加工工場カテゴリ1GHG算出結果

・クエリパラメータのintensityCategory：category4
　カテゴリ4GHG算出結果
 * @summary 計算結果削除
 */
export const deleteCalculation = (
  year: Year,
  factoryId: FactoryId,
  params: DeleteCalculationParams
) => {
  return customMutator<void>({
    url: `/calculation/${year}/${factoryId}`,
    method: "delete",
    params,
  });
};

export type DeleteCalculationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCalculation>>
>;

export type DeleteCalculationMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | DeleteCalculation404
  | DeleteCalculation409
  | DeleteCalculation412
>;

export const useDeleteCalculation = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | DeleteCalculation404
    | DeleteCalculation409
    | DeleteCalculation412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCalculation>>,
    TError,
    { year: Year; factoryId: FactoryId; params: DeleteCalculationParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCalculation>>,
    { year: Year; factoryId: FactoryId; params: DeleteCalculationParams }
  > = (props) => {
    const { year, factoryId, params } = props ?? {};

    return deleteCalculation(year, factoryId, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteCalculation>>,
    TError,
    { year: Year; factoryId: FactoryId; params: DeleteCalculationParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * ユーザ入力の提出が取り消された場合に、 本APIを用いてGHG排出量計算結果テーブルの当該計算結果レコードを削除する。
サプライヤ企業のみ認可あり。
 * @summary 計算結果削除
 */
export const oldDeleteCalculation = (year: Year, factoryId: FactoryId) => {
  return customMutator<void>({
    url: `/old/calculation/${year}/${factoryId}`,
    method: "delete",
  });
};

export type OldDeleteCalculationMutationResult = NonNullable<
  Awaited<ReturnType<typeof oldDeleteCalculation>>
>;

export type OldDeleteCalculationMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | OldDeleteCalculation404
  | OldDeleteCalculation412
>;

export const useOldDeleteCalculation = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | OldDeleteCalculation404
    | OldDeleteCalculation412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oldDeleteCalculation>>,
    TError,
    { year: Year; factoryId: FactoryId },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof oldDeleteCalculation>>,
    { year: Year; factoryId: FactoryId }
  > = (props) => {
    const { year, factoryId } = props ?? {};

    return oldDeleteCalculation(year, factoryId);
  };

  return useMutation<
    Awaited<ReturnType<typeof oldDeleteCalculation>>,
    TError,
    { year: Year; factoryId: FactoryId },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * GHG入力依頼に対する工場の情報を取得する。
ユーザが所属する企業の工場についてのみ情報を取得できる。
 * @summary GHG入力依頼詳細取得
 */
export const getInputRequests = (
  year: Year,
  factoryId: FactoryId,
  signal?: AbortSignal
) => {
  return customMutator<GetInputRequests200>({
    url: `/input-requests/${year}/${factoryId}`,
    method: "get",
    signal,
  });
};

export const getGetInputRequestsQueryKey = (
  year: Year,
  factoryId: FactoryId
) => [`/input-requests/${year}/${factoryId}`];

export type GetInputRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInputRequests>>
>;
export type GetInputRequestsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | GetInputRequests404
>;

export const useGetInputRequests = <
  TData = Awaited<ReturnType<typeof getInputRequests>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetInputRequests404
  >
>(
  year: Year,
  factoryId: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInputRequests>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInputRequestsQueryKey(year, factoryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInputRequests>>
  > = ({ signal }) => getInputRequests(year, factoryId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getInputRequests>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(year && factoryId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * GHG入力依頼に対する工場の情報を取得する。
ユーザが所属する企業の工場についてのみ情報を取得できる。
 * @summary GHG入力依頼詳細取得
 */
export const oldGetInputRequests = (
  year: Year,
  factoryId: FactoryId,
  signal?: AbortSignal
) => {
  return customMutator<OldGetInputRequests200>({
    url: `/old/input-requests/${year}/${factoryId}`,
    method: "get",
    signal,
  });
};

export const getOldGetInputRequestsQueryKey = (
  year: Year,
  factoryId: FactoryId
) => [`/old/input-requests/${year}/${factoryId}`];

export type OldGetInputRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof oldGetInputRequests>>
>;
export type OldGetInputRequestsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | OldGetInputRequests404
>;

export const useOldGetInputRequests = <
  TData = Awaited<ReturnType<typeof oldGetInputRequests>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | OldGetInputRequests404
  >
>(
  year: Year,
  factoryId: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof oldGetInputRequests>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOldGetInputRequestsQueryKey(year, factoryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof oldGetInputRequests>>
  > = ({ signal }) => oldGetInputRequests(year, factoryId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof oldGetInputRequests>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(year && factoryId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * CO2排出係数を一覧取得する
 * @summary CO2排出係数一覧
 */
export const getEmissionFactor = (year: Year, signal?: AbortSignal) => {
  return customMutator<GetEmissionFactor200>({
    url: `/emission-factor/${year}`,
    method: "get",
    signal,
  });
};

export const getGetEmissionFactorQueryKey = (year: Year) => [
  `/emission-factor/${year}`,
];

export type GetEmissionFactorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmissionFactor>>
>;
export type GetEmissionFactorQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetEmissionFactor = <
  TData = Awaited<ReturnType<typeof getEmissionFactor>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  year: Year,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEmissionFactor>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmissionFactorQueryKey(year);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEmissionFactor>>
  > = ({ signal }) => getEmissionFactor(year, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getEmissionFactor>>,
    TError,
    TData
  >({ queryKey, queryFn, enabled: !!year, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 指定した工場のマスタ情報を取得する。
ユーザが所属する企業の工場についてのみ情報を取得できる。
 * @summary 工場情報取得
 */
export const getFactory = (factoryId: FactoryId, signal?: AbortSignal) => {
  return customMutator<GetFactory200>({
    url: `/factory/${factoryId}`,
    method: "get",
    signal,
  });
};

export const getGetFactoryQueryKey = (factoryId: FactoryId) => [
  `/factory/${factoryId}`,
];

export type GetFactoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFactory>>
>;
export type GetFactoryQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | GetFactory404
>;

export const useGetFactory = <
  TData = Awaited<ReturnType<typeof getFactory>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | GetFactory404
  >
>(
  factoryId: FactoryId,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFactory>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFactoryQueryKey(factoryId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFactory>>> = ({
    signal,
  }) => getFactory(factoryId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFactory>>, TError, TData>(
    { queryKey, queryFn, enabled: !!factoryId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 提出されたユーザ入力を基にフロントエンドで値を計算し、本APIを用いて製紙工場カテゴリ1GHG算出結果テーブルに計算結果のレコードを作成・更新する。

サプライヤ企業のみ認可あり。
 * @summary 製紙工場計算結果登録（カテゴリ１）
 */
export const postCalculationCategory1Paper = (
  postCalculationCategory1PaperBody: PostCalculationCategory1PaperBody,
  params: PostCalculationCategory1PaperParams
) => {
  return customMutator<void>({
    url: `/calculation/category1/paper`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: postCalculationCategory1PaperBody,
    params,
  });
};

export type PostCalculationCategory1PaperMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCalculationCategory1Paper>>
>;
export type PostCalculationCategory1PaperMutationBody =
  PostCalculationCategory1PaperBody;
export type PostCalculationCategory1PaperMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PostCalculationCategory1Paper404
  | PostCalculationCategory1Paper409
  | PostCalculationCategory1Paper412
>;

export const usePostCalculationCategory1Paper = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PostCalculationCategory1Paper404
    | PostCalculationCategory1Paper409
    | PostCalculationCategory1Paper412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCalculationCategory1Paper>>,
    TError,
    {
      data: PostCalculationCategory1PaperBody;
      params: PostCalculationCategory1PaperParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCalculationCategory1Paper>>,
    {
      data: PostCalculationCategory1PaperBody;
      params: PostCalculationCategory1PaperParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return postCalculationCategory1Paper(data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCalculationCategory1Paper>>,
    TError,
    {
      data: PostCalculationCategory1PaperBody;
      params: PostCalculationCategory1PaperParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * 提出されたユーザ入力を基にフロントエンドで値を計算し、本APIを用いて加工工場カテゴリ1GHG算出結果テーブルに計算結果のレコードを作成・更新する。

サプライヤ企業のみ認可あり。
 * @summary 加工工場計算結果登録（カテゴリ１）
 */
export const postCalculationCategory1Process = (
  postCalculationCategory1ProcessBody: PostCalculationCategory1ProcessBody,
  params: PostCalculationCategory1ProcessParams
) => {
  return customMutator<void>({
    url: `/calculation/category1/process`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: postCalculationCategory1ProcessBody,
    params,
  });
};

export type PostCalculationCategory1ProcessMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCalculationCategory1Process>>
>;
export type PostCalculationCategory1ProcessMutationBody =
  PostCalculationCategory1ProcessBody;
export type PostCalculationCategory1ProcessMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PostCalculationCategory1Process404
  | PostCalculationCategory1Process409
  | PostCalculationCategory1Process412
>;

export const usePostCalculationCategory1Process = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PostCalculationCategory1Process404
    | PostCalculationCategory1Process409
    | PostCalculationCategory1Process412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCalculationCategory1Process>>,
    TError,
    {
      data: PostCalculationCategory1ProcessBody;
      params: PostCalculationCategory1ProcessParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCalculationCategory1Process>>,
    {
      data: PostCalculationCategory1ProcessBody;
      params: PostCalculationCategory1ProcessParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return postCalculationCategory1Process(data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCalculationCategory1Process>>,
    TError,
    {
      data: PostCalculationCategory1ProcessBody;
      params: PostCalculationCategory1ProcessParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * 提出されたユーザ入力を基にフロントエンドで値を計算し、本APIを用いてカテゴリ4GHG算出結果テーブルに計算結果のレコードを作成・更新する。

サプライヤ企業のみ認可あり。
 * @summary 計算結果登録（カテゴリ４）
 */
export const postCalculationCategory4 = (
  postCalculationCategory4Body: PostCalculationCategory4Body,
  params: PostCalculationCategory4Params
) => {
  return customMutator<void>({
    url: `/calculation/category4`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: postCalculationCategory4Body,
    params,
  });
};

export type PostCalculationCategory4MutationResult = NonNullable<
  Awaited<ReturnType<typeof postCalculationCategory4>>
>;
export type PostCalculationCategory4MutationBody = PostCalculationCategory4Body;
export type PostCalculationCategory4MutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PostCalculationCategory4404
  | PostCalculationCategory4409
  | PostCalculationCategory4412
>;

export const usePostCalculationCategory4 = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PostCalculationCategory4404
    | PostCalculationCategory4409
    | PostCalculationCategory4412
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCalculationCategory4>>,
    TError,
    {
      data: PostCalculationCategory4Body;
      params: PostCalculationCategory4Params;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCalculationCategory4>>,
    {
      data: PostCalculationCategory4Body;
      params: PostCalculationCategory4Params;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return postCalculationCategory4(data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCalculationCategory4>>,
    TError,
    {
      data: PostCalculationCategory4Body;
      params: PostCalculationCategory4Params;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * 原単位を一覧取得する
 * @summary 原単位一覧
 */
export const getIntensity = (year: Year, signal?: AbortSignal) => {
  return customMutator<GetIntensity200>({
    url: `/intensity/${year}`,
    method: "get",
    signal,
  });
};

export const getGetIntensityQueryKey = (year: Year) => [`/intensity/${year}`];

export type GetIntensityQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntensity>>
>;
export type GetIntensityQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetIntensity = <
  TData = Awaited<ReturnType<typeof getIntensity>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  year: Year,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIntensity>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntensityQueryKey(year);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntensity>>> = ({
    signal,
  }) => getIntensity(year, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getIntensity>>,
    TError,
    TData
  >({ queryKey, queryFn, enabled: !!year, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 経路毎の調達量を一時保存する。
ユーザ企業のみ認可あり。
 * @summary 調達量一時保存
 */
export const putTempProcurements = (
  putTempProcurementsBody: PutTempProcurementsBody
) => {
  return customMutator<PutTempProcurements200>({
    url: `/temp/procurements`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: putTempProcurementsBody,
  });
};

export type PutTempProcurementsMutationResult = NonNullable<
  Awaited<ReturnType<typeof putTempProcurements>>
>;
export type PutTempProcurementsMutationBody = PutTempProcurementsBody;
export type PutTempProcurementsMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | PutTempProcurements404
  | PutTempProcurements409
>;

export const usePutTempProcurements = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | PutTempProcurements404
    | PutTempProcurements409
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putTempProcurements>>,
    TError,
    { data: PutTempProcurementsBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putTempProcurements>>,
    { data: PutTempProcurementsBody }
  > = (props) => {
    const { data } = props ?? {};

    return putTempProcurements(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof putTempProcurements>>,
    TError,
    { data: PutTempProcurementsBody },
    TContext
  >(mutationFn, mutationOptions);
};

export const getGetTemporaryStorageMock = () => ({
  key: faker.random.word(),
  value: faker.random.word(),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
});

export const getPutTemporaryStorageMock = () => ({
  key: faker.random.word(),
  value: faker.random.word(),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
});

export const getOldGetInputRequestsYearMock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  requests: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    expirationTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
    beginTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
    submissionTime: faker.helpers.arrayElement([
      `${faker.date.past().toISOString().split(".")[0]}Z`,
      undefined,
    ]),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
    status: faker.helpers.arrayElement(Object.values(InputStatus)),
  })),
});

export const getGetInputRequestsYearMock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  expirationTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  beginTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  requests: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    status: faker.helpers.arrayElement(Object.values(InputStatus)),
    category1: {
      submissionTime: faker.helpers.arrayElement([
        `${faker.date.past().toISOString().split(".")[0]}Z`,
        undefined,
      ]),
      intensityType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(IntensityType)),
        undefined,
      ]),
    },
    category4: {
      submissionTime: faker.helpers.arrayElement([
        `${faker.date.past().toISOString().split(".")[0]}Z`,
        undefined,
      ]),
      intensityType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(IntensityType)),
        undefined,
      ]),
    },
  })),
});

export const getGetDeliveryMock = () => ({
  from: faker.random.word(),
  to: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    landDistance: faker.datatype.number({ min: 0, max: 100000 }),
    seaDistance: faker.datatype.number({ min: 0, max: 100000 }),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
  })),
});

export const getGetCategory1Mock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  factories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    byProduct: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      productType: faker.random.word(),
      productName: faker.random.word(),
      productShortName: faker.random.word(),
      sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
      co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
      co2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
    byClassification: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      classificationType: faker.helpers.arrayElement(
        Object.values(ClassificationType)
      ),
      co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
      co2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
  })),
  nationalAverage: faker.helpers.arrayElement([
    {
      byProduct: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
        co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
        co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
      })),
      byClassification: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        classificationType: faker.helpers.arrayElement(
          Object.values(ClassificationType)
        ),
        co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
        co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
      })),
    },
    undefined,
  ]),
});

export const getGetPaperFactoryCategory1Mock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  factories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    byProduct: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      productType: faker.random.word(),
      productName: faker.random.word(),
      productShortName: faker.random.word(),
      sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
      co2Emission: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
      weightBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
      amountBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
    byClassification: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      classificationType: faker.helpers.arrayElement(
        Object.values(ClassificationType)
      ),
      co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
      weightBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
      amountBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
    intensityType: faker.helpers.arrayElement(Object.values(IntensityType)),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
    deleteFlag: faker.datatype.boolean(),
  })),
});

export const getGetProcessFactoryCategory1Mock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  factories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    co2Emission: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    weightBasedCo2Intensity: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    amountBasedCo2Intensity: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    byClassification: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      classificationType: faker.helpers.arrayElement(
        Object.values(ClassificationType)
      ),
      co2Emission: faker.datatype.number({ min: undefined, max: undefined }),
      weightBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
      amountBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
    intensityType: faker.helpers.arrayElement(Object.values(IntensityType)),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
    deleteFlag: faker.datatype.boolean(),
  })),
});

export const getGetProcurementMock = () => ({
  year: faker.random.word(),
  factoryId: faker.random.word(),
  routes: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    routeId: faker.random.word(),
    destinationId: faker.random.word(),
    productType: faker.random.word(),
    weightBySupplierFactory: faker.datatype.number({ min: 0, max: 10000000 }),
  })),
});

export const getGetProcurementsMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  isTemporary: faker.datatype.boolean(),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
  userFactories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
    routes: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      routeId: faker.random.word(),
      routeName: faker.random.word(),
      productType: faker.random.word(),
      productName: faker.random.word(),
      productShortName: faker.random.word(),
      amount: faker.helpers.arrayElement([
        faker.datatype.number({ min: 0, max: 10000000 }),
        undefined,
      ]),
      supplierFactories: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        companyId: faker.helpers.arrayElement([faker.random.word(), undefined]),
        companyName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForSelf: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForOthers: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        productPurpose: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(ProductPurpose)),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getPutProcurementsMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
  userFactories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
    routes: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      routeId: faker.random.word(),
      routeName: faker.random.word(),
      productType: faker.random.word(),
      productName: faker.random.word(),
      productShortName: faker.random.word(),
      amount: faker.helpers.arrayElement([
        faker.datatype.number({ min: 0, max: 10000000 }),
        undefined,
      ]),
      supplierFactories: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        companyId: faker.helpers.arrayElement([faker.random.word(), undefined]),
        companyName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForSelf: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForOthers: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        productPurpose: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(ProductPurpose)),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getOldGetScope3ByStandardMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  bySupplier: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    companyId: faker.random.word(),
    companyName: faker.random.word(),
    companyType: faker.helpers.arrayElement(Object.values(SupplierCompanyType)),
    bySupplierFactory: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      factoryId: faker.random.word(),
      factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
      factoryName: faker.random.word(),
      factoryNameForSelf: faker.random.word(),
      factoryNameForOthers: faker.random.word(),
      byStandard: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        standardType: faker.random.word(),
        standardName: faker.random.word(),
        standardShortName: faker.random.word(),
        category1Co2Emission: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category4Co2Emission: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getGetScope3ByStandardMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  byStandard: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    standardType: faker.random.word(),
    standardName: faker.random.word(),
    standardShortName: faker.random.word(),
    routes: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      routeId: faker.random.word(),
      routeName: faker.random.word(),
      procurementAmount: faker.datatype.number({ min: 0, max: 10000000 }),
      supplierFactories: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        companyId: faker.helpers.arrayElement([faker.random.word(), undefined]),
        companyName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        companyType: faker.helpers.arrayElement(
          Object.values(SupplierCompanyType)
        ),
        supplierFactoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForSelf: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForOthers: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        productPurpose: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(ProductPurpose)),
          undefined,
        ]),
        category1Co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category4Co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category1Co2IntensityType: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(IntensityType)),
          undefined,
        ]),
        category4Co2IntensityType: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(IntensityType)),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getGetScope3ByFactoryMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  bySupplier: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    companyId: faker.random.word(),
    companyName: faker.random.word(),
    companyType: faker.helpers.arrayElement(Object.values(SupplierCompanyType)),
    bySupplierFactory: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      factoryId: faker.random.word(),
      factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
      factoryName: faker.random.word(),
      factoryNameForSelf: faker.random.word(),
      factoryNameForOthers: faker.random.word(),
      byUserFactory: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        factoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.random.word(),
        factoryNameForSelf: faker.random.word(),
        factoryNameForOthers: faker.random.word(),
        category1Co2Emission: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category4Co2Emission: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getGetScope3ByRouteMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  userFactories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    routes: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      routeId: faker.random.word(),
      routeName: faker.random.word(),
      standardType: faker.random.word(),
      standardName: faker.random.word(),
      standardShortName: faker.random.word(),
      supplierFactories: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        companyId: faker.helpers.arrayElement([faker.random.word(), undefined]),
        companyName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForSelf: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForOthers: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        productPurpose: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(ProductPurpose)),
          undefined,
        ]),
        category1Co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category1Co2IntensityType: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(IntensityType)),
          undefined,
        ]),
        category4Co2Intensity: faker.helpers.arrayElement([
          faker.datatype.number({ min: undefined, max: undefined }),
          undefined,
        ]),
        category4Co2IntensityType: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(IntensityType)),
          undefined,
        ]),
        procurementAmount: faker.helpers.arrayElement([
          faker.datatype.number({ min: 0, max: 10000000 }),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getGetPdfRouteMock = () => faker.random.word();

export const getGetProductMock = () => ({
  year: faker.random.word(),
  factoryId: faker.random.word(),
  products: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    productType: faker.random.word(),
    productName: faker.random.word(),
    productShortName: faker.random.word(),
    sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
    frontLinearAreaToWeightRatio: faker.helpers.arrayElement([
      faker.datatype.number({ min: 0, max: undefined }),
      undefined,
    ]),
    cardBoardAreaToWeightRatio: faker.helpers.arrayElement([
      faker.datatype.number({ min: 0, max: undefined }),
      undefined,
    ]),
    materialTypes: faker.helpers.arrayElements(Object.values(MaterialType)),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
  })),
});

export const getGetInputRequestsMock = () => ({
  year: faker.random.word(),
  factoryId: faker.random.word(),
  factoryName: faker.random.word(),
  factoryNameForSelf: faker.random.word(),
  factoryNameForOthers: faker.random.word(),
  factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
  expirationTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  beginTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  status: faker.helpers.arrayElement(Object.values(InputStatus)),
  category1: {
    submissionTime: faker.helpers.arrayElement([
      `${faker.date.past().toISOString().split(".")[0]}Z`,
      undefined,
    ]),
    intensityType: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(IntensityType)),
      undefined,
    ]),
  },
  category4: {
    submissionTime: faker.helpers.arrayElement([
      `${faker.date.past().toISOString().split(".")[0]}Z`,
      undefined,
    ]),
    intensityType: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(IntensityType)),
      undefined,
    ]),
  },
});

export const getOldGetInputRequestsMock = () => ({
  year: faker.random.word(),
  factoryId: faker.random.word(),
  factoryName: faker.random.word(),
  factoryNameForSelf: faker.random.word(),
  factoryNameForOthers: faker.random.word(),
  factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
  expirationTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  beginTime: `${faker.date.past().toISOString().split(".")[0]}Z`,
  submissionTime: faker.helpers.arrayElement([
    `${faker.date.past().toISOString().split(".")[0]}Z`,
    undefined,
  ]),
  status: faker.helpers.arrayElement(Object.values(InputStatus)),
});

export const getGetEmissionFactorMock = () => ({
  year: faker.random.word(),
  emissionFactorList: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    target: faker.random.word(),
    co2EmissionFactor: faker.datatype.number({ min: 0, max: undefined }),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
  })),
});

export const getGetFactoryMock = () => ({
  companyId: faker.random.word(),
  companyType: faker.helpers.arrayElement(Object.values(CompanyType)),
  factoryId: faker.random.word(),
  factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
  companyName: faker.random.word(),
  factoryName: faker.random.word(),
  shortNameForSelf: faker.random.word(),
  shortNameForOthers: faker.random.word(),
  defaultLandDistance: faker.datatype.number({ min: 0, max: 100000 }),
  defaultSeaDistance: faker.datatype.number({ min: 0, max: 100000 }),
  sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
});

export const getGetIntensityMock = () => ({
  year: faker.random.word(),
  intensityList: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    intensityCategory: faker.helpers.arrayElement(
      Object.values(IntensityCategory)
    ),
    productPurpose: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(ProductPurpose)),
      undefined,
    ]),
    intensityType: faker.helpers.arrayElement(Object.values(IntensityType)),
    intensity: faker.datatype.number({ min: undefined, max: undefined }),
  })),
});

export const getPutTempProcurementsMock = () => ({
  year: faker.random.word(),
  companyId: faker.random.word(),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
  userFactories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    sortOrder: faker.datatype.number({ min: 0, max: 1000000000 }),
    routes: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      routeId: faker.random.word(),
      routeName: faker.random.word(),
      productType: faker.random.word(),
      productName: faker.random.word(),
      productShortName: faker.random.word(),
      amount: faker.helpers.arrayElement([
        faker.datatype.number({ min: 0, max: 10000000 }),
        undefined,
      ]),
      supplierFactories: Array.from(
        { length: faker.datatype.number({ min: 1, max: 10 }) },
        (_, i) => i + 1
      ).map(() => ({
        companyId: faker.helpers.arrayElement([faker.random.word(), undefined]),
        companyName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryId: faker.random.word(),
        factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
        factoryName: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForSelf: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        factoryNameForOthers: faker.helpers.arrayElement([
          faker.random.word(),
          undefined,
        ]),
        productType: faker.random.word(),
        productName: faker.random.word(),
        productShortName: faker.random.word(),
        productPurpose: faker.helpers.arrayElement([
          faker.helpers.arrayElement(Object.values(ProductPurpose)),
          undefined,
        ]),
      })),
    })),
  })),
});

export const getDefaultMSW = () => [
  rest.get("*/temporary-storage", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetTemporaryStorageMock())
    );
  }),
  rest.put("*/temporary-storage", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getPutTemporaryStorageMock())
    );
  }),
  rest.get("*/old/input-requests/:year", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getOldGetInputRequestsYearMock())
    );
  }),
  rest.get("*/input-requests/:year", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetInputRequestsYearMock())
    );
  }),
  rest.get("*/delivery/:from", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetDeliveryMock())
    );
  }),
  rest.get("*/category1", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetCategory1Mock())
    );
  }),
  rest.get("*/category1/paper", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetPaperFactoryCategory1Mock())
    );
  }),
  rest.get("*/category1/process", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetProcessFactoryCategory1Mock())
    );
  }),
  rest.get("*/procurement-by-supplier/:year/:factoryId", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetProcurementMock())
    );
  }),
  rest.get("*/procurements", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetProcurementsMock())
    );
  }),
  rest.put("*/procurements", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getPutProcurementsMock())
    );
  }),
  rest.get("*/old/scope3/by-standard", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getOldGetScope3ByStandardMock())
    );
  }),
  rest.get("*/scope3/by-standard", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetScope3ByStandardMock())
    );
  }),
  rest.get("*/scope3/by-factory", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetScope3ByFactoryMock())
    );
  }),
  rest.get("*/scope3/by-route", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetScope3ByRouteMock())
    );
  }),
  rest.get("*/pdf/route", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetPdfRouteMock())
    );
  }),
  rest.get("*/product/:year/:factoryId", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetProductMock())
    );
  }),
  rest.post("*/calculation", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.delete("*/calculation/:year/:factoryId", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.delete("*/old/calculation/:year/:factoryId", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.get("*/input-requests/:year/:factoryId", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetInputRequestsMock())
    );
  }),
  rest.get("*/old/input-requests/:year/:factoryId", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getOldGetInputRequestsMock())
    );
  }),
  rest.get("*/emission-factor/:year", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetEmissionFactorMock())
    );
  }),
  rest.get("*/factory/:factoryId", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetFactoryMock())
    );
  }),
  rest.post("*/calculation/category1/paper", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.post("*/calculation/category1/process", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.post("*/calculation/category4", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
  rest.get("*/intensity/:year", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetIntensityMock())
    );
  }),
  rest.put("*/temp/procurements", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getPutTempProcurementsMock())
    );
  }),
];
