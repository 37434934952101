import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { EChartsOption } from "echarts";
import React, { useMemo } from "react";

import { AppTheme } from "src/app/configs";
import { FactoriesForTotalIntensity } from "src/app/models";
import { formatIntensity } from "src/app/utils/computeForVisuals";
import { Echarts } from "src/lib/components/atoms";
import { useAspectRatio } from "src/lib/hooks/useAspectRatio";

const useStyles = makeStyles(() => ({
  chart: {
    width: "100%",
    height: "100%",
  },
}));

export type Category1IntensityByProductChartProps = {
  data: FactoriesForTotalIntensity[];
  unit: string;
};

const defaultBarColor = "#C5DEB9";

export const Category1IntensityByProductChart: React.FC<
  Category1IntensityByProductChartProps
> = (props) => {
  const theme = useTheme<AppTheme>();
  const chartOption = useMemo<EChartsOption>(() => {
    const option: EChartsOption = {
      textStyle: {
        fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
        fontSize: theme.typography.body2.fontSize,
      },
      grid: {
        // グラフの周りに軸ラベルのための領域を確保
        top: 40,
        left: 0,
        right: 0,
        bottom: 8,
        containLabel: true,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: "category",
        data: props.data.map((d) => {
          return {
            value: d.factoryName,
            textStyle: {
              fontSize: theme.typography.body2.fontSize,
            },
          };
        }),
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          margin: 16,
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        name: `(${props.unit})`,
        type: "value",
        axisLabel: {
          formatter: (value: number) => {
            return value.toLocaleString();
          },
          fontSize: theme.typography.body2.fontSize,
        },
        nameTextStyle: {
          align: "left",
          padding: parseInt(theme.spacing(3)),
        },
      },
      series: {
        type: "bar",
        data: props.data.map((d) => {
          return {
            value: d.total,
            itemStyle: { color: defaultBarColor },
            label: {
              show: true,
              textStyle: { fontWeight: "normal" },
              position: "top",
              formatter: (data) => {
                return formatIntensity(data.value as number);
              },
            },
          };
        }),
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
      barMaxWidth: 32,
    };
    return option;
  }, [props, theme]);

  const classes = useStyles();
  const ref = useAspectRatio(2);
  return (
    <Box width="100%" {...{ ref }}>
      <Echarts className={classes.chart} option={chartOption} />
    </Box>
  );
};
