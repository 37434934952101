import { DateTime } from "luxon";

import {
  getEmissionFactor,
  GetEmissionFactorQueryError,
  GetEmissionFactorQueryResult,
} from "src/app/apis/client/default";
import { ToStringYearFromDateTime } from "src/app/utils/api-if-converter/api-if-converter";
import { ApiError } from "src/lib/apis";

export type GetEmissionFactorRequest = {
  year: DateTime;
};
export class GetEmissionFactorError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}
export class EmissionFactorApi {
  async get(
    req: GetEmissionFactorRequest
  ): Promise<GetEmissionFactorQueryResult> {
    try {
      const res = await getEmissionFactor(ToStringYearFromDateTime(req.year));

      return {
        year: res.year,
        emissionFactorList: res.emissionFactorList,
      };
    } catch (err) {
      const { errorCode } = err as GetEmissionFactorQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetEmissionFactorError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetEmissionFactorError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetEmissionFactorError("not-authenticated", err);
      }
    }
  }
}
