import { AddRounded } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import produce from "immer";
import React, { Dispatch, SetStateAction, useCallback } from "react";

import { GetProduct200ProductsItem, CompanyType } from "src/app/apis/model";
import { AppCard, Annotate, CheckboxText } from "src/app/components/atoms";
import {
  RowTextField,
  SectionHeading,
  SubSectionHeading,
} from "src/app/components/molecules";
import {
  ManufacturingInput,
  MachineInput,
  SaveAndRegisterButton,
  SaveAndRegisterButtonProps,
} from "src/app/components/organisms";
import {
  GhgInputCategory1Form,
  initMachineForm,
  initManufacturingForm,
  ManufacturingForm,
} from "src/app/components/pages";
import { messages } from "src/app/configs";
import { MaterialCo2EmissionFactor, ProductionAmount } from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { Button, FlexBox } from "src/lib/components/atoms";

export type GhgManufacturingInputTemplateProps = {
  manufacturing: ManufacturingForm;
  products: GetProduct200ProductsItem[];
  manufacturingProducts: readonly string[];
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  isEditable: boolean;
  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];
  companyType: Exclude<CompanyType, "user-company">;
};

export const GhgManufacturingInputTemplate: React.FC<
  GhgManufacturingInputTemplateProps
> = (props) => {
  const { setForm } = props;
  const onClickAddMachine = useCallback(
    () =>
      setForm(
        produce((form) => {
          form.manufacturing.machines.push(
            initMachineForm(props.manufacturingProducts)
          );
        })
      ),
    [props.manufacturingProducts, setForm]
  );
  const addMachineButtonProps = {
    onClick: onClickAddMachine,
    disabled: !props.isEditable,
  };

  const onClickEmissionCheckbox = (
    item:
      | "usedPaper"
      | "paperChip"
      | "internalVirginPulp"
      | "externalVirginPulp"
  ) => {
    return () =>
      setForm(
        produce<GhgInputCategory1Form>((form) => {
          switch (item) {
            case "usedPaper":
              form.manufacturing.materialCo2EmissionFactor[item] = {
                ...initManufacturingForm().materialCo2EmissionFactor[item],
                isChecked:
                  !form.manufacturing.materialCo2EmissionFactor[item].isChecked,
              };
              break;
            case "paperChip":
              form.manufacturing.materialCo2EmissionFactor[item] = {
                ...initManufacturingForm().materialCo2EmissionFactor[item],
                isChecked:
                  !form.manufacturing.materialCo2EmissionFactor[item].isChecked,
              };
              break;
            case "internalVirginPulp":
              form.manufacturing.materialCo2EmissionFactor[item] = {
                ...initManufacturingForm().materialCo2EmissionFactor[item],
                isChecked:
                  !form.manufacturing.materialCo2EmissionFactor[item].isChecked,
              };
              break;
            case "externalVirginPulp":
              form.manufacturing.materialCo2EmissionFactor[item] = {
                ...initManufacturingForm().materialCo2EmissionFactor[item],
                isChecked:
                  !form.manufacturing.materialCo2EmissionFactor[item].isChecked,
              };
              break;
            default:
              throw exhaustiveSwitchCase(item);
          }
        })
      );
  };

  const bindTextField = useBindTextField(
    props.manufacturing,
    (materialAction) =>
      setForm(
        produce<GhgInputCategory1Form>((form) => {
          if (materialAction instanceof Function) {
            form.manufacturing = materialAction(form.manufacturing);
          } else {
            form.manufacturing = materialAction;
          }
        })
      )
  );

  return (
    <AppCard>
      <SaveAndRegisterButton
        justifyContent="flex-end"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />

      {props.companyType === "paper-supplier" && (
        <Box>
          <SectionHeading
            title="排出原単位"
            subtitle={
              <Annotate>
                自社で排出係数を把握している場合は値の入力を、それ以外の場合は「デフォルト値を利用」にチェックを入れてください。
                <br />
                デフォルト値はIDEAに基づいた値を利用しています。
              </Annotate>
            }
          >
            <SubSectionHeading
              title="古紙調達"
              subtitle={
                <Annotate>
                  マテリアルとしての古紙の環境負荷は０としています。ヤードから製紙工場までの古紙輸送に伴う環境負荷についてご入力ください。
                </Annotate>
              }
            >
              <Grid container>
                <Grid item xs={12} lg={5}>
                  <RowTextField
                    label="古紙 [t-CO2eq/t]"
                    {...bindTextField({
                      selector: (form) =>
                        form.materialCo2EmissionFactor.usedPaper.field,
                      validator: MaterialCo2EmissionFactor.Rule,
                      isRequired: true,
                    })}
                    maxLength={MaterialCo2EmissionFactor.MaxLength}
                    errorMessage={MaterialCo2EmissionFactor.Message}
                    inputMode="numeric"
                    disabled={
                      !props.isEditable ||
                      props.manufacturing.materialCo2EmissionFactor.usedPaper
                        .isChecked
                    }
                    extraElement={
                      <CheckboxText
                        label="デフォルト値を利用"
                        isChecked={
                          props.manufacturing.materialCo2EmissionFactor
                            .usedPaper.isChecked
                        }
                        onClick={onClickEmissionCheckbox("usedPaper")}
                        disabled={!props.isEditable}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </SubSectionHeading>
            <SubSectionHeading
              title="パルプ自社製造"
              subtitle={
                <Annotate>
                  該当しないものに関しては「0」でご入力をお願いします。
                </Annotate>
              }
            >
              <Grid container>
                <Grid item xs={12} lg={5}>
                  <RowTextField
                    label={
                      <span>
                        製紙チップ [t-CO2eq/BDT]
                        <br />
                        (植林～チップ製造・輸送)
                      </span>
                    }
                    {...bindTextField({
                      selector: (form) =>
                        form.materialCo2EmissionFactor.paperChip.field,
                      validator: MaterialCo2EmissionFactor.Rule,
                      isRequired: true,
                    })}
                    maxLength={MaterialCo2EmissionFactor.MaxLength}
                    errorMessage={MaterialCo2EmissionFactor.Message}
                    inputMode="numeric"
                    disabled={
                      !props.isEditable ||
                      props.manufacturing.materialCo2EmissionFactor.paperChip
                        .isChecked
                    }
                    extraElement={
                      <CheckboxText
                        label="デフォルト値を利用"
                        isChecked={
                          props.manufacturing.materialCo2EmissionFactor
                            .paperChip.isChecked
                        }
                        onClick={onClickEmissionCheckbox("paperChip")}
                        disabled={!props.isEditable}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={12} lg={5}>
                  <RowTextField
                    label={
                      <span>
                        パルプ製造工程 [t-CO2eq/t]
                        <br />
                        (蒸解～漂白・スクリーン)
                      </span>
                    }
                    {...bindTextField({
                      selector: (form) =>
                        form.materialCo2EmissionFactor.internalVirginPulp.field,
                      validator: MaterialCo2EmissionFactor.Rule,
                      isRequired: true,
                    })}
                    maxLength={MaterialCo2EmissionFactor.MaxLength}
                    errorMessage={MaterialCo2EmissionFactor.Message}
                    inputMode="numeric"
                    disabled={
                      !props.isEditable ||
                      props.manufacturing.materialCo2EmissionFactor
                        .internalVirginPulp.isChecked
                    }
                    extraElement={
                      <CheckboxText
                        label="デフォルト値を利用"
                        isChecked={
                          props.manufacturing.materialCo2EmissionFactor
                            .internalVirginPulp.isChecked
                        }
                        onClick={onClickEmissionCheckbox("internalVirginPulp")}
                        disabled={!props.isEditable}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </SubSectionHeading>
            <SubSectionHeading
              title="パルプ外部調達"
              subtitle={
                <Annotate>
                  該当しないものに関しては「0」でご入力をお願いします。
                </Annotate>
              }
              flex={1}
            >
              <Grid container>
                <Grid item xs={12} lg={5}>
                  <RowTextField
                    label={
                      <span>
                        バージンパルプ [t-CO2eq/t]
                        <br />
                        (植林～パルプ製造)
                      </span>
                    }
                    {...bindTextField({
                      selector: (form) =>
                        form.materialCo2EmissionFactor.externalVirginPulp.field,
                      validator: MaterialCo2EmissionFactor.Rule,
                      isRequired: true,
                    })}
                    maxLength={MaterialCo2EmissionFactor.MaxLength}
                    errorMessage={MaterialCo2EmissionFactor.Message}
                    inputMode="numeric"
                    disabled={
                      !props.isEditable ||
                      props.manufacturing.materialCo2EmissionFactor
                        .externalVirginPulp.isChecked
                    }
                    extraElement={
                      <CheckboxText
                        label="デフォルト値を利用"
                        isChecked={
                          props.manufacturing.materialCo2EmissionFactor
                            .externalVirginPulp.isChecked
                        }
                        onClick={onClickEmissionCheckbox("externalVirginPulp")}
                        disabled={!props.isEditable}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </SubSectionHeading>
          </SectionHeading>
          {props.manufacturing.machines.map((machine, machineIndex) => (
            <MachineInput
              key={machine.key}
              machine={machine}
              machineIndex={machineIndex}
              products={props.products}
              setForm={props.setForm}
              isEditable={props.isEditable}
              isLastMachine={props.manufacturing.machines.length === 1}
            />
          ))}

          {props.manufacturing.machines.length === 0 && (
            <FlexBox
              justifyContent="center"
              alignItems="center"
              minHeight={240}
            >
              <Typography>
                {messages.ghgInput.manufacturing.noMachine}
              </Typography>
            </FlexBox>
          )}

          <FlexBox justifyContent="center" mt={3}>
            {/* 一時保存＋登録ボタンと同じ幅にする記述 */}
            <Box maxWidth={320 + 24} width="100%">
              <Button
                label="マシンを追加"
                variant="outlined"
                color="default"
                startIcon={<AddRounded />}
                {...addMachineButtonProps}
              />
            </Box>
          </FlexBox>
        </Box>
      )}

      {props.companyType === "process-supplier" && (
        <>
          <SectionHeading title="年間総生産量">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <RowTextField
                  label="年間総生産量 [m2/年]"
                  {...bindTextField({
                    validator: ProductionAmount.Rule,
                    selector: (form) => form.amount,
                    isRequired: true,
                  })}
                  maxLength={ProductionAmount.MaxLength}
                  errorMessage={ProductionAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
            </Grid>
          </SectionHeading>

          <ManufacturingInput
            machine={props.manufacturing.machines[0]}
            products={props.products}
            setForm={setForm}
            companyType={props.companyType}
            isEditable={props.isEditable}
          />
        </>
      )}

      <SaveAndRegisterButton
        mt={3}
        justifyContent="center"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />
    </AppCard>
  );
};
