import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { EChartsOption } from "echarts";
import { useMemo } from "react";

import { createTooltipIcon } from ".";

import { AppTheme } from "src/app/configs";
import {
  UserEmissionByFactoryForDisplay,
  UserEmissionByStandardForDisplay,
} from "src/app/models";
import { formatEmission } from "src/app/utils/computeForVisuals";
import { Echarts } from "src/lib/components/atoms";
import { useAspectRatio } from "src/lib/hooks/useAspectRatio";
import { create } from "src/lib/utils/domUtil";

const useStyles = makeStyles(() => ({
  chart: {
    width: "100%",
    height: "100%",
  },
}));

const colors = [
  "#D383C7",
  "#8B7BCB",
  "#708AD3",
  "#70B1D3",
  "#458489",
  "#43A095",
  "#72B265",
  "#F4E074",
  "#F3A261",
  "#E86767",
];

export type UserEmissionChartProps = {
  // FIXME: 引数の種類によって処理が変わる制御結合になっている。コンポーネントを分割した方が良いのでは。
  byStandardData?: UserEmissionByStandardForDisplay[];
  byFactoryData?: UserEmissionByFactoryForDisplay[];
  unit: string;
};

export const UserEmissionChart: React.FC<UserEmissionChartProps> = (props) => {
  const theme = useTheme<AppTheme>();
  const chartOption = useMemo<EChartsOption>(() => {
    const xAxisData = props.byStandardData
      ? [
          ...new Set(
            props.byStandardData?.flatMap((d) => {
              return d.byStandard.map((standard) => {
                return standard.standardShortName;
              });
            })
          ),
        ]
      : [
          ...new Set(
            props.byFactoryData?.flatMap((d) => {
              return d.byUserFactory.map((factory) => {
                return factory.factoryName;
              });
            })
          ),
        ];

    //サプライヤー企業数（棒の種類の数）を取得
    const supplierNumber = props.byStandardData
      ? props.byStandardData.length
      : props.byFactoryData?.length;
    //グラフに表示するX軸アイテムの最大個数を設定
    const displayXItemNumber = (supplierNumber ?? 0) <= 7 ? 10 : 6;
    const option: EChartsOption = {
      textStyle: {
        fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
        fontSize: theme.typography.body2.fontSize,
      },
      grid: {
        // グラフの周りに軸ラベルのための領域を確保
        left: 0,
        right: 0,
        // スクロールバーを表示する際、余白を確保する。
        bottom: xAxisData.length >= displayXItemNumber ? 50 : 8,
        top: 80,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          if (!(params instanceof Array)) {
            throw new Error("params must be array");
          }

          const factoryName = params[0].name;
          const tableContent = create("tbody", () => {});

          for (const it of params) {
            if (it.value !== 0) {
              tableContent.appendChild(
                create("tr", (tr) => {
                  tr.appendChild(
                    createTooltipIcon(
                      it.color === undefined ? "" : it.color.toString()
                    )
                  );
                  tr.appendChild(
                    create("td", (td) => {
                      td.textContent =
                        it.seriesName === undefined ? "" : it.seriesName;
                    })
                  );
                  tr.appendChild(
                    create("td", (td) => {
                      td.style.paddingLeft = "16px";
                      td.style.fontWeight = "bold";
                      td.textContent = formatEmission(it.value as number);
                      td.appendChild(
                        create("span", (span) => {
                          span.style.fontWeight = "normal";
                          span.textContent = ` ${props.unit}`;
                        })
                      );
                    })
                  );
                })
              );
            }
          }

          const root = create("div", (div) => {
            div.appendChild(document.createTextNode(factoryName));
            div.appendChild(create("br", (br) => {}));
            div.appendChild(
              create("table", (table) => {
                table.appendChild(tableContent);
              })
            );
          });

          return [root];
        },
      },
      legend: {
        icon: "circle",
        show: true,
        type: "scroll",
        itemGap: 32,
        left: 0, // 左寄せ
      },
      xAxis: {
        type: "category",
        data: xAxisData,

        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          margin: 16,
          interval: 0,
          rotate: 45,
          fontSize: theme.typography.body2.fontSize,
        },
      },
      yAxis: {
        name: `(${props.unit})`,
        type: "value",
        axisLabel: {
          formatter: (value: number) => {
            return value.toLocaleString();
          },
          fontSize: theme.typography.body2.fontSize,
        },
        nameTextStyle: {
          align: "left",
          padding: parseInt(theme.spacing(3)),
        },
      },
      dataZoom:
        xAxisData.length > displayXItemNumber
          ? [
              {
                type: "slider",
                startValue: 0,
                // 0からスタートするため-1にする
                endValue: displayXItemNumber - 1,
                showDetail: false,
                zoomLock: true,
                height: 15,
                handleSize: 0,
                backgroundColor: "#EEEEEE",
                borderColor: "#EEEEEE",
                fillerColor: "#CCCCCC",
                brushSelect: false,
              },
            ]
          : [],
      series: props.byStandardData
        ? props.byStandardData?.map((it, index) => {
            return {
              type: "bar",
              color: colors[index % colors.length],
              barWidth: 5,
              barGap: "50%",
              name: it.companyName,
              data: xAxisData.map((d) => {
                const standard = it.byStandard.find(
                  (standard) => standard.standardShortName === d
                );
                const value = standard === undefined ? 0 : standard.total;
                return {
                  name: d,
                  value: value,
                };
              }),
            };
          })
        : props.byFactoryData?.map((it, index) => {
            return {
              type: "bar",
              color: colors[index % colors.length],
              barWidth: 5,
              barGap: "100%",
              name: it.companyName,
              data: xAxisData.map((d) => {
                const userFactory = it.byUserFactory.find(
                  (userFactory) => userFactory.factoryName === d
                );
                const value = userFactory === undefined ? 0 : userFactory.total;
                return {
                  name: d,
                  value: value,
                };
              }),
            };
          }),
    };
    return option;
  }, [props.byFactoryData, props.byStandardData, props.unit, theme]);

  const classes = useStyles();
  const ref = useAspectRatio(2);
  return (
    <Box width="100%" {...{ ref }}>
      <Echarts className={classes.chart} option={chartOption} />
    </Box>
  );
};
