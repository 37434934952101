export const ghgInputCategory4TabNames = {
  transport: "transport",
} as const;

export const ghgInputCategory1TabNames = {
  energy: "energy",
  waste: "waste",
  manufacturing: "manufacturing",
} as const;

export const categoryTabNames = {
  totalEmission: "total-emission",
  emissionByClassification: "emission-by-classification",
  intensityByClassification: "intensity-by-classification",
  intensityByProduct: "intensity-by-product",
  emissionByFactory: "emission-by-factory",
  intensityByDestination: "intensity-by-destination",
} as const;

export const userCategoryTabNames = {
  totalEmission: "total-emission",
  emissionByCase: "emission-by-standard",
  emissionByFactory: "emission-by-factory",
} as const;
