/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

/**
 * exceptElectricPowerTransportFuelTypesの親要素
 */
export type TransportFuelType =
  (typeof TransportFuelType)[keyof typeof TransportFuelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransportFuelType = {
  gasoline: "gasoline",
  lightOil: "lightOil",
  cng: "cng",
  cHeavyOil: "cHeavyOil",
  electricPower: "electricPower",
} as const;
