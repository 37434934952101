import {
  GetLocalStorageError,
  GetLocalStorageRequest,
  GetLocalStorageResponse,
  LocalStorageApi,
  SetLocalStorageError,
  SetLocalStorageRequest,
  SetLocalStorageResponse,
} from "./localStorageApi";

import { CookieUtil } from "src/lib/utils/cookieUtil";

export class LocalStorageImpl implements LocalStorageApi {
  async get(req: GetLocalStorageRequest): Promise<GetLocalStorageResponse> {
    try {
      const getLocalStorageResponse: GetLocalStorageResponse = {
        energyInput: undefined,
        wasteInput: undefined,
        manufacturingInput: undefined,
        transportInput: undefined,
      };

      const energy = window.localStorage.getItem(
        `${req.year}/${req.factoryId}/energy`
      );
      if (energy !== null && energy !== "") {
        getLocalStorageResponse.energyInput = JSON.parse(energy);
      }
      const waste = window.localStorage.getItem(
        `${req.year}/${req.factoryId}/waste`
      );
      if (waste !== null && waste !== "") {
        getLocalStorageResponse.wasteInput = JSON.parse(waste);
      }
      const manufacturing = window.localStorage.getItem(
        `${req.year}/${req.factoryId}/manufacturing`
      );
      if (manufacturing !== null && manufacturing !== "") {
        getLocalStorageResponse.manufacturingInput = JSON.parse(manufacturing);
      }
      const transport = window.localStorage.getItem(
        `${req.year}/${req.factoryId}/transport`
      );
      if (transport !== null && transport !== "") {
        getLocalStorageResponse.transportInput = JSON.parse(transport);
      }
      return getLocalStorageResponse;
    } catch (err) {
      if (!(err instanceof DOMException)) {
        throw new GetLocalStorageError("unknown", err);
      }
      if (err.name === "SecurityError" && CookieUtil.isEnabled()) {
        throw new GetLocalStorageError("can-not-use", err);
      }
      if (!CookieUtil.isEnabled()) {
        throw new GetLocalStorageError("cookie-disabled", err);
      }
      throw new GetLocalStorageError("unknown", err);
    }
  }
  async set(req: SetLocalStorageRequest): Promise<SetLocalStorageResponse> {
    try {
      switch (req.targetTab) {
        case "energy":
          window.localStorage.setItem(
            `${req.year}/${req.factoryId}/energy`,
            JSON.stringify(req.energyInput)
          );
          break;
        case "waste":
          window.localStorage.setItem(
            `${req.year}/${req.factoryId}/waste`,
            JSON.stringify(req.wasteInput)
          );
          break;
        case "manufacturing":
          window.localStorage.setItem(
            `${req.year}/${req.factoryId}/manufacturing`,
            JSON.stringify(req.manufacturingInput)
          );
          break;
        case "transport":
          window.localStorage.setItem(
            `${req.year}/${req.factoryId}/transport`,
            JSON.stringify(req.transportInput)
          );
          break;
        default:
      }

      const setLocalStorageResponse: SetLocalStorageResponse = {
        energyInput: req.energyInput,
        wasteInput: req.wasteInput,
        manufacturingInput: req.manufacturingInput,
        transportInput: req.transportInput,
      };
      return setLocalStorageResponse;
    } catch (err) {
      if (!(err instanceof DOMException)) {
        throw new SetLocalStorageError("unknown", err);
      }
      if (err.name === "SecurityError" && CookieUtil.isEnabled()) {
        throw new SetLocalStorageError("can-not-use", err);
      }
      if (!CookieUtil.isEnabled()) {
        throw new SetLocalStorageError("cookie-disabled", err);
      }

      throw new SetLocalStorageError("unknown", err);
    }
  }
}
