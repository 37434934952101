import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme } from "src/app/configs";

export type CheckboxTextProps = {
  label: string;
  isChecked: boolean;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  label: {
    fontSize: "14px",
  },
}));

export const CheckboxText: React.FC<CheckboxTextProps> = (props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      label={<Typography className={classes.label}>{props.label}</Typography>}
      control={
        <Checkbox
          checked={props.isChecked}
          onClick={props.onClick}
          size="small"
          disabled={props.disabled}
          color="secondary"
        />
      }
    />
  );
};
