import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { Annotate } from "src/app/components/atoms";
import {
  ChartAndTableView,
  DataField,
  SingleBarChart,
} from "src/app/components/organisms";
import { TotalEmission } from "src/app/models";

export type Category4EmissionByFactoryTemplateProps = {
  isProcessing: boolean;

  // グラフ用Propsの追加
  chartData: TotalEmission[];

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

export const Category4EmissionByFactoryTemplate: React.FC<
  Category4EmissionByFactoryTemplateProps
> = (props) => {
  return (
    <ChartAndTableView
      title="カテゴリ4　工場別（年）"
      subtitle={
        <Annotate>
          TORCH算定結果のみを表示。自社算定結果を入力した工場情報は表示されません。
        </Annotate>
      }
      chartElement={
        <SingleBarChart
          data={props.chartData}
          unit="(t-CO2eq/年)"
          barColor={["#8678B4", "#47397F"]}
          chartType="normal"
        />
      }
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
        />
      }
      noData={props.chartData.length === 0}
      isProcessing={props.isProcessing}
    />
  );
};
