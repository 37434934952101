/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import { rest } from "msw";

import { FactoryType, IntensityType } from "src/app/apis/model";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InHouseCategory4InputMock = () => [
  ...paperInputRequestsMock,
  // カテゴリ4取得API
  ...getGetCategory4Mock,
  // 計算結果登録（カテゴリ４）API
  ...postCalculationCategory4Mock,
  // 計算結果削除API
  ...deleteCalculationMock,

  /**
   * カテゴリ4取得APIのエラー
   * ケースNo.15~22
   */
  // ...getGetCategory4NetworkMock,
  // ...getGetCategory4TimeoutMock,
  // ...getGetCategory4UnknownMock,
  // ...getGetCategory4ERR000000Mock,
  // ...getGetCategory4ERR000001Mock,
  // ...getGetCategory4ERR000002Mock,
  // ...getGetCategory4ERR000003Mock,
  // ...getGetCategory4ERR000004Mock,

  //
  /**
   * 計算結果登録（カテゴリ４）API
   * ケースNo.23~35
   */
  // ...postCalculationCategory4NetworkMock,
  // ...postCalculationCategory4TimeoutMock,
  // ...postCalculationCategory4UnknownMock,
  // ...postCalculationCategory4ERR000000Mock,
  // ...postCalculationCategory4ERR000001Mock,
  // ...postCalculationCategory4ERR000002Mock,
  // ...postCalculationCategory4ERR000003Mock,
  // ...postCalculationCategory4ERR000004Mock,
  // ...postCalculationCategory4ERR026001Mock,
  // ...postCalculationCategory4ERR026002Mock,
  // ...postCalculationCategory4ERR026003Mock,
  // ...postCalculationCategory4ERR026004Mock,
  // ...postCalculationCategory4ERR026005Mock,

  /**
   * 計算結果削除APIのエラー
   * ケースNo.36~51
   */
  // ...deleteCalculationNetworkMock,
  // ...deleteCalculationTimeoutMock,
  // ...deleteCalculationUnknownMock,
  // ...deleteCalculationERR000000Mock,
  // ...deleteCalculationERR000001Mock,
  // ...deleteCalculationERR000002Mock,
  // ...deleteCalculationERR000003Mock,
  // ...deleteCalculationERR000004Mock,
  // ...deleteCalculationERR020000Mock,
  // ...deleteCalculationERR020001Mock,
  // ...deleteCalculationERR020002Mock,
  // ...deleteCalculationERR020003Mock,
  // ...deleteCalculationERR020004Mock,
  // ...deleteCalculationERR020005Mock,
  // ...deleteCalculationERR020006Mock,
  // ...deleteCalculationERR020007Mock,
];
const getGetCategory4Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: DateTime.fromFormat("2021", "yyyy"),
        factories: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: FactoryType.paper,
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 0,
            byDestination: [
              {
                destinationId: "a",
                weightBasedCo2Intensity: 0.01,
                tonKmBasedCo2Intensity: 0.02,
              },
              {
                destinationId: "b",
                weightBasedCo2Intensity: 0.03,
                tonKmBasedCo2Intensity: 0.04,
              },
              {
                destinationId: "c",
                weightBasedCo2Intensity: 0.05,
                tonKmBasedCo2Intensity: 0.06,
              },
            ],
            intensityType: IntensityType["in-house"],
            version: 0,
            deleteFlag: false,
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: FactoryType.paper,
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 1,
            byDestination: [
              {
                destinationId: "a",
                weightBasedCo2Intensity: 0.11,
                tonKmBasedCo2Intensity: 0.12,
              },
              {
                destinationId: "b",
                weightBasedCo2Intensity: 0.13,
                tonKmBasedCo2Intensity: 0.14,
              },
              {
                destinationId: "c",
                weightBasedCo2Intensity: 0.15,
                tonKmBasedCo2Intensity: 0.16,
              },
            ],
            intensityType: IntensityType["in-house"],
            version: 3,
            deleteFlag: false,
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: FactoryType.paper,
            co2Emission: 1100,
            tonKmBasedCo2Intensity: 1,
            byDestination: [
              {
                destinationId: "a",
                weightBasedCo2Intensity: 0.11,
                tonKmBasedCo2Intensity: 0.12,
              },
              {
                destinationId: "b",
                weightBasedCo2Intensity: 0.13,
                tonKmBasedCo2Intensity: 0.14,
              },
              {
                destinationId: "c",
                weightBasedCo2Intensity: 0.15,
                tonKmBasedCo2Intensity: 0.16,
              },
            ],
            intensityType: IntensityType["in-house"],
            version: 0,
            deleteFlag: true,
          },
        ],
      })
    );
  }),
];

const postCalculationCategory4Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
];

const deleteCalculationMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204, "Mocked status"));
  }),
];

const getGetCategory4NetworkMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4TimeoutMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4UnknownMock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4ERR000000Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4ERR000001Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4ERR000002Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4ERR000003Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const getGetCategory4ERR000004Mock = [
  rest.get("*/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4NetworkMock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4TimeoutMock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4UnknownMock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR000000Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR000001Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR000002Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR000003Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR000004Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR026001Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR026001",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR026002Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR026002",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR026003Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR026003",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR026004Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR026004",
        message: "Mocked message",
      })
    );
  }),
];

const postCalculationCategory4ERR026005Mock = [
  rest.post("*/calculation/category4", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR026005",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationUnknownMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "unknown",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationNetworkMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "network",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationTimeoutMock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(500, "Mocked status"),
      ctx.json({
        errorCode: "timeout",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000000Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000000",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000001Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(400, "Mocked status"),
      ctx.json({
        errorCode: "ERR000001",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000002Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000002",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000003Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(401, "Mocked status"),
      ctx.json({
        errorCode: "ERR000003",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR000004Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(403, "Mocked status"),
      ctx.json({
        errorCode: "ERR000004",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020000Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020000",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020001Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020001",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020004Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020004",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020005Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020005",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020006Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020006",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020007Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(404, "Mocked status"),
      ctx.json({
        errorCode: "ERR020007",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020003Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(409, "Mocked status"),
      ctx.json({
        errorCode: "ERR020003",
        message: "Mocked message",
      })
    );
  }),
];

const deleteCalculationERR020002Mock = [
  rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(412, "Mocked status"),
      ctx.json({
        errorCode: "ERR020002",
        message: "Mocked message",
      })
    );
  }),
];

const paperInputRequestsMock = [
  rest.get("*/input-requests/:year", (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        companyId: "工場",
        year: "2021",
        expirationTime: "2021-11-01T12:00:00.000",
        beginTime: "2021-10-01T00:00:00.000",
        requests: [
          {
            factoryId: "hokkaido",
            factoryName: "XX株式会社　北海道工場",
            factoryNameForSelf: "北",
            factoryNameForOthers: "X・北海道（製紙）",
            factoryType: "paper",
            status: "requested",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
          {
            factoryId: "sendai",
            factoryName: "XX株式会社　仙台工場",
            factoryNameForSelf: "仙台工場仙台工場仙台",
            factoryNameForOthers: "X・仙台（製紙）",
            factoryType: "paper",
            status: "requested",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
          {
            factoryId: "takasaki",
            factoryName: "XX株式会社　高崎工場",
            factoryNameForSelf: "高崎工場",
            factoryNameForOthers: "X・高崎（製紙）",
            factoryType: "paper",
            status: "confirmed",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "yasio",
            factoryName: "XX株式会社　八潮工場",
            factoryNameForSelf: "八潮工場",
            factoryNameForOthers: "X・八潮（製紙）",
            factoryType: "paper",
            status: "expired",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
          },
          {
            factoryId: "oosaka",
            factoryName: "XX株式会社　大阪工場",
            factoryNameForSelf: "大阪工場",
            factoryNameForOthers: "X・大阪（製紙）",
            factoryType: "paper",
            status: "in-progress",
            category1: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "torch-based",
            },
            category4: {
              submissionTime: "2021-10-15T12:00:00.000",
              intensityType: "in-house",
            },
          },
        ],
      })
    );
  }),
];
