import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

import { NestedCard } from "src/app/components/atoms";
import noDataImage from "src/app/images/noData.png";
import noDataImage2x from "src/app/images/noData@2x.png";
import { FlexBox, Image } from "src/lib/components/atoms";

export type ChartCardProps = {
  chartTitle?: string;
  chartElement: ReactElement;
  noData: boolean;
  isProcessing: boolean;
  selectElement?: ReactElement;
};

export const ChartCard: React.FC<ChartCardProps> = (props) => {
  return (
    <NestedCard>
      <Box mb={3}>
        <Typography variant="h3">{props.chartTitle}</Typography>
      </Box>
      {props.selectElement}
      {!props.isProcessing && props.noData ? (
        <FlexBox alignItems="center">
          <Image
            alt="NoData"
            src={noDataImage}
            src2x={noDataImage2x}
            width="100%"
          />
        </FlexBox>
      ) : (
        props.chartElement
      )}
    </NestedCard>
  );
};
