export const keysToObject = <K extends string, V>(
  keys: readonly K[],
  value: V
): Record<K, V> => {
  return Object.fromEntries(keys.map((key) => [key, value])) as Record<K, V>;
};

export const entriesToObject = <K extends string, V>(
  entries: readonly [K, V][]
): Partial<Record<K, V>> => {
  const res: Partial<Record<K, V>> = {};
  for (const [key, value] of entries) {
    res[key] = value;
  }
  return res;
};

export const objectToEntries = <K extends string, V>(
  obj: Partial<Record<K, V>>
): [K, V][] => {
  return Object.entries(obj) as [K, V][];
};
