/* eslint-disable @typescript-eslint/no-unused-vars */
import BigNumber from "bignumber.js";
import { DateTime } from "luxon";
import { rest } from "msw";

import { InputStatus, IntensityType } from "src/app/apis/model";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GhgInputCategory4Mock = () => [
  ...getDeliveryMock(),

  /**
   * ケースNo.1～No.15
   */
  ...getInputRequestMock(),
  ...getCategory4Mock(),
  ...getEmissionFactorMock(),

  /**
   * ケースNo.16～No.17
   */
  // ...getInputRequestSubmittedMock(),
  // ...getCategory4Mock(),
  // ...deleteCalculationMock(),

  /**
   * ケースNo.18
   */
  // ...getInputRequestMock(),
  // ...getCategory4NetworkMock(),

  /**
   * ケースNo.19
   */
  // ...getInputRequestMock(),
  // ...getCategory4TimeoutMock(),

  /**
   * ケースNo.20
   */
  // ...getInputRequestMock(),
  // ...getCategory4UnknownMock(),

  /**
   * ケースNo.21
   */
  // ...getInputRequestMock(),
  // ...getCategory4ERR000000Mock(),

  /**
   * ケースNo.22
   */
  // ...getInputRequestMock(),
  // ...getCategory4ERR000001Mock(),

  /**
   * ケースNo.23
   */
  // ...getInputRequestMock(),
  // ...getCategory4ERR000002Mock(),

  /**
   * ケースNo.24
   */
  // ...getInputRequestMock(),
  // ...getCategory4ERR000003Mock(),

  /**
   * ケースNo.25
   */
  // ...getInputRequestMock(),
  // ...getCategory4ERR000004Mock(),

  /**
   * ケースNo.27
   */
  // ...getInputRequestSubmittedMock(),
  // ...getCategory4Mock(),
  // ...deleteCalculationERR020003Mock(),

  /**
   * ケースNo.29
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorUnknownMock(),

  /**
   * ケースNo.30
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorNetworkMock(),

  /**
   * ケースNo.31
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorERR000002Mock(),

  /**
   * ケースNo.32
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorMock(),
  // ...getFactoryERR023000Mock(),

  /**
   * ケースNo.35
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorEmptyMock(),

  /**
   * ケースNo.36
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorMock(),
  // ...getProcurementBySupplierERR027003Mock(),

  /**
   * ケースNo.39
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorMock(),
  // ...postCalculationCategory4ERR026003Mock(),

  /**
   * ケースNo.40
   */
  // ...getInputRequestMock(),
  // ...getCategory4Mock(),
  // ...getEmissionFactorMock(),
  // ...postCalculationCategory4ERR026004Mock(),
];

// SDA002:GHG入力依頼詳細取得API
const getInputRequestMock = () => {
  return [
    rest.get("*/input-requests/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: DateTime.fromFormat("2021", "yyyy"),
          factoryId: "hokkaido",
          factoryName: "XX株式会社　北海道工場",
          factoryNameForSelf: "北海道工場",
          factoryNameForOthers: "X・北海道（製紙）",
          factoryType: "paper",
          expirationTime: DateTime.fromISO("2021-11-01T12:00:00.000Z"),
          beginTime: DateTime.fromISO("2021-10-01T00:00:00.000Z"),
          status: InputStatus.requested,
          category1: {},
          category4: {
            submissionTime: undefined,
            intensityType: IntensityType["torch-based"],
          },
        })
      );
    }),
  ];
};

const getInputRequestSubmittedMock = () => {
  return [
    rest.get("*/input-requests/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: DateTime.fromFormat("2021", "yyyy"),
          factoryId: "hokkaido",
          factoryName: "XX株式会社　北海道工場",
          factoryNameForSelf: "北海道工場",
          factoryNameForOthers: "X・北海道（製紙）",
          factoryType: "paper",
          expirationTime: DateTime.fromISO("2021-11-01T12:00:00.000Z"),
          beginTime: DateTime.fromISO("2021-10-01T00:00:00.000Z"),
          status: InputStatus.submitted,
          category1: {},
          category4: {
            submissionTime: DateTime.fromISO("2021-10-15T12:00:00.000Z"),
            intensityType: IntensityType["torch-based"],
          },
        })
      );
    }),
  ];
};

// SDA015:カテゴリ4取得API
const getCategory4Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          companyId: "company-id-01",
          year: DateTime.fromFormat("2021", "yyyy"),
          factories: [
            {
              factoryId: "hokkaido",
              factoryName: "XX株式会社　北海道工場",
              factoryNameForSelf: "北海道工場",
              factoryNameForOthers: "X・北海道（製紙）",
              factoryType: "paper",
              co2Emission: 100,
              tonKmBasedCo2Intensity: 200,
              byDestination: [],
              intensityType: IntensityType["torch-based"],
              version: "1",
              deleteFlag: false,
            },
            {
              factoryId: "sendai",
              factoryName: "XX株式会社　仙台工場",
              factoryNameForSelf: "仙台工場",
              factoryNameForOthers: "X・仙台（製紙）",
              factoryType: "process",
              co2Emission: 100,
              tonKmBasedCo2Intensity: 200,
              byDestination: [],
              intensityType: IntensityType["torch-based"],
              version: "1",
              deleteFlag: false,
            },
          ],
        })
      );
    }),
  ];
};

const getCategory4NetworkMock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "network", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4TimeoutMock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "timeout", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4UnknownMock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "unknown", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4ERR000000Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000000", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4ERR000001Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(400, "Mocked status"),
        ctx.json({ errorCode: "ERR000001", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4ERR000002Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000002", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4ERR000003Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000003", message: "Mock Message" })
      );
    }),
  ];
};

const getCategory4ERR000004Mock = () => {
  return [
    rest.get("*/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(403, "Mocked status"),
        ctx.json({ errorCode: "ERR000004", message: "Mock Message" })
      );
    }),
  ];
};

// SDA003:計算結果削除API
const deleteCalculationMock = () => {
  return [
    rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
      return res(ctx.delay(1000), ctx.status(204, "Mocked status"));
    }),
  ];
};

const deleteCalculationERR020003Mock = () => {
  return [
    rest.delete("*/calculation/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(409, "Mocked status"),
        ctx.json({ errorCode: "ERR020003", message: "Mock Message" })
      );
    }),
  ];
};

// SDA010:納入先一覧API
const getDeliveryMock = () => {
  return [
    rest.get("*/delivery/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          from: "hokkaido",
          to: [
            {
              factoryId: "sendai",
              factoryName: "XX株式会社　仙台工場",
              factoryNameForSelf: "仙台工場",
              factoryNameForOthers: "X・仙台（製紙）",
              factoryType: "paper",
              landDistance: new BigNumber(200),
              seaDistance: new BigNumber(500),
              version: 0,
            },
            {
              factoryId: "takasaki",
              factoryName: "XX株式会社　高崎工場",
              factoryNameForSelf: "高崎工場",
              factoryNameForOthers: "X・高崎（加工）",
              factoryType: "process",
              landDistance: new BigNumber(400),
              seaDistance: new BigNumber(600),
              version: 0,
            },
          ],
        })
      );
    }),
  ];
};

const getEmissionFactorMock = () => {
  return [
    rest.get("*/emission-factor/:year", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: "2021",
          emissionFactorList: [
            {
              target: "gasoline",
              co2EmissionFactor: 1,
              version: 0,
            },
            {
              target: "lightOil",
              co2EmissionFactor: 1,
              version: 0,
            },
            {
              target: "cng",
              co2EmissionFactor: 1,
              version: 0,
            },
            {
              target: "cHeavyOil",
              co2EmissionFactor: 1,
              version: 0,
            },
            {
              target: "electricPower",
              co2EmissionFactor: 1,
              version: 0,
            },
            {
              target: "rail",
              co2EmissionFactor: 1,
              version: 0,
            },
          ],
        })
      );
    }),
  ];
};

const getEmissionFactorEmptyMock = () => {
  return [
    rest.get("*/emission-factor/:year", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json({
          year: "2021",
          emissionFactorList: [],
        })
      );
    }),
  ];
};

const getEmissionFactorUnknownMock = () => {
  return [
    rest.get("*/emission-factor/:year", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "unknown", message: "Mock Message" })
      );
    }),
  ];
};

const getEmissionFactorNetworkMock = () => {
  return [
    rest.get("*/emission-factor/:year", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(500, "Mocked status"),
        ctx.json({ errorCode: "network", message: "Mock Message" })
      );
    }),
  ];
};

const getEmissionFactorERR000002Mock = () => {
  return [
    rest.get("*/emission-factor/:year", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(401, "Mocked status"),
        ctx.json({ errorCode: "ERR000002", message: "Mock Message" })
      );
    }),
  ];
};

const getFactoryERR023000Mock = () => {
  return [
    rest.get("*/factory/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(404, "Mocked status"),
        ctx.json({ errorCode: "ERR023000", message: "Mock Message" })
      );
    }),
  ];
};

const getProcurementBySupplierERR027003Mock = () => {
  return [
    rest.get("*/procurement-by-supplier/:year/:factoryId", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(404, "Mocked status"),
        ctx.json({ errorCode: "ERR027003", message: "Mock Message" })
      );
    }),
  ];
};

const postCalculationCategory4ERR026003Mock = () => {
  return [
    rest.post("*/calculation/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(412, "Mocked status"),
        ctx.json({ errorCode: "ERR026003", message: "Mock Message" })
      );
    }),
  ];
};

const postCalculationCategory4ERR026004Mock = () => {
  return [
    rest.post("*/calculation/category4", (req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(409, "Mocked status"),
        ctx.json({ errorCode: "ERR026004", message: "Mock Message" })
      );
    }),
  ];
};
