/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  UseMutationOptions,
  MutationFunction,
} from "@tanstack/react-query";
import type {
  PostAuthLogin200,
  BadRequestResponse,
  PostAuthLogin401,
  ForbiddenResponse,
  PostAuthLoginBody,
} from "../model";
import { rest } from "msw";
import { faker } from "@faker-js/faker";
import { CompanyType } from "../model";
import { customMutator } from "../custom-mutator";
import type { ErrorType } from "../custom-mutator";

/**
 * パスワード認証を行う。

１つのユーザは同時に複数の有効なセッションを保持できる。
 * @summary ログインAPI
 */
export const postAuthLogin = (postAuthLoginBody: PostAuthLoginBody) => {
  return customMutator<PostAuthLogin200>({
    url: `/auth/login`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: postAuthLoginBody,
  });
};

export type PostAuthLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthLogin>>
>;
export type PostAuthLoginMutationBody = PostAuthLoginBody;
export type PostAuthLoginMutationError = ErrorType<
  BadRequestResponse | PostAuthLogin401 | ForbiddenResponse
>;

export const usePostAuthLogin = <
  TError = ErrorType<BadRequestResponse | PostAuthLogin401 | ForbiddenResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: PostAuthLoginBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthLogin>>,
    { data: PostAuthLoginBody }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthLogin(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: PostAuthLoginBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * セッションの破棄を行う
 * @summary ログアウトAPI
 */
export const postAuthLogout = () => {
  return customMutator<void>({ url: `/auth/logout`, method: "post" });
};

export type PostAuthLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthLogout>>
>;

export type PostAuthLogoutMutationError = ErrorType<
  BadRequestResponse | ForbiddenResponse
>;

export const usePostAuthLogout = <
  TError = ErrorType<BadRequestResponse | ForbiddenResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogout>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthLogout>>,
    TVariables
  > = () => {
    return postAuthLogout();
  };

  return useMutation<
    Awaited<ReturnType<typeof postAuthLogout>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

export const getPostAuthLoginMock = () => ({
  companyId: faker.random.word(),
  loginId: faker.random.word(),
  userId: faker.random.word(),
  userName: faker.random.word(),
  role: faker.helpers.arrayElement(Object.values(CompanyType)),
  version: faker.datatype.number({ min: 0, max: 1000000000 }),
});

export const getAuthMSW = () => [
  rest.post("*/auth/login", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getPostAuthLoginMock())
    );
  }),
  rest.post("*/auth/logout", (_req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200, "Mocked status"));
  }),
];
