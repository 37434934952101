import { AppBar, AppBarProps, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
  },
}));

export type HeaderBaseProps = AppBarProps;

/**
 * 特にコンテンツのない素のヘッダー
 */
export const HeaderBase: React.FC<HeaderBaseProps> = (props) => {
  const classes = useStyles();
  const { className, ...restProps } = props;
  return (
    <AppBar className={clsx(classes.appBar, className)} {...restProps}>
      <Toolbar disableGutters>{props.children}</Toolbar>
    </AppBar>
  );
};
