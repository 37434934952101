/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type ProductPurpose =
  (typeof ProductPurpose)[keyof typeof ProductPurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductPurpose = {
  frontLinear: "frontLinear",
  backLinear: "backLinear",
  corrugationMedium: "corrugationMedium",
} as const;
