import {
  getCategory4,
  GetCategory4QueryError,
  GetCategory4QueryResult,
} from "src/app/apis/client/category4";
import { GetCategory4Params } from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class GetCategory4ApiError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}

export class Category4Api {
  async getCategory4(
    req: GetCategory4Params
  ): Promise<GetCategory4QueryResult> {
    try {
      const res = await getCategory4(req);
      return {
        companyId: res.companyId,
        year: res.year,
        factories: res.factories.map((factory) => ({
          factoryId: factory.factoryId,
          factoryName: factory.factoryName,
          factoryNameForSelf: factory.factoryNameForSelf,
          factoryNameForOthers: factory.factoryNameForOthers,
          factoryType: factory.factoryType,
          co2Emission: factory.co2Emission,
          tonKmBasedCo2Intensity: factory.tonKmBasedCo2Intensity,
          byDestination: factory.byDestination.map((byDestination) => ({
            destinationId: byDestination.destinationId,
            factoryName: byDestination.factoryName,
            factoryNameForSelf: byDestination.factoryNameForSelf,
            factoryNameForOthers: byDestination.factoryNameForOthers,
            factoryType: byDestination.factoryType,
            weightBasedCo2Intensity: byDestination.weightBasedCo2Intensity,
            tonKmBasedCo2Intensity: byDestination.tonKmBasedCo2Intensity,
          })),
          intensityType: factory.intensityType,
          version: factory.version,
          deleteFlag: factory.deleteFlag,
        })),
      };
    } catch (err) {
      const { errorCode } = err as GetCategory4QueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetCategory4ApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetCategory4ApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetCategory4ApiError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
