import { InfoOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

export type AnnotateProps = {
  children?: React.ReactNode;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  infoMessage: {
    color: theme.palette.text.disabled,
    lineHeight: 1,
  },
  infoIcon: {
    width: 16,
    height: 16,
  },
  secondInfoLine: {
    lineHeight: 1.4,
    textTransform: "none",
    marginLeft: 2,
  },
}));

export const Annotate: React.FC<AnnotateProps> = (props) => {
  const classes = useStyles();

  return (
    <Typography variant="overline" className={classes.infoMessage}>
      <FlexBox>
        <InfoOutlined className={classes.infoIcon} />
        <span className={classes.secondInfoLine}>{props.children}</span>
      </FlexBox>
    </Typography>
  );
};
