import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { v4 as uuid } from "uuid";

import {
  GhgInputCategory1Form,
  GhgInputCategory1TabType,
  GhgInputCategory4Form,
  GhgInputCategory4TabType,
} from "./ghgInputForm";
import {
  validateEnergyForm,
  validateManufacturingForm,
  validateTransportForm,
  validateWasteForm,
} from "./ghgInputFormValidator";

import {
  CalculationApi,
  DeleteCalculationError,
} from "src/app/apis/calculation-api/calculationApi";
import {
  GetProductQueryResult,
  GetDeliveryQueryResult,
} from "src/app/apis/client/default";
import {
  DeliveryApi,
  GetDeliveryApiError,
} from "src/app/apis/delivery-api/deliveryApi";
import {
  getLocalStorageApi,
  SetLocalStorageError,
  SetLocalStorageResponse,
} from "src/app/apis/local-storage-api";
import { CompanyType, IntensityCategory } from "src/app/apis/model";
import {
  GetProductApiRequest,
  GetProductError,
  ProductApi,
} from "src/app/apis/product-api/productApi";
import { OTHER_KEY } from "src/app/components/templates";
import { envConfig } from "src/app/configs";
import { LocalStorageInput } from "src/app/models";
import { exhaustiveSwitchCase } from "src/lib/apis";

export class ServiceError<T> {
  type: T;
  cause?: unknown;

  constructor(type: T, cause?: unknown) {
    this.type = type;
    this.cause = cause;
  }
}

export class LocalStorageServiceRegisterError extends ServiceError<
  "can-not-use" | "cookie-disabled" | "unknown" | "validation"
> {}
export class GhgInputServiceSubmitError extends ServiceError<
  | "factory-not-found"
  | "deliveries-not-found"
  | "product-not-found"
  | "emission-factor-not-found"
  | "machine-count-incorrect"
  | "weight-factor-incorrect"
  | "weight-factor-is-zero"
  | "expired"
  | "not-authenticated"
  | "network"
  | "unknown"
> {}

export class GhgInputServiceUnSubmitError extends ServiceError<
  "expired" | "conflict" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputServiceGetDeliveryError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}

export class GhgInputServiceGetProductError extends ServiceError<
  "factory-not-found" | "not-authenticated" | "network" | "unknown"
> {}
export class GhgInputService {
  /**
   * 特定のタブの情報をバリデーションの上、ローカルストレージに登録する
   */
  async register(input: {
    targetTab: GhgInputCategory1TabType | GhgInputCategory4TabType;
    year: DateTime;
    factoryId: string;
    form: GhgInputCategory1Form | GhgInputCategory4Form;
    setForm:
      | Dispatch<SetStateAction<GhgInputCategory1Form>>
      | Dispatch<SetStateAction<GhgInputCategory4Form>>;
    companyType: Exclude<CompanyType, "user-company">;
    localStorage?: LocalStorageInput;
  }): Promise<{
    res: SetLocalStorageResponse;
  }> {
    const { year, factoryId, form, setForm, targetTab } = input;

    if (targetTab === "energy") {
      const { isValid, energy } = validateEnergyForm(
        (form as GhgInputCategory1Form).energy
      );
      setForm((form: any) => ({ ...form, energy }));
      if (!isValid) {
        throw new LocalStorageServiceRegisterError("validation");
      }
    }

    if (targetTab === "waste") {
      const { isValid, waste } = validateWasteForm(
        (form as GhgInputCategory1Form).waste,
        input.companyType
      );
      setForm((form: any) => ({ ...form, waste }));
      if (!isValid) {
        throw new LocalStorageServiceRegisterError("validation");
      }
    }

    if (targetTab === "manufacturing") {
      const { isValid, manufacturing } = validateManufacturingForm(
        (form as GhgInputCategory1Form).manufacturing,
        input.companyType
      );
      setForm((form: any) => ({ ...form, manufacturing }));
      if (!isValid) {
        throw new LocalStorageServiceRegisterError("validation");
      }
    }

    if (targetTab === "transport") {
      const { isValid, transport } = validateTransportForm(
        (form as GhgInputCategory4Form).transport
      );
      setForm((form: any) => ({ ...form, transport }));
      if (!isValid) {
        throw new LocalStorageServiceRegisterError("validation");
      }
    }

    try {
      const localStorageApi = getLocalStorageApi();
      // CDP004:一時保存
      const res = await localStorageApi.set({
        factoryId: factoryId,
        year: year.toFormat("yyyy"),
        targetTab,
        energyInput: {
          ...(form as GhgInputCategory1Form).energy,
          version:
            targetTab === "energy"
              ? envConfig.version.toString()
              : input.localStorage?.energyInput?.version ?? "1",
          isRegistered:
            targetTab === "energy"
              ? true
              : input.localStorage?.energyInput?.isRegistered ?? false,
        },
        wasteInput: {
          ...(form as GhgInputCategory1Form).waste,
          version:
            targetTab === "waste"
              ? envConfig.version.toString()
              : input.localStorage?.wasteInput?.version ?? "1",
          isRegistered:
            targetTab === "waste"
              ? true
              : input.localStorage?.wasteInput?.isRegistered ?? false,
        },
        manufacturingInput: {
          ...(form as GhgInputCategory1Form).manufacturing,
          version:
            targetTab === "manufacturing"
              ? envConfig.version.toString()
              : input.localStorage?.manufacturingInput?.version ?? "1",
          isRegistered:
            targetTab === "manufacturing"
              ? true
              : input.localStorage?.manufacturingInput?.isRegistered ?? false,
        },
        transportInput: {
          ...(targetTab === "transport"
            ? {
                ...(form as GhgInputCategory4Form).transport,
                transportByRail: {
                  ...(form as GhgInputCategory4Form).transport.transportByRail,
                  transportByRails: (
                    form as GhgInputCategory4Form
                  ).transport.transportByRail.transportByRails.map((it) => {
                    return {
                      ...it,
                      destinationId:
                        it.destinationId.value === OTHER_KEY
                          ? { ...it.destinationId, value: uuid() }
                          : it.destinationId,
                    };
                  }),
                },
              }
            : {}),
          version:
            targetTab === "transport"
              ? envConfig.version.toString()
              : input.localStorage?.transportInput?.version ?? "1",
          isRegistered:
            targetTab === "transport"
              ? true
              : input.localStorage?.transportInput?.isRegistered ?? false,
        },
      });

      return { res };
    } catch (err) {
      if (!(err instanceof SetLocalStorageError)) {
        throw new LocalStorageServiceRegisterError("unknown", err);
      }
      switch (err.type) {
        case "can-not-use":
          throw new LocalStorageServiceRegisterError("can-not-use");
        case "cookie-disabled":
          throw new LocalStorageServiceRegisterError("cookie-disabled");
        case "unknown":
          throw new LocalStorageServiceRegisterError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }

  async unSubmit(input: {
    intensityCategory: IntensityCategory;
    version: number;
    year: DateTime;
    factoryId: string;
  }): Promise<void> {
    try {
      const calculationApi = new CalculationApi();
      // SDA003:計算結果削除API
      await calculationApi.deleteCalculation({
        intensityCategory: input.intensityCategory,
        version: input.version,
        year: input.year,
        factoryId: input.factoryId,
      });
    } catch (err) {
      if (!(err instanceof DeleteCalculationError)) {
        throw new GhgInputServiceUnSubmitError("unknown", err);
      }
      switch (err.type) {
        case "expired":
          throw new GhgInputServiceUnSubmitError("expired", err);
        case "conflict":
          throw new GhgInputServiceUnSubmitError("conflict", err);
        case "not-authenticated":
          throw new GhgInputServiceUnSubmitError("not-authenticated", err);
        case "network":
          throw new GhgInputServiceUnSubmitError("network", err);
        case "unknown":
          throw new GhgInputServiceUnSubmitError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }

  /**
   * 納入先一覧を取得する
   */
  async getDelivery(input: { from: string }): Promise<GetDeliveryQueryResult> {
    try {
      const deliveryApi = new DeliveryApi();
      const deliveryList = await deliveryApi.getDelivery({
        from: input.from,
      });
      return deliveryList;
    } catch (err) {
      if (!(err instanceof GetDeliveryApiError)) {
        throw new GhgInputServiceGetDeliveryError("unknown", err);
      }
      switch (err.type) {
        case "factory-not-found":
          throw new GhgInputServiceGetDeliveryError("factory-not-found", err);
        case "not-authenticated":
          throw new GhgInputServiceGetDeliveryError("not-authenticated", err);
        case "network":
          throw new GhgInputServiceGetDeliveryError("network", err);
        case "unknown":
          throw new GhgInputServiceGetDeliveryError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }

  /**
   * 製品・規格一覧を取得する
   */
  async getProduct(
    input: GetProductApiRequest
  ): Promise<GetProductQueryResult> {
    try {
      const productApi = new ProductApi();
      const productList = await productApi.getProduct(input);
      return productList;
    } catch (err) {
      if (!(err instanceof GetProductError)) {
        throw new GhgInputServiceGetProductError("unknown", err);
      }
      switch (err.type) {
        case "not-authenticated":
          throw new GhgInputServiceGetProductError("not-authenticated", err);
        case "factory-not-found":
          throw new GhgInputServiceGetProductError("factory-not-found", err);
        case "network":
          throw new GhgInputServiceGetProductError("network", err);
        case "unknown":
          throw new GhgInputServiceGetProductError("unknown", err);
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }
}
