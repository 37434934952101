import {
  PostCalculationCategory4MutationResult,
  postCalculationCategory4,
  PostCalculationCategory4MutationError,
} from "src/app/apis/client/default";
import {
  PostCalculationCategory4Body,
  PostCalculationCategory4Params,
} from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PostCalculationCategory4Error extends ApiError<
  | "conflict"
  | "expired"
  | "not-authenticated"
  | "factory-not-found"
  | "network"
  | "unknown"
> {}

export class CalculationCategory4Api {
  async postCalculationCategory4(
    req: PostCalculationCategory4Body,
    params: PostCalculationCategory4Params
  ): Promise<PostCalculationCategory4MutationResult> {
    try {
      await postCalculationCategory4(req, params);
    } catch (err) {
      const e = err as PostCalculationCategory4MutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostCalculationCategory4Error("network", err);
        case "ERR000002":
        case "ERR000003":
          throw new PostCalculationCategory4Error("not-authenticated", err);
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "unknown":
          throw new PostCalculationCategory4Error("unknown", err);
        case "ERR026001":
        case "ERR026002":
        case "ERR026005":
          throw new PostCalculationCategory4Error("factory-not-found", err);
        case "ERR026003":
          throw new PostCalculationCategory4Error("expired", err);
        case "ERR026004":
          throw new PostCalculationCategory4Error("conflict", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
