import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactElement } from "react";

import { FlexBox } from "src/lib/components/atoms";

export interface ProcurementsInputFieldProps {
  label?: string | ReactElement;
  hasError?: boolean;
  errorMessage?: string;
  valiant?: "h4" | "h5" | "h6";
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    overflowWrap: "break-word",
    flex: 1,
  },
  errorMessage: {
    lineHeight: 1,
  },
}));

export const ProcurementsInputField: React.FC<ProcurementsInputFieldProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Box>
      <FlexBox alignItems="center">
        <FlexBox flex={1} justifyContent="flex-end">
          <Box mr={2}>
            <Typography
              variant={props.valiant !== undefined ? props.valiant : "h5"}
              className={classes.label}
            >
              {props.label}
            </Typography>
          </Box>
        </FlexBox>
        <Box flex={1.1}>{props.children}</Box>
      </FlexBox>
      <FlexBox>
        <Box flex={1} />
        {/* エラーがないときも高さを確保するためminHeightを指定 */}
        <FlexBox flex={1.1} minHeight={16} alignItems="flex-start">
          {props.hasError && (
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.errorMessage}
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
    </Box>
  );
};
