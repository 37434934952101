import {
  generatePath,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

import { CategoryNavigationTemplate } from "src/app/components/templates";
import {
  useCategory1Data,
  useCategory4Data,
  useRouteMatch,
} from "src/app/hooks";
import { useDefaultYear, useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { categoryTabNames } from "src/app/navigation/tabs";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";

export type ChartAndTableType =
  | "totalEmission"
  | "emissionByClassification"
  | "intensityByClassification"
  | "intensityByProduct"
  | "emissionByFactory"
  | "intensityByDestination";

export const CategoryNavigationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState] = useAuthState<AuthState>();

  const yearDropdownProps = useYearDropdown();

  // カテゴリ1取得
  const {
    isProcessing: isCategory1Processing,
    category1DataForDisplay: category1Data,
  } = useCategory1Data(yearDropdownProps?.value);

  // カテゴリ4取得
  const {
    isProcessing: isCategory4Processing,
    category4DataForDisplay: category4Data,
  } = useCategory4Data(yearDropdownProps?.value);

  const params = useParams<{ year: string; tab: string }>();
  const match = useRouteMatch([paths.category1.tab, paths.category4.tab]);
  const [defaultYear] = useDefaultYear();

  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return (
      <Navigate
        to={generatePath(match ?? paths.category1.tab, {
          tab: params.tab ?? null,
          year: defaultYear.toFormat("yyyy"),
        })}
      />
    );
  }

  if (authState === undefined || authState.role === "user-company") {
    return <Navigate to="/login" />;
  }

  const tabList = Object.values(categoryTabNames);
  const invalidUrl = tabList.every(
    (val) => location.pathname.endsWith(`/${val}`) === false
  );

  return (
    <CategoryNavigationTemplate
      isProcessing={isCategory1Processing || isCategory4Processing}
      yearDropdownProps={yearDropdownProps}
      companyType={authState.role}
      category1Data={category1Data}
      category4Data={category4Data}
      tabItemProps={{
        totalEmission: {
          onClick: () =>
            navigate(`/category1/${yearDropdownProps.value}/total-emission`),
          selected:
            location.pathname.endsWith("/total-emission") &&
            location.pathname.startsWith("/category1"),
        },
        emissionByClassification: {
          onClick: () =>
            navigate(
              `/category1/${yearDropdownProps.value}/emission-by-classification`
            ),
          selected: location.pathname.endsWith("/emission-by-classification"),
        },
        intensityByClassification: {
          onClick: () =>
            navigate(
              `/category1/${yearDropdownProps.value}/intensity-by-classification`
            ),
          selected: location.pathname.endsWith("/intensity-by-classification"),
        },
        intensityByProduct: {
          onClick: () =>
            navigate(
              `/category1/${yearDropdownProps.value}/intensity-by-product`
            ),
          selected: location.pathname.endsWith("/intensity-by-product"),
        },
        emissionByFactory: {
          onClick: () =>
            navigate(`/category4/${yearDropdownProps.value}/total-emission`),
          selected:
            location.pathname.endsWith("/total-emission") &&
            location.pathname.startsWith("/category4"),
        },
        intensityByDestination: {
          onClick: () =>
            navigate(
              `/category4/${yearDropdownProps.value}/intensity-by-destination`
            ),
          selected: location.pathname.endsWith("/intensity-by-destination"),
        },
      }}
      invalidUrl={invalidUrl}
    />
  );
};
