/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

/**
 * commonWasteTypes,paperOnlyWasteTypes,processOnlyWasteTypesの親要素
 */
export type WasteType = (typeof WasteType)[keyof typeof WasteType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WasteType = {
  sludge: "sludge",
  ash: "ash",
  basePaperLoss: "basePaperLoss",
  screenLees1: "screenLees1",
  screenLees2: "screenLees2",
  screenLees3: "screenLees3",
  pulper1: "pulper1",
  pulper2: "pulper2",
  pulper3: "pulper3",
  offCutLoss: "offCutLoss",
} as const;
