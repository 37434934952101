import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Dropdown, DropdownProps } from "src/app/components/atoms";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles(() => ({
  dropDown: { minWidth: 180 },
}));

export type FactoryAndStandardDropdownProps = {
  factory: DropdownProps;
  standard: DropdownProps;
};

export const FactoryAndStandardDropdown: React.FC<
  FactoryAndStandardDropdownProps
> = (props) => {
  const classes = useStyles();
  return (
    <FlexBox>
      <Box>
        <Typography>自社工場</Typography>
        <Box mt={0.5} className={classes.dropDown}>
          <Dropdown {...props.factory} />
        </Box>
      </Box>
      <FlexBox flexDirection="column" justifyContent="flex-end">
        <Box mx={2} mb={0.5}>
          <Typography variant="h1">×</Typography>
        </Box>
      </FlexBox>
      <Box>
        <Typography>規格</Typography>
        <Box mt={0.5} className={classes.dropDown}>
          <Dropdown {...props.standard} />
        </Box>
      </Box>
    </FlexBox>
  );
};
