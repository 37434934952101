/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type WasteMethods = (typeof WasteMethods)[keyof typeof WasteMethods];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WasteMethods = {
  inHouseIncineration: "inHouseIncineration",
  incineration: "incineration",
  landfill: "landfill",
  ashLandfill: "ashLandfill",
  recycle: "recycle",
} as const;
