import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Navigate, generatePath } from "react-router-dom";

import { CompanyType } from "src/app/apis/model";
import { AppTab, AppTabItem } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  Category1EmissionByClassificationPage,
  Category1IntensityByClassificationPage,
  Category1IntensityByProductPage,
  Category1TotalEmissionPage,
  Category4EmissionByFactoryPage,
  Category4IntensityByDestinationPage,
  ChartAndTableType,
} from "src/app/components/pages";
import { AppTheme } from "src/app/configs";
import {
  Category4DataForDisplay,
  PaperCategory1DataForDisplay,
  ProcessCategory1DataForDisplay,
} from "src/app/hooks";
import { paths } from "src/app/navigation/paths";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  category1: {
    maxWidth: 120,
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category1Badge,
  },
  category4: {
    maxWidth: 120,
    minHeight: 28,
    borderRadius: 50,
    backgroundColor: theme.palette.category4Badge,
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
}));

export type CategoryNavigationTemplateProps = {
  isProcessing: boolean;
  companyType: Exclude<CompanyType, "user-company">;
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay;
  category4Data?: Category4DataForDisplay;
  yearDropdownProps: YearDropdownProps;
  tabItemProps: Record<
    ChartAndTableType,
    {
      selected: boolean;
      onClick: () => void;
    }
  >;
  invalidUrl?: boolean;
};

export const CategoryNavigationTemplate: React.FC<
  CategoryNavigationTemplateProps
> = (props) => {
  const classes = useStyles();
  const year = props.yearDropdownProps.value;

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h1">GHG算出結果</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FlexBox
            className={classes.category1}
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography className={classes.typography} variant="h4">
              カテゴリ1
            </Typography>
          </FlexBox>
          <AppTab>
            <AppTabItem
              disableBadge={true}
              label="工場：年"
              {...props.tabItemProps.totalEmission}
            />
            <AppTabItem
              disableBadge={true}
              label="工場×分類：年"
              {...props.tabItemProps.emissionByClassification}
            />
            <AppTabItem
              disableBadge={true}
              label="工場×分類：原単位"
              {...props.tabItemProps.intensityByClassification}
            />
            <AppTabItem
              disableBadge={true}
              label={`工場${
                props.companyType === "paper-supplier" ? "×製品" : ""
              }：原単位`}
              {...props.tabItemProps.intensityByProduct}
            />
          </AppTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FlexBox
            className={classes.category4}
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <Typography className={classes.typography} variant="h4">
              カテゴリ4
            </Typography>
          </FlexBox>
          <AppTab>
            <AppTabItem
              disableBadge={true}
              label="工場：年"
              {...props.tabItemProps.emissionByFactory}
            />
            <AppTabItem
              disableBadge={true}
              label="工場×納入先：原単位"
              {...props.tabItemProps.intensityByDestination}
            />
          </AppTab>
        </Grid>
      </Grid>
      <Box mt={4}>
        {props.tabItemProps.totalEmission.selected && (
          <Category1TotalEmissionPage
            category1Data={props.category1Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.tabItemProps.emissionByClassification.selected && (
          <Category1EmissionByClassificationPage
            companyType={props.companyType}
            category1Data={props.category1Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.tabItemProps.intensityByClassification.selected && (
          <Category1IntensityByClassificationPage
            companyType={props.companyType}
            category1Data={props.category1Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.tabItemProps.intensityByProduct.selected && (
          <Category1IntensityByProductPage
            companyType={props.companyType}
            category1Data={props.category1Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.tabItemProps.emissionByFactory.selected && (
          <Category4EmissionByFactoryPage
            category4Data={props.category4Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.tabItemProps.intensityByDestination.selected && (
          <Category4IntensityByDestinationPage
            companyType={props.companyType}
            category4Data={props.category4Data}
            isProcessing={props.isProcessing}
          />
        )}
        {props.invalidUrl && (
          <Navigate
            to={generatePath(paths.category1.tab, {
              year: year ?? "",
              tab: "total-emission",
            })}
          />
        )}
        <FullScreenSpinner open={props.isProcessing} />
      </Box>
    </Content>
  );
};
