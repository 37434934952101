/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type InputStatus = (typeof InputStatus)[keyof typeof InputStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InputStatus = {
  requested: "requested",
  submitted: "submitted",
  confirmed: "confirmed",
  expired: "expired",
  "in-progress": "in-progress",
} as const;
