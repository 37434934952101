import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

import { AppTheme } from "src/app/configs";
import crown from "src/app/images/icon/crown.svg";
import { FlexBox, Image } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme, RankBadgeProps>((theme) => ({
  common: {
    width: "5.0rem",
    height: "5.0rem",
    borderRadius: "50%",
    backgroundColor: theme.palette.emissionUnitRank.common,
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
  first: {
    width: "6.0rem",
    height: "6.0rem",
    backgroundColor: theme.palette.emissionUnitRank.first,
  },
  second: {
    backgroundColor: theme.palette.emissionUnitRank.second,
  },
  third: {
    backgroundColor: theme.palette.emissionUnitRank.third,
  },
}));

export type RankBadgeProps = {
  rank: number;
};

export const RankBadge: React.FC<RankBadgeProps> = (props) => {
  const classes = useStyles(props);
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={clsx(
        classes.common,
        props.rank === 1 && classes.first,
        props.rank === 2 && classes.second,
        props.rank === 3 && classes.third
      )}
    >
      {props.rank === 1 && (
        <Image alt="crown" src={crown} width={20} height={13} />
      )}
      <Typography variant="h4" className={classes.typography}>
        No.{props.rank}
      </Typography>
    </FlexBox>
  );
};
