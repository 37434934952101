import { Box, Theme, StyledEngineProvider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Dialog } from "src/app/components/atoms";
import { AppTheme } from "src/app/configs";
import { Button, FlexBox } from "src/lib/components/atoms";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles<AppTheme>((theme) => ({
  title: {
    backgroundColor: "#DBE1CD",
    color: theme.palette.text.primary,
  },
  button: {
    maxWidth: 120,
    width: "100%",
    marginLeft: "10px",
  },
  message: {
    // メッセージ中の改行をそのまま改行として表示
    whiteSpace: "pre-wrap",
  },
}));

export type ConfirmationDialogProps = {
  message: string;
  open: boolean;
  onClickOk: () => void;
  onClickCancel: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.onClickCancel}>
      <Box className={classes.title} px={2} py={1}>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          確認
        </Typography>
      </Box>
      <Box p={2}>
        <Box>
          <Typography variant="body2" className={classes.message}>
            {props.message}
          </Typography>
        </Box>

        <FlexBox justifyContent="flex-end" mt={2}>
          <Button
            color="primary"
            className={classes.button}
            label="キャンセル"
            variant="outlined"
            onClick={props.onClickCancel}
          />
          <StyledEngineProvider injectFirst>
            <Button
              color="primary"
              className={classes.button}
              label="OK"
              variant="contained"
              onClick={props.onClickOk}
            />
          </StyledEngineProvider>
        </FlexBox>
      </Box>
    </Dialog>
  );
};
