import { Box, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppCard } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  ImageHeading,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import { DashBoardCard } from "src/app/components/organisms";
import { TotalEmission } from "src/app/models";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    boxShadow: "inherit",
  },
}));

export type CardPropsForTotal = {
  data: number;
  onClick: () => void;
};
export type CardPropsForChart = {
  data: TotalEmission[];
  onClick: () => void;
};
export type UserDashboardTemplateProps = {
  isProcessing: boolean;
  yearDropdownProps: YearDropdownProps;

  category1TotalEmissionProps: CardPropsForTotal;
  category4TotalEmissionProps: CardPropsForTotal;
  category1EmissionByStandardProps: CardPropsForChart;
  category4EmissionByStandardProps: CardPropsForChart;
  category1EmissionByFactoryProps: CardPropsForChart;
  category4EmissionByFactoryProps: CardPropsForChart;
};

export const UserDashboardTemplate: React.FC<UserDashboardTemplateProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Content>
      <FlexBox justifyContent="space-between">
        <ImageHeading title="段ボール" variant="cardBoard" />
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>
      <AppCard backgroundColor="inherit" className={classes.card}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DashBoardCard
                category="category1"
                yearDropdownProps={props.yearDropdownProps}
                isProcessing={props.isProcessing}
                totalEmissionCardProps={props.category1TotalEmissionProps}
                byStandardChartCardProps={
                  props.category1EmissionByStandardProps
                }
                byFactoryChartCardProps={props.category1EmissionByFactoryProps}
              />
            </Grid>
            <Grid item xs={6}>
              <DashBoardCard
                category="category4"
                yearDropdownProps={props.yearDropdownProps}
                isProcessing={props.isProcessing}
                totalEmissionCardProps={props.category4TotalEmissionProps}
                byStandardChartCardProps={
                  props.category4EmissionByStandardProps
                }
                byFactoryChartCardProps={props.category4EmissionByFactoryProps}
              />
            </Grid>
          </Grid>
        </Box>
      </AppCard>
      <FullScreenSpinner open={props.isProcessing} />
    </Content>
  );
};
