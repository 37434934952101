import { Box, Typography } from "@mui/material";

import { NestedCard } from "src/app/components/atoms";
import { FlexBox, ImageProps, Image } from "src/lib/components/atoms";

export type DashBoardCardItemProps = {
  className?: string;
  titleIconProps?: ImageProps;
  title: string;
  children?: React.ReactNode;
};

export const DashBoardCardItem: React.FC<DashBoardCardItemProps> = (props) => {
  return (
    <NestedCard className={props.className}>
      <FlexBox>
        {props.titleIconProps !== undefined && (
          <Box mr={1}>
            <Image
              alt={props.titleIconProps.alt}
              src={props.titleIconProps.src}
              src2x={props.titleIconProps.src2x}
            />
          </Box>
        )}
        <Typography variant="h4">{props.title}</Typography>
      </FlexBox>
      <Box mt={3}>{props.children}</Box>
    </NestedCard>
  );
};
