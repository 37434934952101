import { useState } from "react";
import { generatePath, Navigate } from "react-router-dom";

import { GetScope3ByRouteQueryResult } from "src/app/apis/client/default";
import {
  FactoryType,
  ProductPurpose,
  ProductType,
  StandardType,
} from "src/app/apis/model";
import { GetByRouteError, Scope3Api } from "src/app/apis/scope3-api/scope3Api";
import { RouteListRowType } from "src/app/components/molecules";
import { UserIntensityTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { useDefaultYear, useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState, CsvDataType } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { concatFactoryName } from "src/app/utils/computeForVisuals";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";
import { CsvUtil } from "src/lib/utils/csvUtil";
import { DownloadUtil } from "src/lib/utils/downloadUtil";
import { sum } from "src/lib/utils/mathUtil";

export const UserIntensityPage: React.FC = () => {
  const snackbar = useCustomSnackbar();
  const [, setAuthState] = useAuthState<AuthState>();
  const yearDropdownProps = useYearDropdown();

  const [factoryDropdownValue, setFactoryDropdownValue] = useState("");
  const [standardDropdownValue, setStandardDropdownValue] = useState("");
  const [isNoData, setIsNoData] = useState(false);
  const [isApiError, setIsApiError] = useState(false);

  const {
    isProcessing,
    data: byRouteData,
    setData: setByRouteData,
  } = useAsyncEffect(async () => {
    if (yearDropdownProps?.value === undefined) {
      return;
    }

    setByRouteData(undefined);
    setIsNoData(false);
    setIsApiError(false);
    setFactoryDropdownValue("");
    setStandardDropdownValue("");

    const scope3Api = new Scope3Api();

    try {
      const res = await scope3Api.getByRoute({
        year: yearDropdownProps.year.toFormat("yyyy"),
      });
      const factoryDropdownItems = getFactoryDropdownItems(res);
      if (factoryDropdownItems.length > 0) {
        setFactoryDropdownValue(factoryDropdownItems[0].value);
        const standardDropdownItems = getStandardDropdownItems(
          res,
          factoryDropdownItems[0].value
        );
        if (standardDropdownItems.length > 0)
          setStandardDropdownValue(standardDropdownItems[0].value);
      }
      return res;
    } catch (err) {
      if (!(err instanceof GetByRouteError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "not-authenticated":
          setAuthState(undefined);
          setIsApiError(true);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          break;
        case "network":
          setIsApiError(true);
          snackbar.error(messages.common.network, {
            preventDuplicate: true,
          });
          break;
        case "unknown":
          setIsApiError(true);
          snackbar.error(messages.common.unknown, {
            preventDuplicate: true,
          });
          break;
        case "no-data":
          setIsApiError(true);
          setIsNoData(true);
          break;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }, [yearDropdownProps?.value]);

  const [defaultYear] = useDefaultYear();
  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return (
      <Navigate
        to={generatePath(paths.user.intensity.year, {
          year: defaultYear.toFormat("yyyy"),
        })}
      />
    );
  }

  const factoryDropdownProps = {
    items:
      byRouteData === undefined ? [] : getFactoryDropdownItems(byRouteData),
    value: factoryDropdownValue,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setFactoryDropdownValue(event.target.value);
      if (byRouteData !== undefined) {
        const standardDropdownItems = getStandardDropdownItems(
          byRouteData,
          event.target.value
        );
        setStandardDropdownValue(
          standardDropdownItems.length > 0 ? standardDropdownItems[0].value : ""
        );
      }
    },
    disabled: isProcessing,
  };
  const standardDropdownProps = {
    items:
      byRouteData === undefined
        ? []
        : getStandardDropdownItems(byRouteData, factoryDropdownValue),
    value: standardDropdownValue,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      setStandardDropdownValue(event.target.value),
    disabled: isProcessing,
  };

  const data = createData(
    factoryDropdownValue,
    standardDropdownValue,
    byRouteData
  );

  const getCsv = () => {
    const csvData: CsvDataType = [];
    factoryDropdownProps.items.forEach((factoryDropdown) => {
      standardDropdownProps.items.forEach((standardDropdown) => {
        const data = createData(
          factoryDropdown.value,
          standardDropdown.value,
          byRouteData
        );
        data.forEach((data) => {
          //合計値
          csvData.push({
            routeName: data.routeName,
            standardName: standardDropdown.displayName,
            userFactoryName: factoryDropdown.displayName,
            hierarchy: "1",
            total: data.total.toString(),
            category1Total: data.category1Total.toString(),
            category4Total: data.category4Total.toString(),
            process: "-",
            productName: "-",
            productPurpose: "-",
            factoryName: "-",
            category1: "-",
            category4: "-",
          });
          data.tableData.forEach((tableData) => {
            //表のデータ
            csvData.push({
              routeName: data.routeName,
              standardName: standardDropdown.displayName,
              userFactoryName: factoryDropdown.displayName,
              hierarchy: "2",
              total: "-",
              category1Total: "-",
              category4Total: "-",
              process: tableData.process,
              productName: tableData.productName,
              productPurpose: tableData.productPurpose,
              factoryName: tableData.factoryName,
              category1: tableData.category1.toString(),
              category4: tableData.category4.toString(),
            });
          });
        });
      });
    });

    const csvFileContent = CsvUtil.stringify(
      [
        { keyName: "routeName", headerName: "ルートNo" },
        { keyName: "standardName", headerName: "規格" },
        { keyName: "userFactoryName", headerName: "自社工場" },
        { keyName: "hierarchy", headerName: "階層" },
        { keyName: "total", headerName: "統合原単位" },
        { keyName: "category1Total", headerName: "原単位(カテゴリ1)" },
        { keyName: "category4Total", headerName: "原単位(カテゴリ4)" },
        { keyName: "process", headerName: "工程" },
        { keyName: "productName", headerName: "製品" },
        { keyName: "process", headerName: "製品区分" },
        { keyName: "factoryName", headerName: "工場" },
        { keyName: "category1", headerName: "カテゴリ1" },
        { keyName: "category4", headerName: "カテゴリ4" },
      ],
      csvData
    );
    // ダウンロードしたい文字列をBlobに変換する
    // 文字化け対策のため、ファイル先頭にBOMを挿入し、ファイルがUTF-8であることを明示する
    const UTF8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([UTF8_BOM, csvFileContent], {
      type: "text/csv",
    });
    // CSVファイルとしてダウンロード
    const csvFileName = "ルート別排出原単位一覧";
    DownloadUtil.download(blob, csvFileName);
  };

  return (
    <UserIntensityTemplate
      yearDropdownProps={yearDropdownProps}
      factoryDropdownProps={factoryDropdownProps}
      standardDropdownProps={standardDropdownProps}
      isProcessing={isProcessing}
      getCsv={getCsv}
      data={data}
      isNoData={isNoData}
      isApiError={isApiError}
    />
  );
};

type RouteDataType = {
  factoryType: FactoryType;
  productName: string;
  productPurpose?: ProductPurpose;
  factoryNameForOthers: string;
  companyId: string;
  companyName: string;
  category1Co2Intensity: number;
  category4Co2Intensity: number;
};
const getTableData = (routeData: RouteDataType[]): RouteListRowType[] => {
  const tableData = [];
  const getPushData = (input: {
    process: string;
    productPurpose: string;
    dataArray: RouteDataType[];
  }): RouteListRowType | undefined => {
    if (input.dataArray.length > 0) {
      return {
        process: input.process,
        productName:
          input.dataArray[0].factoryType === "paper"
            ? input.dataArray[0].productName
            : "-",
        productPurpose: input.productPurpose,
        factoryName: concatFactoryName(
          input.dataArray.map((data) => {
            return {
              factoryNameForOthers: data.factoryNameForOthers,
              companyId: data.companyId,
              companyName: data.companyName,
            };
          })
        ),
        category1: sum(
          ...input.dataArray.map((data) => data.category1Co2Intensity)
        ),
        category4: sum(
          ...input.dataArray.map((data) => data.category4Co2Intensity)
        ),
      };
    }
    return undefined;
  };

  const paperData = routeData.filter((data) => data.factoryType === "paper");
  const frontLinearData = getPushData({
    process: "製紙工程",
    productPurpose: "表ライナー",
    dataArray: paperData.filter(
      (data) => data.productPurpose === "frontLinear"
    ),
  });
  frontLinearData !== undefined && tableData.push(frontLinearData);

  const backLinearData = getPushData({
    process: "製紙工程",
    productPurpose: "裏ライナー",
    dataArray: paperData.filter((data) => data.productPurpose === "backLinear"),
  });
  backLinearData !== undefined && tableData.push(backLinearData);

  const corrugationMediumData = getPushData({
    process: "製紙工程",
    productPurpose: "中芯",
    dataArray: paperData.filter(
      (data) => data.productPurpose === "corrugationMedium"
    ),
  });
  corrugationMediumData !== undefined && tableData.push(corrugationMediumData);

  const processData = getPushData({
    process: "加工",
    productPurpose: "-",
    dataArray: routeData.filter((data) => data.factoryType === "process"),
  });
  processData !== undefined && tableData.push(processData);

  return tableData;
};

const sortValueFunc = (
  a: { value: string; displayName: string },
  b: { value: string; displayName: string }
) => {
  const sa = String(a.value).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  const sb = String(b.value).replace(/(\d+)/g, (m) => m.padStart(30, "0"));
  return sa < sb ? -1 : sa > sb ? 1 : 0;
};

const getFactoryDropdownItems = (byRouteData: GetScope3ByRouteQueryResult) => {
  const uniqueFactoryIds = [
    ...new Set(
      byRouteData.userFactories.map((userFactory) => userFactory.factoryId)
    ),
  ];
  return uniqueFactoryIds
    .map((factoryId) => {
      const factoryInfo = byRouteData.userFactories.find(
        (userFactory) => userFactory.factoryId === factoryId
      );
      if (factoryInfo === undefined) {
        throw new Error(`never come here: ${factoryId}`);
      }
      return {
        value: factoryId,
        displayName: factoryInfo.factoryName,
      };
    })
    .sort(sortValueFunc);
};
const getStandardDropdownItems = (
  byRouteData: GetScope3ByRouteQueryResult,
  factoryDropdownValue: string
) => {
  if (factoryDropdownValue === "") {
    return [];
  }
  const duplicatedItems = byRouteData.userFactories
    .filter((userFactory) => userFactory.factoryId === factoryDropdownValue)
    .flatMap((userFactory) => {
      return userFactory.routes.map((route) => {
        return {
          value: route.standardType,
          displayName: route.standardShortName,
        };
      });
    });
  return [
    ...new Map(
      duplicatedItems.map((standardType) => {
        return [standardType.value, standardType];
      })
    ).values(),
  ].sort(sortValueFunc);
};

const createData = (
  factoryDropdownValue: string,
  standardDropdownValue: string,
  byRouteData?: GetScope3ByRouteQueryResult
) => {
  const data = [];
  if (byRouteData !== undefined) {
    const selectedUserFactory = byRouteData.userFactories.find(
      (userFactory) => userFactory.factoryId === factoryDropdownValue
    );
    if (selectedUserFactory !== undefined) {
      const selectedRoutes = selectedUserFactory.routes.filter(
        (route) => route.standardType === standardDropdownValue
      );
      const completedRoutes = selectedRoutes.filter(
        (
          route
        ): route is {
          routeId: string;
          routeName: string;
          standardType: StandardType;
          standardName: string;
          standardShortName: string;
          supplierFactories: {
            companyId: string;
            companyName: string;
            factoryId: string;
            factoryType: FactoryType;
            factoryName: string;
            factoryNameForSelf: string;
            factoryNameForOthers: string;
            productType: ProductType;
            productName: string;
            productShortName: string;
            productPurpose?: ProductPurpose;
            category1Co2Intensity: number;
            category4Co2Intensity: number;
          }[];
        } =>
          route.supplierFactories.length > 0 &&
          route.supplierFactories.every(
            (it) =>
              it.category1Co2Intensity !== undefined &&
              it.category4Co2Intensity !== undefined
          )
      );
      for (const route of completedRoutes) {
        const tableData = getTableData(route.supplierFactories);
        const category1Total = sum(
          ...route.supplierFactories.map((it) => it.category1Co2Intensity)
        );
        const category4Total = sum(
          ...route.supplierFactories.map((it) => it.category4Co2Intensity)
        );
        data.push({
          routeId: route.routeId,
          routeName: route.routeName,
          total: category1Total + category4Total,
          category1Total: category1Total,
          category4Total: category4Total,
          tableData: tableData,
        });
      }
    }
  }
  data.sort((a, b) => a.total - b.total);

  return data;
};
