/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type FactoryType = (typeof FactoryType)[keyof typeof FactoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FactoryType = {
  paper: "paper",
  process: "process",
  user: "user",
} as const;
