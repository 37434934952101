import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { AppTheme } from "src/app/configs";

const useStyles = makeStyles<AppTheme>((theme) => ({
  footer: {
    color: theme.palette.footer,
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <Box py={3} display="flex" justifyContent="center">
      <Typography variant="caption" className={classes.footer}>
        Copyright © Marubeni Corporation All Rights Reserved.
      </Typography>
    </Box>
  );
};
