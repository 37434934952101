import { MenuItem, Select, SelectProps } from "@mui/material";
import React, { FocusEventHandler } from "react";

export type DropDownItem<T extends string = string> = {
  value: T;
  displayName: string;
};

type AdditionalProps = {
  items: DropDownItem[];
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  hasError?: boolean;
};

export type DropDownInputProps = AdditionalProps &
  Omit<SelectProps, keyof AdditionalProps>;

export const DropDownInput: React.FC<DropDownInputProps> = (props) => {
  const { items, value, onChange, onBlur, hasError, ...selectProps } = props;

  return (
    <Select
      {...selectProps}
      value={value}
      error={hasError}
      inputProps={{ onChange: onChange }}
      onBlur={onBlur}
      fullWidth
    >
      {props.items.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.displayName}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const parseDropDownItem = (obj: unknown): DropDownItem => {
  if (typeof obj !== "object" || obj === null) {
    throw new TypeError("parseDropDownItem: non object");
  }

  const { value, displayName }: { value?: unknown; displayName?: unknown } =
    obj;

  if (typeof value !== "string") {
    throw new TypeError("parseDropDownItem: value must be string");
  }
  if (typeof displayName !== "string") {
    throw new TypeError("parseDropDownItem: displayName must be string");
  }
  return { value, displayName };
};
