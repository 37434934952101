import { Box, Grid } from "@mui/material";
import produce from "immer";
import { Draft, WritableDraft } from "immer/dist/internal";
import React, { Dispatch, SetStateAction } from "react";

import { GetProduct200ProductsItem, CompanyType } from "src/app/apis/model";
import { Annotate } from "src/app/components/atoms";
import {
  RowTextField,
  SectionHeading,
  SubSectionHeading,
} from "src/app/components/molecules";
import {
  Field,
  GhgInputCategory1Form,
  MachineForm,
} from "src/app/components/pages";
import {
  ElectricPowerAmount,
  MaterialAmount,
  ProductAmount,
  SteamAmount,
  WaterAmount,
} from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";

export type ManufacturingInputProps = {
  machine: MachineForm;
  products: GetProduct200ProductsItem[];
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  isEditable: boolean;
  bindPaperTextField?: (params: {
    selector: (
      form: MachineForm | WritableDraft<MachineForm>
    ) => Field | Draft<Field> | undefined;
    validator: (value: string) => boolean;
    isRequired: boolean;
  }) => {
    value: string;
    hasError: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => void;
  };
  companyType: Exclude<CompanyType, "user-company">;
};

export const ManufacturingInput: React.FC<ManufacturingInputProps> = React.memo(
  (props) => {
    const bindProcessTextField = useBindTextField(
      props.machine,
      (machineAction) =>
        props.setForm(
          produce<GhgInputCategory1Form>((form) => {
            if (machineAction instanceof Function) {
              form.manufacturing.machines[0] = machineAction(
                form.manufacturing.machines[0]
              );
            } else {
              form.manufacturing.machines[0] = machineAction;
            }
          })
        )
    );

    const bindTextField = props.bindPaperTextField
      ? props.bindPaperTextField
      : bindProcessTextField;

    return (
      <Box>
        {props.companyType === "paper-supplier" && (
          <SectionHeading title="年間生産量">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                {props.products.map((product, index) => {
                  return (
                    props.products.length / 2 > index && (
                      <RowTextField
                        key={props.machine.key + product.productType}
                        label={`${product.productName} [t/年]`}
                        {...bindTextField({
                          validator: ProductAmount.Rule,
                          selector: (form) =>
                            form.paperDeals.find(
                              (it) => it.productType === product.productType
                            )?.field,
                          isRequired: true,
                        })}
                        maxLength={ProductAmount.MaxLength}
                        errorMessage={ProductAmount.Message}
                        disabled={!props.isEditable}
                        inputMode="numeric"
                      />
                    )
                  );
                })}
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                {props.products.map((product, index) => {
                  return (
                    props.products.length / 2 <= index && (
                      <RowTextField
                        key={props.machine.key + product.productType}
                        label={`${product.productName} [t/年]`}
                        {...bindTextField({
                          validator: ProductAmount.Rule,
                          selector: (form) =>
                            form.paperDeals.find(
                              (it) => it.productType === product.productType
                            )?.field,
                          isRequired: true,
                        })}
                        maxLength={ProductAmount.MaxLength}
                        errorMessage={ProductAmount.Message}
                        disabled={!props.isEditable}
                        inputMode="numeric"
                      />
                    )
                  );
                })}
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </SectionHeading>
        )}

        <SectionHeading
          title="マテリアル投入量"
          subtitle={
            props.companyType === "paper-supplier" ? (
              <Annotate>
                硫酸バンド、紙力増強剤は原液換算した数値を入力してください。
              </Annotate>
            ) : undefined
          }
          mt={3}
        >
          {props.companyType === "paper-supplier" && (
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <RowTextField
                  label="古紙 [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.usedPaper,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label="硫酸バンド [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) =>
                      form.materialConsumptions.aluminiumSulfate,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label="染料 [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.dye,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label="サイズ剤 [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.sizingAgent,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <RowTextField
                  label="製紙チップ [BDT/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.paperChip,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label={
                    <span>
                      バージンパルプ [t/年]
                      <br />
                      （自社製造分）
                    </span>
                  }
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) =>
                      form.materialConsumptions.internalVirginPulp,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label={
                    <span>
                      バージンパルプ [t/年]
                      <br />
                      （外部調達分）
                    </span>
                  }
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) =>
                      form.materialConsumptions.externalVirginPulp,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />

                <RowTextField
                  label="紙力増強剤 [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) =>
                      form.materialConsumptions.strengtheningAgent,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
          {props.companyType !== "paper-supplier" && (
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <RowTextField
                  label="スターチ [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.starch,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
                <RowTextField
                  label="ニス [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.varnish,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <RowTextField
                  label="インク [t/年]"
                  {...bindTextField({
                    validator: MaterialAmount.Rule,
                    selector: (form) => form.materialConsumptions.ink,
                    isRequired: true,
                  })}
                  maxLength={MaterialAmount.MaxLength}
                  errorMessage={MaterialAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
        </SectionHeading>

        <SectionHeading
          title={
            props.companyType === "paper-supplier"
              ? "エネルギー・水投入量"
              : "水投入量"
          }
          mt={3}
          subtitle={
            props.companyType === "paper-supplier" ? (
              <Annotate>
                バージンパルプ製造工程におけるエネルギー量は含めずご入力ください。
              </Annotate>
            ) : undefined
          }
        >
          {props.companyType === "paper-supplier" && (
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <SubSectionHeading title="エネルギー投入量" flex={1}>
                  <RowTextField
                    label="蒸気 [t/年]"
                    {...bindTextField({
                      validator: SteamAmount.Rule,
                      selector: (form) => form.steamConsumption,
                      isRequired: true,
                    })}
                    maxLength={SteamAmount.MaxLength}
                    errorMessage={SteamAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                  <RowTextField
                    label="電力 [kWh/年]"
                    {...bindTextField({
                      validator: ElectricPowerAmount.Rule,
                      selector: (form) => form.electricPowerConsumption,
                      isRequired: true,
                    })}
                    maxLength={ElectricPowerAmount.MaxLength}
                    errorMessage={ElectricPowerAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                </SubSectionHeading>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <SubSectionHeading title="水投入量" flex={1}>
                  <RowTextField
                    label={
                      <span>
                        工業用水 [千m<sup>3</sup>/年]
                      </span>
                    }
                    {...bindTextField({
                      validator: WaterAmount.Rule,
                      selector: (form) => form.industrialWaterConsumption,
                      isRequired: true,
                    })}
                    maxLength={WaterAmount.MaxLength}
                    errorMessage={WaterAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                  <RowTextField
                    label={
                      <span>
                        地下水・河川水 <br />
                        [千m<sup>3</sup>/年]
                      </span>
                    }
                    {...bindTextField({
                      validator: WaterAmount.Rule,
                      selector: (form) => form.groundWaterConsumption,
                      isRequired: true,
                    })}
                    maxLength={WaterAmount.MaxLength}
                    errorMessage={WaterAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                </SubSectionHeading>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
          {props.companyType !== "paper-supplier" && (
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <RowTextField
                  label={
                    <span>
                      工業用水 [千m<sup>3</sup>/年]
                    </span>
                  }
                  {...bindTextField({
                    validator: WaterAmount.Rule,
                    selector: (form) => form.industrialWaterConsumption,
                    isRequired: true,
                  })}
                  maxLength={WaterAmount.MaxLength}
                  errorMessage={WaterAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <RowTextField
                  label={
                    <span>
                      地下水・河川水 <br />
                      [千m<sup>3</sup>/年]
                    </span>
                  }
                  {...bindTextField({
                    validator: WaterAmount.Rule,
                    selector: (form) => form.groundWaterConsumption,
                    isRequired: true,
                  })}
                  maxLength={WaterAmount.MaxLength}
                  errorMessage={WaterAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
        </SectionHeading>
      </Box>
    );
  }
);
