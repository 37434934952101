import { ReactElement } from "react";

import { Text } from "src/app/components/atoms";
import { ProcurementsInputField } from "src/app/components/molecules";
import { AutoCompleteType, InputModeOptions } from "src/lib/components/atoms";

export type ProcurementsTextFieldProps = {
  label: string | ReactElement;
  errorMessage?: string;
  maxLength?: number;
  secure?: boolean;
  autoComplete?: AutoCompleteType;
  inputMode?: InputModeOptions;
  value?: string;
  hasError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  valiant?: "h4" | "h5" | "h6";
};

export const ProcurementsTextField: React.FC<ProcurementsTextFieldProps> = (
  props
) => {
  return (
    <ProcurementsInputField
      label={props.label}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      valiant={props.valiant}
    >
      <Text
        maxLength={props.maxLength}
        secure={props.secure}
        autoComplete={props.autoComplete}
        inputMode={props.inputMode}
        value={props.value}
        hasError={props.hasError}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </ProcurementsInputField>
  );
};
