import { Backdrop, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export type FullScreenSpinnerProps = {
  open: boolean;
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const FullScreenSpinner: React.FC<FullScreenSpinnerProps> = (props) => {
  const classes = useStyles();
  return (
    <Backdrop open={props.open} className={classes.backdrop}>
      <CircularProgress size={64} color="secondary" />
    </Backdrop>
  );
};
