import {
  PutTempProcurementsMutationBody,
  PutTempProcurementsMutationError,
  PutTempProcurementsMutationResult,
  putTempProcurements,
} from "src/app/apis/client/default";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PutUserTempProcurementApiError extends ApiError<
  "not-authenticated" | "network" | "conflict" | "unknown"
> {}

export class UserTempProcurementApi {
  async putUserTempProcurement(
    req: PutTempProcurementsMutationBody
  ): Promise<PutTempProcurementsMutationResult> {
    try {
      const res = await putTempProcurements(req);
      return res;
    } catch (err) {
      const { errorCode } = err as PutTempProcurementsMutationError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PutUserTempProcurementApiError("network", err);
        // NOTE : フロントエンド目線でハンドリングする価値がない（ユーザーにとってはunknownのエラーと同じ）
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "ERR028000":
          throw new PutUserTempProcurementApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new PutUserTempProcurementApiError("not-authenticated", err);
        case "ERR028001":
          throw new PutUserTempProcurementApiError("conflict", err);

        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
