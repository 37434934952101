import { DateTime } from "luxon";

import {
  GetCategory1ApiError,
  PaperCategory1Api,
  ProcessCategory1Api,
} from "src/app/apis/category1-api/category1Api";
import {
  ClassificationType,
  MiddleClassificationType,
} from "src/app/apis/model";
import { messages } from "src/app/configs";
import {
  AuthState,
  PaperFactoryCategory1FactoryDataForDisplay,
  ProcessFactoryCategory1FactoryDataForDisplay,
} from "src/app/models";
import { ToDateTimeYearFromString } from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

export type PaperCategory1DataForDisplay = {
  companyId: string;
  year: DateTime;
  factories: PaperFactoryCategory1FactoryDataForDisplay[];
};

export type ProcessCategory1DataForDisplay = {
  companyId: string;
  year: DateTime;
  factories: ProcessFactoryCategory1FactoryDataForDisplay[];
};

const getClassification = (classificationType: ClassificationType) => {
  const middleClassificationList: {
    type: MiddleClassificationType;
    name: string;
  }[] = [
    {
      type: "energy",
      name: "エネルギー",
    },
    {
      type: "disposal",
      name: "廃棄",
    },
    {
      type: "water",
      name: "水",
    },
    {
      type: "materials",
      name: "マテリアル",
    },
  ];
  const classificationList: {
    type: ClassificationType;
    name: string;
    middleClassification: MiddleClassificationType;
  }[] = [
    {
      type: "privatePower",
      name: "自家電力",
      middleClassification: "energy",
    },
    {
      type: "privateSteam",
      name: "自家蒸気",
      middleClassification: "energy",
    },
    {
      type: "purchasedPower",
      name: "購入電力",
      middleClassification: "energy",
    },
    {
      type: "purchasedSteam",
      name: "購入蒸気",
      middleClassification: "energy",
    },
    {
      type: "certificate",
      name: "証書",
      middleClassification: "energy",
    },
    {
      type: "waste",
      name: "廃棄物処理",
      middleClassification: "disposal",
    },
    {
      type: "drainage",
      name: "排水処理",
      middleClassification: "disposal",
    },
    { type: "water", name: "水", middleClassification: "water" },
    {
      type: "mainMaterial",
      name: "主原料",
      middleClassification: "materials",
    },
    {
      type: "chemicals",
      name: "薬品",
      middleClassification: "materials",
    },
    {
      type: "dye",
      name: "染料",
      middleClassification: "materials",
    },
    {
      type: "starch",
      name: "スターチ",
      middleClassification: "materials",
    },
    {
      type: "ink",
      name: "インク",
      middleClassification: "materials",
    },
    {
      type: "varnish",
      name: "ニス",
      middleClassification: "materials",
    },
  ];

  const classification = classificationList.find(
    ({ type }) => type === classificationType
  );

  if (classification === undefined) {
    throw new Error(classificationType);
  }

  const middleClassification = middleClassificationList.find(
    ({ type }) => type === classification.middleClassification
  );

  if (middleClassification === undefined) {
    throw new Error(classification.middleClassification);
  }

  return {
    classificationName: classification.name,
    middleClassificationType: middleClassification.type,
    middleClassificationName: middleClassification.name,
  };
};

export const useCategory1Data = (
  year?: string
): {
  isProcessing: boolean;
  category1DataForDisplay?:
    | PaperCategory1DataForDisplay
    | ProcessCategory1DataForDisplay;
} => {
  const snackbar = useCustomSnackbar();
  const [authState, setAuthState] = useAuthState<AuthState>();

  const { isProcessing, data } = useAsyncEffect(async () => {
    if (year === undefined) {
      return;
    }

    try {
      let category1Api;
      let data;
      if (authState?.role === "paper-supplier") {
        category1Api = new PaperCategory1Api();
        const res = await category1Api.getCategory1({
          year: year,
        });

        // データ整形
        data = {
          ...res,
          year: ToDateTimeYearFromString(res.year),
          factories: res.factories.map((factory) => ({
            ...factory,
            byProduct: factory.byProduct.map((it) => ({
              ...it,
            })),
            byClassification: factory.byClassification.map((it) => {
              const classification = getClassification(it.classificationType);
              return {
                ...it,
                ...classification,
              };
            }),
          })),
        };
      } else {
        category1Api = new ProcessCategory1Api();
        const res = await category1Api.getCategory1({
          year: year,
        });

        // データ整形
        data = {
          ...res,
          year: ToDateTimeYearFromString(res.year),
          factories: res.factories.map((factory) => ({
            ...factory,
            byClassification: factory.byClassification.map((it) => {
              const classification = getClassification(it.classificationType);
              return {
                ...it,
                ...classification,
              };
            }),
          })),
        };
      }
      return data;
    } catch (err) {
      if (!(err instanceof GetCategory1ApiError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          break;
        case "network":
          snackbar.error(messages.common.network, {
            preventDuplicate: true,
          });
          break;
        case "unknown":
          snackbar.error(messages.common.unknown, {
            preventDuplicate: true,
          });
          break;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }, [year]);

  const category1DataForDisplay = data;

  return { isProcessing, category1DataForDisplay };
};
