import { useState } from "react";

import { ConfirmationDialogProps } from "src/app/components/organisms";

export const useConfirmationDialog = (
  message: string,
  onClickOk: () => void
): {
  openDialog: () => void;
  dialogProps: ConfirmationDialogProps;
} => {
  const [open, setOpen] = useState(false);
  return {
    openDialog: () => {
      setOpen(true);
    },
    dialogProps: {
      open,
      onClickOk: () => {
        setOpen(false);
        onClickOk();
      },
      onClickCancel: () => {
        setOpen(false);
      },
      message,
    },
  };
};
