import { Navigate, generatePath, useNavigate } from "react-router-dom";

import {
  GetScope3ByFactoryQueryResult,
  OldGetScope3ByStandardQueryResult,
} from "src/app/apis/client/default";
import {
  GetByFactoryApiError,
  GetByStandardError,
  Scope3Api,
} from "src/app/apis/scope3-api/scope3Api";
import { UserDashboardTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { useDefaultYear, useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { compareFunc } from "src/app/utils/computeForVisuals";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";
import { sum } from "src/lib/utils/mathUtil";

//TODO:画面実装時にOldから置き換える
export const byStandardConvertToChartData = (
  data?: OldGetScope3ByStandardQueryResult
) => {
  const standardData =
    data !== undefined
      ? data.bySupplier.flatMap((supplier) => {
          return supplier.bySupplierFactory.flatMap((factory) => {
            return factory.byStandard;
          });
        })
      : [];

  const standardTypes = [
    ...new Set(standardData.map((it) => it.standardType)),
  ].sort(compareFunc);

  const chartData = standardTypes.map((standardType) => {
    const filteredData = standardData.filter(
      (it) => it.standardType === standardType
    );
    return {
      dataName: filteredData[0].standardShortName,
      category1Co2Emission: sum(
        ...filteredData.map((it) => it.category1Co2Emission)
      ),
      category4Co2Emission: sum(
        ...filteredData.map((it) => it.category4Co2Emission)
      ),
    };
  });

  return chartData;
};

const byFactoryConvertToChartData = (data?: GetScope3ByFactoryQueryResult) => {
  const userFactoryData =
    data !== undefined
      ? data.bySupplier.flatMap((supplier) => {
          return supplier.bySupplierFactory.flatMap((factory) => {
            return factory.byUserFactory;
          });
        })
      : [];

  const userFactoryIds = [
    ...new Set(userFactoryData.map((it) => it.factoryId)),
  ];

  const chartData = userFactoryIds.map((userFactoryId) => {
    const filteredData = userFactoryData.filter(
      (it) => it.factoryId === userFactoryId
    );

    return {
      dataName: filteredData[0].factoryName,
      category1Co2Emission: sum(
        ...filteredData.map((it) => it.category1Co2Emission)
      ),
      category4Co2Emission: sum(
        ...filteredData.map((it) => it.category4Co2Emission)
      ),
    };
  });

  return chartData;
};

export const UserDashboardPage: React.FC = () => {
  const [authState, setAuthState] = useAuthState<AuthState>();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();

  const yearDropdownProps = useYearDropdown();

  const { isProcessing: isByStandardProcessing, data: byStandardData } =
    useAsyncEffect(async () => {
      if (yearDropdownProps?.value === undefined) {
        return;
      }

      const scope3Api = new Scope3Api();

      try {
        return await scope3Api.getByStandard({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      } catch (err) {
        if (!(err instanceof GetByStandardError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            break;
          case "network":
            snackbar.error(messages.common.network, {
              preventDuplicate: true,
            });
            break;
          case "unknown":
            snackbar.error(messages.common.unknown, {
              preventDuplicate: true,
            });
            break;
          case "no-data":
            break;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }, [yearDropdownProps?.value]);

  const { isProcessing: isByFactoryProcessing, data: byFactoryData } =
    useAsyncEffect(async () => {
      if (yearDropdownProps?.value === undefined) {
        return;
      }

      const scope3Api = new Scope3Api();

      try {
        return await scope3Api.getByFactory({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      } catch (err) {
        if (!(err instanceof GetByFactoryApiError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            break;
          case "network":
            snackbar.error(messages.common.network, {
              preventDuplicate: true,
            });
            break;
          case "unknown":
            snackbar.error(messages.common.unknown, {
              preventDuplicate: true,
            });
            break;
          case "no-data":
            break;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }, [yearDropdownProps?.value]);

  const [defaultYear] = useDefaultYear();

  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return (
      <Navigate
        to={generatePath(paths.user.dashboard.year, {
          year: defaultYear.toFormat("yyyy"),
        })}
      />
    );
  }

  if (authState === undefined || authState.role !== "user-company") {
    return <Navigate to="/login" />;
  }

  const getOnClickDetail = (
    category: "category1" | "category4",
    type: "total" | "standard" | "factory"
  ) => {
    const year =
      yearDropdownProps.value === undefined
        ? defaultYear
        : yearDropdownProps.value;

    const urls = {
      category1: {
        total: `/user/category1/${year}/total-emission`,
        standard: `/user/category1/${year}/emission-by-standard`,
        factory: `/user/category1/${year}/emission-by-factory`,
      },
      category4: {
        total: `/user/category4/${year}/total-emission`,
        standard: `/user/category4/${year}/emission-by-standard`,
        factory: `/user/category4/${year}/emission-by-factory`,
      },
    };

    return () => {
      navigate(urls[category][type]);
    };
  };

  const byStandardChartData = byStandardConvertToChartData(byStandardData);
  const byFactoryChartData = byFactoryConvertToChartData(byFactoryData);
  return (
    <UserDashboardTemplate
      isProcessing={isByStandardProcessing || isByFactoryProcessing}
      yearDropdownProps={yearDropdownProps}
      category1TotalEmissionProps={{
        data: sum(...byStandardChartData.map((it) => it.category1Co2Emission)),
        onClick: getOnClickDetail("category1", "total"),
      }}
      category4TotalEmissionProps={{
        data: sum(...byStandardChartData.map((it) => it.category4Co2Emission)),
        onClick: getOnClickDetail("category4", "total"),
      }}
      category1EmissionByStandardProps={{
        data: byStandardChartData.map((it) => {
          return {
            dataName: it.dataName,
            value: it.category1Co2Emission,
          };
        }),
        onClick: getOnClickDetail("category1", "standard"),
      }}
      category4EmissionByStandardProps={{
        data: byStandardChartData.map((it) => {
          return {
            dataName: it.dataName,
            value: it.category4Co2Emission,
          };
        }),
        onClick: getOnClickDetail("category4", "standard"),
      }}
      category1EmissionByFactoryProps={{
        data: byFactoryChartData.map((it) => {
          return {
            dataName: it.dataName,
            value: it.category1Co2Emission,
          };
        }),
        onClick: getOnClickDetail("category1", "factory"),
      }}
      category4EmissionByFactoryProps={{
        data: byFactoryChartData.map((it) => {
          return {
            dataName: it.dataName,
            value: it.category4Co2Emission,
          };
        }),
        onClick: getOnClickDetail("category4", "factory"),
      }}
    />
  );
};
