import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import chroma from "chroma-js";

import { CompanyType } from "src/app/apis/model";
import { AppCard } from "src/app/components/atoms";
import { PieChartCard } from "src/app/components/molecules";
import {
  ChartAndTableViewUser,
  DataField,
  PieChart,
} from "src/app/components/organisms";
import { theme } from "src/app/configs";
import Arrow from "src/app/images/arrow.png";
import Arrow2x from "src/app/images/arrow@2x.png";
import noDataImage from "src/app/images/noData.png";
import noDataImage2x from "src/app/images/noData@2x.png";
import { SuppliersForTotalEmission } from "src/app/models";
import { getCompanyTypeName } from "src/app/utils/computeForVisuals";
import { FlexBox, Image } from "src/lib/components/atoms";

export type UserTotalEmissionTemplateProps = {
  isProcessing: boolean;
  category: "category1" | "category4";

  // グラフ用Props
  chartData: SuppliersForTotalEmission[];

  // 表用Props
  tableColumns: GridColDef[];
  tableData: GridRowsProp;
  fileName: string;
};

const useStyles = makeStyles((theme) => ({
  chartCard: {
    height: "100%",
    maxWidth: "50%",
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export const UserTotalEmissionTemplate: React.FC<
  UserTotalEmissionTemplateProps
> = (props) => {
  const classes = useStyles();

  const paperSupplier = props.chartData.filter(
    (it) => it.companyType === "paper-supplier"
  );
  const processSupplier = props.chartData.filter(
    (it) => it.companyType === "process-supplier"
  );

  return (
    <ChartAndTableViewUser
      cardTitle="年間GHG排出量内訳"
      tableElement={
        <DataField
          columns={props.tableColumns}
          data={props.tableData}
          fileName={props.fileName}
          variant={
            props.category === "category1" ? "cate1Header" : "cate4Header"
          }
        />
      }
      backgroundColor={
        props.category === "category1"
          ? theme.palette.cate1ForUserCompanyBackground
          : theme.palette.cate4ForUserCompanyBackground
      }
    >
      {!props.isProcessing && props.chartData.length === 0 ? (
        <AppCard className={classes.card}>
          <FlexBox alignItems="center">
            <Image
              alt="NoData"
              src={noDataImage}
              src2x={noDataImage2x}
              width="100%"
            />
          </FlexBox>
        </AppCard>
      ) : (
        <Grid container spacing={2}>
          {!(paperSupplier.length === 0) && (
            <Grid item xs className={classes.chartCard}>
              <PieChartCard
                titleElement={
                  props.category === "category1"
                    ? getCategory1TitleElement("paper-supplier")
                    : getCategory4TitleElement("paper-supplier")
                }
                chartElement={
                  <PieChart
                    colors={getColors(
                      theme.palette.pieChart.paper,
                      paperSupplier.length
                    )}
                    data={paperSupplier}
                  />
                }
                noData={paperSupplier.length === 0}
                isProcessing={props.isProcessing}
              />
            </Grid>
          )}
          {!(processSupplier.length === 0) && (
            <Grid item xs className={classes.chartCard}>
              <PieChartCard
                titleElement={
                  props.category === "category1"
                    ? getCategory1TitleElement("process-supplier")
                    : getCategory4TitleElement("process-supplier")
                }
                chartElement={
                  <PieChart
                    colors={getColors(
                      theme.palette.pieChart.process,
                      processSupplier.length
                    )}
                    data={processSupplier}
                  />
                }
                noData={processSupplier.length === 0}
                isProcessing={props.isProcessing}
              />
            </Grid>
          )}
        </Grid>
      )}
    </ChartAndTableViewUser>
  );
};

const getColors = (color: string, dataCount: number) => {
  const colors = [];
  for (let i = 0; i < dataCount; ++i) {
    colors.push(
      chroma(color)
        .alpha(1 - (1 / dataCount) * i)
        .hex()
    );
  }
  return colors;
};

const getCategory1TitleElement = (
  companyType: "paper-supplier" | "process-supplier"
) => {
  return (
    <FlexBox alignItems="center" mb={2}>
      <Typography variant="h4">{getCompanyTypeName(companyType)}</Typography>
    </FlexBox>
  );
};

const getCategory4TitleElement = (
  companyType: "paper-supplier" | "process-supplier"
) => {
  const pair: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "paper-supplier": CompanyType[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "process-supplier": CompanyType[];
  } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "paper-supplier": ["process-supplier"],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "process-supplier": ["user-company"],
  };
  return (
    <FlexBox alignItems="center" mb={2}>
      <Typography variant="h5">{getCompanyTypeName(companyType)}</Typography>
      <FlexBox ml={1}>
        <Image alt="arrow" src={Arrow} src2x={Arrow2x} />
      </FlexBox>
      {pair[companyType].map((pairCompany) => {
        return (
          <FlexBox alignItems="center" key={undefined} ml={1}>
            <Typography variant="h5">
              {getCompanyTypeName(pairCompany)}
            </Typography>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
