class LoadConfigContext {
  constructor(private readonly name: string, private readonly value: string) {}

  toString(): string {
    return this.value;
  }

  toNumber(): number {
    const parsedValue = Number(this.value);
    if (Number.isNaN(parsedValue)) {
      throw new Error(`${this.name} must be number`);
    }
    return parsedValue;
  }

  toBoolean(): boolean {
    const lowerCaseValue = this.value.toLowerCase();
    if (lowerCaseValue !== "true" && lowerCaseValue !== "false") {
      throw new Error(`${this.name} must be boolean`);
    }
    return lowerCaseValue === "true";
  }

  toObject<T>(parser: (object: unknown) => T): T {
    let json: unknown;
    try {
      json = JSON.parse(this.value);
    } catch (err) {
      throw new Error(`${this.name} must be JSON`);
    }

    if (typeof json !== "object" || json === null || json instanceof Array) {
      throw new Error(`${this.name} must be JSON object`);
    }

    let result: T;
    try {
      result = parser(json);
    } catch (err) {
      throw new Error(`${this.name} is invalid format`);
    }

    return result;
  }

  toArrayOf<T>(parser: (object: unknown) => T): T[] {
    let json: unknown;
    try {
      json = JSON.parse(this.value);
    } catch (err) {
      throw new Error(`${this.name} must be JSON`);
    }

    if (!(json instanceof Array)) {
      throw new Error(`${this.name} must be JSON array`);
    }
    const elemArray: unknown[] = json;

    let result: T[];
    try {
      result = elemArray.map(parser);
    } catch (err) {
      throw new Error(`${this.name} has invalid element`);
    }

    return result;
  }
}

export const loadConfig = (envName: string) => {
  const envValue = process.env[envName];
  if (envValue === undefined || envValue === "") {
    throw new Error(`${envName} must not be empty`);
  }
  return new LoadConfigContext(envName, envValue);
};
