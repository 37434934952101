import React from "react";
import { createRoot } from "react-dom/client";

import { worker } from "./app/apis/mock/worker";
import { envConfig } from "./app/configs";

import { App } from "src/app/components/App";
import { reportWebVitals } from "src/reportWebVitals";

if (envConfig.enableApiMock) {
  void worker.start();
}

/*
 * 画面幅の小さいデバイスでは、十分な幅を持つ仮想的な画面にコンテンツを描画し、それを実際の画面に縮小して表示させたい。
 * これを実現するために viewport を使用する。
 * React の初回レンダリングに先立って viewport を設定しておく。
 */
const setViewport = (): void => {
  const viewport = document.querySelector('head meta[name="viewport"]');
  if (viewport === null) {
    console.error("Meta tag for viewport was not found");
    return;
  }

  // window.screen.width は viewport 適用前の「生の」デバイスの画面幅を表す。
  // cf. https://developer.mozilla.org/ja/docs/Web/API/Window/screen
  // cf. https://qiita.com/ryounagaoka/items/045b2808a5ed43f96607#%E3%82%B9%E3%83%9E%E3%83%9B%E5%88%A4%E5%AE%9A%E3%81%ABwindowscreenwidth%E3%82%92%E4%BD%BF%E3%81%86
  const tabletTargetDeviceWidth = 1025;
  if (window.screen.width < tabletTargetDeviceWidth) {
    viewport.setAttribute(
      "content",
      `width=${tabletTargetDeviceWidth}, maximum-scale=1.0, viewport-fit=cover`
    );
  }
};
setViewport();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
