import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EChartsOption } from "echarts";
import React, { useMemo } from "react";

import { createTooltipIcon } from ".";

import { AppTheme } from "src/app/configs";
import { SuppliersForTotalEmission } from "src/app/models";
import { formatEmission } from "src/app/utils/computeForVisuals";
import { Echarts } from "src/lib/components/atoms";
import { useAspectRatio } from "src/lib/hooks/useAspectRatio";
import { create } from "src/lib/utils/domUtil";
import { sum } from "src/lib/utils/mathUtil";

const useStyles = makeStyles(() => ({
  chart: {
    width: "100%",
    height: "100%",
  },
}));

export type PieChartProps = {
  data: SuppliersForTotalEmission[];
  colors: string[];
};

export const PieChart: React.FC<PieChartProps> = (props) => {
  const theme = useTheme<AppTheme>();
  const chartOption = useMemo<EChartsOption>(() => {
    const option: EChartsOption = {
      textStyle: {
        fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
      },
      tooltip: {
        trigger: "item",
        confine: true,
        formatter: (params) => {
          if (params instanceof Array) {
            throw new Error("params must be object");
          }

          const total = sum(...props.data.map((it) => it.total));
          const ratio =
            total !== 0 ? ((params.value as number) / total) * 100 : 0;

          const root = create("div", (div) => {
            div.appendChild(
              createTooltipIcon(
                params.color === undefined ? "" : params.color.toString()
              )
            );
            div.appendChild(document.createTextNode(params.name));
            div.appendChild(create("br", (br) => {}));
            div.appendChild(
              create("div", (div) => {
                div.style.fontWeight = "bold";
                div.textContent =
                  ratio.toLocaleString(undefined, {
                    maximumFractionDigits: 1,
                  }) + "%";
              })
            );
            div.appendChild(
              create("div", (div) => {
                div.style.fontWeight = "normal";
                div.textContent = "(" + formatEmission(params.value as number);
                div.appendChild(
                  create("span", (span) => {
                    span.style.fontWeight = "normal";
                    span.style.fontSize = "10px";
                    span.textContent = " t-CO2eq/年";
                  })
                );
                div.appendChild(
                  create("span", (span) => {
                    span.style.fontWeight = "normal";
                    span.textContent = ")";
                  })
                );
              })
            );
          });

          return [root];
        },
      },
      legend: {
        textStyle: {
          fontSize: theme.typography.overline.fontSize,
        },
        orient: "vertical",
        type: "scroll",
        right: 0,
        icon: "circle",
      },
      series: {
        type: "pie",
        top: 8,
        bottom: 8,
        left: 8,
        right: 140,
        radius: ["40%", "100%"],
        label: {
          show: false,
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
        },
        data: props.data.map((it, index) => {
          return {
            name: it.companyName,
            value: it.total,
            itemStyle: {
              color: props.colors[index],
            },
          };
        }),
      },
    };
    return option;
  }, [props.colors, props.data, theme.typography.overline.fontSize]);

  const classes = useStyles();
  const ref = useAspectRatio(1.3);
  return (
    <Box width="100%" {...{ ref }}>
      <Echarts className={classes.chart} option={chartOption} />
    </Box>
  );
};
