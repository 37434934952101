import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import { GetApp } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { AppCard, LargeButton } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  ImageHeading,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  ProcurementList,
} from "src/app/components/organisms";
import { ProcurementsForm } from "src/app/components/pages";
import { AppTheme, messages } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

export type UserProcurementAmountInputTemplateProps = {
  form: ProcurementsForm | undefined;
  setForm: Dispatch<SetStateAction<ProcurementsForm | undefined>>;
  isProcessing: boolean;
  // isDownloading: boolean;
  isEditable: boolean;
  hasError: boolean;
  yearDropdownProps: YearDropdownProps;
  onSubmit: () => void;
  onTempSubmit: () => void;
  onCancelSubmit: () => void;
  onCancelSubmitDialogProps: ConfirmationDialogProps;
  // getPdf: () => void;
  loadMore: () => void;
  displayLength: number;
  cancelButtonDisplay: boolean;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  appCard: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.procurementBackground,
  },
  link: {
    cursor: "pointer",
    fontSize: theme.typography.body1.fontSize,
  },
  linkIcon: {
    verticalAlign: "middle",
  },
  tempButton: { width: "200px", backgroundColor: "#FFFFFF" },
  submitButton: { width: "200px" },
}));

export const UserProcurementAmountInputTemplate: React.FC<
  UserProcurementAmountInputTemplateProps
> = (props) => {
  const classes = useStyles();

  const userFactories =
    props.form?.userFactories === undefined ? [] : props.form.userFactories;

  let hasRoutes = false;
  for (const factory of userFactories) {
    if (factory.routes.length > 0) {
      hasRoutes = true;
      break;
    }
  }

  const hasMore = props.displayLength < userFactories.length;

  return (
    <Content>
      <FlexBox justifyContent="space-between">
        <ImageHeading title="段ボール" variant="cardBoard" />
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>

      {props.isProcessing || props.hasError ? (
        // ロード中またはエラー発生時は、見栄えを考慮して登録ボタンと背景のカードごと描画しない
        <Box />
      ) : userFactories.length === 0 ? (
        // 調達量一覧データが0件の場合はメッセージを表示
        <FlexBox my={2} justifyContent="center">
          <Typography variant="body1">
            {messages.userProcurementAmount.nothing}
          </Typography>
        </FlexBox>
      ) : (
        <AppCard className={classes.appCard}>
          <Box>
            {/* TODO システム汎用化のためコメントアウト */}
            {/* <Box mb={3}>
              <Link
                onClick={props.getPdf}
                color="textPrimary"
                className={classes.link}
              >
                <GetApp fontSize="small" className={classes.linkIcon} />
                取引ルート一覧をダウンロード
              </Link>
            </Box> */}
            <InfiniteScroll
              next={props.loadMore}
              hasMore={hasMore}
              loader={
                <FlexBox justifyContent="center" alignItems="center">
                  <CircularProgress size={32} />
                </FlexBox>
              }
              dataLength={props.displayLength}
            >
              {userFactories
                .slice(0, props.displayLength)
                .map((formByUserFactory) => {
                  return (
                    <Box mb={4} key={formByUserFactory.factoryId}>
                      <ProcurementList
                        formByUserFactory={formByUserFactory}
                        setForm={props.setForm}
                        disabled={!props.isEditable}
                      />
                    </Box>
                  );
                })}
            </InfiniteScroll>

            {/* 現在表示している納入先工場データ数が上限に達したら登録ボタンを表示 */}
            {/*
                NOTE : 1つのユーザ工場に紐づく経路データの数が少ない（1~2件）の場合初期表示でスクロールが発生しない
                NOTE : ただし、極端に経路が少ないような業務データは想定されない
             */}
            {!hasMore && hasRoutes && (
              <FlexBox justifyContent="center">
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <LargeButton
                    label="一時保存"
                    variant="outlined"
                    color="primary"
                    className={classes.tempButton}
                    onClick={props.onTempSubmit}
                    disabled={props.cancelButtonDisplay}
                  />
                  {props.cancelButtonDisplay ? (
                    <LargeButton
                      label="登録取消"
                      variant="contained"
                      color="secondary"
                      className={classes.submitButton}
                      onClick={props.onCancelSubmit}
                      disabled={props.form?.userFactories
                        ?.map((a) => a.routes.length)
                        .every((b) => b === 0)}
                    />
                  ) : (
                    <LargeButton
                      label="登録"
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                      onClick={props.onSubmit}
                      disabled={props.form?.userFactories
                        ?.map((a) => a.routes.length)
                        .every((b) => b === 0)}
                    />
                  )}
                </Stack>
              </FlexBox>
            )}
          </Box>
        </AppCard>
      )}

      <FullScreenSpinner
        open={
          props.isProcessing
          //TODO システム汎用化のためコメントアウト
          //  || props.isDownloading
        }
      />
      <ConfirmationDialog {...props.onCancelSubmitDialogProps} />
    </Content>
  );
};
