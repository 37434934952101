import {
  AccountCircleOutlined,
  ExitToAppRounded,
  KeyboardArrowDownRounded,
  MenuRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";

import { LargeButton } from "src/app/components/atoms";
import { DrawerHandle } from "src/app/components/organisms";
import { AppTheme } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.headerBorder}`,
  },
  headerContent: {
    width: "100%",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(0.5),
  },
  avatarButton: {
    paddingRight: 0,
  },
}));

export type HeaderProps = {
  className?: string;
  drawerHandle: DrawerHandle;
  userName: string;
  onClickLogo: () => void;
  onClickLogout: () => void;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "avatarPopup",
  });

  return (
    <FlexBox
      className={clsx(props.className, classes.header)}
      justifyContent="center"
      px={6}
    >
      <FlexBox
        className={classes.headerContent}
        py={1.5}
        justifyContent="space-between"
      >
        <IconButton onClick={props.drawerHandle.toggle} size="large">
          <MenuRounded />
        </IconButton>
        <Box>
          <AvatarButton
            userName={props.userName}
            {...bindTrigger(popupState)}
          />
          <Popover
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            {...bindPopover(popupState)}
          >
            <PopoverContent onClickLogout={props.onClickLogout} />
          </Popover>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};

type PopoverContentProps = {
  onClickLogout: () => void;
};
const PopoverContent: React.FC<PopoverContentProps> = (props) => {
  return (
    <Box px={2} py={1}>
      <LargeButton
        label="ログアウト"
        color="default"
        variant="text"
        startIcon={<ExitToAppRounded />}
        onClick={props.onClickLogout}
      />
    </Box>
  );
};

type AvatarButtonProps = ButtonProps & { userName: string };
const AvatarButton: React.FC<AvatarButtonProps> = (props) => {
  const { userName, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <Button {...buttonProps} className={classes.avatarButton} color="default">
      <AccountCircleOutlined className={classes.small} />
      <Typography variant="body2">{props.userName ?? "----"}</Typography>
      <KeyboardArrowDownRounded />
    </Button>
  );
};
