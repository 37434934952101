import { DateTime } from "luxon";

import {
  getProcurement,
  GetProcurementQueryError,
  GetProcurementQueryResult,
} from "src/app/apis/client/default";
import { ToStringYearFromDateTime } from "src/app/utils/api-if-converter/api-if-converter";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export interface GetProcurementBySupplierApiRequest {
  year: DateTime;
  factoryId: string;
}

export class GetProcurementBySupplierError extends ApiError<
  | "procurement-by-supplier-not-found"
  | "not-authenticated"
  | "network"
  | "unknown"
> {}

export class ProcurementBySupplierApi {
  async getProcurementBySupplier(
    params: GetProcurementBySupplierApiRequest
  ): Promise<GetProcurementQueryResult> {
    try {
      const res = await getProcurement(
        ToStringYearFromDateTime(params.year),
        params.factoryId
      );
      return res;
    } catch (err) {
      const { errorCode } = err as GetProcurementQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetProcurementBySupplierError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetProcurementBySupplierError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetProcurementBySupplierError("not-authenticated", err);
        case "ERR027001":
        case "ERR027002":
        case "ERR027003":
          throw new GetProcurementBySupplierError(
            "procurement-by-supplier-not-found",
            err
          );
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
