import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";

export type RadioButtonItem<T extends string = string> = {
  value: T;
  label: string;
};
export type RadioButtonProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  items: RadioButtonItem[];
};

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    <RadioGroup onChange={props.onChange} defaultValue={props.items[0].value}>
      <Box display="flex">
        {props.items.map((item) => (
          <FormControlLabel
            key={item.value}
            control={<Radio color="default" />}
            value={item.value}
            label={item.label}
          />
        ))}
      </Box>
    </RadioGroup>
  );
};
