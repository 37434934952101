import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

import { AppCard, NestedCard } from "src/app/components/atoms";
import noDataImage from "src/app/images/noData.png";
import noDataImage2x from "src/app/images/noData@2x.png";
import { FlexBox, Image } from "src/lib/components/atoms";

export type ChartAndTableViewProps = {
  title: string;
  subtitle?: ReactElement | string;
  dropdownElement?: ReactElement;
  chartElement: ReactElement;
  tableElement: ReactElement;
  noData: boolean;
  isProcessing: boolean;
};

export const ChartAndTableView: React.FC<ChartAndTableViewProps> = (props) => {
  return (
    <AppCard>
      <Box mb={5}>
        <NestedCard>
          <Box mb={3}>
            <Typography variant="h3">{props.title}</Typography>
            {props.subtitle !== undefined && (
              <Box pt="8px">{props.subtitle}</Box>
            )}
          </Box>
          {props.dropdownElement}
          {!props.isProcessing && props.noData ? (
            <FlexBox alignItems="center">
              <Image
                alt="NoData"
                src={noDataImage}
                src2x={noDataImage2x}
                width="100%"
              />
            </FlexBox>
          ) : (
            props.chartElement
          )}
        </NestedCard>
      </Box>
      {props.tableElement}
    </AppCard>
  );
};
