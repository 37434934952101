import { DateTime } from "luxon";
import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { envConfig } from "src/app/configs";
import { DropDownItem } from "src/lib/components/atoms";
import { parseNumber } from "src/lib/utils/numberUtil";

const yearDropdownItems: DropDownItem[] = envConfig.displayYear
  .split(",")
  .map((item) => {
    return { value: item, displayName: item + "年" };
  });

const getLatestDropdownYear = () => {
  let latestYearNumber = 0;
  for (const yearDropdownItem of yearDropdownItems) {
    const yearNumber = parseNumber(yearDropdownItem.value);
    if (!isNaN(yearNumber) && latestYearNumber < yearNumber) {
      latestYearNumber = yearNumber;
    }
  }
  return latestYearNumber.toString();
};

export type DefaultYearState = DateTime;

const initialDefaultYearState: DefaultYearState = DateTime.fromFormat(
  getLatestDropdownYear(),
  "yyyy"
);

export type DefaultYearContextType = [
  DefaultYearState,
  Dispatch<SetStateAction<DefaultYearState>>
];

const DefaultYearContext = createContext<DefaultYearContextType>([
  initialDefaultYearState,
  () => {},
]);

/**
 * アプリで現在選択されている年を返します。
 * この年は useYearDropdown　で年が選択される度に更新されます。
 */
export const useDefaultYear = (): DefaultYearContextType => {
  return useContext(DefaultYearContext);
};
type Props = {
  children?: React.ReactNode;
};

export const DefaultYearProvider: React.FC<Props> = (props) => {
  const [state, setState] = useState<DefaultYearState>(initialDefaultYearState);

  const value = useMemo<DefaultYearContextType>(() => {
    return [state, setState];
  }, [state]);

  return (
    <DefaultYearContext.Provider value={value}>
      {props.children}
    </DefaultYearContext.Provider>
  );
};

/**
 * パスパラメータに :year が含まれるページで呼び出してください
 * URLのパスから現在選択されている年を取得し、YearDropdown コンポーネントへの props を返します
 * ドロップダウンが操作されると、基づき、選択した年の画面へ遷移させます
 * パスパラメータ :year が 年として不正なときは undefined を返します
 */
export const useYearDropdown = ():
  | {
      value: string;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
      items: DropDownItem[];
      year: DateTime;
    }
  | undefined => {
  const navigate = useNavigate();
  const params = useParams<{ year?: string }>();
  const { pathname, state } = useLocation();

  const [, setDefaultYear] = useDefaultYear();
  useEffect(() => {
    if (isValidYear(params.year)) {
      setDefaultYear(DateTime.fromFormat(params.year, "yyyy"));
    }
  }, [params.year, setDefaultYear]);

  const res = useMemo(() => {
    if (params.year === undefined) {
      throw new Error(`path pattern ${pathname} does not include year`);
    }

    const year = params.year;
    return {
      value: year,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        if (params.year === undefined) {
          return;
        }
        // path = "/input/:year/:factoryId" のようなときに year のパラメータだけ置き換える
        navigate(
          pathname.replace(params.year, e.target.value),
          state !== null ? { state: { ...state } } : {}
        );
      },
      items: yearDropdownItems,
      year: DateTime.fromFormat(year, "yyyy"),
    };
    // paramsはレンダリングのたびに異なるオブジェクトが返るため、依存リストにそのまま入れると、メモ化の意味がなくなる
    // paramsをJSON文字列化することで、これを回避する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, pathname, JSON.stringify(params)]);

  return isValidYear(params.year) ? res : undefined;
};

const isValidYear = (year: string | undefined): year is string => {
  return (
    year !== undefined &&
    DateTime.fromFormat(year, "yyyy").isValid &&
    yearDropdownItems.map((it) => it.value).includes(year)
  );
};
