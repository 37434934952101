import { DateTime } from "luxon";

import {
  GetInputRequestsQueryError,
  GetInputRequestsQueryResult,
  GetInputRequestsYearQueryError,
  GetInputRequestsYearQueryResult,
  getInputRequests,
  getInputRequestsYear,
} from "src/app/apis/client/default";
import { FactoryType, InputStatus } from "src/app/apis/model";
import { ToStringYearFromDateTime } from "src/app/utils/api-if-converter/api-if-converter";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export type GetInputRequestsApiYearRequest = {
  year: DateTime;
};

export class GetAllInputRequestsApiError extends ApiError<
  "not-authenticated" | "network" | "unknown"
> {}

export type GetInputRequestResponse = {
  year: DateTime;
  factoryId: string;
  factoryName: string;
  factoryNameForSelf: string;
  factoryNameForOthers: string;
  factoryType: FactoryType;
  expirationTime: DateTime;
  beginTime: DateTime;
  submissionTime?: DateTime;
  status: InputStatus;
};

export type GetInputRequestRequest = {
  year: DateTime;
  factoryId: string;
};

export class GetInputRequestApiError extends ApiError<
  | "not-authenticated"
  | "network"
  | "unknown"
  | "factory-not-found"
  | "input-requests-not-found"
> {}

export class InputRequestApi {
  async getAllInputRequests(
    req: GetInputRequestsApiYearRequest
  ): Promise<GetInputRequestsYearQueryResult> {
    try {
      const res = await getInputRequestsYear(
        ToStringYearFromDateTime(req.year)
      );
      return res;
    } catch (err) {
      const e = err as GetInputRequestsYearQueryError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetAllInputRequestsApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetAllInputRequestsApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetAllInputRequestsApiError("not-authenticated", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }

  async getInputRequest(
    req: GetInputRequestRequest
  ): Promise<GetInputRequestsQueryResult> {
    try {
      const res = await getInputRequests(
        ToStringYearFromDateTime(req.year),
        req.factoryId
      );

      return res;
    } catch (err) {
      const { errorCode } = err as GetInputRequestsQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetInputRequestApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetInputRequestApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetInputRequestApiError("not-authenticated", err);
        case "ERR021000":
          throw new GetInputRequestApiError("factory-not-found", err);
        // TODO:ERR021002について画面実装時に併せて正しいエラーハンドリングを実装
        case "ERR021002":
        case "ERR021001":
          throw new GetInputRequestApiError("input-requests-not-found", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
