import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dispatch, SetStateAction } from "react";
import { Navigate } from "react-router-dom";

import { GetProduct200ProductsItem, CompanyType } from "src/app/apis/model";
import { AppTab, AppTabItem, LargeButton } from "src/app/components/atoms";
import {
  Content,
  FullScreenSpinner,
  YearDropdown,
  YearDropdownProps,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  ErrorDialog,
  ErrorDialogProps,
  GhgInputHeading,
  SaveAndRegisterButtonProps,
  TransitionConfirmationDialog,
} from "src/app/components/organisms";
import {
  GhgInputCategory1Form,
  GhgInputCategory1TabType,
} from "src/app/components/pages";
import {
  GhgEnergyInputTemplate,
  GhgManufacturingInputTemplate,
  GhgWasteInputTemplate,
} from "src/app/components/templates";
import { messages } from "src/app/configs";
import { FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  infoMessage: {
    color: theme.palette.text.disabled,
  },
}));

export type GhgInputCategory1TemplateProps = {
  form: GhgInputCategory1Form;
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  products: GetProduct200ProductsItem[];
  manufacturingProducts: readonly string[];

  isProcessing: boolean;
  isEditable: boolean;
  isUnsavedForm: boolean;
  basePath: string;

  factoryName?: string;
  onClickBack: () => void;
  yearDropdownProps: YearDropdownProps;
  showUnSubmitButton: boolean;
  submitButtonProps: {
    onClick: () => void;
    disabled: boolean;
    isProcessing: boolean;
  };
  submitDialogProps: ConfirmationDialogProps;
  unSubmitButtonProps: {
    onClick: () => void;
    disabled: boolean;
    isProcessing: boolean;
  };
  unSubmitDialogProps: ConfirmationDialogProps;
  submitButtonMessage?: string;

  tabItemProps: Record<
    GhgInputCategory1TabType,
    {
      checked: boolean;
      selected: boolean;
      onClick: () => void;
    }
  >;

  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];

  companyType: Exclude<CompanyType, "user-company">;

  errorDialogProps: ErrorDialogProps;
  invalidUrl?: boolean;
};

export const GhgInputCategory1Template: React.FC<
  GhgInputCategory1TemplateProps
> = (props) => {
  const classes = useStyles();

  return (
    <Content>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography variant="h1">GHG算出情報入力（カテゴリ１）</Typography>
        <YearDropdown {...props.yearDropdownProps} />
      </FlexBox>

      <FlexBox mt={2}>
        <GhgInputHeading
          title={props.factoryName ?? ""}
          onClickBack={props.onClickBack}
        />
      </FlexBox>

      <Box position="relative" mt={2}>
        <AppTab>
          <AppTabItem label="エネルギー" {...props.tabItemProps.energy} />
          <AppTabItem label="廃棄物" {...props.tabItemProps.waste} />
          <AppTabItem label="製造工程" {...props.tabItemProps.manufacturing} />
        </AppTab>
        <FlexBox
          position="absolute"
          right={0}
          bottom={8}
          flexDirection="column"
          alignItems="flex-end"
        >
          {props.showUnSubmitButton ? (
            <LargeButton
              label="確定取消"
              color="secondary"
              variant="contained"
              {...props.unSubmitButtonProps}
            />
          ) : (
            <LargeButton
              label="確定"
              color="primary"
              variant="contained"
              {...props.submitButtonProps}
            />
          )}
          <Typography variant="overline" className={classes.infoMessage}>
            {props.submitButtonMessage}
          </Typography>
        </FlexBox>
      </Box>

      <Box mt={3}>
        {props.tabItemProps.energy.selected && (
          <GhgEnergyInputTemplate
            energy={props.form.energy}
            setForm={props.setForm}
            companyType={props.companyType}
            isEditable={props.isEditable}
            saveButtonProps={props.saveButtonProps}
            registerButtonProps={props.registerButtonProps}
          />
        )}
        {props.tabItemProps.waste.selected && (
          <GhgWasteInputTemplate
            waste={props.form.waste}
            setForm={props.setForm}
            companyType={props.companyType}
            isEditable={props.isEditable}
            saveButtonProps={props.saveButtonProps}
            registerButtonProps={props.registerButtonProps}
          />
        )}
        {props.tabItemProps.manufacturing.selected && (
          <GhgManufacturingInputTemplate
            manufacturing={props.form.manufacturing}
            products={props.products}
            manufacturingProducts={props.manufacturingProducts}
            setForm={props.setForm}
            companyType={props.companyType}
            isEditable={props.isEditable}
            saveButtonProps={props.saveButtonProps}
            registerButtonProps={props.registerButtonProps}
          />
        )}
        {props.invalidUrl && <Navigate to={`${props.basePath}/energy`} />}
      </Box>

      <FullScreenSpinner open={props.isProcessing} />
      <TransitionConfirmationDialog
        when={props.isUnsavedForm}
        message={messages.ghgInput.confirmNavigation}
      />

      <ConfirmationDialog {...props.submitDialogProps} />
      <ConfirmationDialog {...props.unSubmitDialogProps} />
      <ErrorDialog {...props.errorDialogProps} />
    </Content>
  );
};
