import { Card } from "@mui/material";
import React from "react";

import { theme } from "src/app/configs";

export type AppCardProps = {
  className?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
};

export const AppCard: React.FC<AppCardProps> = (props) => {
  return (
    <Card
      className={props.className}
      sx={{
        padding: theme.spacing(3),
        backgroundColor: props.backgroundColor,
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {props.children}
    </Card>
  );
};
