import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import {
  ClassificationType,
  CompanyType,
  IntensityType,
} from "src/app/apis/model";
import { FactoryIntensity } from "src/app/components/atoms";
import { Category1IntensityByClassificationTemplate } from "src/app/components/templates";
import {
  PaperCategory1DataForDisplay,
  ProcessCategory1DataForDisplay,
} from "src/app/hooks";
import {
  Category1IntensityByClassificationType,
  PaperFactoryCategory1FactoryDataForDisplay,
  ProcessFactoryCategory1FactoryDataForDisplay,
} from "src/app/models";
import {
  getProcessNameForVisual,
  getUnitForCo2Intensity,
} from "src/app/utils/computeForVisuals";

export type Category1IntensityByClassificationPageProps = {
  companyType: Exclude<CompanyType, "user-company">;
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay;
  isProcessing: boolean;
};

const convertCategory1DataToChartProps = (
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const getValue = (
    factory:
      | PaperFactoryCategory1FactoryDataForDisplay
      | ProcessFactoryCategory1FactoryDataForDisplay,
    name: ClassificationType
  ): number => {
    return (
      factory.byClassification.find((it) => it.classificationType === name)
        ?.amountBasedCo2Intensity ?? 0
    );
  };

  const chartData: Category1IntensityByClassificationType[] = [];
  if (category1Data?.factories !== undefined) {
    for (const factory of category1Data.factories) {
      if (factory.intensityType === "torch-based") {
        chartData.push({
          factoryName: factory.factoryNameForSelf,
          energy: {
            privateSteam: getValue(factory, "privateSteam"),
            privatePower: getValue(factory, "privatePower"),
            purchasedPower: getValue(factory, "purchasedPower"),
            purchasedSteam: getValue(factory, "purchasedSteam"),
            certificate: getValue(factory, "certificate"),
          },
          disposal: {
            waste: getValue(factory, "waste"),
            drainage: getValue(factory, "drainage"),
          },
          water: { water: getValue(factory, "water") },
          materials: {
            mainMaterial: getValue(factory, "mainMaterial"),
            chemicals: getValue(factory, "chemicals"),
            dye: getValue(factory, "dye"),
            starch: getValue(factory, "starch"),
            ink: getValue(factory, "ink"),
            varnish: getValue(factory, "varnish"),
          },
        });
      }
    }
  }

  return { chartData };
};

const convertCategory1DataToTableProps = (
  category1Data:
    | PaperCategory1DataForDisplay
    | ProcessCategory1DataForDisplay
    | undefined,
  companyType: Exclude<CompanyType, "user-company">
) => {
  const unit = getUnitForCo2Intensity(companyType);
  const processName = getProcessNameForVisual(companyType, "category1");

  // table
  const tableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 1,
    },
    {
      field: "middleClassification",
      headerName: "中分類",
      flex: 1,
    },
    {
      field: "classification",
      headerName: "小分類",
      flex: 1,
    },
    {
      field: "co2Intensity",
      headerName: `${processName}原単位(${unit})`,
      flex: 1.5,
      renderCell: (params) => getIntensity(params),
    },
  ];

  const tableData = [];
  if (category1Data?.factories !== undefined) {
    for (const factory of category1Data.factories) {
      if (factory.intensityType === "torch-based") {
        for (const it of factory.byClassification) {
          if (it.amountBasedCo2Intensity !== undefined)
            tableData.push({
              id: factory.factoryId + it.classificationType,
              factoryName: factory.factoryNameForSelf,
              middleClassification: it.middleClassificationName,
              classification: it.classificationName,
              co2Intensity:
                Math.round(it.amountBasedCo2Intensity * 1000) / 1000,
              intensityType: factory.intensityType,
            });
        }
      }
    }
  }

  const fileName = `カテゴリ１分類別（原単位）_${category1Data?.year.toFormat(
    "yyyy"
  )}`;

  return {
    tableColumns,
    tableData,
    fileName,
    unit,
  };
};

export const Category1IntensityByClassificationPage: React.FC<
  Category1IntensityByClassificationPageProps
> = (props) => {
  const chartProps = convertCategory1DataToChartProps(props.category1Data);
  const tableProps = convertCategory1DataToTableProps(
    props.category1Data,
    props.companyType
  );
  return (
    <Category1IntensityByClassificationTemplate
      isProcessing={props.isProcessing}
      companyType={props.companyType}
      {...chartProps}
      {...tableProps}
    />
  );
};

const getIntensity = (params: GridRenderCellParams) => {
  const { intensityType } = params.row as { intensityType: IntensityType };
  return (
    <Box display="flex">
      <FactoryIntensity intensityType={intensityType} />

      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : ""}
      </Box>
    </Box>
  );
};
