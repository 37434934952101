import { GridColDef } from "@mui/x-data-grid";
import { bindTextField, StringType, useForm } from "@somq14/use-form";
import { useLocation } from "react-router-dom";

import { GetScope3ByFactoryQueryResult } from "src/app/apis/client/default";
import { CompanyType } from "src/app/apis/model";
import {
  RadioButtonItem,
  RadioButtonProps,
} from "src/app/components/molecules";
import { UserEmissionByFactoryTemplate } from "src/app/components/templates";
import { UserEmissionByFactoryForDisplay } from "src/app/models";
import { convertByFactoryDataToChartProps } from "src/app/utils/computeForVisuals";

export type UserByFactoryPageProps = {
  data?: GetScope3ByFactoryQueryResult;
  isProcessing: boolean;
};

const convertDataToChartProps = (
  selectedSupplier: string,
  data?: GetScope3ByFactoryQueryResult,
  category?: "category1" | "category4"
) => {
  const userFactoryData = convertByFactoryDataToChartProps(data);

  const chartData: UserEmissionByFactoryForDisplay[] = userFactoryData
    .filter((it) => it.companyType === selectedSupplier)
    .map((userFactory) => {
      return {
        companyId: userFactory.companyId,
        companyType: userFactory.companyType,
        companyName: userFactory.companyName,
        byUserFactory: userFactory.byUserFactory.map((factory) => {
          return {
            factoryId: factory.factoryId,
            factoryType: factory.factoryType,
            factoryName: factory.factoryName,
            factoryNameForSelf: factory.factoryNameForSelf,
            factoryNameForOthers: factory.factoryNameForOthers,
            total:
              category === "category1"
                ? factory.category1Total
                : factory.category4Total,
          };
        }),
      };
    });

  return { chartData };
};

const convertDataToTableProps = (
  data?: GetScope3ByFactoryQueryResult,
  category?: "category1" | "category4"
) => {
  const tableColumns: GridColDef[] = [
    {
      field: "userFactoryName",
      headerName: "自社工場",
      flex: 2,
    },
    {
      field: "supplierCompanyName",
      headerName: "サプライヤー企業",
      flex: 3,
    },
    {
      field: "supplierFactoryName",
      headerName: "サプライヤー工場",
      flex: 3,
    },
    {
      field: "co2Emission",
      headerName: "GHG総排出量(t-CO2eq/年)",
      flex: 3,
      valueFormatter: (params) =>
        typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : "",
    },
  ];

  const tableData = [];
  if (data !== undefined) {
    for (const bySupplier of data.bySupplier) {
      for (const bySupplierFactory of bySupplier.bySupplierFactory) {
        for (const byUserFactory of bySupplierFactory.byUserFactory) {
          const co2Emission =
            category === "category1"
              ? byUserFactory.category1Co2Emission
              : byUserFactory.category4Co2Emission;

          if (co2Emission !== undefined) {
            tableData.push({
              id:
                byUserFactory.factoryId +
                bySupplier.companyId +
                bySupplierFactory.factoryId,
              userFactoryName: byUserFactory.factoryName,
              supplierCompanyName: bySupplier.companyName,
              supplierFactoryName: bySupplierFactory.factoryName,
              co2Emission: Math.round(co2Emission),
            });
          }
        }
      }
    }
  }

  const fileName =
    category === "category1"
      ? `カテゴリ１工場別_${data?.year}`
      : `カテゴリ４工場別_${data?.year}`;

  return {
    tableColumns,
    tableData,
    fileName,
  };
};

export const UserEmissionByFactoryPage: React.FC<UserByFactoryPageProps> = (
  props
) => {
  const companyType: RadioButtonItem<CompanyType>[] = [
    { value: "paper-supplier", label: "製紙" },
    { value: "process-supplier", label: "加工" },
  ];
  const form = useForm<{ selectedSupplierType: string }>({
    selectedSupplierType: {
      type: StringType,
      initial: companyType[0].value,
    },
  });
  const companyTypeRadioButtonProps: RadioButtonProps = {
    items: companyType,
    ...bindTextField(form.fields.selectedSupplierType),
  };
  const location = useLocation();

  const category = location.pathname.startsWith("/user/category1/")
    ? "category1"
    : "category4";

  const tableProps = convertDataToTableProps(props.data, category);
  const chartProps = convertDataToChartProps(
    form.fields.selectedSupplierType.value,
    props.data,
    category
  );

  return (
    <UserEmissionByFactoryTemplate
      isProcessing={props.isProcessing}
      category={category}
      {...tableProps}
      {...chartProps}
      radioButtonProps={companyTypeRadioButtonProps}
    />
  );
};
