import {
  GetLocalStorageRequest,
  GetLocalStorageResponse,
  LocalStorageApi,
  SetLocalStorageRequest,
  SetLocalStorageResponse,
} from "./localStorageApi";
import { LocalStorageImpl } from "./localStorageApiImpl";

export class LocalStorageMock implements LocalStorageApi {
  async get(req: GetLocalStorageRequest): Promise<GetLocalStorageResponse> {
    // GHG算出情報入力（カテゴリ1・一次データ）
    // GHG算出情報入力（カテゴリ4・一次データ）
    return new LocalStorageImpl().get(req);

    // 入力依頼一覧（工場一覧）
    // return {
    //   energyInput: { version: 2 },
    //   wasteInput: { version: 1 },
    //   manufacturingInput: { version: 1 },
    //   transportInput: { version: 1 },
    // };
  }

  async set(req: SetLocalStorageRequest): Promise<SetLocalStorageResponse> {
    // GHG算出情報入力（カテゴリ1・一次データ）
    // GHG算出情報入力（カテゴリ4・一次データ）
    return new LocalStorageImpl().set(req);

    // 入力依頼一覧（工場一覧）
    // return {};
  }
}
