import BigNumber from "bignumber.js";
import { DateTime } from "luxon";

export const ToDateTimeFromString = (value: string): DateTime => {
  return DateTime.fromISO(value);
};

export const ToStringFromDateTime = (value: DateTime): string => {
  return value.toUTC().toISO();
};

export const ToDateTimeYearFromString = (value: string): DateTime => {
  return DateTime.fromFormat(value, "yyyy");
};

export const ToStringYearFromDateTime = (value: DateTime): string => {
  return value.toFormat("yyyy");
};

export const ToBigNumberFromNumber = (value: number): BigNumber => {
  return new BigNumber(value);
};

export const ToNumberFromBigNumber = (value: BigNumber): number => {
  return value.toNumber();
};

export const ToNumberFromBigNumberOrUndefined = (
  value: BigNumber | undefined
): number | undefined => {
  if (value !== undefined) {
    return value.toNumber();
  }
};
