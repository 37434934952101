import React from "react";

export type ImageProps = {
  className?: string;
  width?: number | string;
  height?: number | string;
  src: string;
  src2x?: string;
  alt: string;
};

export const Image: React.FC<ImageProps> = (props) => {
  return (
    <img
      className={props.className}
      width={props.width}
      height={props.height}
      src={props.src}
      srcSet={
        props.src2x === undefined
          ? undefined
          : `${props.src} 1x, ${props.src2x} 2x`
      } // Retina対応のため
      alt={props.alt}
    />
  );
};
