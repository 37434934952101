import { Pattern, StringType, useForm } from "@somq14/use-form";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthApi, PostLoginError } from "src/app/apis/auth-api/authApi";
import { LoginTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { CompanyId, LoginId, LoginPassword } from "src/app/domains";
import { AuthState } from "src/app/models";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { logoutOtherTab } from "src/lib/hooks/useLogoutOtherTab";
import { CookieUtil } from "src/lib/utils/cookieUtil";

export type LoginForm = {
  companyId: string;
  loginId: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [, setAuthState] = useAuthState<AuthState>();

  const form = useForm<LoginForm>({
    companyId: {
      type: StringType,
      rules: [Pattern(CompanyId.Pattern)],
    },
    loginId: {
      type: StringType,
      rules: [Pattern(LoginId.Pattern)],
    },
    password: {
      type: StringType,
      rules: [Pattern(LoginPassword.Pattern)],
    },
  });

  const { isLoading: isProcessing, mutate: onClickLogin } = useMutation(
    async () => {
      const isValid = await form.validate();
      if (!isValid) {
        return;
      }

      const formValue = await form.convert();
      const authApi = new AuthApi();
      try {
        const res = await authApi.login({
          companyId: formValue.companyId,
          loginId: formValue.loginId,
          password: formValue.password,
        });
        setAuthState(res);
      } catch (err) {
        if (!(err instanceof PostLoginError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "authentication-failed":
            snackbar.error(messages.login.authenticationFailed);
            form.fields.password.setValue("");
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }

      logoutOtherTab();

      snackbar.closeAll();
      navigate("/input");
    }
  );

  const snackbar = useCustomSnackbar();
  useEffect(() => {
    if (!CookieUtil.isEnabled()) {
      snackbar.error(messages.login.cookieDisabled);
    }
    // localStorageが利用可能かチェックする
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      localStorage.length;
    } catch (err) {
      if (!(err instanceof DOMException)) {
        snackbar.error(messages.localStorage.canNotUse);
        return;
      }
      if (err.name === "SecurityError" && CookieUtil.isEnabled())
        snackbar.error(messages.localStorage.canNotUse);
      return;
    }
    return;
  }, [snackbar]);

  return (
    <LoginTemplate
      onClickLogin={onClickLogin}
      isProcessing={isProcessing}
      form={form.fields}
    />
  );
};
