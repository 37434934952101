/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */

export type SupplierCompanyType =
  (typeof SupplierCompanyType)[keyof typeof SupplierCompanyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SupplierCompanyType = {
  "paper-supplier": "paper-supplier",
  "process-supplier": "process-supplier",
} as const;
