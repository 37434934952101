import { AddCircle, Cancel } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactElement } from "react";

import { AppTheme } from "src/app/configs";
import { Button, FlexBox } from "src/lib/components/atoms";

const useStyles = makeStyles<AppTheme>((theme) => ({
  header: {
    backgroundColor: theme.palette.ghgHeader.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: "4px",
  },
  errorMessage: {
    lineHeight: 1,
  },
  firstInputTitle: {
    marginLeft: 20,
  },
}));

// レコード数を増減できる表状の入力項目（本体）
export type TransportTableInputProps = {
  firstInputColumn: string;
  secondInputColumn: string;
  thirdInputColumn: string;
  fourthInputColumn: string;
  onClickAddButton: () => void;
  isEditable?: boolean;
  children?: React.ReactNode;
};
export const TransportTableInput: React.FC<TransportTableInputProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      <Grid container className={classes.header}>
        <Grid item xs={2.7}>
          <Box className={classes.firstInputTitle}>
            <Typography variant="h5">{props.firstInputColumn}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2.7}>
          <Box ml={3}>
            <Typography variant="h5">{props.fourthInputColumn}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2.7}>
          <Box ml={4}>
            <Typography variant="h5">{props.secondInputColumn}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box ml={4}>
            <Typography variant="h5">{props.thirdInputColumn}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box pt={2}>{props.children}</Box>
      <Box ml={2}>
        <Grid container columnSpacing={3}>
          <Grid item xs={2.7}>
            <Box>
              <Button
                color="default"
                label="行を追加"
                variant="outlined"
                startIcon={<AddCircle />}
                onClick={props.onClickAddButton}
                disabled={!props.isEditable}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

// レコード数を増減できる表状の入力項目（1レコード分）
type TransportTableRowsProps = {
  firstInput: ReactElement;
  firstInputErrorMessage: string;
  firstInputHasError?: boolean;
  secondInput: ReactElement;
  secondInputErrorMessage: string;
  secondInputHasError?: boolean;
  thirdInput: ReactElement;
  thirdInputErrorMessage: string;
  thirdInputHasError?: boolean;
  fourthInput: ReactElement;
  fourthInputErrorMessage: string;
  fourthInputHasError?: boolean;
  onClickRemoveButton: () => void;
  isLastRow?: boolean;
  isEditable: boolean;
};
export const TransportTableRows: React.FC<TransportTableRowsProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      columnSpacing={3}
    >
      <Grid item xs={2.7}>
        <Box>{props.firstInput}</Box>
      </Grid>
      <Grid item xs={2.7}>
        <Box>{props.fourthInput}</Box>
      </Grid>
      <Grid item xs={2.7}>
        <Box>{props.secondInput}</Box>
      </Grid>
      <Grid item xs={2.7}>
        <Box>{props.thirdInput}</Box>
      </Grid>
      <Grid item xs={1}>
        {!props.isLastRow && (
          <FlexBox>
            <IconButton
              onClick={props.onClickRemoveButton}
              size="small"
              disabled={!props.isEditable}
            >
              <Cancel />
            </IconButton>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={2.7}>
        {props.firstInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.firstInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={2.7}>
        {props.fourthInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.fourthInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={2.7}>
        {props.secondInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.secondInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={2.7}>
        {props.thirdInputHasError && (
          <FlexBox flex={1} minHeight={16} alignItems="flex-start">
            <Typography
              variant="overline"
              color="error"
              className={classes.errorMessage}
            >
              {props.thirdInputErrorMessage}
            </Typography>
          </FlexBox>
        )}
      </Grid>
      <Grid item xs={1}>
        {/* エラーがないときも高さを確保するためminHeightを指定 */}
        <Box minHeight={16} />
      </Grid>
    </Grid>
  );
};
