import {
  postAuthLogin,
  postAuthLogout,
  PostAuthLoginMutationBody,
  PostAuthLoginMutationResult,
  PostAuthLogoutMutationError,
  PostAuthLoginMutationError,
} from "src/app/apis/client/auth";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PostLoginError extends ApiError<
  "authentication-failed" | "network" | "unknown"
> {}

export class PostLogoutError extends ApiError<"network" | "unknown"> {}

export class AuthApi {
  async login(
    req: PostAuthLoginMutationBody
  ): Promise<PostAuthLoginMutationResult> {
    try {
      const res = await postAuthLogin(req);

      return res;
    } catch (err) {
      const e = err as PostAuthLoginMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostLoginError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new PostLoginError("unknown", err);
        case "ERR001000":
          throw new PostLoginError("authentication-failed", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }

  async logout(): Promise<{}> {
    try {
      await postAuthLogout();
      return {};
    } catch (err) {
      const e = err as PostAuthLogoutMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostLogoutError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new PostLogoutError("unknown", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
