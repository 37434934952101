import { useMutation } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Field } from "./ghgInputForm";
import { validateInHouseCategory1Form } from "./ghgInputFormValidator";
import {
  GhgInputService,
  GhgInputServiceGetProductError,
} from "./ghgInputService";

import {
  CalculationApi,
  DeleteCalculationError,
} from "src/app/apis/calculation-api/calculationApi";
import {
  CalculationCategory1PaperApi,
  PostCalculationCategory1PaperError,
} from "src/app/apis/calculation-category1-paper-api/calculationCategory1PaperApi";
import {
  CalculationCategory1ProcessApi,
  PostCalculationCategory1ProcessError,
} from "src/app/apis/calculation-category1-process-api/calculationCategory1ProcessApi";
import {
  GetCategory1ApiError,
  PaperCategory1Api,
  ProcessCategory1Api,
} from "src/app/apis/category1-api/category1Api";
import {
  CompanyType,
  GetProduct200ProductsItem,
  IntensityCategory,
  IntensityType,
} from "src/app/apis/model";
import { YearDropdownProps } from "src/app/components/molecules";
import { InHouseCategory1InputTemplate } from "src/app/components/templates/InHouseCategory1InputTemplate";
import { envConfig, messages } from "src/app/configs";
import { useConfirmationDialog } from "src/app/hooks";
import { useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import {
  ToStringFromDateTime,
  ToStringYearFromDateTime,
} from "src/app/utils/api-if-converter/api-if-converter";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";
import { parseNumber } from "src/lib/utils/numberUtil";

type InHouseCategory1InputPagePathParam = {
  year: string;
  factoryId: string;
};

export type InHouseCategory1InputForm = {
  paperDeals?: { productType: string; field: Field }[];
  amountBasedCo2Intensity?: Field;
};

const initInHouseCategory1InputForm = (
  productTypes?: GetProduct200ProductsItem[]
): InHouseCategory1InputForm => {
  return {
    paperDeals:
      productTypes === undefined
        ? []
        : productTypes?.map((product) => ({
            productType: product.productType,
            field: { value: "" },
          })),
    amountBasedCo2Intensity: { value: "" },
  };
};

export const InHouseCategory1InputPage: React.FC = () => {
  const params = useParams() as InHouseCategory1InputPagePathParam;

  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const yearDropDownProps = useYearDropdown();
  const [authState, setAuthState] = useAuthState<AuthState>();
  const {
    state: { factoryName },
  } = useLocation();

  const [products, setProducts] = useState<GetProduct200ProductsItem[]>([]);

  const [form, setForm] = useState<InHouseCategory1InputForm>(
    initInHouseCategory1InputForm()
  );
  const updateForm: Dispatch<SetStateAction<InHouseCategory1InputForm>> =
    useCallback((form) => {
      setForm(form);
    }, []);

  const previousPagePath: string = paths.input.year.replace(
    ":year",
    yearDropDownProps?.year.toFormat("yyyy") ?? ""
  );

  /** 初期値取得処理 */
  const initData = async () => {
    if (yearDropDownProps?.year === undefined) {
      return;
    }

    const ghgInputService = new GhgInputService();

    let version = 0;
    // 製紙工場カテゴリ
    let factoryPaper = undefined;
    // 加工工場カテゴリ
    let factoryProcess = undefined;
    try {
      // SDA007:製品・規格情報取得API
      const resultProducts = await ghgInputService.getProduct({
        year: params.year,
        factoryId: params.factoryId,
      });
      setProducts(resultProducts.products);

      // サプライヤ（製紙）の場合
      if (authState?.role === "paper-supplier") {
        const paperCategory1Api = new PaperCategory1Api();
        // SDA013:製紙工場カテゴリ1取得API
        const resultCategory1 = await paperCategory1Api.getCategory1({
          year: yearDropDownProps.year.toFormat("yyyy"),
        });
        factoryPaper = resultCategory1.factories.find(
          (info) => info.factoryId === params.factoryId
        );

        if (factoryPaper !== undefined) {
          version = factoryPaper.version;
        }

        if (factoryPaper !== undefined && !factoryPaper.deleteFlag) {
          setForm({
            paperDeals: factoryPaper.byProduct.map((info) => {
              return {
                productType: info.productType,
                field: {
                  value:
                    info.amountBasedCo2Intensity === undefined
                      ? ""
                      : info.amountBasedCo2Intensity.toString(),
                },
              };
            }),
          });
        } else {
          setForm(initInHouseCategory1InputForm(resultProducts.products));
          factoryPaper = undefined;
        }
      }

      // サプライヤ（加工）の場合
      if (authState?.role === "process-supplier") {
        const processCategory1Api = new ProcessCategory1Api();
        // SDA014:加工工場カテゴリ1取得API
        const result = await processCategory1Api.getCategory1({
          year: yearDropDownProps.year.toFormat("yyyy"),
        });
        factoryProcess = result.factories.find(
          (info) => info.factoryId === params.factoryId
        );

        if (factoryProcess !== undefined) {
          version = factoryProcess.version;
        }

        if (factoryProcess !== undefined && !factoryProcess.deleteFlag) {
          setForm({
            amountBasedCo2Intensity: {
              value:
                factoryProcess.amountBasedCo2Intensity === undefined
                  ? ""
                  : factoryProcess.amountBasedCo2Intensity.toString(),
            },
          });
        } else {
          setForm(initInHouseCategory1InputForm());
          factoryProcess = undefined;
        }
      }
    } catch (err) {
      if (
        !(err instanceof GhgInputServiceGetProductError) &&
        !(err instanceof GetCategory1ApiError)
      ) {
        snackbar.error(messages.common.unknown);
        return;
      }

      const errType = err.type;
      switch (errType) {
        case "factory-not-found":
          navigate(previousPagePath);
          snackbar.error(messages.ghgInput.factoryNotFound);
          return;
        case "not-authenticated":
          navigate(paths.login);
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          return;
        case "network":
          navigate(previousPagePath);
          snackbar.error(messages.common.network);
          return;
        case "unknown":
          navigate(previousPagePath);
          snackbar.error(messages.common.unknown);
          return;
        default:
          throw exhaustiveSwitchCase(errType);
      }
    }

    return { factoryPaper, factoryProcess, version };
  };

  /** [初期表示]イベント */
  const {
    data: apiRes,
    isProcessing: isLoading,
    setData: setApiRes,
  } = useAsyncEffect(async () => {
    // 初期値取得処理
    return await initData();
  }, [yearDropDownProps?.year]);

  /** [確定]イベント */
  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        apiRes?.factoryPaper !== undefined ||
        apiRes?.factoryProcess !== undefined
      ) {
        return;
      }

      // バリデーション処理
      const { isValid, inputForm } = validateInHouseCategory1Form(
        form,
        authState.role
      );
      setForm((form) => ({ ...form, ...inputForm }));
      if (!isValid) {
        snackbar.error(messages.ghgInput.registration.validationError);
        return;
      }

      try {
        if (
          authState.role === "paper-supplier" &&
          inputForm.paperDeals !== undefined
        ) {
          const calculationCategory1PaperApi =
            new CalculationCategory1PaperApi();
          // SDA004:製紙工場計算結果登録（カテゴリ１）API
          await calculationCategory1PaperApi.postCalculationCategory1Paper(
            {
              year: ToStringYearFromDateTime(yearDropDownProps.year),
              companyId: authState.companyId,
              factoryId: params.factoryId,
              byProduct: inputForm.paperDeals.map((product) => ({
                productType: product.productType,
                amountBasedCo2Intensity: parseNumber(product.field.value),
                weightBasedCo2Intensity: parseNumber(product.field.value),
              })),
              byClassification: [],
              submissionTime: ToStringFromDateTime(DateTime.now()),
              intensityType: IntensityType["in-house"],
              version: apiRes?.version ?? 0,
              appVersion: parseNumber(envConfig.version),
            },
            { intensityType: "in-house" }
          );
        }

        if (
          authState.role === "process-supplier" &&
          inputForm.amountBasedCo2Intensity !== undefined
        ) {
          let cardBoardAreaToWeightRatio = undefined;
          const averageProducts = products.filter(
            (it) => it.productType === "averageProduct"
          );
          if (averageProducts.length > 0) {
            cardBoardAreaToWeightRatio =
              averageProducts[0].cardBoardAreaToWeightRatio;
          }

          const calculationCategory1ProcessApi =
            new CalculationCategory1ProcessApi();
          // SDA005:加工工場計算結果登録（カテゴリ１）API
          await calculationCategory1ProcessApi.postCalculationCategory1Process(
            {
              year: ToStringYearFromDateTime(yearDropDownProps.year),
              companyId: authState.companyId,
              factoryId: params.factoryId,
              amountBasedCo2Intensity: parseNumber(
                inputForm.amountBasedCo2Intensity.value
              ),
              weightBasedCo2Intensity: new BigNumber(
                inputForm.amountBasedCo2Intensity.value
              )
                .times(cardBoardAreaToWeightRatio ?? 1)
                .toNumber(),
              byClassification: [],
              submissionTime: ToStringFromDateTime(DateTime.now().toUTC()),
              intensityType: IntensityType["in-house"],
              version: apiRes?.version ?? 0,
              appVersion: parseNumber(envConfig.version),
            },
            { intensityType: "in-house" }
          );
        }

        snackbar.success(messages.ghgInput.submit.success);

        setApiRes(await initData());
      } catch (err) {
        if (
          !(err instanceof PostCalculationCategory1PaperError) &&
          !(err instanceof PostCalculationCategory1ProcessError)
        ) {
          snackbar.error(messages.common.unknown);
          return;
        }

        const errType = err.type;
        switch (errType) {
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          case "not-authenticated":
            navigate(paths.login);
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "factory-not-found":
            snackbar.error(messages.ghgInput.factoryNotFound);
            return;
          case "expired":
            snackbar.error(messages.ghgInput.registration.expired);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.registration.conflict);
            return;
          default:
            throw exhaustiveSwitchCase(errType);
        }
      }
    }
  );

  /**  [確定取消]イベント */
  const { mutate: onUnSubmit, isLoading: isUnSubmitting } = useMutation(
    async () => {
      if (
        yearDropDownProps === undefined ||
        authState === undefined ||
        (apiRes?.factoryPaper === undefined &&
          apiRes?.factoryProcess === undefined)
      ) {
        return;
      }

      try {
        const calculationApi = new CalculationApi();
        // SDA003:計算結果削除API
        await calculationApi.deleteCalculation({
          intensityCategory: IntensityCategory.category1,
          version: apiRes.version,
          year: yearDropDownProps.year,
          factoryId: params.factoryId,
        });

        snackbar.success(messages.ghgInput.unSubmit.success);

        setApiRes(await initData());
      } catch (err) {
        if (!(err instanceof DeleteCalculationError)) {
          snackbar.error(messages.common.unknown);
          return;
        }

        switch (err.type) {
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          case "not-authenticated":
            navigate(paths.login);
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "expired":
            snackbar.error(messages.ghgInput.unSubmit.expired);
            return;
          case "conflict":
            snackbar.error(messages.ghgInput.unSubmit.conflict);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  /** 確定の確認ダイアログ */
  const { dialogProps: submitDialogProps, openDialog: openSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.submit.confirm, onSubmit);

  /** 確定取消の確認ダイアログ */
  const { dialogProps: unSubmitDialogProps, openDialog: openUnSubmitDialog } =
    useConfirmationDialog(messages.ghgInput.unSubmit.confirm, onUnSubmit);

  const isProcessing = isLoading || isSubmitting || isUnSubmitting;

  const isEditable =
    authState?.role === "paper-supplier"
      ? apiRes?.factoryPaper === undefined
      : apiRes?.factoryProcess === undefined;

  return (
    <InHouseCategory1InputTemplate
      yearDropdownProps={yearDropDownProps as YearDropdownProps}
      factoryName={factoryName}
      companyType={authState?.role as Exclude<CompanyType, "user-company">}
      isProcessing={isProcessing}
      products={products}
      isEditable={isEditable}
      form={form}
      setForm={updateForm}
      onClickBack={() => navigate(previousPagePath)}
      submitDialogProps={submitDialogProps}
      submitButtonProps={{
        onClick: openSubmitDialog,
        isProcessing: isSubmitting,
      }}
      unSubmitDialogProps={unSubmitDialogProps}
      unSubmitButtonProps={{
        onClick: openUnSubmitDialog,
        isProcessing: isUnSubmitting,
      }}
    />
  );
};
