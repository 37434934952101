import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { Dispatch, SetStateAction } from "react";

import { FuelType, PowerType, CompanyType } from "src/app/apis/model";
import {
  Annotate,
  AppCard,
  CheckboxText,
  Dropdown,
  Text,
} from "src/app/components/atoms";
import {
  RowTextField,
  RowTextFieldProps,
  SectionHeading,
  SubSectionHeading,
  TitledArea,
  TitledAreaContents,
} from "src/app/components/molecules";
import {
  ConfirmationDialog,
  EnergyTableInput,
  EnergyTableRows,
  SaveAndRegisterButton,
  SaveAndRegisterButtonProps,
  TableInput,
  TableRows,
} from "src/app/components/organisms";
import {
  EnergyForm,
  GhgInputCategory1Form,
  initCertificateForm,
  initElectricPowerPurchaseForm,
  initEnergyForm,
} from "src/app/components/pages";
import { msg, messages } from "src/app/configs";
import {
  Co2EmissionFactor,
  ElectricPowerAmount,
  FuelAmount,
  PowerType as PowerTypeDomain,
  SteamAmount,
} from "src/app/domains";
import { useBindTextField } from "src/app/hooks/useBindTextField";
import { useConfirmationDialog } from "src/app/hooks/useConfirmationDialog";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { DropDownItem } from "src/lib/components/atoms";
import { validateAll } from "src/lib/utils/validationUtil";

// 出典
const Source = {
  Rule: validateAll(),
  Message: msg("input", "出典"),
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

// 証書電源種別のDropdownItems
const certificatesDropdownItems: DropDownItem<PowerType>[] = [
  {
    value: "solar",
    displayName: "太陽光",
  },
  {
    value: "wind",
    displayName: "風力",
  },
  {
    value: "hydroelectric",
    displayName: "水力",
  },
  {
    value: "biomass",
    displayName: "バイオマス",
  },
  {
    value: "others",
    displayName: "その他",
  },
];

export type GhgEnergyInputTemplateProps = {
  energy: EnergyForm;
  setForm: Dispatch<SetStateAction<GhgInputCategory1Form>>;
  isEditable: boolean;
  saveButtonProps: SaveAndRegisterButtonProps["saveButtonProps"];
  registerButtonProps: SaveAndRegisterButtonProps["registerButtonProps"];
  companyType: Exclude<CompanyType, "user-company">;
};
export const GhgEnergyInputTemplate: React.FC<GhgEnergyInputTemplateProps> = (
  props
) => {
  const classes = useStyles();
  const { setForm } = props;

  const onClickCheckbox = (
    item:
      | "boiler"
      | "generator"
      | "coGenerator"
      | "solar"
      | "hydroPower"
      | "energySale"
      | "steamPurchase"
      | "greenCertificates"
      | "jCredits"
  ) => {
    return () =>
      setForm(
        produce<GhgInputCategory1Form>((form) => {
          switch (item) {
            case "boiler":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
                occurredSteam:
                  props.companyType === "paper-supplier"
                    ? { value: "" }
                    : { value: "0", error: false },
              };
              break;
            case "generator":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "coGenerator":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
                occurredSteamForSteam:
                  props.companyType === "paper-supplier"
                    ? { value: "" }
                    : { value: "0", error: false },
                occurredSteamForElectricPower:
                  props.companyType === "paper-supplier"
                    ? { value: "" }
                    : { value: "0", error: false },
              };
              break;
            case "solar":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "hydroPower":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "energySale":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "steamPurchase":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "greenCertificates":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            case "jCredits":
              form.energy[item] = {
                ...initEnergyForm()[item],
                isChecked: !form.energy[item].isChecked,
              };
              break;
            default:
              throw exhaustiveSwitchCase(item);
          }
        })
      );
  };

  const onClickEmissionCheckbox = (item: "emissionFactor") => {
    return () =>
      setForm(
        produce<GhgInputCategory1Form>((form) => {
          form.energy.steamPurchase[item] = {
            ...initEnergyForm().steamPurchase[item],
            isChecked: !form.energy.steamPurchase[item].isChecked,
          };
        })
      );
  };

  const bindTextField = useBindTextField(props.energy, (energyAction) =>
    setForm(
      produce<GhgInputCategory1Form>((form) => {
        if (energyAction instanceof Function) {
          form.energy = energyAction(form.energy);
        } else {
          form.energy = energyAction;
        }
      })
    )
  );

  const bindFuelInput = (facility: "boiler" | "generator" | "coGenerator") => {
    return (fuelType: FuelType) => {
      const bind = bindTextField({
        selector: (form) => form[facility].fuelConsumptions[fuelType],
        validator: FuelAmount.Rule,
        isRequired: true,
      });
      return {
        ...bind,
        maxLength: FuelAmount.MaxLength,
        errorMessage: FuelAmount.Message,
        disabled: !props.isEditable,
      };
    };
  };

  // EnergyTableInputのための関数
  const bindElectricPowerAmount = (
    index: number,
    inputName: "electricPowerPurchases" | "greenCertificates" | "jCredits"
  ) => {
    const bind = bindTextField({
      selector: (form) =>
        inputName === "electricPowerPurchases"
          ? form.electricPowerPurchases[index].amount
          : form[inputName].certificates[index].amount,
      validator: ElectricPowerAmount.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      maxLength: ElectricPowerAmount.MaxLength,
      disabled: !props.isEditable,
    };
  };
  const bindCo2EmissionFactor = (index: number) => {
    const bind = bindTextField({
      selector: (form) => form.electricPowerPurchases[index].emissionFactor,
      validator: Co2EmissionFactor.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      disabled: !props.isEditable,
    };
  };
  const bindSource = (index: number) => {
    const bind = bindTextField({
      selector: (form) => form.electricPowerPurchases[index].source,
      validator: Source.Rule,
      isRequired: true,
    });
    return {
      ...bind,
      disabled: !props.isEditable,
    };
  };
  const bindPowerType = (
    index: number,
    inputName: "greenCertificates" | "jCredits"
  ) => {
    const bind = bindTextField({
      selector: (form) => form[inputName].certificates[index].powerType,
      validator: PowerTypeDomain.Rule,
      isRequired: false,
    });
    return {
      ...bind,
      items: certificatesDropdownItems,
      disabled: !props.isEditable,
    };
  };
  const onClickAddButton = (
    inputName: "electricPowerPurchases" | "greenCertificates" | "jCredits"
  ) => {
    return () =>
      setForm(
        produce((form) => {
          inputName === "electricPowerPurchases"
            ? form.energy.electricPowerPurchases.push(
                initElectricPowerPurchaseForm()
              )
            : form.energy[inputName].certificates.push(initCertificateForm());
        })
      );
  };
  const onClickRemoveButton = (
    key: string,
    inputName: "electricPowerPurchases" | "greenCertificates" | "jCredits"
  ) => {
    return () => {
      setForm(
        produce((form) => {
          const array =
            inputName === "electricPowerPurchases"
              ? form.energy.electricPowerPurchases
              : form.energy[inputName].certificates;
          const index = array.findIndex((it) => it.key === key);
          if (index !== -1) {
            array.splice(index, 1);
          }
        })
      );
    };
  };

  const { openDialog: openBoilerDialog, dialogProps: boilerDialogProps } =
    useConfirmationDialog(messages.ghgInput.energy, onClickCheckbox("boiler"));

  const { openDialog: openGeneratorDialog, dialogProps: generatorDialogProps } =
    useConfirmationDialog(
      messages.ghgInput.energy,
      onClickCheckbox("generator")
    );

  const {
    openDialog: openCoGeneratorDialog,
    dialogProps: coGeneratorDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.energy,
    onClickCheckbox("coGenerator")
  );

  const { openDialog: openSolarDialog, dialogProps: solarDialogProps } =
    useConfirmationDialog(messages.ghgInput.energy, onClickCheckbox("solar"));

  const {
    openDialog: openHydroPowerDialog,
    dialogProps: hydroPowerDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.energy,
    onClickCheckbox("hydroPower")
  );

  const {
    openDialog: openEnergySaleDialog,
    dialogProps: energySaleDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.energy,
    onClickCheckbox("energySale")
  );

  const {
    openDialog: openSteamPurchaseDialog,
    dialogProps: steamPurchaseDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.energy,
    onClickCheckbox("steamPurchase")
  );

  const {
    openDialog: openGreenCertificatesDialog,
    dialogProps: greenCertificatesDialogProps,
  } = useConfirmationDialog(
    messages.ghgInput.energy,
    onClickCheckbox("greenCertificates")
  );

  const { openDialog: openJCreditsDialog, dialogProps: jCreditsDialogProps } =
    useConfirmationDialog(
      messages.ghgInput.energy,
      onClickCheckbox("jCredits")
    );

  return (
    <AppCard>
      <SaveAndRegisterButton
        justifyContent="flex-end"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
      />

      <SectionHeading title="外部からのエネルギー年間購入量">
        <TitledArea
          title={<Typography variant="h4">電力</Typography>}
          subtitle={
            <Annotate>
              出典には契約締結日付・契約締結会社・契約タイトルの3点でご入力ください。
            </Annotate>
          }
        >
          <TableInput
            firstInputColumn="購入電力量 [kWh/年]"
            secondInputColumn="CO2排出係数 [t-CO2eq/kWh]"
            thirdInputColumn="出典"
            isEditable={props.isEditable}
            onClickAddButton={onClickAddButton("electricPowerPurchases")}
          >
            <Box ml={2}>
              {props.energy.electricPowerPurchases.map(
                (electricPowerPurchase, index) => (
                  <TableRows
                    firstInput={
                      <Text
                        inputMode="numeric"
                        {...bindElectricPowerAmount(
                          index,
                          "electricPowerPurchases"
                        )}
                      />
                    }
                    firstInputErrorMessage={ElectricPowerAmount.Message}
                    firstInputHasError={
                      bindElectricPowerAmount(index, "electricPowerPurchases")
                        .hasError
                    }
                    secondInput={
                      <Text
                        inputMode="numeric"
                        {...bindCo2EmissionFactor(index)}
                      />
                    }
                    secondInputErrorMessage={Co2EmissionFactor.Message}
                    secondInputHasError={bindCo2EmissionFactor(index).hasError}
                    thirdInput={
                      <Text inputMode="text" {...bindSource(index)} />
                    }
                    thirdInputErrorMessage={Source.Message}
                    thirdInputHasError={bindSource(index).hasError}
                    onClickRemoveButton={onClickRemoveButton(
                      electricPowerPurchase.key,
                      "electricPowerPurchases"
                    )}
                    isLastRow={props.energy.electricPowerPurchases.length === 1}
                    isEditable={props.isEditable}
                    key={electricPowerPurchase.key}
                  />
                )
              )}
            </Box>
          </TableInput>
        </TitledArea>

        {props.companyType !== "paper-supplier" && (
          <Box mt={3}>
            <TitledArea
              title={
                <TitledAreaContents
                  title="蒸気（熱）"
                  onClick={
                    props.energy.steamPurchase.isChecked
                      ? openSteamPurchaseDialog
                      : onClickCheckbox("steamPurchase")
                  }
                  checked={props.energy.steamPurchase.isChecked}
                  disabled={!props.isEditable}
                />
              }
              collapse={!props.energy.steamPurchase.isChecked}
              subtitle={
                <Annotate>
                  自社で排出係数を把握している場合は値の入力を、それ以外の場合は「デフォルト値を利用」にチェックを入れてください。
                  <br />
                  デフォルト値はIDEAに基づいた値を利用しています。
                </Annotate>
              }
            >
              <Grid container spacing={3}>
                <Grid item lg={5} xs={12}>
                  <RowTextField
                    label="購入蒸気（熱）量 [t/年]"
                    {...bindTextField({
                      validator: SteamAmount.Rule,
                      selector: (form) => form.steamPurchase.steamAmount,
                      isRequired: true,
                    })}
                    maxLength={SteamAmount.MaxLength}
                    errorMessage={SteamAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                  <RowTextField
                    label="CO2排出係数 [t-CO2eq/t]"
                    {...bindTextField({
                      validator: Co2EmissionFactor.Rule,
                      selector: (form) =>
                        form.steamPurchase.emissionFactor.field,
                      isRequired: true,
                    })}
                    errorMessage={Co2EmissionFactor.Message}
                    disabled={
                      !props.isEditable ||
                      props.energy.steamPurchase.emissionFactor.isChecked
                    }
                    inputMode="numeric"
                    extraElement={
                      <CheckboxText
                        label="デフォルト値を利用"
                        isChecked={
                          props.energy.steamPurchase.emissionFactor.isChecked
                        }
                        onClick={onClickEmissionCheckbox("emissionFactor")}
                        disabled={!props.isEditable}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </TitledArea>
            <ConfirmationDialog {...steamPurchaseDialogProps} />
          </Box>
        )}
      </SectionHeading>

      <SectionHeading title="エネルギー設備" mt={3}>
        <TitledArea
          title={
            <TitledAreaContents
              title="蒸気（熱）供給システム向け投入燃料"
              onClick={
                props.energy.boiler.isChecked
                  ? openBoilerDialog
                  : onClickCheckbox("boiler")
              }
              checked={props.energy.boiler.isChecked}
              disabled={!props.isEditable}
            />
          }
          collapse={!props.energy.boiler.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備保有状況・投入燃料量">
                <FuelInput bindFuelInput={bindFuelInput("boiler")} />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {props.companyType === "paper-supplier" && (
                <SubSectionHeading title="エネルギー設備別発生エネルギー">
                  <RowTextField
                    label="蒸気（熱） [t/年]"
                    {...bindTextField({
                      validator: SteamAmount.Rule,
                      selector: (form) => form.boiler.occurredSteam,
                      isRequired: true,
                    })}
                    maxLength={SteamAmount.MaxLength}
                    errorMessage={SteamAmount.Message}
                    disabled={!props.isEditable}
                    inputMode="numeric"
                  />
                </SubSectionHeading>
              )}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...boilerDialogProps} />

        <TitledArea
          title={
            <TitledAreaContents
              title="コジェネレーションシステム向け投入燃料"
              onClick={
                props.energy.coGenerator.isChecked
                  ? openCoGeneratorDialog
                  : onClickCheckbox("coGenerator")
              }
              checked={props.energy.coGenerator.isChecked}
              disabled={!props.isEditable}
            />
          }
          mt={2}
          collapse={!props.energy.coGenerator.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備保有状況・投入燃料量">
                <FuelInput bindFuelInput={bindFuelInput("coGenerator")} />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備別発生エネルギー">
                {props.companyType === "paper-supplier" && (
                  <React.Fragment>
                    <RowTextField
                      label="蒸気（熱） [t/年]"
                      {...bindTextField({
                        validator: SteamAmount.Rule,
                        selector: (form) =>
                          form.coGenerator.occurredSteamForSteam,
                        isRequired: true,
                      })}
                      maxLength={SteamAmount.MaxLength}
                      errorMessage={SteamAmount.Message}
                      disabled={!props.isEditable}
                      inputMode="numeric"
                    />
                    <RowTextField
                      label="発電（熱） [t/年]"
                      {...bindTextField({
                        validator: SteamAmount.Rule,
                        selector: (form) =>
                          form.coGenerator.occurredSteamForElectricPower,
                        isRequired: true,
                      })}
                      maxLength={SteamAmount.MaxLength}
                      errorMessage={SteamAmount.Message}
                      disabled={!props.isEditable}
                      inputMode="numeric"
                    />
                  </React.Fragment>
                )}
                <RowTextField
                  label="電力 [kWh/年]"
                  {...bindTextField({
                    validator: ElectricPowerAmount.Rule,
                    selector: (form) => form.coGenerator.occurredElectricPower,
                    isRequired: true,
                  })}
                  maxLength={ElectricPowerAmount.MaxLength}
                  errorMessage={ElectricPowerAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...coGeneratorDialogProps} />

        <TitledArea
          title={
            <TitledAreaContents
              title="発電システム向け投入燃料"
              onClick={
                props.energy.generator.isChecked
                  ? openGeneratorDialog
                  : onClickCheckbox("generator")
              }
              checked={props.energy.generator.isChecked}
              disabled={!props.isEditable}
            />
          }
          mt={2}
          collapse={!props.energy.generator.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備保有状況・投入燃料量">
                <FuelInput bindFuelInput={bindFuelInput("generator")} />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備別発生エネルギー">
                <RowTextField
                  label="電力 [kWh/年]"
                  {...bindTextField({
                    validator: ElectricPowerAmount.Rule,
                    selector: (form) => form.generator.occurredElectricPower,
                    isRequired: true,
                  })}
                  maxLength={ElectricPowerAmount.MaxLength}
                  errorMessage={ElectricPowerAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...generatorDialogProps} />

        <TitledArea
          title={
            <TitledAreaContents
              title="太陽光発電システム"
              onClick={
                props.energy.solar.isChecked
                  ? openSolarDialog
                  : onClickCheckbox("solar")
              }
              checked={props.energy.solar.isChecked}
              disabled={!props.isEditable}
            />
          }
          mt={2}
          collapse={!props.energy.solar.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備保有状況・投入燃料量">
                <Box className={classes.disabled}>
                  <Typography>該当項目なし</Typography>
                </Box>
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備別発生エネルギー">
                <RowTextField
                  label="電力 [kWh/年]"
                  {...bindTextField({
                    validator: ElectricPowerAmount.Rule,
                    selector: (form) => form.solar.occurredElectricPower,
                    isRequired: true,
                  })}
                  maxLength={ElectricPowerAmount.MaxLength}
                  errorMessage={ElectricPowerAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...solarDialogProps} />

        <TitledArea
          title={
            <TitledAreaContents
              title="水力発電システム"
              onClick={
                props.energy.hydroPower.isChecked
                  ? openHydroPowerDialog
                  : onClickCheckbox("hydroPower")
              }
              checked={props.energy.hydroPower.isChecked}
              disabled={!props.isEditable}
            />
          }
          mt={2}
          collapse={!props.energy.hydroPower.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備保有状況・投入燃料量">
                <Box className={classes.disabled}>
                  <Typography>該当項目なし</Typography>
                </Box>
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SubSectionHeading title="エネルギー設備別発生エネルギー">
                <RowTextField
                  label="電力 [kWh/年]"
                  {...bindTextField({
                    validator: ElectricPowerAmount.Rule,
                    selector: (form) => form.hydroPower.occurredElectricPower,
                    isRequired: true,
                  })}
                  maxLength={ElectricPowerAmount.MaxLength}
                  errorMessage={ElectricPowerAmount.Message}
                  disabled={!props.isEditable}
                  inputMode="numeric"
                />
              </SubSectionHeading>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...hydroPowerDialogProps} />
      </SectionHeading>

      <SectionHeading
        title="外部へのエネルギー年間販売量"
        subtitle={
          <Annotate>
            関係会社への販売分についても「外部」に含まれますのでご入力をお願いします。
          </Annotate>
        }
        mt={3}
      >
        <TitledArea
          title={
            <TitledAreaContents
              title="外部へのエネルギー販売"
              onClick={
                props.energy.energySale.isChecked
                  ? openEnergySaleDialog
                  : onClickCheckbox("energySale")
              }
              checked={props.energy.energySale.isChecked}
              disabled={!props.isEditable}
            />
          }
          collapse={!props.energy.energySale.isChecked}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <RowTextField
                label="蒸気 [t/年]"
                {...bindTextField({
                  validator: SteamAmount.Rule,
                  selector: (form) => form.energySale.steam,
                  isRequired: true,
                })}
                maxLength={SteamAmount.MaxLength}
                errorMessage={SteamAmount.Message}
                disabled={!props.isEditable}
                inputMode="numeric"
              />
              <RowTextField
                label="電力 [kWh/年]"
                {...bindTextField({
                  validator: ElectricPowerAmount.Rule,
                  selector: (form) => form.energySale.electricPower,
                  isRequired: true,
                })}
                maxLength={ElectricPowerAmount.MaxLength}
                errorMessage={ElectricPowerAmount.Message}
                disabled={!props.isEditable}
                inputMode="numeric"
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </TitledArea>

        <ConfirmationDialog {...energySaleDialogProps} />
      </SectionHeading>

      <SectionHeading
        title="証書年間購入量"
        subtitle={
          <Annotate>
            付帯設備やその他製品のマシンへの割振り分は差し引かず、全量ご入力お願いします。
            <br />
            販売している場合は、購入量から販売量を差し引いた値をご入力お願いします。※購入量が０で販売している場合は、－（マイナス）で記載下さい。
          </Annotate>
        }
        mt={3}
      >
        <TitledArea
          title={
            <TitledAreaContents
              title="グリーン電力証書"
              onClick={
                props.energy.greenCertificates.isChecked
                  ? openGreenCertificatesDialog
                  : onClickCheckbox("greenCertificates")
              }
              checked={props.energy.greenCertificates.isChecked}
              disabled={!props.isEditable}
            />
          }
          collapse={!props.energy.greenCertificates.isChecked}
        >
          <EnergyTableInput
            firstInputColumn="電源種別"
            secondInputColumn="数量 [kWh/年]"
            onClickAddButton={onClickAddButton("greenCertificates")}
            isEditable={props.isEditable}
          >
            {props.energy.greenCertificates.certificates.map(
              (certificate, index) => (
                <EnergyTableRows
                  firstInput={
                    <Dropdown {...bindPowerType(index, "greenCertificates")} />
                  }
                  firstInputErrorMessage={PowerTypeDomain.Message}
                  firstInputHasError={
                    bindPowerType(index, "greenCertificates").hasError
                  }
                  secondInput={
                    <Text
                      {...bindElectricPowerAmount(index, "greenCertificates")}
                      inputMode="numeric"
                    />
                  }
                  secondInputErrorMessage={ElectricPowerAmount.Message}
                  secondInputHasError={
                    bindElectricPowerAmount(index, "greenCertificates").hasError
                  }
                  onClickRemoveButton={onClickRemoveButton(
                    certificate.key,
                    "greenCertificates"
                  )}
                  isLastRow={
                    props.energy.greenCertificates.certificates.length === 1
                  }
                  isEditable={props.isEditable}
                  key={certificate.key}
                />
              )
            )}
          </EnergyTableInput>
        </TitledArea>

        <ConfirmationDialog {...greenCertificatesDialogProps} />

        <TitledArea
          title={
            <TitledAreaContents
              title="J-クレジット（再エネ由来）"
              onClick={
                props.energy.jCredits.isChecked
                  ? openJCreditsDialog
                  : onClickCheckbox("jCredits")
              }
              checked={props.energy.jCredits.isChecked}
              disabled={!props.isEditable}
            />
          }
          mt={2}
          collapse={!props.energy.jCredits.isChecked}
        >
          <EnergyTableInput
            firstInputColumn="電源種別"
            secondInputColumn="数量 [kWh/年]"
            onClickAddButton={onClickAddButton("jCredits")}
            isEditable={props.isEditable}
          >
            {props.energy.jCredits.certificates.map((certificate, index) => (
              <EnergyTableRows
                firstInput={<Dropdown {...bindPowerType(index, "jCredits")} />}
                firstInputErrorMessage={PowerTypeDomain.Message}
                firstInputHasError={bindPowerType(index, "jCredits").hasError}
                secondInput={
                  <Text
                    {...bindElectricPowerAmount(index, "jCredits")}
                    inputMode="numeric"
                  />
                }
                secondInputErrorMessage={ElectricPowerAmount.Message}
                secondInputHasError={
                  bindElectricPowerAmount(index, "jCredits").hasError
                }
                onClickRemoveButton={onClickRemoveButton(
                  certificate.key,
                  "jCredits"
                )}
                isLastRow={props.energy.jCredits.certificates.length === 1}
                isEditable={props.isEditable}
                key={certificate.key}
              />
            ))}
          </EnergyTableInput>
        </TitledArea>

        <ConfirmationDialog {...jCreditsDialogProps} />
      </SectionHeading>

      <SaveAndRegisterButton
        justifyContent="center"
        saveButtonProps={props.saveButtonProps}
        registerButtonProps={props.registerButtonProps}
        mt={3}
      />
    </AppCard>
  );
};

// エネルギー設備燃料の入力項目
type FuelInputProps = {
  bindFuelInput: (fuelType: FuelType) => Partial<RowTextFieldProps>;
};
const FuelInput: React.FC<FuelInputProps> = (props) => {
  return (
    <Box>
      <RowTextField
        label="液化天然ガス [t/年]"
        {...props.bindFuelInput("lng")}
        inputMode="numeric"
      />
      <RowTextField
        label={
          <span>
            都市ガス [千m<sup>3</sup>/年]
          </span>
        }
        {...props.bindFuelInput("cityGas")}
        inputMode="numeric"
      />
      <RowTextField
        label={<span>LPG [t/年]</span>}
        {...props.bindFuelInput("lpg")}
        inputMode="numeric"
      />
      <RowTextField
        label="灯油 [kL/年]"
        {...props.bindFuelInput("kerosene")}
        inputMode="numeric"
      />
      <RowTextField
        label="軽油 [kL/年]"
        {...props.bindFuelInput("lightOil")}
        inputMode="numeric"
      />
      <RowTextField
        label="A重油 [kL/年]"
        {...props.bindFuelInput("aHeavyOil")}
        inputMode="numeric"
      />
      <RowTextField
        label="C重油 [kL/年]"
        {...props.bindFuelInput("cHeavyOil")}
        inputMode="numeric"
      />
      <RowTextField
        label="石炭 [t/年]"
        {...props.bindFuelInput("coal")}
        inputMode="numeric"
      />
      <RowTextField
        label="廃プラ/RDF [t/年]"
        {...props.bindFuelInput("rdf")}
        inputMode="numeric"
      />
      <RowTextField
        label="RPF [t/年]"
        {...props.bindFuelInput("rpf")}
        inputMode="numeric"
      />
      <RowTextField
        label={"バイオマス（黒液） \n[GJ/年]"}
        {...props.bindFuelInput("biomass")}
        inputMode="numeric"
      />
    </Box>
  );
};
