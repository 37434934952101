import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppTheme } from "src/app/configs";

export type RouteListTableProps = {
  data: RouteListRowType[];
};

export type RouteListRowType = {
  process: string;
  productName: string;
  productPurpose: string;
  factoryName: string;
  category1: number;
  category4: number;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
  table: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  thead: {
    backgroundColor: theme.palette.emissionUnitSummary,
  },
  wideTh: {
    width: "20%",
  },
  th: {
    width: "16%",
  },
}));

export const RouteListTable: React.FC<RouteListTableProps> = (props) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell className={classes.th}>
              <Typography variant="h5">工程</Typography>
            </TableCell>
            <TableCell className={classes.th}>
              <Typography variant="h5">製品</Typography>
            </TableCell>
            <TableCell className={classes.th}>
              <Typography variant="h5">製品区分</Typography>
            </TableCell>
            <TableCell className={classes.wideTh}>
              <Typography variant="h5">工場</Typography>
            </TableCell>
            <TableCell className={classes.th}>
              <Typography variant="h5">カテゴリ１</Typography>
            </TableCell>
            <TableCell className={classes.th}>
              <Typography variant="h5">カテゴリ４</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.length > 0 &&
            props.data.map((data) => (
              <TableRow
                key={
                  data.process +
                  data.productName +
                  data.productPurpose +
                  data.factoryName
                }
              >
                <TableCell>
                  <Typography variant="body2">{data.process}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{data.productName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{data.productPurpose}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{data.factoryName}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    {data.category1.toFixed(3)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    {data.category4.toFixed(3)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
