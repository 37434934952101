import {
  PostCalculationCategory1ProcessMutationError,
  PostCalculationCategory1ProcessMutationResult,
  postCalculationCategory1Process,
} from "src/app/apis/client/default";
import { PostCalculationCategory1ProcessParams } from "src/app/apis/model";
import { PostCalculationCategory1ProcessBody } from "src/app/apis/model/postCalculationCategory1ProcessBody";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class PostCalculationCategory1ProcessError extends ApiError<
  | "conflict"
  | "expired"
  | "not-authenticated"
  | "factory-not-found"
  | "network"
  | "unknown"
> {}

export class CalculationCategory1ProcessApi {
  async postCalculationCategory1Process(
    req: PostCalculationCategory1ProcessBody,
    params: PostCalculationCategory1ProcessParams
  ): Promise<PostCalculationCategory1ProcessMutationResult> {
    try {
      await postCalculationCategory1Process(req, params);
    } catch (err) {
      const e = err as PostCalculationCategory1ProcessMutationError;
      const errorCode = e.errorCode;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new PostCalculationCategory1ProcessError("network", err);
        case "ERR000002":
        case "ERR000003":
          throw new PostCalculationCategory1ProcessError(
            "not-authenticated",
            err
          );
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
        case "unknown":
          throw new PostCalculationCategory1ProcessError("unknown", err);
        case "ERR025001":
        case "ERR025002":
        case "ERR025005":
          throw new PostCalculationCategory1ProcessError(
            "factory-not-found",
            err
          );
        case "ERR025003":
          throw new PostCalculationCategory1ProcessError("expired", err);
        case "ERR025004":
          throw new PostCalculationCategory1ProcessError("conflict", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
