import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.text.primary,
  },
}));

export type SectionHeadingProps = {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
} & Omit<BoxProps, "title">;

export const SectionHeading: React.FC<SectionHeadingProps> = (props) => {
  const classes = useStyles();

  const { title, subtitle, children, ...restProps } = props;
  return (
    <Box {...restProps}>
      <Typography className={classes.heading} variant="h2">
        {title}
      </Typography>
      {subtitle !== undefined && <Box mt={1}>{subtitle}</Box>}
      <Box pt={3}>{children}</Box>
    </Box>
  );
};
