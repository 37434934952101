import { Constructor } from "type-fest";

export class BaseException extends Error {
  constructor(
    message?: string,
    readonly detail?: unknown,
    readonly cause?: unknown
  ) {
    super(message);
    // スタックトレースに表示される例外の名前を設定する (設定しないと"Error"になる)
    // クラス (継承されている場合はサブクラス) の名前に基づいて例外の名前を決める
    this.name = new.target.name;
    if (
      cause instanceof Error &&
      typeof this.stack === "string" &&
      typeof cause.stack === "string"
    ) {
      this.stack = [this.stack, `Caused By: ${cause.stack}`].join("\n");
    }
  }
}
export class TypedException<T extends string> extends BaseException {
  constructor(
    readonly type: T | "unexpected",
    detail: unknown,
    cause: unknown
  ) {
    super(type, detail, cause);
  }
}
export const wrapException = <E extends TypedException<T>, T extends string>(
  SubTypedException: Constructor<E, [T | "unexpected", unknown, unknown]>,
  err: unknown
): E => {
  return err instanceof SubTypedException
    ? err
    : new SubTypedException(
        "unexpected",
        { description: "wrapped unexpected exception" },
        err
      );
};
