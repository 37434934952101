import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FocusEventHandler } from "react";

import { Dropdown } from "src/app/components/atoms";
import { DropDownItem } from "src/lib/components/atoms";

const useStyles = makeStyles((theme) => ({
  dropDown: { minWidth: 120 },
}));

export type YearDropdownProps = {
  items: DropDownItem[];
  hasError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
};
export const YearDropdown: React.FC<YearDropdownProps> = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.dropDown}>
      <Dropdown
        items={props.items}
        hasError={props.hasError}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      />
    </Box>
  );
};
