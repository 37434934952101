import {
  GetScope3ByFactoryQueryError,
  GetScope3ByFactoryQueryResult,
  GetScope3ByRouteQueryError,
  GetScope3ByRouteQueryResult,
  OldGetScope3ByStandardQueryError,
  OldGetScope3ByStandardQueryResult,
  getScope3ByFactory,
  getScope3ByRoute,
  oldGetScope3ByStandard,
} from "src/app/apis/client/default";
import {
  GetScope3ByFactoryParams,
  GetScope3ByRouteParams,
  OldGetScope3ByStandardParams,
} from "src/app/apis/model";
import { ApiError, exhaustiveSwitchCase } from "src/lib/apis";

export class GetByFactoryApiError extends ApiError<
  "not-authenticated" | "network" | "unknown" | "no-data"
> {}

export class GetByStandardError extends ApiError<
  "not-authenticated" | "network" | "unknown" | "no-data"
> {}

export class GetByRouteError extends ApiError<
  "not-authenticated" | "network" | "unknown" | "no-data"
> {}

export class Scope3Api {
  async getByFactory(
    req: GetScope3ByFactoryParams
  ): Promise<GetScope3ByFactoryQueryResult> {
    try {
      const res = await getScope3ByFactory(req);
      return res;
    } catch (err) {
      const { errorCode } = err as GetScope3ByFactoryQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetByFactoryApiError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetByFactoryApiError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetByFactoryApiError("not-authenticated", err);
        case "ERR015000":
          throw new GetByFactoryApiError("no-data", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
  //TODO:画面実装時にOldから置き換える
  async getByStandard(
    req: OldGetScope3ByStandardParams
  ): Promise<OldGetScope3ByStandardQueryResult> {
    try {
      const res = await oldGetScope3ByStandard(req);
      return res;
    } catch (err) {
      const { errorCode } = err as OldGetScope3ByStandardQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetByStandardError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetByStandardError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetByStandardError("not-authenticated", err);
        case "ERR014000":
          throw new GetByStandardError("no-data", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }

  async getByRoute(
    req: GetScope3ByRouteParams
  ): Promise<GetScope3ByRouteQueryResult> {
    try {
      const res = await getScope3ByRoute(req);
      return res;
    } catch (err) {
      const { errorCode } = err as GetScope3ByRouteQueryError;
      switch (errorCode) {
        case "network":
        case "timeout":
          throw new GetByStandardError("network", err);
        case "unknown":
        case "ERR000000":
        case "ERR000001":
        case "ERR000004":
          throw new GetByStandardError("unknown", err);
        case "ERR000002":
        case "ERR000003":
          throw new GetByStandardError("not-authenticated", err);
        case "ERR016000":
          throw new GetByStandardError("no-data", err);
        default:
          throw exhaustiveSwitchCase(errorCode);
      }
    }
  }
}
