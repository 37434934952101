import BigNumber from "bignumber.js";

import { TransportFuelType } from "src/app/apis/model";
import { ProcurementBySupplierModel } from "src/app/models";
import { total } from "src/lib/utils/bignumberUtil";

export type Category4ProviderInput = {
  factoryId: string;
  deliveries: {
    destinationId: string;
    landDistance: BigNumber;
    seaDistance: BigNumber;
    transportByRail?: {
      railDistance: BigNumber;
      truckDistance: BigNumber;
      railTransportAmount?: BigNumber;
      isOther: boolean;
    };
  }[];
  fuelConsumptions: {
    transportFuelType: TransportFuelType;
    amount: BigNumber;
  }[];
  fuelEmissionFactors: Record<TransportFuelType, BigNumber>;
  railEmissionFactor: BigNumber;
  inputProcurementBySupplier: ProcurementBySupplierModel[];
  defaultTransport: {
    defaultLandDistance: BigNumber;
    defaultSeaDistance: BigNumber;
  };
  transportTotalAmount: BigNumber;
};

export class Category4Provider {
  compute(input: Category4ProviderInput): {
    factoryId: string;
    co2Emission: BigNumber;
    byDestination: {
      destinationId: string;
      tonKmBasedCo2Intensity?: BigNumber;
      weightBasedCo2Intensity?: BigNumber;
    }[];
  } {
    const landFuelConsumptions = input.fuelConsumptions.filter(
      (fuelConsumption) => fuelConsumption.transportFuelType !== "cHeavyOil"
    );
    const seaFuelConsumptions = input.fuelConsumptions.filter(
      (fuelConsumption) => fuelConsumption.transportFuelType === "cHeavyOil"
    );

    // CO2排出量を算出する
    const computeEmission = (fuelConsumption: {
      transportFuelType: TransportFuelType;
      amount: BigNumber;
    }) => {
      return fuelConsumption.amount.times(
        input.fuelEmissionFactors[fuelConsumption.transportFuelType]
      );
    };

    // 総排出量 [t-CO2eq]
    // 陸上輸送の総排出量を計算
    const landTotalEmission = total(
      ...landFuelConsumptions.map((fuelConsumption) =>
        computeEmission(fuelConsumption)
      )
    );
    // 海上輸送の総排出量を計算
    const seaTotalEmission = total(
      ...seaFuelConsumptions.map((fuelConsumption) =>
        computeEmission(fuelConsumption)
      )
    );
    // 鉄道輸送の総排出量を計算
    const railTotalEmission = total(
      ...input.deliveries.map((delivery) => {
        // 調達量を取得
        const weight = delivery.transportByRail?.isOther
          ? delivery.transportByRail?.railTransportAmount ?? new BigNumber(0) // その他の場合は鉄道輸送量を返す
          : total(
              ...input.inputProcurementBySupplier
                .filter(
                  (supplier) =>
                    supplier.destinationId === delivery.destinationId
                )
                .map((supplier) => supplier.weightBySupplierFactory) // 特定できる調達量を取得
            );

        const distance =
          delivery.transportByRail?.railDistance ?? new BigNumber(0); // 鉄道輸送がない場合は0を返す

        return weight.times(distance).times(input.railEmissionFactor); // 重さと距離と鉄道排出係数を掛け算
      })
    );

    //納入元工場別年間排出量
    const co2Emission = total(
      ...[landTotalEmission, seaTotalEmission, railTotalEmission]
    );

    //納入量元工場×納入先工場別の排出原単位

    //海上輸送の総トンキロ
    let seaProcurementWeight = new BigNumber(0);
    let seaTotalTonKilo = total(
      ...input.deliveries.map((delivery) => {
        // 特定できる調達量を取得
        const weight = total(
          ...input.inputProcurementBySupplier
            .filter(
              (supplier) => supplier.destinationId === delivery.destinationId
            )
            .map((supplier) => supplier.weightBySupplierFactory)
        );
        seaProcurementWeight = seaProcurementWeight.plus(weight);
        // 鉄道輸送がない場合はseaDistanceを使用
        const distance = delivery.transportByRail
          ? new BigNumber(0) // 鉄道輸送がある場合は0を返す
          : delivery.seaDistance;

        return weight.times(distance);
      })
    );
    seaTotalTonKilo = seaTotalTonKilo.plus(
      input.transportTotalAmount
        .minus(seaProcurementWeight)
        .times(input.defaultTransport.defaultSeaDistance)
    );

    //鉄道輸送の総トンキロ
    const railTotalTonKilo = total(
      ...input.deliveries.map((delivery) => {
        // 調達量を取得
        const weight = delivery.transportByRail?.isOther
          ? delivery.transportByRail?.railTransportAmount ?? new BigNumber(0) // その他の場合は鉄道輸送量を返す
          : total(
              ...input.inputProcurementBySupplier
                .filter(
                  (supplier) =>
                    supplier.destinationId === delivery.destinationId
                )
                .map((supplier) => supplier.weightBySupplierFactory) // 特定できる調達量を取得
            );

        const distance =
          delivery.transportByRail?.railDistance ?? new BigNumber(0); // 鉄道輸送がない場合は0を返す

        return weight.times(distance); // 重さと距離を掛け算
      })
    );

    // 陸上輸送の総トンキロ
    let landProcurementWeight = new BigNumber(0);
    let landTotalTonKilo = total(
      ...input.deliveries.map((delivery) => {
        // 特定できる調達量を取得
        const weight = total(
          ...input.inputProcurementBySupplier
            .filter(
              (supplier) => supplier.destinationId === delivery.destinationId
            )
            .map((supplier) => {
              return supplier.weightBySupplierFactory; // 特定できる調達量を取得
            })
        );
        landProcurementWeight = landProcurementWeight.plus(weight);

        const distance = delivery.transportByRail?.isOther
          ? new BigNumber(0) //その他はここでは扱わない
          : delivery.transportByRail?.truckDistance ?? // 鉄道輸送がある場合、鉄道前後のトラックの輸送距離を使う
            delivery.landDistance; // 鉄道輸送がない場合は、landDistanceを使用

        return weight.times(distance); // 重さと距離を掛け算
      })
    );
    landTotalTonKilo = landTotalTonKilo.plus(
      input.transportTotalAmount
        .minus(landProcurementWeight)
        .times(input.defaultTransport.defaultLandDistance)
    );

    //納入先別排出原単位の計算

    return {
      factoryId: input.factoryId,
      co2Emission: co2Emission,
      byDestination: (() => {
        const results = [];
        for (const delivery of input.deliveries) {
          // isOtherがtrueの場合はスキップ
          if (delivery.transportByRail?.isOther) {
            continue;
          }

          // 調達量の合計を計算
          const targetWeight = total(
            ...input.inputProcurementBySupplier
              .filter(
                (supplier) => supplier.destinationId === delivery.destinationId
              )
              .map((supplier) => supplier.weightBySupplierFactory)
          );

          // 納入先へのトンキロ [t km]
          //陸上輸送のトンキロ
          const landTargetTonKilo = targetWeight.times(
            // 鉄道輸送がある場合、鉄道前後のトラックの輸送距離を使う
            delivery.transportByRail?.truckDistance ?? delivery.landDistance
          );

          //海上輸送のトンキロ
          const seaTargetTonKilo = targetWeight.times(
            // 鉄道輸送がある場合、船舶輸送はしていないとみなす
            delivery.transportByRail === undefined ? delivery.seaDistance : 0
          );

          //鉄道輸送のトンキロ
          const railTargetTonKilo = targetWeight.times(
            // 鉄道輸送がある場合、鉄道輸送距離を使う
            delivery.transportByRail !== undefined
              ? delivery.transportByRail.railDistance
              : 0
          );
          const byDestination = this.computeEmissionAndIntensity({
            landTotalEmission,
            seaTotalEmission,
            landTotalTonKilo,
            seaTotalTonKilo,
            railTotalEmission,
            railTotalTonKilo,
            landTargetTonKilo: landTargetTonKilo,
            seaTargetTonKilo: seaTargetTonKilo,
            railTargetTonKilo: railTargetTonKilo,
            targetWeight: targetWeight,
          });

          results.push({
            ...byDestination,
            destinationId: delivery.destinationId,
          });
        }
        return results;
      })(),
    };
  }

  computeEmissionAndIntensity(input: {
    landTotalEmission: BigNumber;
    landTotalTonKilo: BigNumber;
    landTargetTonKilo: BigNumber;
    seaTotalEmission: BigNumber;
    seaTotalTonKilo: BigNumber;
    seaTargetTonKilo: BigNumber;
    railTotalEmission: BigNumber;
    railTotalTonKilo: BigNumber;
    railTargetTonKilo: BigNumber;
    targetWeight: BigNumber;
  }): {
    tonKmBasedCo2Intensity?: BigNumber;
    weightBasedCo2Intensity?: BigNumber;
  } {
    const {
      landTotalEmission,
      landTotalTonKilo,
      landTargetTonKilo,
      seaTotalEmission,
      seaTotalTonKilo,
      seaTargetTonKilo,
      railTotalEmission,
      railTotalTonKilo,
      railTargetTonKilo,
      targetWeight,
    } = input;

    const computeEmission = (input: {
      totalEmission: BigNumber;
      totalTonKilo: BigNumber;
      targetTonKilo: BigNumber;
    }): BigNumber => {
      return input.totalTonKilo.isZero()
        ? new BigNumber(0)
        : input.totalEmission
            .times(input.targetTonKilo)
            .div(input.totalTonKilo);
    };
    //納入先への陸上輸送由来排出量
    const targetLandCo2Emission = computeEmission({
      totalEmission: landTotalEmission,
      totalTonKilo: landTotalTonKilo,
      targetTonKilo: landTargetTonKilo,
    });

    //納入先への海上輸送由来排出量
    const targetSeaCo2Emission = computeEmission({
      totalEmission: seaTotalEmission,
      totalTonKilo: seaTotalTonKilo,
      targetTonKilo: seaTargetTonKilo,
    });

    //納入先への鉄道輸送由来排出量
    const targetRailCo2Emission = computeEmission({
      totalEmission: railTotalEmission,
      totalTonKilo: railTotalTonKilo,
      targetTonKilo: railTargetTonKilo,
    });

    const targetCo2Emission = total(
      ...[targetLandCo2Emission, targetSeaCo2Emission, targetRailCo2Emission]
    );

    const targetTonKilo = total(
      ...[landTargetTonKilo, seaTargetTonKilo, railTargetTonKilo]
    );

    return {
      tonKmBasedCo2Intensity: targetTonKilo.isZero()
        ? undefined
        : targetCo2Emission.div(targetTonKilo).times(1000),
      weightBasedCo2Intensity: targetWeight.isZero()
        ? undefined
        : targetCo2Emission.div(targetWeight).times(1000),
    };
  }
}
