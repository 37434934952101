import { Box } from "@mui/material";
import React from "react";

/**
 * 画面コンテンツを覆うためのコンポーネント
 * 全画面に共通の以下の機能を持つ
 *
 * ・レスポンシブにコンテンツ幅を変化させる
 */
export type ContentProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Content: React.FC<ContentProps> = (props) => {
  return (
    <Box
      sx={{
        // 画面共通の余白を設定
        pt: 3,
        px: 5,
        // Content は左右中央に配置する
        mx: "auto",
        // 原則として横幅画面いっぱいに広がる。
        width: "100%",
        // ただし、画面が横に広がりすぎないようにする。
        maxWidth: 40 + 1360 + 40, // 1440px
      }}
      className={props.className}
    >
      {props.children}
    </Box>
  );
};
