import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

export const useRouteMatch = (paths: string[]): string | undefined => {
  const { pathname } = useLocation();

  return useMemo(() => {
    return paths.find((path) => !!matchPath(path, pathname));
  }, [pathname, paths]);
};
