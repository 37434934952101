import { useState } from "react";

import { ErrorDialogProps } from "src/app/components/organisms";

export const useErrorDialog = (): {
  openDialog: (message: string) => void;
  dialogProps: ErrorDialogProps;
} => {
  const [errorDialogProps, setErrorDialogProps] = useState<{
    message: string;
    open: boolean;
  }>({
    message: "",
    open: false,
  });
  const onClickClose = () => {
    setErrorDialogProps({ message: errorDialogProps.message, open: false });
  };

  return {
    openDialog: (message) => {
      setErrorDialogProps({
        message: message,
        open: true,
      });
    },
    dialogProps: { ...errorDialogProps, onClickClose },
  };
};
