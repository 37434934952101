import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  eyeButton: {
    padding: 0,
    color: theme.palette.text.secondary,
  },
}));

export const EyeButton = (props: { visible: boolean; onClick: () => void }) => {
  const classes = useStyles();
  return (
    <InputAdornment position="end">
      <IconButton
        className={classes.eyeButton}
        onClick={props.onClick}
        size="large"
      >
        {props.visible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};
