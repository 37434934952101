import { TextField, TextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useState } from "react";

import { EyeButton } from "src/lib/components/atoms";

// https://developer.mozilla.org/ja/docs/Web/HTML/Attributes/autocomplete#Browser_compatibility
export type AutoCompleteType =
  | "username"
  | "email"
  | "current-password"
  | "new-password";

// https://developer.mozilla.org/ja/docs/Web/HTML/Global_attributes/inputmode
export type InputModeOptions =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";

export type AdditionalProps = {
  maxLength?: number;
  secure?: boolean;
  autoComplete?: AutoCompleteType;
  inputMode?: InputModeOptions;
  value?: string;
  hasError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export type TextInputProps = Omit<
  TextFieldProps,
  keyof AdditionalProps | "type"
> &
  AdditionalProps;

const useStyles = makeStyles(() => ({
  input: {
    // IE独自のパスワード表示ボタンを非表示にする
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&::-ms-reveal": {
      display: "none",
    },
    // IE独自のクリアボタンを非表示にする
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    maxLength,
    secure,
    autoComplete,
    inputMode,
    value,
    hasError,
    onChange,
    onBlur,
    ...restProps
  } = props;

  const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const onClickEye = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <TextField
      {...restProps}
      type={visible || !props.secure ? "text" : "password"}
      inputProps={{
        ...{
          maxLength: props.maxLength,
          className: classes.input,
          inputMode: props.inputMode,
        },
        ...props.inputProps,
        // NOTE : 数値の場合に入力文字を右寄せにする
        ...(inputMode === "numeric" && { style: { textAlign: "right" } }),
      }}
      InputProps={{
        ...{
          endAdornment: props.secure && (
            <EyeButton onClick={onClickEye} visible={visible} />
          ),
        },
        ...props.InputProps,
      }}
      autoComplete={props.autoComplete}
      value={props.value}
      error={props.hasError}
      onChange={props.onChange}
      onBlur={props.onBlur}
      fullWidth
    />
  );
};
