import {
  generatePath,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  GetByFactoryApiError,
  GetByStandardError,
  Scope3Api,
} from "src/app/apis/scope3-api/scope3Api";
import { UserCategoryNavigationTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { useRouteMatch } from "src/app/hooks";
import { useDefaultYear, useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { userCategoryTabNames } from "src/app/navigation/tabs";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";

export type UserChartAndTableType =
  | "category1TotalEmission"
  | "category1EmissionByCase"
  | "category1EmissionByFactory"
  | "category4TotalEmission"
  | "category4EmissionByCase"
  | "category4EmissionByFactory";

export const UserCategoryNavigationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useAuthState<AuthState>();
  const snackbar = useCustomSnackbar();

  const yearDropdownProps = useYearDropdown();

  //ByStandardApi

  const { isProcessing: isByStandardProcessing, data: byStandardData } =
    useAsyncEffect(async () => {
      if (yearDropdownProps?.value === undefined) {
        return;
      }

      const scope3Api = new Scope3Api();

      try {
        return await scope3Api.getByStandard({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      } catch (err) {
        if (!(err instanceof GetByStandardError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            break;
          case "network":
            snackbar.error(messages.common.network, {
              preventDuplicate: true,
            });
            break;
          case "unknown":
            snackbar.error(messages.common.unknown, {
              preventDuplicate: true,
            });
            break;
          case "no-data":
            break;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }, [yearDropdownProps?.value]);

  //ByFactoryApi

  const { isProcessing: isByFactoryProcessing, data: byFactoryData } =
    useAsyncEffect(async () => {
      if (yearDropdownProps?.year === undefined) {
        return;
      }

      const byFactoryApi = new Scope3Api();

      try {
        return await byFactoryApi.getByFactory({
          year: yearDropdownProps.year.toFormat("yyyy"),
        });
      } catch (err) {
        if (!(err instanceof GetByFactoryApiError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            break;
          case "network":
            snackbar.error(messages.common.network, {
              preventDuplicate: true,
            });
            break;
          case "unknown":
            snackbar.error(messages.common.unknown, {
              preventDuplicate: true,
            });
            break;
          case "no-data":
            break;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }, [yearDropdownProps?.value]);

  const params = useParams();
  const match = useRouteMatch([
    paths.user.category1.tab,
    paths.user.category4.tab,
  ]);
  const [defaultYear] = useDefaultYear();

  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return (
      <Navigate
        to={generatePath(match ?? paths.user.category1.tab, {
          tab: params.tab ?? null,
          year: defaultYear.toFormat("yyyy"),
        })}
      />
    );
  }

  if (authState === undefined || authState.role !== "user-company") {
    return <Navigate to="/login" />;
  }

  const tabList = Object.values(userCategoryTabNames);
  const invalidUrl = tabList.every(
    (val) => location.pathname.endsWith(`/${val}`) === false
  );

  return (
    <UserCategoryNavigationTemplate
      isProcessing={isByFactoryProcessing || isByStandardProcessing}
      yearDropdownProps={yearDropdownProps}
      byFactoryData={byFactoryData}
      byStandardData={byStandardData}
      tabItemProps={{
        category1TotalEmission: {
          onClick: () =>
            navigate(
              `/user/category1/${yearDropdownProps.value}/total-emission`
            ),
          selected:
            location.pathname.startsWith("/user/category1/") &&
            location.pathname.endsWith("/total-emission"),
        },
        category1EmissionByCase: {
          onClick: () =>
            navigate(
              `/user/category1/${yearDropdownProps.value}/emission-by-standard`
            ),
          selected:
            location.pathname.startsWith("/user/category1/") &&
            location.pathname.endsWith("/emission-by-standard"),
        },
        category1EmissionByFactory: {
          onClick: () =>
            navigate(
              `/user/category1/${yearDropdownProps.value}/emission-by-factory`
            ),
          selected:
            location.pathname.startsWith("/user/category1/") &&
            location.pathname.endsWith("/emission-by-factory"),
        },
        category4TotalEmission: {
          onClick: () =>
            navigate(
              `/user/category4/${yearDropdownProps.value}/total-emission`
            ),
          selected:
            location.pathname.startsWith("/user/category4/") &&
            location.pathname.endsWith("/total-emission"),
        },
        category4EmissionByCase: {
          onClick: () =>
            navigate(
              `/user/category4/${yearDropdownProps.value}/emission-by-standard`
            ),
          selected:
            location.pathname.startsWith("/user/category4/") &&
            location.pathname.endsWith("/emission-by-standard"),
        },
        category4EmissionByFactory: {
          onClick: () =>
            navigate(
              `/user/category4/${yearDropdownProps.value}/emission-by-factory`
            ),
          selected:
            location.pathname.startsWith("/user/category4/") &&
            location.pathname.endsWith("/emission-by-factory"),
        },
      }}
      invalidUrl={invalidUrl}
    />
  );
};
